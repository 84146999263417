.loader_loader__jIGs_ {
  margin: auto;
  text-align: center;
}

.loader_loader__jIGs_ i {
  color: #e23e57;
  font-size: 32px;
}

.Toastify__toast-container {
  z-index: 9999;
  -webkit-transform: translate3d(0, 0, 9999px);
  position: fixed;
  padding: 4px;
  width: 320px;
  box-sizing: border-box;
  color: #fff;
}
.Toastify__toast-container--top-left {
  top: 1em;
  left: 1em;
}
.Toastify__toast-container--top-center {
  top: 1em;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
}
.Toastify__toast-container--top-right {
  top: 1em;
  right: 1em;
}
.Toastify__toast-container--bottom-left {
  bottom: 1em;
  left: 1em;
}
.Toastify__toast-container--bottom-center {
  bottom: 1em;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
}
.Toastify__toast-container--bottom-right {
  bottom: 1em;
  right: 1em;
}

@media only screen and (max-width : 480px) {
  .Toastify__toast-container {
    width: 100vw;
    padding: 0;
    left: 0;
    margin: 0;
  }
  .Toastify__toast-container--top-left, .Toastify__toast-container--top-center, .Toastify__toast-container--top-right {
    top: 0;
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
  .Toastify__toast-container--bottom-left, .Toastify__toast-container--bottom-center, .Toastify__toast-container--bottom-right {
    bottom: 0;
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
  .Toastify__toast-container--rtl {
    right: 0;
    left: auto;
    left: initial;
  }
}
.Toastify__toast {
  position: relative;
  min-height: 64px;
  box-sizing: border-box;
  margin-bottom: 1rem;
  padding: 8px;
  border-radius: 1px;
  box-shadow: 0 1px 10px 0 rgba(0, 0, 0, 0.1), 0 2px 15px 0 rgba(0, 0, 0, 0.05);
  display: flex;
  justify-content: space-between;
  max-height: 800px;
  overflow: hidden;
  font-family: sans-serif;
  cursor: pointer;
  direction: ltr;
}
.Toastify__toast--rtl {
  direction: rtl;
}
.Toastify__toast--dark {
  background: #121212;
  color: #fff;
}
.Toastify__toast--default {
  background: #fff;
  color: #aaa;
}
.Toastify__toast--info {
  background: #3498db;
}
.Toastify__toast--success {
  background: #07bc0c;
}
.Toastify__toast--warning {
  background: #f1c40f;
}
.Toastify__toast--error {
  background: #e74c3c;
}
.Toastify__toast-body {
  margin: auto 0;
  flex: 1 1 auto;
}

@media only screen and (max-width : 480px) {
  .Toastify__toast {
    margin-bottom: 0;
  }
}
.Toastify__close-button {
  color: #fff;
  background: transparent;
  outline: none;
  border: none;
  padding: 0;
  cursor: pointer;
  opacity: 0.7;
  transition: 0.3s ease;
  align-self: flex-start;
}
.Toastify__close-button--default {
  color: #000;
  opacity: 0.3;
}
.Toastify__close-button > svg {
  fill: currentColor;
  height: 16px;
  width: 14px;
}
.Toastify__close-button:hover, .Toastify__close-button:focus {
  opacity: 1;
}

@-webkit-keyframes Toastify__trackProgress {
  0% {
    -webkit-transform: scaleX(1);
            transform: scaleX(1);
  }
  100% {
    -webkit-transform: scaleX(0);
            transform: scaleX(0);
  }
}

@keyframes Toastify__trackProgress {
  0% {
    -webkit-transform: scaleX(1);
            transform: scaleX(1);
  }
  100% {
    -webkit-transform: scaleX(0);
            transform: scaleX(0);
  }
}
.Toastify__progress-bar {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 5px;
  z-index: 9999;
  opacity: 0.7;
  background-color: rgba(255, 255, 255, 0.7);
  -webkit-transform-origin: left;
          transform-origin: left;
}
.Toastify__progress-bar--animated {
  -webkit-animation: Toastify__trackProgress linear 1 forwards;
          animation: Toastify__trackProgress linear 1 forwards;
}
.Toastify__progress-bar--controlled {
  transition: -webkit-transform 0.2s;
  transition: transform 0.2s;
  transition: transform 0.2s, -webkit-transform 0.2s;
}
.Toastify__progress-bar--rtl {
  right: 0;
  left: auto;
  left: initial;
  -webkit-transform-origin: right;
          transform-origin: right;
}
.Toastify__progress-bar--default {
  background: linear-gradient(to right, #4cd964, #5ac8fa, #007aff, #34aadc, #5856d6, #ff2d55);
}
.Toastify__progress-bar--dark {
  background: #bb86fc;
}
@-webkit-keyframes Toastify__bounceInRight {
  from, 60%, 75%, 90%, to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
            animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  from {
    opacity: 0;
    -webkit-transform: translate3d(3000px, 0, 0);
            transform: translate3d(3000px, 0, 0);
  }
  60% {
    opacity: 1;
    -webkit-transform: translate3d(-25px, 0, 0);
            transform: translate3d(-25px, 0, 0);
  }
  75% {
    -webkit-transform: translate3d(10px, 0, 0);
            transform: translate3d(10px, 0, 0);
  }
  90% {
    -webkit-transform: translate3d(-5px, 0, 0);
            transform: translate3d(-5px, 0, 0);
  }
  to {
    -webkit-transform: none;
            transform: none;
  }
}
@keyframes Toastify__bounceInRight {
  from, 60%, 75%, 90%, to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
            animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  from {
    opacity: 0;
    -webkit-transform: translate3d(3000px, 0, 0);
            transform: translate3d(3000px, 0, 0);
  }
  60% {
    opacity: 1;
    -webkit-transform: translate3d(-25px, 0, 0);
            transform: translate3d(-25px, 0, 0);
  }
  75% {
    -webkit-transform: translate3d(10px, 0, 0);
            transform: translate3d(10px, 0, 0);
  }
  90% {
    -webkit-transform: translate3d(-5px, 0, 0);
            transform: translate3d(-5px, 0, 0);
  }
  to {
    -webkit-transform: none;
            transform: none;
  }
}
@-webkit-keyframes Toastify__bounceOutRight {
  20% {
    opacity: 1;
    -webkit-transform: translate3d(-20px, 0, 0);
            transform: translate3d(-20px, 0, 0);
  }
  to {
    opacity: 0;
    -webkit-transform: translate3d(2000px, 0, 0);
            transform: translate3d(2000px, 0, 0);
  }
}
@keyframes Toastify__bounceOutRight {
  20% {
    opacity: 1;
    -webkit-transform: translate3d(-20px, 0, 0);
            transform: translate3d(-20px, 0, 0);
  }
  to {
    opacity: 0;
    -webkit-transform: translate3d(2000px, 0, 0);
            transform: translate3d(2000px, 0, 0);
  }
}
@-webkit-keyframes Toastify__bounceInLeft {
  from, 60%, 75%, 90%, to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
            animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  0% {
    opacity: 0;
    -webkit-transform: translate3d(-3000px, 0, 0);
            transform: translate3d(-3000px, 0, 0);
  }
  60% {
    opacity: 1;
    -webkit-transform: translate3d(25px, 0, 0);
            transform: translate3d(25px, 0, 0);
  }
  75% {
    -webkit-transform: translate3d(-10px, 0, 0);
            transform: translate3d(-10px, 0, 0);
  }
  90% {
    -webkit-transform: translate3d(5px, 0, 0);
            transform: translate3d(5px, 0, 0);
  }
  to {
    -webkit-transform: none;
            transform: none;
  }
}
@keyframes Toastify__bounceInLeft {
  from, 60%, 75%, 90%, to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
            animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  0% {
    opacity: 0;
    -webkit-transform: translate3d(-3000px, 0, 0);
            transform: translate3d(-3000px, 0, 0);
  }
  60% {
    opacity: 1;
    -webkit-transform: translate3d(25px, 0, 0);
            transform: translate3d(25px, 0, 0);
  }
  75% {
    -webkit-transform: translate3d(-10px, 0, 0);
            transform: translate3d(-10px, 0, 0);
  }
  90% {
    -webkit-transform: translate3d(5px, 0, 0);
            transform: translate3d(5px, 0, 0);
  }
  to {
    -webkit-transform: none;
            transform: none;
  }
}
@-webkit-keyframes Toastify__bounceOutLeft {
  20% {
    opacity: 1;
    -webkit-transform: translate3d(20px, 0, 0);
            transform: translate3d(20px, 0, 0);
  }
  to {
    opacity: 0;
    -webkit-transform: translate3d(-2000px, 0, 0);
            transform: translate3d(-2000px, 0, 0);
  }
}
@keyframes Toastify__bounceOutLeft {
  20% {
    opacity: 1;
    -webkit-transform: translate3d(20px, 0, 0);
            transform: translate3d(20px, 0, 0);
  }
  to {
    opacity: 0;
    -webkit-transform: translate3d(-2000px, 0, 0);
            transform: translate3d(-2000px, 0, 0);
  }
}
@-webkit-keyframes Toastify__bounceInUp {
  from, 60%, 75%, 90%, to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
            animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 3000px, 0);
            transform: translate3d(0, 3000px, 0);
  }
  60% {
    opacity: 1;
    -webkit-transform: translate3d(0, -20px, 0);
            transform: translate3d(0, -20px, 0);
  }
  75% {
    -webkit-transform: translate3d(0, 10px, 0);
            transform: translate3d(0, 10px, 0);
  }
  90% {
    -webkit-transform: translate3d(0, -5px, 0);
            transform: translate3d(0, -5px, 0);
  }
  to {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
  }
}
@keyframes Toastify__bounceInUp {
  from, 60%, 75%, 90%, to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
            animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 3000px, 0);
            transform: translate3d(0, 3000px, 0);
  }
  60% {
    opacity: 1;
    -webkit-transform: translate3d(0, -20px, 0);
            transform: translate3d(0, -20px, 0);
  }
  75% {
    -webkit-transform: translate3d(0, 10px, 0);
            transform: translate3d(0, 10px, 0);
  }
  90% {
    -webkit-transform: translate3d(0, -5px, 0);
            transform: translate3d(0, -5px, 0);
  }
  to {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
  }
}
@-webkit-keyframes Toastify__bounceOutUp {
  20% {
    -webkit-transform: translate3d(0, -10px, 0);
            transform: translate3d(0, -10px, 0);
  }
  40%, 45% {
    opacity: 1;
    -webkit-transform: translate3d(0, 20px, 0);
            transform: translate3d(0, 20px, 0);
  }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, -2000px, 0);
            transform: translate3d(0, -2000px, 0);
  }
}
@keyframes Toastify__bounceOutUp {
  20% {
    -webkit-transform: translate3d(0, -10px, 0);
            transform: translate3d(0, -10px, 0);
  }
  40%, 45% {
    opacity: 1;
    -webkit-transform: translate3d(0, 20px, 0);
            transform: translate3d(0, 20px, 0);
  }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, -2000px, 0);
            transform: translate3d(0, -2000px, 0);
  }
}
@-webkit-keyframes Toastify__bounceInDown {
  from, 60%, 75%, 90%, to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
            animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, -3000px, 0);
            transform: translate3d(0, -3000px, 0);
  }
  60% {
    opacity: 1;
    -webkit-transform: translate3d(0, 25px, 0);
            transform: translate3d(0, 25px, 0);
  }
  75% {
    -webkit-transform: translate3d(0, -10px, 0);
            transform: translate3d(0, -10px, 0);
  }
  90% {
    -webkit-transform: translate3d(0, 5px, 0);
            transform: translate3d(0, 5px, 0);
  }
  to {
    -webkit-transform: none;
            transform: none;
  }
}
@keyframes Toastify__bounceInDown {
  from, 60%, 75%, 90%, to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
            animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, -3000px, 0);
            transform: translate3d(0, -3000px, 0);
  }
  60% {
    opacity: 1;
    -webkit-transform: translate3d(0, 25px, 0);
            transform: translate3d(0, 25px, 0);
  }
  75% {
    -webkit-transform: translate3d(0, -10px, 0);
            transform: translate3d(0, -10px, 0);
  }
  90% {
    -webkit-transform: translate3d(0, 5px, 0);
            transform: translate3d(0, 5px, 0);
  }
  to {
    -webkit-transform: none;
            transform: none;
  }
}
@-webkit-keyframes Toastify__bounceOutDown {
  20% {
    -webkit-transform: translate3d(0, 10px, 0);
            transform: translate3d(0, 10px, 0);
  }
  40%, 45% {
    opacity: 1;
    -webkit-transform: translate3d(0, -20px, 0);
            transform: translate3d(0, -20px, 0);
  }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, 2000px, 0);
            transform: translate3d(0, 2000px, 0);
  }
}
@keyframes Toastify__bounceOutDown {
  20% {
    -webkit-transform: translate3d(0, 10px, 0);
            transform: translate3d(0, 10px, 0);
  }
  40%, 45% {
    opacity: 1;
    -webkit-transform: translate3d(0, -20px, 0);
            transform: translate3d(0, -20px, 0);
  }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, 2000px, 0);
            transform: translate3d(0, 2000px, 0);
  }
}
.Toastify__bounce-enter--top-left, .Toastify__bounce-enter--bottom-left {
  -webkit-animation-name: Toastify__bounceInLeft;
          animation-name: Toastify__bounceInLeft;
}
.Toastify__bounce-enter--top-right, .Toastify__bounce-enter--bottom-right {
  -webkit-animation-name: Toastify__bounceInRight;
          animation-name: Toastify__bounceInRight;
}
.Toastify__bounce-enter--top-center {
  -webkit-animation-name: Toastify__bounceInDown;
          animation-name: Toastify__bounceInDown;
}
.Toastify__bounce-enter--bottom-center {
  -webkit-animation-name: Toastify__bounceInUp;
          animation-name: Toastify__bounceInUp;
}

.Toastify__bounce-exit--top-left, .Toastify__bounce-exit--bottom-left {
  -webkit-animation-name: Toastify__bounceOutLeft;
          animation-name: Toastify__bounceOutLeft;
}
.Toastify__bounce-exit--top-right, .Toastify__bounce-exit--bottom-right {
  -webkit-animation-name: Toastify__bounceOutRight;
          animation-name: Toastify__bounceOutRight;
}
.Toastify__bounce-exit--top-center {
  -webkit-animation-name: Toastify__bounceOutUp;
          animation-name: Toastify__bounceOutUp;
}
.Toastify__bounce-exit--bottom-center {
  -webkit-animation-name: Toastify__bounceOutDown;
          animation-name: Toastify__bounceOutDown;
}

@-webkit-keyframes Toastify__zoomIn {
  from {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
            transform: scale3d(0.3, 0.3, 0.3);
  }
  50% {
    opacity: 1;
  }
}

@keyframes Toastify__zoomIn {
  from {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
            transform: scale3d(0.3, 0.3, 0.3);
  }
  50% {
    opacity: 1;
  }
}
@-webkit-keyframes Toastify__zoomOut {
  from {
    opacity: 1;
  }
  50% {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
            transform: scale3d(0.3, 0.3, 0.3);
  }
  to {
    opacity: 0;
  }
}
@keyframes Toastify__zoomOut {
  from {
    opacity: 1;
  }
  50% {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
            transform: scale3d(0.3, 0.3, 0.3);
  }
  to {
    opacity: 0;
  }
}
.Toastify__zoom-enter {
  -webkit-animation-name: Toastify__zoomIn;
          animation-name: Toastify__zoomIn;
}

.Toastify__zoom-exit {
  -webkit-animation-name: Toastify__zoomOut;
          animation-name: Toastify__zoomOut;
}

@-webkit-keyframes Toastify__flipIn {
  from {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
            transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
    opacity: 0;
  }
  40% {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
            transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
  }
  60% {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, 10deg);
            transform: perspective(400px) rotate3d(1, 0, 0, 10deg);
    opacity: 1;
  }
  80% {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, -5deg);
            transform: perspective(400px) rotate3d(1, 0, 0, -5deg);
  }
  to {
    -webkit-transform: perspective(400px);
            transform: perspective(400px);
  }
}

@keyframes Toastify__flipIn {
  from {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
            transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
    opacity: 0;
  }
  40% {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
            transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
  }
  60% {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, 10deg);
            transform: perspective(400px) rotate3d(1, 0, 0, 10deg);
    opacity: 1;
  }
  80% {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, -5deg);
            transform: perspective(400px) rotate3d(1, 0, 0, -5deg);
  }
  to {
    -webkit-transform: perspective(400px);
            transform: perspective(400px);
  }
}
@-webkit-keyframes Toastify__flipOut {
  from {
    -webkit-transform: perspective(400px);
            transform: perspective(400px);
  }
  30% {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
            transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
    opacity: 1;
  }
  to {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
            transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
    opacity: 0;
  }
}
@keyframes Toastify__flipOut {
  from {
    -webkit-transform: perspective(400px);
            transform: perspective(400px);
  }
  30% {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
            transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
    opacity: 1;
  }
  to {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
            transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
    opacity: 0;
  }
}
.Toastify__flip-enter {
  -webkit-animation-name: Toastify__flipIn;
          animation-name: Toastify__flipIn;
}

.Toastify__flip-exit {
  -webkit-animation-name: Toastify__flipOut;
          animation-name: Toastify__flipOut;
}

@-webkit-keyframes Toastify__slideInRight {
  from {
    -webkit-transform: translate3d(110%, 0, 0);
            transform: translate3d(110%, 0, 0);
    visibility: visible;
  }
  to {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
  }
}

@keyframes Toastify__slideInRight {
  from {
    -webkit-transform: translate3d(110%, 0, 0);
            transform: translate3d(110%, 0, 0);
    visibility: visible;
  }
  to {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
  }
}
@-webkit-keyframes Toastify__slideInLeft {
  from {
    -webkit-transform: translate3d(-110%, 0, 0);
            transform: translate3d(-110%, 0, 0);
    visibility: visible;
  }
  to {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
  }
}
@keyframes Toastify__slideInLeft {
  from {
    -webkit-transform: translate3d(-110%, 0, 0);
            transform: translate3d(-110%, 0, 0);
    visibility: visible;
  }
  to {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
  }
}
@-webkit-keyframes Toastify__slideInUp {
  from {
    -webkit-transform: translate3d(0, 110%, 0);
            transform: translate3d(0, 110%, 0);
    visibility: visible;
  }
  to {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
  }
}
@keyframes Toastify__slideInUp {
  from {
    -webkit-transform: translate3d(0, 110%, 0);
            transform: translate3d(0, 110%, 0);
    visibility: visible;
  }
  to {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
  }
}
@-webkit-keyframes Toastify__slideInDown {
  from {
    -webkit-transform: translate3d(0, -110%, 0);
            transform: translate3d(0, -110%, 0);
    visibility: visible;
  }
  to {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
  }
}
@keyframes Toastify__slideInDown {
  from {
    -webkit-transform: translate3d(0, -110%, 0);
            transform: translate3d(0, -110%, 0);
    visibility: visible;
  }
  to {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
  }
}
@-webkit-keyframes Toastify__slideOutRight {
  from {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
  }
  to {
    visibility: hidden;
    -webkit-transform: translate3d(110%, 0, 0);
            transform: translate3d(110%, 0, 0);
  }
}
@keyframes Toastify__slideOutRight {
  from {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
  }
  to {
    visibility: hidden;
    -webkit-transform: translate3d(110%, 0, 0);
            transform: translate3d(110%, 0, 0);
  }
}
@-webkit-keyframes Toastify__slideOutLeft {
  from {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
  }
  to {
    visibility: hidden;
    -webkit-transform: translate3d(-110%, 0, 0);
            transform: translate3d(-110%, 0, 0);
  }
}
@keyframes Toastify__slideOutLeft {
  from {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
  }
  to {
    visibility: hidden;
    -webkit-transform: translate3d(-110%, 0, 0);
            transform: translate3d(-110%, 0, 0);
  }
}
@-webkit-keyframes Toastify__slideOutDown {
  from {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
  }
  to {
    visibility: hidden;
    -webkit-transform: translate3d(0, 500px, 0);
            transform: translate3d(0, 500px, 0);
  }
}
@keyframes Toastify__slideOutDown {
  from {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
  }
  to {
    visibility: hidden;
    -webkit-transform: translate3d(0, 500px, 0);
            transform: translate3d(0, 500px, 0);
  }
}
@-webkit-keyframes Toastify__slideOutUp {
  from {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
  }
  to {
    visibility: hidden;
    -webkit-transform: translate3d(0, -500px, 0);
            transform: translate3d(0, -500px, 0);
  }
}
@keyframes Toastify__slideOutUp {
  from {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
  }
  to {
    visibility: hidden;
    -webkit-transform: translate3d(0, -500px, 0);
            transform: translate3d(0, -500px, 0);
  }
}
.Toastify__slide-enter--top-left, .Toastify__slide-enter--bottom-left {
  -webkit-animation-name: Toastify__slideInLeft;
          animation-name: Toastify__slideInLeft;
}
.Toastify__slide-enter--top-right, .Toastify__slide-enter--bottom-right {
  -webkit-animation-name: Toastify__slideInRight;
          animation-name: Toastify__slideInRight;
}
.Toastify__slide-enter--top-center {
  -webkit-animation-name: Toastify__slideInDown;
          animation-name: Toastify__slideInDown;
}
.Toastify__slide-enter--bottom-center {
  -webkit-animation-name: Toastify__slideInUp;
          animation-name: Toastify__slideInUp;
}

.Toastify__slide-exit--top-left, .Toastify__slide-exit--bottom-left {
  -webkit-animation-name: Toastify__slideOutLeft;
          animation-name: Toastify__slideOutLeft;
}
.Toastify__slide-exit--top-right, .Toastify__slide-exit--bottom-right {
  -webkit-animation-name: Toastify__slideOutRight;
          animation-name: Toastify__slideOutRight;
}
.Toastify__slide-exit--top-center {
  -webkit-animation-name: Toastify__slideOutUp;
          animation-name: Toastify__slideOutUp;
}
.Toastify__slide-exit--bottom-center {
  -webkit-animation-name: Toastify__slideOutDown;
          animation-name: Toastify__slideOutDown;
}

/*# sourceMappingURL=ReactToastify.css.map */
/*!
 * Bootstrap v4.6.2 (https://getbootstrap.com/)
 * Copyright 2011-2022 The Bootstrap Authors
 * Copyright 2011-2022 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
 */
:root {
  --blue: #007bff;
  --indigo: #6610f2;
  --purple: #6f42c1;
  --pink: #e83e8c;
  --red: #dc3545;
  --orange: #fd7e14;
  --yellow: #ffc107;
  --green: #28a745;
  --teal: #20c997;
  --cyan: #17a2b8;
  --white: #fff;
  --gray: #6c757d;
  --gray-dark: #343a40;
  --primary: #007bff;
  --secondary: #6c757d;
  --success: #28a745;
  --info: #17a2b8;
  --warning: #ffc107;
  --danger: #dc3545;
  --light: #f8f9fa;
  --dark: #343a40;
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
  --font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: left;
  background-color: #fff;
}

[tabindex="-1"]:focus:not(:focus-visible) {
  outline: 0 !important;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 0.5rem;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
  -webkit-text-decoration-skip-ink: none;
  text-decoration-skip-ink: none;
}

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: 700;
}

dd {
  margin-bottom: .5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

b,
strong {
  font-weight: bolder;
}

small {
  font-size: 80%;
}

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

a {
  color: #007bff;
  text-decoration: none;
  background-color: transparent;
}

a:hover {
  color: #0056b3;
  text-decoration: underline;
}

a:not([href]):not([class]) {
  color: inherit;
  text-decoration: none;
}

a:not([href]):not([class]):hover {
  color: inherit;
  text-decoration: none;
}

pre,
code,
kbd,
samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  font-size: 1em;
}

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  -ms-overflow-style: scrollbar;
}

figure {
  margin: 0 0 1rem;
}

img {
  vertical-align: middle;
  border-style: none;
}

svg {
  overflow: hidden;
  vertical-align: middle;
}

table {
  border-collapse: collapse;
}

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #6c757d;
  text-align: left;
  caption-side: bottom;
}

th {
  text-align: inherit;
  text-align: -webkit-match-parent;
}

label {
  display: inline-block;
  margin-bottom: 0.5rem;
}

button {
  border-radius: 0;
}

button:focus:not(:focus-visible) {
  outline: 0;
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button,
input {
  overflow: visible;
}

button,
select {
  text-transform: none;
}

[role="button"] {
  cursor: pointer;
}

select {
  word-wrap: normal;
}

button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
}

button:not(:disabled),
[type="button"]:not(:disabled),
[type="reset"]:not(:disabled),
[type="submit"]:not(:disabled) {
  cursor: pointer;
}

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

input[type="radio"],
input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0;
}

textarea {
  overflow: auto;
  resize: vertical;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: .5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal;
}

progress {
  vertical-align: baseline;
}

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: none;
}

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

summary {
  display: list-item;
  cursor: pointer;
}

template {
  display: none;
}

[hidden] {
  display: none !important;
}

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2;
}

h1, .h1 {
  font-size: 2.5rem;
}

h2, .h2 {
  font-size: 2rem;
}

h3, .h3 {
  font-size: 1.75rem;
}

h4, .h4 {
  font-size: 1.5rem;
}

h5, .h5 {
  font-size: 1.25rem;
}

h6, .h6 {
  font-size: 1rem;
}

.lead {
  font-size: 1.25rem;
  font-weight: 300;
}

.display-1 {
  font-size: 6rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-2 {
  font-size: 5.5rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-3 {
  font-size: 4.5rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-4 {
  font-size: 3.5rem;
  font-weight: 300;
  line-height: 1.2;
}

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

small,
.small {
  font-size: 0.875em;
  font-weight: 400;
}

mark,
.mark {
  padding: 0.2em;
  background-color: #fcf8e3;
}

.list-unstyled {
  padding-left: 0;
  list-style: none;
}

.list-inline {
  padding-left: 0;
  list-style: none;
}

.list-inline-item {
  display: inline-block;
}

.list-inline-item:not(:last-child) {
  margin-right: 0.5rem;
}

.initialism {
  font-size: 90%;
  text-transform: uppercase;
}

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem;
}

.blockquote-footer {
  display: block;
  font-size: 0.875em;
  color: #6c757d;
}

.blockquote-footer::before {
  content: "\2014\00A0";
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

.img-thumbnail {
  padding: 0.25rem;
  background-color: #fff;
  border: 1px solid #dee2e6;
  border-radius: 0.25rem;
  max-width: 100%;
  height: auto;
}

.figure {
  display: inline-block;
}

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1;
}

.figure-caption {
  font-size: 90%;
  color: #6c757d;
}

code {
  font-size: 87.5%;
  color: #e83e8c;
  word-wrap: break-word;
}

a > code {
  color: inherit;
}

kbd {
  padding: 0.2rem 0.4rem;
  font-size: 87.5%;
  color: #fff;
  background-color: #212529;
  border-radius: 0.2rem;
}

kbd kbd {
  padding: 0;
  font-size: 100%;
  font-weight: 700;
}

pre {
  display: block;
  font-size: 87.5%;
  color: #212529;
}

pre code {
  font-size: inherit;
  color: inherit;
  word-break: normal;
}

.pre-scrollable {
  max-height: 340px;
  overflow-y: scroll;
}

.container,
.container-fluid,
.container-sm,
.container-md,
.container-lg,
.container-xl {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {
  .container, .container-sm {
    max-width: 540px;
  }
}

@media (min-width: 768px) {
  .container, .container-sm, .container-md {
    max-width: 720px;
  }
}

@media (min-width: 992px) {
  .container, .container-sm, .container-md, .container-lg {
    max-width: 960px;
  }
}

@media (min-width: 1200px) {
  .container, .container-sm, .container-md, .container-lg, .container-xl {
    max-width: 1140px;
  }
}

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}

.no-gutters {
  margin-right: 0;
  margin-left: 0;
}

.no-gutters > .col,
.no-gutters > [class*="col-"] {
  padding-right: 0;
  padding-left: 0;
}

.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col,
.col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm,
.col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md,
.col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg,
.col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl,
.col-xl-auto {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
}

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
}

.row-cols-1 > * {
  flex: 0 0 100%;
  max-width: 100%;
}

.row-cols-2 > * {
  flex: 0 0 50%;
  max-width: 50%;
}

.row-cols-3 > * {
  flex: 0 0 33.333333%;
  max-width: 33.333333%;
}

.row-cols-4 > * {
  flex: 0 0 25%;
  max-width: 25%;
}

.row-cols-5 > * {
  flex: 0 0 20%;
  max-width: 20%;
}

.row-cols-6 > * {
  flex: 0 0 16.666667%;
  max-width: 16.666667%;
}

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: 100%;
}

.col-1 {
  flex: 0 0 8.333333%;
  max-width: 8.333333%;
}

.col-2 {
  flex: 0 0 16.666667%;
  max-width: 16.666667%;
}

.col-3 {
  flex: 0 0 25%;
  max-width: 25%;
}

.col-4 {
  flex: 0 0 33.333333%;
  max-width: 33.333333%;
}

.col-5 {
  flex: 0 0 41.666667%;
  max-width: 41.666667%;
}

.col-6 {
  flex: 0 0 50%;
  max-width: 50%;
}

.col-7 {
  flex: 0 0 58.333333%;
  max-width: 58.333333%;
}

.col-8 {
  flex: 0 0 66.666667%;
  max-width: 66.666667%;
}

.col-9 {
  flex: 0 0 75%;
  max-width: 75%;
}

.col-10 {
  flex: 0 0 83.333333%;
  max-width: 83.333333%;
}

.col-11 {
  flex: 0 0 91.666667%;
  max-width: 91.666667%;
}

.col-12 {
  flex: 0 0 100%;
  max-width: 100%;
}

.order-first {
  order: -1;
}

.order-last {
  order: 13;
}

.order-0 {
  order: 0;
}

.order-1 {
  order: 1;
}

.order-2 {
  order: 2;
}

.order-3 {
  order: 3;
}

.order-4 {
  order: 4;
}

.order-5 {
  order: 5;
}

.order-6 {
  order: 6;
}

.order-7 {
  order: 7;
}

.order-8 {
  order: 8;
}

.order-9 {
  order: 9;
}

.order-10 {
  order: 10;
}

.order-11 {
  order: 11;
}

.order-12 {
  order: 12;
}

.offset-1 {
  margin-left: 8.333333%;
}

.offset-2 {
  margin-left: 16.666667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.333333%;
}

.offset-5 {
  margin-left: 41.666667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.333333%;
}

.offset-8 {
  margin-left: 66.666667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.333333%;
}

.offset-11 {
  margin-left: 91.666667%;
}

@media (min-width: 576px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .row-cols-sm-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .row-cols-sm-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .row-cols-sm-3 > * {
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  .row-cols-sm-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .row-cols-sm-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .row-cols-sm-6 > * {
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-sm-1 {
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }
  .col-sm-2 {
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-sm-4 {
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  .col-sm-5 {
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }
  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-sm-7 {
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }
  .col-sm-8 {
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }
  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-sm-10 {
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }
  .col-sm-11 {
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }
  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-sm-first {
    order: -1;
  }
  .order-sm-last {
    order: 13;
  }
  .order-sm-0 {
    order: 0;
  }
  .order-sm-1 {
    order: 1;
  }
  .order-sm-2 {
    order: 2;
  }
  .order-sm-3 {
    order: 3;
  }
  .order-sm-4 {
    order: 4;
  }
  .order-sm-5 {
    order: 5;
  }
  .order-sm-6 {
    order: 6;
  }
  .order-sm-7 {
    order: 7;
  }
  .order-sm-8 {
    order: 8;
  }
  .order-sm-9 {
    order: 9;
  }
  .order-sm-10 {
    order: 10;
  }
  .order-sm-11 {
    order: 11;
  }
  .order-sm-12 {
    order: 12;
  }
  .offset-sm-0 {
    margin-left: 0;
  }
  .offset-sm-1 {
    margin-left: 8.333333%;
  }
  .offset-sm-2 {
    margin-left: 16.666667%;
  }
  .offset-sm-3 {
    margin-left: 25%;
  }
  .offset-sm-4 {
    margin-left: 33.333333%;
  }
  .offset-sm-5 {
    margin-left: 41.666667%;
  }
  .offset-sm-6 {
    margin-left: 50%;
  }
  .offset-sm-7 {
    margin-left: 58.333333%;
  }
  .offset-sm-8 {
    margin-left: 66.666667%;
  }
  .offset-sm-9 {
    margin-left: 75%;
  }
  .offset-sm-10 {
    margin-left: 83.333333%;
  }
  .offset-sm-11 {
    margin-left: 91.666667%;
  }
}

@media (min-width: 768px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .row-cols-md-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .row-cols-md-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .row-cols-md-3 > * {
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  .row-cols-md-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .row-cols-md-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .row-cols-md-6 > * {
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-md-1 {
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }
  .col-md-2 {
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-md-4 {
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  .col-md-5 {
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }
  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-md-7 {
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }
  .col-md-8 {
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }
  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-md-10 {
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }
  .col-md-11 {
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }
  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-md-first {
    order: -1;
  }
  .order-md-last {
    order: 13;
  }
  .order-md-0 {
    order: 0;
  }
  .order-md-1 {
    order: 1;
  }
  .order-md-2 {
    order: 2;
  }
  .order-md-3 {
    order: 3;
  }
  .order-md-4 {
    order: 4;
  }
  .order-md-5 {
    order: 5;
  }
  .order-md-6 {
    order: 6;
  }
  .order-md-7 {
    order: 7;
  }
  .order-md-8 {
    order: 8;
  }
  .order-md-9 {
    order: 9;
  }
  .order-md-10 {
    order: 10;
  }
  .order-md-11 {
    order: 11;
  }
  .order-md-12 {
    order: 12;
  }
  .offset-md-0 {
    margin-left: 0;
  }
  .offset-md-1 {
    margin-left: 8.333333%;
  }
  .offset-md-2 {
    margin-left: 16.666667%;
  }
  .offset-md-3 {
    margin-left: 25%;
  }
  .offset-md-4 {
    margin-left: 33.333333%;
  }
  .offset-md-5 {
    margin-left: 41.666667%;
  }
  .offset-md-6 {
    margin-left: 50%;
  }
  .offset-md-7 {
    margin-left: 58.333333%;
  }
  .offset-md-8 {
    margin-left: 66.666667%;
  }
  .offset-md-9 {
    margin-left: 75%;
  }
  .offset-md-10 {
    margin-left: 83.333333%;
  }
  .offset-md-11 {
    margin-left: 91.666667%;
  }
}

@media (min-width: 992px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .row-cols-lg-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .row-cols-lg-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .row-cols-lg-3 > * {
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  .row-cols-lg-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .row-cols-lg-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .row-cols-lg-6 > * {
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-lg-1 {
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }
  .col-lg-2 {
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-lg-4 {
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  .col-lg-5 {
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }
  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-lg-7 {
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }
  .col-lg-8 {
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }
  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-lg-10 {
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }
  .col-lg-11 {
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }
  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-lg-first {
    order: -1;
  }
  .order-lg-last {
    order: 13;
  }
  .order-lg-0 {
    order: 0;
  }
  .order-lg-1 {
    order: 1;
  }
  .order-lg-2 {
    order: 2;
  }
  .order-lg-3 {
    order: 3;
  }
  .order-lg-4 {
    order: 4;
  }
  .order-lg-5 {
    order: 5;
  }
  .order-lg-6 {
    order: 6;
  }
  .order-lg-7 {
    order: 7;
  }
  .order-lg-8 {
    order: 8;
  }
  .order-lg-9 {
    order: 9;
  }
  .order-lg-10 {
    order: 10;
  }
  .order-lg-11 {
    order: 11;
  }
  .order-lg-12 {
    order: 12;
  }
  .offset-lg-0 {
    margin-left: 0;
  }
  .offset-lg-1 {
    margin-left: 8.333333%;
  }
  .offset-lg-2 {
    margin-left: 16.666667%;
  }
  .offset-lg-3 {
    margin-left: 25%;
  }
  .offset-lg-4 {
    margin-left: 33.333333%;
  }
  .offset-lg-5 {
    margin-left: 41.666667%;
  }
  .offset-lg-6 {
    margin-left: 50%;
  }
  .offset-lg-7 {
    margin-left: 58.333333%;
  }
  .offset-lg-8 {
    margin-left: 66.666667%;
  }
  .offset-lg-9 {
    margin-left: 75%;
  }
  .offset-lg-10 {
    margin-left: 83.333333%;
  }
  .offset-lg-11 {
    margin-left: 91.666667%;
  }
}

@media (min-width: 1200px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .row-cols-xl-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .row-cols-xl-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .row-cols-xl-3 > * {
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  .row-cols-xl-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .row-cols-xl-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .row-cols-xl-6 > * {
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-xl-1 {
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }
  .col-xl-2 {
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-xl-4 {
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  .col-xl-5 {
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }
  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-xl-7 {
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }
  .col-xl-8 {
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }
  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-xl-10 {
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }
  .col-xl-11 {
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }
  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-xl-first {
    order: -1;
  }
  .order-xl-last {
    order: 13;
  }
  .order-xl-0 {
    order: 0;
  }
  .order-xl-1 {
    order: 1;
  }
  .order-xl-2 {
    order: 2;
  }
  .order-xl-3 {
    order: 3;
  }
  .order-xl-4 {
    order: 4;
  }
  .order-xl-5 {
    order: 5;
  }
  .order-xl-6 {
    order: 6;
  }
  .order-xl-7 {
    order: 7;
  }
  .order-xl-8 {
    order: 8;
  }
  .order-xl-9 {
    order: 9;
  }
  .order-xl-10 {
    order: 10;
  }
  .order-xl-11 {
    order: 11;
  }
  .order-xl-12 {
    order: 12;
  }
  .offset-xl-0 {
    margin-left: 0;
  }
  .offset-xl-1 {
    margin-left: 8.333333%;
  }
  .offset-xl-2 {
    margin-left: 16.666667%;
  }
  .offset-xl-3 {
    margin-left: 25%;
  }
  .offset-xl-4 {
    margin-left: 33.333333%;
  }
  .offset-xl-5 {
    margin-left: 41.666667%;
  }
  .offset-xl-6 {
    margin-left: 50%;
  }
  .offset-xl-7 {
    margin-left: 58.333333%;
  }
  .offset-xl-8 {
    margin-left: 66.666667%;
  }
  .offset-xl-9 {
    margin-left: 75%;
  }
  .offset-xl-10 {
    margin-left: 83.333333%;
  }
  .offset-xl-11 {
    margin-left: 91.666667%;
  }
}

.table {
  width: 100%;
  margin-bottom: 1rem;
  color: #212529;
}

.table th,
.table td {
  padding: 0.75rem;
  vertical-align: top;
  border-top: 1px solid #dee2e6;
}

.table thead th {
  vertical-align: bottom;
  border-bottom: 2px solid #dee2e6;
}

.table tbody + tbody {
  border-top: 2px solid #dee2e6;
}

.table-sm th,
.table-sm td {
  padding: 0.3rem;
}

.table-bordered {
  border: 1px solid #dee2e6;
}

.table-bordered th,
.table-bordered td {
  border: 1px solid #dee2e6;
}

.table-bordered thead th,
.table-bordered thead td {
  border-bottom-width: 2px;
}

.table-borderless th,
.table-borderless td,
.table-borderless thead th,
.table-borderless tbody + tbody {
  border: 0;
}

.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05);
}

.table-hover tbody tr:hover {
  color: #212529;
  background-color: rgba(0, 0, 0, 0.075);
}

.table-primary,
.table-primary > th,
.table-primary > td {
  background-color: #b8daff;
}

.table-primary th,
.table-primary td,
.table-primary thead th,
.table-primary tbody + tbody {
  border-color: #7abaff;
}

.table-hover .table-primary:hover {
  background-color: #9fcdff;
}

.table-hover .table-primary:hover > td,
.table-hover .table-primary:hover > th {
  background-color: #9fcdff;
}

.table-secondary,
.table-secondary > th,
.table-secondary > td {
  background-color: #d6d8db;
}

.table-secondary th,
.table-secondary td,
.table-secondary thead th,
.table-secondary tbody + tbody {
  border-color: #b3b7bb;
}

.table-hover .table-secondary:hover {
  background-color: #c8cbcf;
}

.table-hover .table-secondary:hover > td,
.table-hover .table-secondary:hover > th {
  background-color: #c8cbcf;
}

.table-success,
.table-success > th,
.table-success > td {
  background-color: #c3e6cb;
}

.table-success th,
.table-success td,
.table-success thead th,
.table-success tbody + tbody {
  border-color: #8fd19e;
}

.table-hover .table-success:hover {
  background-color: #b1dfbb;
}

.table-hover .table-success:hover > td,
.table-hover .table-success:hover > th {
  background-color: #b1dfbb;
}

.table-info,
.table-info > th,
.table-info > td {
  background-color: #bee5eb;
}

.table-info th,
.table-info td,
.table-info thead th,
.table-info tbody + tbody {
  border-color: #86cfda;
}

.table-hover .table-info:hover {
  background-color: #abdde5;
}

.table-hover .table-info:hover > td,
.table-hover .table-info:hover > th {
  background-color: #abdde5;
}

.table-warning,
.table-warning > th,
.table-warning > td {
  background-color: #ffeeba;
}

.table-warning th,
.table-warning td,
.table-warning thead th,
.table-warning tbody + tbody {
  border-color: #ffdf7e;
}

.table-hover .table-warning:hover {
  background-color: #ffe8a1;
}

.table-hover .table-warning:hover > td,
.table-hover .table-warning:hover > th {
  background-color: #ffe8a1;
}

.table-danger,
.table-danger > th,
.table-danger > td {
  background-color: #f5c6cb;
}

.table-danger th,
.table-danger td,
.table-danger thead th,
.table-danger tbody + tbody {
  border-color: #ed969e;
}

.table-hover .table-danger:hover {
  background-color: #f1b0b7;
}

.table-hover .table-danger:hover > td,
.table-hover .table-danger:hover > th {
  background-color: #f1b0b7;
}

.table-light,
.table-light > th,
.table-light > td {
  background-color: #fdfdfe;
}

.table-light th,
.table-light td,
.table-light thead th,
.table-light tbody + tbody {
  border-color: #fbfcfc;
}

.table-hover .table-light:hover {
  background-color: #ececf6;
}

.table-hover .table-light:hover > td,
.table-hover .table-light:hover > th {
  background-color: #ececf6;
}

.table-dark,
.table-dark > th,
.table-dark > td {
  background-color: #c6c8ca;
}

.table-dark th,
.table-dark td,
.table-dark thead th,
.table-dark tbody + tbody {
  border-color: #95999c;
}

.table-hover .table-dark:hover {
  background-color: #b9bbbe;
}

.table-hover .table-dark:hover > td,
.table-hover .table-dark:hover > th {
  background-color: #b9bbbe;
}

.table-active,
.table-active > th,
.table-active > td {
  background-color: rgba(0, 0, 0, 0.075);
}

.table-hover .table-active:hover {
  background-color: rgba(0, 0, 0, 0.075);
}

.table-hover .table-active:hover > td,
.table-hover .table-active:hover > th {
  background-color: rgba(0, 0, 0, 0.075);
}

.table .thead-dark th {
  color: #fff;
  background-color: #343a40;
  border-color: #454d55;
}

.table .thead-light th {
  color: #495057;
  background-color: #e9ecef;
  border-color: #dee2e6;
}

.table-dark {
  color: #fff;
  background-color: #343a40;
}

.table-dark th,
.table-dark td,
.table-dark thead th {
  border-color: #454d55;
}

.table-dark.table-bordered {
  border: 0;
}

.table-dark.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(255, 255, 255, 0.05);
}

.table-dark.table-hover tbody tr:hover {
  color: #fff;
  background-color: rgba(255, 255, 255, 0.075);
}

@media (max-width: 575.98px) {
  .table-responsive-sm {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-sm > .table-bordered {
    border: 0;
  }
}

@media (max-width: 767.98px) {
  .table-responsive-md {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-md > .table-bordered {
    border: 0;
  }
}

@media (max-width: 991.98px) {
  .table-responsive-lg {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-lg > .table-bordered {
    border: 0;
  }
}

@media (max-width: 1199.98px) {
  .table-responsive-xl {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-xl > .table-bordered {
    border: 0;
  }
}

.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}

.table-responsive > .table-bordered {
  border: 0;
}

.form-control {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .form-control {
    transition: none;
  }
}

.form-control::-ms-expand {
  background-color: transparent;
  border: 0;
}

.form-control:focus {
  color: #495057;
  background-color: #fff;
  border-color: #80bdff;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.form-control::-webkit-input-placeholder {
  color: #6c757d;
  opacity: 1;
}

.form-control:-ms-input-placeholder {
  color: #6c757d;
  opacity: 1;
}

.form-control::placeholder {
  color: #6c757d;
  opacity: 1;
}

.form-control:disabled, .form-control[readonly] {
  background-color: #e9ecef;
  opacity: 1;
}

input[type="date"].form-control,
input[type="time"].form-control,
input[type="datetime-local"].form-control,
input[type="month"].form-control {
  -webkit-appearance: none;
  appearance: none;
}

select.form-control:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #495057;
}

select.form-control:focus::-ms-value {
  color: #495057;
  background-color: #fff;
}

.form-control-file,
.form-control-range {
  display: block;
  width: 100%;
}

.col-form-label {
  padding-top: calc(0.375rem + 1px);
  padding-bottom: calc(0.375rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5;
}

.col-form-label-lg {
  padding-top: calc(0.5rem + 1px);
  padding-bottom: calc(0.5rem + 1px);
  font-size: 1.25rem;
  line-height: 1.5;
}

.col-form-label-sm {
  padding-top: calc(0.25rem + 1px);
  padding-bottom: calc(0.25rem + 1px);
  font-size: 0.875rem;
  line-height: 1.5;
}

.form-control-plaintext {
  display: block;
  width: 100%;
  padding: 0.375rem 0;
  margin-bottom: 0;
  font-size: 1rem;
  line-height: 1.5;
  color: #212529;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0;
}

.form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
  padding-right: 0;
  padding-left: 0;
}

.form-control-sm {
  height: calc(1.5em + 0.5rem + 2px);
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}

.form-control-lg {
  height: calc(1.5em + 1rem + 2px);
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}

select.form-control[size], select.form-control[multiple] {
  height: auto;
}

textarea.form-control {
  height: auto;
}

.form-group {
  margin-bottom: 1rem;
}

.form-text {
  display: block;
  margin-top: 0.25rem;
}

.form-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px;
}

.form-row > .col,
.form-row > [class*="col-"] {
  padding-right: 5px;
  padding-left: 5px;
}

.form-check {
  position: relative;
  display: block;
  padding-left: 1.25rem;
}

.form-check-input {
  position: absolute;
  margin-top: 0.3rem;
  margin-left: -1.25rem;
}

.form-check-input[disabled] ~ .form-check-label,
.form-check-input:disabled ~ .form-check-label {
  color: #6c757d;
}

.form-check-label {
  margin-bottom: 0;
}

.form-check-inline {
  display: inline-flex;
  align-items: center;
  padding-left: 0;
  margin-right: 0.75rem;
}

.form-check-inline .form-check-input {
  position: static;
  margin-top: 0;
  margin-right: 0.3125rem;
  margin-left: 0;
}

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #28a745;
}

.valid-tooltip {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(40, 167, 69, 0.9);
  border-radius: 0.25rem;
}

.form-row > .col > .valid-tooltip,
.form-row > [class*="col-"] > .valid-tooltip {
  left: 5px;
}

.was-validated :valid ~ .valid-feedback,
.was-validated :valid ~ .valid-tooltip,
.is-valid ~ .valid-feedback,
.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .form-control:valid, .form-control.is-valid {
  border-color: #28a745;
  padding-right: calc(1.5em + 0.75rem) !important;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}

.was-validated .form-control:valid:focus, .form-control.is-valid:focus {
  border-color: #28a745;
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}

.was-validated select.form-control:valid, select.form-control.is-valid {
  padding-right: 3rem !important;
  background-position: right 1.5rem center;
}

.was-validated textarea.form-control:valid, textarea.form-control.is-valid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem);
}

.was-validated .custom-select:valid, .custom-select.is-valid {
  border-color: #28a745;
  padding-right: calc(0.75em + 2.3125rem) !important;
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") right 0.75rem center/8px 10px no-repeat, #fff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e") center right 1.75rem/calc(0.75em + 0.375rem) calc(0.75em + 0.375rem) no-repeat;
}

.was-validated .custom-select:valid:focus, .custom-select.is-valid:focus {
  border-color: #28a745;
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}

.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: #28a745;
}

.was-validated .form-check-input:valid ~ .valid-feedback,
.was-validated .form-check-input:valid ~ .valid-tooltip, .form-check-input.is-valid ~ .valid-feedback,
.form-check-input.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .custom-control-input:valid ~ .custom-control-label, .custom-control-input.is-valid ~ .custom-control-label {
  color: #28a745;
}

.was-validated .custom-control-input:valid ~ .custom-control-label::before, .custom-control-input.is-valid ~ .custom-control-label::before {
  border-color: #28a745;
}

.was-validated .custom-control-input:valid:checked ~ .custom-control-label::before, .custom-control-input.is-valid:checked ~ .custom-control-label::before {
  border-color: #34ce57;
  background-color: #34ce57;
}

.was-validated .custom-control-input:valid:focus ~ .custom-control-label::before, .custom-control-input.is-valid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}

.was-validated .custom-control-input:valid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-valid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #28a745;
}

.was-validated .custom-file-input:valid ~ .custom-file-label, .custom-file-input.is-valid ~ .custom-file-label {
  border-color: #28a745;
}

.was-validated .custom-file-input:valid:focus ~ .custom-file-label, .custom-file-input.is-valid:focus ~ .custom-file-label {
  border-color: #28a745;
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #dc3545;
}

.invalid-tooltip {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(220, 53, 69, 0.9);
  border-radius: 0.25rem;
}

.form-row > .col > .invalid-tooltip,
.form-row > [class*="col-"] > .invalid-tooltip {
  left: 5px;
}

.was-validated :invalid ~ .invalid-feedback,
.was-validated :invalid ~ .invalid-tooltip,
.is-invalid ~ .invalid-feedback,
.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .form-control:invalid, .form-control.is-invalid {
  border-color: #dc3545;
  padding-right: calc(1.5em + 0.75rem) !important;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}

.was-validated .form-control:invalid:focus, .form-control.is-invalid:focus {
  border-color: #dc3545;
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}

.was-validated select.form-control:invalid, select.form-control.is-invalid {
  padding-right: 3rem !important;
  background-position: right 1.5rem center;
}

.was-validated textarea.form-control:invalid, textarea.form-control.is-invalid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem);
}

.was-validated .custom-select:invalid, .custom-select.is-invalid {
  border-color: #dc3545;
  padding-right: calc(0.75em + 2.3125rem) !important;
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") right 0.75rem center/8px 10px no-repeat, #fff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e") center right 1.75rem/calc(0.75em + 0.375rem) calc(0.75em + 0.375rem) no-repeat;
}

.was-validated .custom-select:invalid:focus, .custom-select.is-invalid:focus {
  border-color: #dc3545;
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}

.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
  color: #dc3545;
}

.was-validated .form-check-input:invalid ~ .invalid-feedback,
.was-validated .form-check-input:invalid ~ .invalid-tooltip, .form-check-input.is-invalid ~ .invalid-feedback,
.form-check-input.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .custom-control-input:invalid ~ .custom-control-label, .custom-control-input.is-invalid ~ .custom-control-label {
  color: #dc3545;
}

.was-validated .custom-control-input:invalid ~ .custom-control-label::before, .custom-control-input.is-invalid ~ .custom-control-label::before {
  border-color: #dc3545;
}

.was-validated .custom-control-input:invalid:checked ~ .custom-control-label::before, .custom-control-input.is-invalid:checked ~ .custom-control-label::before {
  border-color: #e4606d;
  background-color: #e4606d;
}

.was-validated .custom-control-input:invalid:focus ~ .custom-control-label::before, .custom-control-input.is-invalid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}

.was-validated .custom-control-input:invalid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-invalid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #dc3545;
}

.was-validated .custom-file-input:invalid ~ .custom-file-label, .custom-file-input.is-invalid ~ .custom-file-label {
  border-color: #dc3545;
}

.was-validated .custom-file-input:invalid:focus ~ .custom-file-label, .custom-file-input.is-invalid:focus ~ .custom-file-label {
  border-color: #dc3545;
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}

.form-inline {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
}

.form-inline .form-check {
  width: 100%;
}

@media (min-width: 576px) {
  .form-inline label {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 0;
  }
  .form-inline .form-group {
    display: flex;
    flex: 0 0 auto;
    flex-flow: row wrap;
    align-items: center;
    margin-bottom: 0;
  }
  .form-inline .form-control {
    display: inline-block;
    width: auto;
    vertical-align: middle;
  }
  .form-inline .form-control-plaintext {
    display: inline-block;
  }
  .form-inline .input-group,
  .form-inline .custom-select {
    width: auto;
  }
  .form-inline .form-check {
    display: flex;
    align-items: center;
    justify-content: center;
    width: auto;
    padding-left: 0;
  }
  .form-inline .form-check-input {
    position: relative;
    flex-shrink: 0;
    margin-top: 0;
    margin-right: 0.25rem;
    margin-left: 0;
  }
  .form-inline .custom-control {
    align-items: center;
    justify-content: center;
  }
  .form-inline .custom-control-label {
    margin-bottom: 0;
  }
}

.btn {
  display: inline-block;
  font-weight: 400;
  color: #212529;
  text-align: center;
  vertical-align: middle;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .btn {
    transition: none;
  }
}

.btn:hover {
  color: #212529;
  text-decoration: none;
}

.btn:focus, .btn.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.btn.disabled, .btn:disabled {
  opacity: 0.65;
}

.btn:not(:disabled):not(.disabled) {
  cursor: pointer;
}

a.btn.disabled,
fieldset:disabled a.btn {
  pointer-events: none;
}

.btn-primary {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}

.btn-primary:hover {
  color: #fff;
  background-color: #0069d9;
  border-color: #0062cc;
}

.btn-primary:focus, .btn-primary.focus {
  color: #fff;
  background-color: #0069d9;
  border-color: #0062cc;
  box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.5);
}

.btn-primary.disabled, .btn-primary:disabled {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}

.btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active,
.show > .btn-primary.dropdown-toggle {
  color: #fff;
  background-color: #0062cc;
  border-color: #005cbf;
}

.btn-primary:not(:disabled):not(.disabled):active:focus, .btn-primary:not(:disabled):not(.disabled).active:focus,
.show > .btn-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.5);
}

.btn-secondary {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}

.btn-secondary:hover {
  color: #fff;
  background-color: #5a6268;
  border-color: #545b62;
}

.btn-secondary:focus, .btn-secondary.focus {
  color: #fff;
  background-color: #5a6268;
  border-color: #545b62;
  box-shadow: 0 0 0 0.2rem rgba(130, 138, 145, 0.5);
}

.btn-secondary.disabled, .btn-secondary:disabled {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}

.btn-secondary:not(:disabled):not(.disabled):active, .btn-secondary:not(:disabled):not(.disabled).active,
.show > .btn-secondary.dropdown-toggle {
  color: #fff;
  background-color: #545b62;
  border-color: #4e555b;
}

.btn-secondary:not(:disabled):not(.disabled):active:focus, .btn-secondary:not(:disabled):not(.disabled).active:focus,
.show > .btn-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(130, 138, 145, 0.5);
}

.btn-success {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}

.btn-success:hover {
  color: #fff;
  background-color: #218838;
  border-color: #1e7e34;
}

.btn-success:focus, .btn-success.focus {
  color: #fff;
  background-color: #218838;
  border-color: #1e7e34;
  box-shadow: 0 0 0 0.2rem rgba(72, 180, 97, 0.5);
}

.btn-success.disabled, .btn-success:disabled {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}

.btn-success:not(:disabled):not(.disabled):active, .btn-success:not(:disabled):not(.disabled).active,
.show > .btn-success.dropdown-toggle {
  color: #fff;
  background-color: #1e7e34;
  border-color: #1c7430;
}

.btn-success:not(:disabled):not(.disabled):active:focus, .btn-success:not(:disabled):not(.disabled).active:focus,
.show > .btn-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(72, 180, 97, 0.5);
}

.btn-info {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}

.btn-info:hover {
  color: #fff;
  background-color: #138496;
  border-color: #117a8b;
}

.btn-info:focus, .btn-info.focus {
  color: #fff;
  background-color: #138496;
  border-color: #117a8b;
  box-shadow: 0 0 0 0.2rem rgba(58, 176, 195, 0.5);
}

.btn-info.disabled, .btn-info:disabled {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}

.btn-info:not(:disabled):not(.disabled):active, .btn-info:not(:disabled):not(.disabled).active,
.show > .btn-info.dropdown-toggle {
  color: #fff;
  background-color: #117a8b;
  border-color: #10707f;
}

.btn-info:not(:disabled):not(.disabled):active:focus, .btn-info:not(:disabled):not(.disabled).active:focus,
.show > .btn-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(58, 176, 195, 0.5);
}

.btn-warning {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}

.btn-warning:hover {
  color: #212529;
  background-color: #e0a800;
  border-color: #d39e00;
}

.btn-warning:focus, .btn-warning.focus {
  color: #212529;
  background-color: #e0a800;
  border-color: #d39e00;
  box-shadow: 0 0 0 0.2rem rgba(222, 170, 12, 0.5);
}

.btn-warning.disabled, .btn-warning:disabled {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}

.btn-warning:not(:disabled):not(.disabled):active, .btn-warning:not(:disabled):not(.disabled).active,
.show > .btn-warning.dropdown-toggle {
  color: #212529;
  background-color: #d39e00;
  border-color: #c69500;
}

.btn-warning:not(:disabled):not(.disabled):active:focus, .btn-warning:not(:disabled):not(.disabled).active:focus,
.show > .btn-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(222, 170, 12, 0.5);
}

.btn-danger {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}

.btn-danger:hover {
  color: #fff;
  background-color: #c82333;
  border-color: #bd2130;
}

.btn-danger:focus, .btn-danger.focus {
  color: #fff;
  background-color: #c82333;
  border-color: #bd2130;
  box-shadow: 0 0 0 0.2rem rgba(225, 83, 97, 0.5);
}

.btn-danger.disabled, .btn-danger:disabled {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}

.btn-danger:not(:disabled):not(.disabled):active, .btn-danger:not(:disabled):not(.disabled).active,
.show > .btn-danger.dropdown-toggle {
  color: #fff;
  background-color: #bd2130;
  border-color: #b21f2d;
}

.btn-danger:not(:disabled):not(.disabled):active:focus, .btn-danger:not(:disabled):not(.disabled).active:focus,
.show > .btn-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(225, 83, 97, 0.5);
}

.btn-light {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}

.btn-light:hover {
  color: #212529;
  background-color: #e2e6ea;
  border-color: #dae0e5;
}

.btn-light:focus, .btn-light.focus {
  color: #212529;
  background-color: #e2e6ea;
  border-color: #dae0e5;
  box-shadow: 0 0 0 0.2rem rgba(216, 217, 219, 0.5);
}

.btn-light.disabled, .btn-light:disabled {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}

.btn-light:not(:disabled):not(.disabled):active, .btn-light:not(:disabled):not(.disabled).active,
.show > .btn-light.dropdown-toggle {
  color: #212529;
  background-color: #dae0e5;
  border-color: #d3d9df;
}

.btn-light:not(:disabled):not(.disabled):active:focus, .btn-light:not(:disabled):not(.disabled).active:focus,
.show > .btn-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(216, 217, 219, 0.5);
}

.btn-dark {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}

.btn-dark:hover {
  color: #fff;
  background-color: #23272b;
  border-color: #1d2124;
}

.btn-dark:focus, .btn-dark.focus {
  color: #fff;
  background-color: #23272b;
  border-color: #1d2124;
  box-shadow: 0 0 0 0.2rem rgba(82, 88, 93, 0.5);
}

.btn-dark.disabled, .btn-dark:disabled {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}

.btn-dark:not(:disabled):not(.disabled):active, .btn-dark:not(:disabled):not(.disabled).active,
.show > .btn-dark.dropdown-toggle {
  color: #fff;
  background-color: #1d2124;
  border-color: #171a1d;
}

.btn-dark:not(:disabled):not(.disabled):active:focus, .btn-dark:not(:disabled):not(.disabled).active:focus,
.show > .btn-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(82, 88, 93, 0.5);
}

.btn-outline-primary {
  color: #007bff;
  border-color: #007bff;
}

.btn-outline-primary:hover {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}

.btn-outline-primary:focus, .btn-outline-primary.focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5);
}

.btn-outline-primary.disabled, .btn-outline-primary:disabled {
  color: #007bff;
  background-color: transparent;
}

.btn-outline-primary:not(:disabled):not(.disabled):active, .btn-outline-primary:not(:disabled):not(.disabled).active,
.show > .btn-outline-primary.dropdown-toggle {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}

.btn-outline-primary:not(:disabled):not(.disabled):active:focus, .btn-outline-primary:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5);
}

.btn-outline-secondary {
  color: #6c757d;
  border-color: #6c757d;
}

.btn-outline-secondary:hover {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}

.btn-outline-secondary:focus, .btn-outline-secondary.focus {
  box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
}

.btn-outline-secondary.disabled, .btn-outline-secondary:disabled {
  color: #6c757d;
  background-color: transparent;
}

.btn-outline-secondary:not(:disabled):not(.disabled):active, .btn-outline-secondary:not(:disabled):not(.disabled).active,
.show > .btn-outline-secondary.dropdown-toggle {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}

.btn-outline-secondary:not(:disabled):not(.disabled):active:focus, .btn-outline-secondary:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
}

.btn-outline-success {
  color: #28a745;
  border-color: #28a745;
}

.btn-outline-success:hover {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}

.btn-outline-success:focus, .btn-outline-success.focus {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5);
}

.btn-outline-success.disabled, .btn-outline-success:disabled {
  color: #28a745;
  background-color: transparent;
}

.btn-outline-success:not(:disabled):not(.disabled):active, .btn-outline-success:not(:disabled):not(.disabled).active,
.show > .btn-outline-success.dropdown-toggle {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}

.btn-outline-success:not(:disabled):not(.disabled):active:focus, .btn-outline-success:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5);
}

.btn-outline-info {
  color: #17a2b8;
  border-color: #17a2b8;
}

.btn-outline-info:hover {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}

.btn-outline-info:focus, .btn-outline-info.focus {
  box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
}

.btn-outline-info.disabled, .btn-outline-info:disabled {
  color: #17a2b8;
  background-color: transparent;
}

.btn-outline-info:not(:disabled):not(.disabled):active, .btn-outline-info:not(:disabled):not(.disabled).active,
.show > .btn-outline-info.dropdown-toggle {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}

.btn-outline-info:not(:disabled):not(.disabled):active:focus, .btn-outline-info:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
}

.btn-outline-warning {
  color: #ffc107;
  border-color: #ffc107;
}

.btn-outline-warning:hover {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}

.btn-outline-warning:focus, .btn-outline-warning.focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
}

.btn-outline-warning.disabled, .btn-outline-warning:disabled {
  color: #ffc107;
  background-color: transparent;
}

.btn-outline-warning:not(:disabled):not(.disabled):active, .btn-outline-warning:not(:disabled):not(.disabled).active,
.show > .btn-outline-warning.dropdown-toggle {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}

.btn-outline-warning:not(:disabled):not(.disabled):active:focus, .btn-outline-warning:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
}

.btn-outline-danger {
  color: #dc3545;
  border-color: #dc3545;
}

.btn-outline-danger:hover {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}

.btn-outline-danger:focus, .btn-outline-danger.focus {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5);
}

.btn-outline-danger.disabled, .btn-outline-danger:disabled {
  color: #dc3545;
  background-color: transparent;
}

.btn-outline-danger:not(:disabled):not(.disabled):active, .btn-outline-danger:not(:disabled):not(.disabled).active,
.show > .btn-outline-danger.dropdown-toggle {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}

.btn-outline-danger:not(:disabled):not(.disabled):active:focus, .btn-outline-danger:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5);
}

.btn-outline-light {
  color: #f8f9fa;
  border-color: #f8f9fa;
}

.btn-outline-light:hover {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}

.btn-outline-light:focus, .btn-outline-light.focus {
  box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}

.btn-outline-light.disabled, .btn-outline-light:disabled {
  color: #f8f9fa;
  background-color: transparent;
}

.btn-outline-light:not(:disabled):not(.disabled):active, .btn-outline-light:not(:disabled):not(.disabled).active,
.show > .btn-outline-light.dropdown-toggle {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}

.btn-outline-light:not(:disabled):not(.disabled):active:focus, .btn-outline-light:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}

.btn-outline-dark {
  color: #343a40;
  border-color: #343a40;
}

.btn-outline-dark:hover {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}

.btn-outline-dark:focus, .btn-outline-dark.focus {
  box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
}

.btn-outline-dark.disabled, .btn-outline-dark:disabled {
  color: #343a40;
  background-color: transparent;
}

.btn-outline-dark:not(:disabled):not(.disabled):active, .btn-outline-dark:not(:disabled):not(.disabled).active,
.show > .btn-outline-dark.dropdown-toggle {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}

.btn-outline-dark:not(:disabled):not(.disabled):active:focus, .btn-outline-dark:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
}

.btn-link {
  font-weight: 400;
  color: #007bff;
  text-decoration: none;
}

.btn-link:hover {
  color: #0056b3;
  text-decoration: underline;
}

.btn-link:focus, .btn-link.focus {
  text-decoration: underline;
}

.btn-link:disabled, .btn-link.disabled {
  color: #6c757d;
  pointer-events: none;
}

.btn-lg, .btn-group-lg > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}

.btn-sm, .btn-group-sm > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}

.btn-block {
  display: block;
  width: 100%;
}

.btn-block + .btn-block {
  margin-top: 0.5rem;
}

input[type="submit"].btn-block,
input[type="reset"].btn-block,
input[type="button"].btn-block {
  width: 100%;
}

.fade {
  transition: opacity 0.15s linear;
}

@media (prefers-reduced-motion: reduce) {
  .fade {
    transition: none;
  }
}

.fade:not(.show) {
  opacity: 0;
}

.collapse:not(.show) {
  display: none;
}

.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease;
}

@media (prefers-reduced-motion: reduce) {
  .collapsing {
    transition: none;
  }
}

.collapsing.width {
  width: 0;
  height: auto;
  transition: width 0.35s ease;
}

@media (prefers-reduced-motion: reduce) {
  .collapsing.width {
    transition: none;
  }
}

.dropup,
.dropright,
.dropdown,
.dropleft {
  position: relative;
}

.dropdown-toggle {
  white-space: nowrap;
}

.dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}

.dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0.125rem 0 0;
  font-size: 1rem;
  color: #212529;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem;
}

.dropdown-menu-left {
  right: auto;
  left: 0;
}

.dropdown-menu-right {
  right: 0;
  left: auto;
}

@media (min-width: 576px) {
  .dropdown-menu-sm-left {
    right: auto;
    left: 0;
  }
  .dropdown-menu-sm-right {
    right: 0;
    left: auto;
  }
}

@media (min-width: 768px) {
  .dropdown-menu-md-left {
    right: auto;
    left: 0;
  }
  .dropdown-menu-md-right {
    right: 0;
    left: auto;
  }
}

@media (min-width: 992px) {
  .dropdown-menu-lg-left {
    right: auto;
    left: 0;
  }
  .dropdown-menu-lg-right {
    right: 0;
    left: auto;
  }
}

@media (min-width: 1200px) {
  .dropdown-menu-xl-left {
    right: auto;
    left: 0;
  }
  .dropdown-menu-xl-right {
    right: 0;
    left: auto;
  }
}

.dropup .dropdown-menu {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: 0.125rem;
}

.dropup .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent;
}

.dropup .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropright .dropdown-menu {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: 0.125rem;
}

.dropright .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid;
}

.dropright .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropright .dropdown-toggle::after {
  vertical-align: 0;
}

.dropleft .dropdown-menu {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: 0.125rem;
}

.dropleft .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
}

.dropleft .dropdown-toggle::after {
  display: none;
}

.dropleft .dropdown-toggle::before {
  display: inline-block;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent;
}

.dropleft .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropleft .dropdown-toggle::before {
  vertical-align: 0;
}

.dropdown-menu[x-placement^="top"], .dropdown-menu[x-placement^="right"], .dropdown-menu[x-placement^="bottom"], .dropdown-menu[x-placement^="left"] {
  right: auto;
  bottom: auto;
}

.dropdown-divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid #e9ecef;
}

.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.25rem 1.5rem;
  clear: both;
  font-weight: 400;
  color: #212529;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
}

.dropdown-item:hover, .dropdown-item:focus {
  color: #16181b;
  text-decoration: none;
  background-color: #e9ecef;
}

.dropdown-item.active, .dropdown-item:active {
  color: #fff;
  text-decoration: none;
  background-color: #007bff;
}

.dropdown-item.disabled, .dropdown-item:disabled {
  color: #adb5bd;
  pointer-events: none;
  background-color: transparent;
}

.dropdown-menu.show {
  display: block;
}

.dropdown-header {
  display: block;
  padding: 0.5rem 1.5rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  color: #6c757d;
  white-space: nowrap;
}

.dropdown-item-text {
  display: block;
  padding: 0.25rem 1.5rem;
  color: #212529;
}

.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle;
}

.btn-group > .btn,
.btn-group-vertical > .btn {
  position: relative;
  flex: 1 1 auto;
}

.btn-group > .btn:hover,
.btn-group-vertical > .btn:hover {
  z-index: 1;
}

.btn-group > .btn:focus, .btn-group > .btn:active, .btn-group > .btn.active,
.btn-group-vertical > .btn:focus,
.btn-group-vertical > .btn:active,
.btn-group-vertical > .btn.active {
  z-index: 1;
}

.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}

.btn-toolbar .input-group {
  width: auto;
}

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) {
  margin-left: -1px;
}

.btn-group > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group > .btn-group:not(:last-child) > .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.dropdown-toggle-split {
  padding-right: 0.5625rem;
  padding-left: 0.5625rem;
}

.dropdown-toggle-split::after,
.dropup .dropdown-toggle-split::after,
.dropright .dropdown-toggle-split::after {
  margin-left: 0;
}

.dropleft .dropdown-toggle-split::before {
  margin-right: 0;
}

.btn-sm + .dropdown-toggle-split, .btn-group-sm > .btn + .dropdown-toggle-split {
  padding-right: 0.375rem;
  padding-left: 0.375rem;
}

.btn-lg + .dropdown-toggle-split, .btn-group-lg > .btn + .dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem;
}

.btn-group-vertical {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.btn-group-vertical > .btn,
.btn-group-vertical > .btn-group {
  width: 100%;
}

.btn-group-vertical > .btn:not(:first-child),
.btn-group-vertical > .btn-group:not(:first-child) {
  margin-top: -1px;
}

.btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group-vertical > .btn-group:not(:last-child) > .btn {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.btn-group-vertical > .btn:not(:first-child),
.btn-group-vertical > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.btn-group-toggle > .btn,
.btn-group-toggle > .btn-group > .btn {
  margin-bottom: 0;
}

.btn-group-toggle > .btn input[type="radio"],
.btn-group-toggle > .btn input[type="checkbox"],
.btn-group-toggle > .btn-group > .btn input[type="radio"],
.btn-group-toggle > .btn-group > .btn input[type="checkbox"] {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
}

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
}

.input-group > .form-control,
.input-group > .form-control-plaintext,
.input-group > .custom-select,
.input-group > .custom-file {
  position: relative;
  flex: 1 1 auto;
  width: 1%;
  min-width: 0;
  margin-bottom: 0;
}

.input-group > .form-control + .form-control,
.input-group > .form-control + .custom-select,
.input-group > .form-control + .custom-file,
.input-group > .form-control-plaintext + .form-control,
.input-group > .form-control-plaintext + .custom-select,
.input-group > .form-control-plaintext + .custom-file,
.input-group > .custom-select + .form-control,
.input-group > .custom-select + .custom-select,
.input-group > .custom-select + .custom-file,
.input-group > .custom-file + .form-control,
.input-group > .custom-file + .custom-select,
.input-group > .custom-file + .custom-file {
  margin-left: -1px;
}

.input-group > .form-control:focus,
.input-group > .custom-select:focus,
.input-group > .custom-file .custom-file-input:focus ~ .custom-file-label {
  z-index: 3;
}

.input-group > .custom-file .custom-file-input:focus {
  z-index: 4;
}

.input-group > .form-control:not(:first-child),
.input-group > .custom-select:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.input-group > .custom-file {
  display: flex;
  align-items: center;
}

.input-group > .custom-file:not(:last-child) .custom-file-label,
.input-group > .custom-file:not(:last-child) .custom-file-label::after {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group > .custom-file:not(:first-child) .custom-file-label {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.input-group:not(.has-validation) > .form-control:not(:last-child),
.input-group:not(.has-validation) > .custom-select:not(:last-child),
.input-group:not(.has-validation) > .custom-file:not(:last-child) .custom-file-label,
.input-group:not(.has-validation) > .custom-file:not(:last-child) .custom-file-label::after {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group.has-validation > .form-control:nth-last-child(n + 3),
.input-group.has-validation > .custom-select:nth-last-child(n + 3),
.input-group.has-validation > .custom-file:nth-last-child(n + 3) .custom-file-label,
.input-group.has-validation > .custom-file:nth-last-child(n + 3) .custom-file-label::after {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group-prepend,
.input-group-append {
  display: flex;
}

.input-group-prepend .btn,
.input-group-append .btn {
  position: relative;
  z-index: 2;
}

.input-group-prepend .btn:focus,
.input-group-append .btn:focus {
  z-index: 3;
}

.input-group-prepend .btn + .btn,
.input-group-prepend .btn + .input-group-text,
.input-group-prepend .input-group-text + .input-group-text,
.input-group-prepend .input-group-text + .btn,
.input-group-append .btn + .btn,
.input-group-append .btn + .input-group-text,
.input-group-append .input-group-text + .input-group-text,
.input-group-append .input-group-text + .btn {
  margin-left: -1px;
}

.input-group-prepend {
  margin-right: -1px;
}

.input-group-append {
  margin-left: -1px;
}

.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.375rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  text-align: center;
  white-space: nowrap;
  background-color: #e9ecef;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
}

.input-group-text input[type="radio"],
.input-group-text input[type="checkbox"] {
  margin-top: 0;
}

.input-group-lg > .form-control:not(textarea),
.input-group-lg > .custom-select {
  height: calc(1.5em + 1rem + 2px);
}

.input-group-lg > .form-control,
.input-group-lg > .custom-select,
.input-group-lg > .input-group-prepend > .input-group-text,
.input-group-lg > .input-group-append > .input-group-text,
.input-group-lg > .input-group-prepend > .btn,
.input-group-lg > .input-group-append > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}

.input-group-sm > .form-control:not(textarea),
.input-group-sm > .custom-select {
  height: calc(1.5em + 0.5rem + 2px);
}

.input-group-sm > .form-control,
.input-group-sm > .custom-select,
.input-group-sm > .input-group-prepend > .input-group-text,
.input-group-sm > .input-group-append > .input-group-text,
.input-group-sm > .input-group-prepend > .btn,
.input-group-sm > .input-group-append > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}

.input-group-lg > .custom-select,
.input-group-sm > .custom-select {
  padding-right: 1.75rem;
}

.input-group > .input-group-prepend > .btn,
.input-group > .input-group-prepend > .input-group-text,
.input-group:not(.has-validation) > .input-group-append:not(:last-child) > .btn,
.input-group:not(.has-validation) > .input-group-append:not(:last-child) > .input-group-text,
.input-group.has-validation > .input-group-append:nth-last-child(n + 3) > .btn,
.input-group.has-validation > .input-group-append:nth-last-child(n + 3) > .input-group-text,
.input-group > .input-group-append:last-child > .btn:not(:last-child):not(.dropdown-toggle),
.input-group > .input-group-append:last-child > .input-group-text:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group > .input-group-append > .btn,
.input-group > .input-group-append > .input-group-text,
.input-group > .input-group-prepend:not(:first-child) > .btn,
.input-group > .input-group-prepend:not(:first-child) > .input-group-text,
.input-group > .input-group-prepend:first-child > .btn:not(:first-child),
.input-group > .input-group-prepend:first-child > .input-group-text:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.custom-control {
  position: relative;
  z-index: 1;
  display: block;
  min-height: 1.5rem;
  padding-left: 1.5rem;
  -webkit-print-color-adjust: exact;
  color-adjust: exact;
  print-color-adjust: exact;
}

.custom-control-inline {
  display: inline-flex;
  margin-right: 1rem;
}

.custom-control-input {
  position: absolute;
  left: 0;
  z-index: -1;
  width: 1rem;
  height: 1.25rem;
  opacity: 0;
}

.custom-control-input:checked ~ .custom-control-label::before {
  color: #fff;
  border-color: #007bff;
  background-color: #007bff;
}

.custom-control-input:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.custom-control-input:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #80bdff;
}

.custom-control-input:not(:disabled):active ~ .custom-control-label::before {
  color: #fff;
  background-color: #b3d7ff;
  border-color: #b3d7ff;
}

.custom-control-input[disabled] ~ .custom-control-label, .custom-control-input:disabled ~ .custom-control-label {
  color: #6c757d;
}

.custom-control-input[disabled] ~ .custom-control-label::before, .custom-control-input:disabled ~ .custom-control-label::before {
  background-color: #e9ecef;
}

.custom-control-label {
  position: relative;
  margin-bottom: 0;
  vertical-align: top;
}

.custom-control-label::before {
  position: absolute;
  top: 0.25rem;
  left: -1.5rem;
  display: block;
  width: 1rem;
  height: 1rem;
  pointer-events: none;
  content: "";
  background-color: #fff;
  border: 1px solid #adb5bd;
}

.custom-control-label::after {
  position: absolute;
  top: 0.25rem;
  left: -1.5rem;
  display: block;
  width: 1rem;
  height: 1rem;
  content: "";
  background: 50% / 50% 50% no-repeat;
}

.custom-checkbox .custom-control-label::before {
  border-radius: 0.25rem;
}

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26l2.974 2.99L8 2.193z'/%3e%3c/svg%3e");
}

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::before {
  border-color: #007bff;
  background-color: #007bff;
}

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='4' viewBox='0 0 4 4'%3e%3cpath stroke='%23fff' d='M0 2h4'/%3e%3c/svg%3e");
}

.custom-checkbox .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(0, 123, 255, 0.5);
}

.custom-checkbox .custom-control-input:disabled:indeterminate ~ .custom-control-label::before {
  background-color: rgba(0, 123, 255, 0.5);
}

.custom-radio .custom-control-label::before {
  border-radius: 50%;
}

.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
}

.custom-radio .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(0, 123, 255, 0.5);
}

.custom-switch {
  padding-left: 2.25rem;
}

.custom-switch .custom-control-label::before {
  left: -2.25rem;
  width: 1.75rem;
  pointer-events: all;
  border-radius: 0.5rem;
}

.custom-switch .custom-control-label::after {
  top: calc(0.25rem + 2px);
  left: calc(-2.25rem + 2px);
  width: calc(1rem - 4px);
  height: calc(1rem - 4px);
  background-color: #adb5bd;
  border-radius: 0.5rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-transform 0.15s ease-in-out;
  transition: transform 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: transform 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-transform 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .custom-switch .custom-control-label::after {
    transition: none;
  }
}

.custom-switch .custom-control-input:checked ~ .custom-control-label::after {
  background-color: #fff;
  -webkit-transform: translateX(0.75rem);
  transform: translateX(0.75rem);
}

.custom-switch .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(0, 123, 255, 0.5);
}

.custom-select {
  display: inline-block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 1.75rem 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  vertical-align: middle;
  background: #fff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") right 0.75rem center/8px 10px no-repeat;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  -webkit-appearance: none;
  appearance: none;
}

.custom-select:focus {
  border-color: #80bdff;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.custom-select:focus::-ms-value {
  color: #495057;
  background-color: #fff;
}

.custom-select[multiple], .custom-select[size]:not([size="1"]) {
  height: auto;
  padding-right: 0.75rem;
  background-image: none;
}

.custom-select:disabled {
  color: #6c757d;
  background-color: #e9ecef;
}

.custom-select::-ms-expand {
  display: none;
}

.custom-select:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #495057;
}

.custom-select-sm {
  height: calc(1.5em + 0.5rem + 2px);
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
  font-size: 0.875rem;
}

.custom-select-lg {
  height: calc(1.5em + 1rem + 2px);
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  font-size: 1.25rem;
}

.custom-file {
  position: relative;
  display: inline-block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  margin-bottom: 0;
}

.custom-file-input {
  position: relative;
  z-index: 2;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  margin: 0;
  overflow: hidden;
  opacity: 0;
}

.custom-file-input:focus ~ .custom-file-label {
  border-color: #80bdff;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.custom-file-input[disabled] ~ .custom-file-label,
.custom-file-input:disabled ~ .custom-file-label {
  background-color: #e9ecef;
}

.custom-file-input:lang(en) ~ .custom-file-label::after {
  content: "Browse";
}

.custom-file-input ~ .custom-file-label[data-browse]::after {
  content: attr(data-browse);
}

.custom-file-label {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  overflow: hidden;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
}

.custom-file-label::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 3;
  display: block;
  height: calc(1.5em + 0.75rem);
  padding: 0.375rem 0.75rem;
  line-height: 1.5;
  color: #495057;
  content: "Browse";
  background-color: #e9ecef;
  border-left: inherit;
  border-radius: 0 0.25rem 0.25rem 0;
}

.custom-range {
  width: 100%;
  height: 1.4rem;
  padding: 0;
  background-color: transparent;
  -webkit-appearance: none;
  appearance: none;
}

.custom-range:focus {
  outline: 0;
}

.custom-range:focus::-webkit-slider-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.custom-range:focus::-moz-range-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.custom-range:focus::-ms-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.custom-range::-moz-focus-outer {
  border: 0;
}

.custom-range::-webkit-slider-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: -0.25rem;
  background-color: #007bff;
  border: 0;
  border-radius: 1rem;
  -webkit-transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  -webkit-appearance: none;
  appearance: none;
}

@media (prefers-reduced-motion: reduce) {
  .custom-range::-webkit-slider-thumb {
    -webkit-transition: none;
    transition: none;
  }
}

.custom-range::-webkit-slider-thumb:active {
  background-color: #b3d7ff;
}

.custom-range::-webkit-slider-runnable-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #dee2e6;
  border-color: transparent;
  border-radius: 1rem;
}

.custom-range::-moz-range-thumb {
  width: 1rem;
  height: 1rem;
  background-color: #007bff;
  border: 0;
  border-radius: 1rem;
  -moz-transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}

@media (prefers-reduced-motion: reduce) {
  .custom-range::-moz-range-thumb {
    -moz-transition: none;
    transition: none;
  }
}

.custom-range::-moz-range-thumb:active {
  background-color: #b3d7ff;
}

.custom-range::-moz-range-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #dee2e6;
  border-color: transparent;
  border-radius: 1rem;
}

.custom-range::-ms-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: 0;
  margin-right: 0.2rem;
  margin-left: 0.2rem;
  background-color: #007bff;
  border: 0;
  border-radius: 1rem;
  -ms-transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}

@media (prefers-reduced-motion: reduce) {
  .custom-range::-ms-thumb {
    -ms-transition: none;
    transition: none;
  }
}

.custom-range::-ms-thumb:active {
  background-color: #b3d7ff;
}

.custom-range::-ms-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: transparent;
  border-color: transparent;
  border-width: 0.5rem;
}

.custom-range::-ms-fill-lower {
  background-color: #dee2e6;
  border-radius: 1rem;
}

.custom-range::-ms-fill-upper {
  margin-right: 15px;
  background-color: #dee2e6;
  border-radius: 1rem;
}

.custom-range:disabled::-webkit-slider-thumb {
  background-color: #adb5bd;
}

.custom-range:disabled::-webkit-slider-runnable-track {
  cursor: default;
}

.custom-range:disabled::-moz-range-thumb {
  background-color: #adb5bd;
}

.custom-range:disabled::-moz-range-track {
  cursor: default;
}

.custom-range:disabled::-ms-thumb {
  background-color: #adb5bd;
}

.custom-control-label::before,
.custom-file-label,
.custom-select {
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .custom-control-label::before,
  .custom-file-label,
  .custom-select {
    transition: none;
  }
}

.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.nav-link {
  display: block;
  padding: 0.5rem 1rem;
}

.nav-link:hover, .nav-link:focus {
  text-decoration: none;
}

.nav-link.disabled {
  color: #6c757d;
  pointer-events: none;
  cursor: default;
}

.nav-tabs {
  border-bottom: 1px solid #dee2e6;
}

.nav-tabs .nav-link {
  margin-bottom: -1px;
  background-color: transparent;
  border: 1px solid transparent;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}

.nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
  isolation: isolate;
  border-color: #e9ecef #e9ecef #dee2e6;
}

.nav-tabs .nav-link.disabled {
  color: #6c757d;
  background-color: transparent;
  border-color: transparent;
}

.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  color: #495057;
  background-color: #fff;
  border-color: #dee2e6 #dee2e6 #fff;
}

.nav-tabs .dropdown-menu {
  margin-top: -1px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav-pills .nav-link {
  background: none;
  border: 0;
  border-radius: 0.25rem;
}

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff;
  background-color: #007bff;
}

.nav-fill > .nav-link,
.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center;
}

.nav-justified > .nav-link,
.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center;
}

.tab-content > .tab-pane {
  display: none;
}

.tab-content > .active {
  display: block;
}

.navbar {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 1rem;
}

.navbar .container,
.navbar .container-fluid, .navbar .container-sm, .navbar .container-md, .navbar .container-lg, .navbar .container-xl {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}

.navbar-brand {
  display: inline-block;
  padding-top: 0.3125rem;
  padding-bottom: 0.3125rem;
  margin-right: 1rem;
  font-size: 1.25rem;
  line-height: inherit;
  white-space: nowrap;
}

.navbar-brand:hover, .navbar-brand:focus {
  text-decoration: none;
}

.navbar-nav {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.navbar-nav .nav-link {
  padding-right: 0;
  padding-left: 0;
}

.navbar-nav .dropdown-menu {
  position: static;
  float: none;
}

.navbar-text {
  display: inline-block;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center;
}

.navbar-toggler {
  padding: 0.25rem 0.75rem;
  font-size: 1.25rem;
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 0.25rem;
}

.navbar-toggler:hover, .navbar-toggler:focus {
  text-decoration: none;
}

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  content: "";
  background: 50% / 100% 100% no-repeat;
}

.navbar-nav-scroll {
  max-height: 75vh;
  overflow-y: auto;
}

@media (max-width: 575.98px) {
  .navbar-expand-sm > .container,
  .navbar-expand-sm > .container-fluid, .navbar-expand-sm > .container-sm, .navbar-expand-sm > .container-md, .navbar-expand-sm > .container-lg, .navbar-expand-sm > .container-xl {
    padding-right: 0;
    padding-left: 0;
  }
}

@media (min-width: 576px) {
  .navbar-expand-sm {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-sm .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-sm .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-sm .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-sm > .container,
  .navbar-expand-sm > .container-fluid, .navbar-expand-sm > .container-sm, .navbar-expand-sm > .container-md, .navbar-expand-sm > .container-lg, .navbar-expand-sm > .container-xl {
    flex-wrap: nowrap;
  }
  .navbar-expand-sm .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-sm .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-sm .navbar-toggler {
    display: none;
  }
}

@media (max-width: 767.98px) {
  .navbar-expand-md > .container,
  .navbar-expand-md > .container-fluid, .navbar-expand-md > .container-sm, .navbar-expand-md > .container-md, .navbar-expand-md > .container-lg, .navbar-expand-md > .container-xl {
    padding-right: 0;
    padding-left: 0;
  }
}

@media (min-width: 768px) {
  .navbar-expand-md {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-md .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-md .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-md .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-md > .container,
  .navbar-expand-md > .container-fluid, .navbar-expand-md > .container-sm, .navbar-expand-md > .container-md, .navbar-expand-md > .container-lg, .navbar-expand-md > .container-xl {
    flex-wrap: nowrap;
  }
  .navbar-expand-md .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-md .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-md .navbar-toggler {
    display: none;
  }
}

@media (max-width: 991.98px) {
  .navbar-expand-lg > .container,
  .navbar-expand-lg > .container-fluid, .navbar-expand-lg > .container-sm, .navbar-expand-lg > .container-md, .navbar-expand-lg > .container-lg, .navbar-expand-lg > .container-xl {
    padding-right: 0;
    padding-left: 0;
  }
}

@media (min-width: 992px) {
  .navbar-expand-lg {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-lg .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-lg .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-lg > .container,
  .navbar-expand-lg > .container-fluid, .navbar-expand-lg > .container-sm, .navbar-expand-lg > .container-md, .navbar-expand-lg > .container-lg, .navbar-expand-lg > .container-xl {
    flex-wrap: nowrap;
  }
  .navbar-expand-lg .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-lg .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-lg .navbar-toggler {
    display: none;
  }
}

@media (max-width: 1199.98px) {
  .navbar-expand-xl > .container,
  .navbar-expand-xl > .container-fluid, .navbar-expand-xl > .container-sm, .navbar-expand-xl > .container-md, .navbar-expand-xl > .container-lg, .navbar-expand-xl > .container-xl {
    padding-right: 0;
    padding-left: 0;
  }
}

@media (min-width: 1200px) {
  .navbar-expand-xl {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-xl .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-xl .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-xl .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-xl > .container,
  .navbar-expand-xl > .container-fluid, .navbar-expand-xl > .container-sm, .navbar-expand-xl > .container-md, .navbar-expand-xl > .container-lg, .navbar-expand-xl > .container-xl {
    flex-wrap: nowrap;
  }
  .navbar-expand-xl .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-xl .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-xl .navbar-toggler {
    display: none;
  }
}

.navbar-expand {
  flex-flow: row nowrap;
  justify-content: flex-start;
}

.navbar-expand > .container,
.navbar-expand > .container-fluid, .navbar-expand > .container-sm, .navbar-expand > .container-md, .navbar-expand > .container-lg, .navbar-expand > .container-xl {
  padding-right: 0;
  padding-left: 0;
}

.navbar-expand .navbar-nav {
  flex-direction: row;
}

.navbar-expand .navbar-nav .dropdown-menu {
  position: absolute;
}

.navbar-expand .navbar-nav .nav-link {
  padding-right: 0.5rem;
  padding-left: 0.5rem;
}

.navbar-expand > .container,
.navbar-expand > .container-fluid, .navbar-expand > .container-sm, .navbar-expand > .container-md, .navbar-expand > .container-lg, .navbar-expand > .container-xl {
  flex-wrap: nowrap;
}

.navbar-expand .navbar-nav-scroll {
  overflow: visible;
}

.navbar-expand .navbar-collapse {
  display: flex !important;
  flex-basis: auto;
}

.navbar-expand .navbar-toggler {
  display: none;
}

.navbar-light .navbar-brand {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-light .navbar-brand:hover, .navbar-light .navbar-brand:focus {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-light .navbar-nav .nav-link {
  color: rgba(0, 0, 0, 0.5);
}

.navbar-light .navbar-nav .nav-link:hover, .navbar-light .navbar-nav .nav-link:focus {
  color: rgba(0, 0, 0, 0.7);
}

.navbar-light .navbar-nav .nav-link.disabled {
  color: rgba(0, 0, 0, 0.3);
}

.navbar-light .navbar-nav .show > .nav-link,
.navbar-light .navbar-nav .active > .nav-link,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .nav-link.active {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-light .navbar-toggler {
  color: rgba(0, 0, 0, 0.5);
  border-color: rgba(0, 0, 0, 0.1);
}

.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%280, 0, 0, 0.5%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

.navbar-light .navbar-text {
  color: rgba(0, 0, 0, 0.5);
}

.navbar-light .navbar-text a {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-light .navbar-text a:hover, .navbar-light .navbar-text a:focus {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-dark .navbar-brand {
  color: #fff;
}

.navbar-dark .navbar-brand:hover, .navbar-dark .navbar-brand:focus {
  color: #fff;
}

.navbar-dark .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 0.5);
}

.navbar-dark .navbar-nav .nav-link:hover, .navbar-dark .navbar-nav .nav-link:focus {
  color: rgba(255, 255, 255, 0.75);
}

.navbar-dark .navbar-nav .nav-link.disabled {
  color: rgba(255, 255, 255, 0.25);
}

.navbar-dark .navbar-nav .show > .nav-link,
.navbar-dark .navbar-nav .active > .nav-link,
.navbar-dark .navbar-nav .nav-link.show,
.navbar-dark .navbar-nav .nav-link.active {
  color: #fff;
}

.navbar-dark .navbar-toggler {
  color: rgba(255, 255, 255, 0.5);
  border-color: rgba(255, 255, 255, 0.1);
}

.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.5%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

.navbar-dark .navbar-text {
  color: rgba(255, 255, 255, 0.5);
}

.navbar-dark .navbar-text a {
  color: #fff;
}

.navbar-dark .navbar-text a:hover, .navbar-dark .navbar-text a:focus {
  color: #fff;
}

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
}

.card > hr {
  margin-right: 0;
  margin-left: 0;
}

.card > .list-group {
  border-top: inherit;
  border-bottom: inherit;
}

.card > .list-group:first-child {
  border-top-width: 0;
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}

.card > .list-group:last-child {
  border-bottom-width: 0;
  border-bottom-right-radius: calc(0.25rem - 1px);
  border-bottom-left-radius: calc(0.25rem - 1px);
}

.card > .card-header + .list-group,
.card > .list-group + .card-footer {
  border-top: 0;
}

.card-body {
  flex: 1 1 auto;
  min-height: 1px;
  padding: 1.25rem;
}

.card-title {
  margin-bottom: 0.75rem;
}

.card-subtitle {
  margin-top: -0.375rem;
  margin-bottom: 0;
}

.card-text:last-child {
  margin-bottom: 0;
}

.card-link:hover {
  text-decoration: none;
}

.card-link + .card-link {
  margin-left: 1.25rem;
}

.card-header {
  padding: 0.75rem 1.25rem;
  margin-bottom: 0;
  background-color: rgba(0, 0, 0, 0.03);
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}

.card-header:first-child {
  border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0;
}

.card-footer {
  padding: 0.75rem 1.25rem;
  background-color: rgba(0, 0, 0, 0.03);
  border-top: 1px solid rgba(0, 0, 0, 0.125);
}

.card-footer:last-child {
  border-radius: 0 0 calc(0.25rem - 1px) calc(0.25rem - 1px);
}

.card-header-tabs {
  margin-right: -0.625rem;
  margin-bottom: -0.75rem;
  margin-left: -0.625rem;
  border-bottom: 0;
}

.card-header-pills {
  margin-right: -0.625rem;
  margin-left: -0.625rem;
}

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1.25rem;
  border-radius: calc(0.25rem - 1px);
}

.card-img,
.card-img-top,
.card-img-bottom {
  flex-shrink: 0;
  width: 100%;
}

.card-img,
.card-img-top {
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}

.card-img,
.card-img-bottom {
  border-bottom-right-radius: calc(0.25rem - 1px);
  border-bottom-left-radius: calc(0.25rem - 1px);
}

.card-deck .card {
  margin-bottom: 15px;
}

@media (min-width: 576px) {
  .card-deck {
    display: flex;
    flex-flow: row wrap;
    margin-right: -15px;
    margin-left: -15px;
  }
  .card-deck .card {
    flex: 1 0;
    margin-right: 15px;
    margin-bottom: 0;
    margin-left: 15px;
  }
}

.card-group > .card {
  margin-bottom: 15px;
}

@media (min-width: 576px) {
  .card-group {
    display: flex;
    flex-flow: row wrap;
  }
  .card-group > .card {
    flex: 1 0;
    margin-bottom: 0;
  }
  .card-group > .card + .card {
    margin-left: 0;
    border-left: 0;
  }
  .card-group > .card:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-top,
  .card-group > .card:not(:last-child) .card-header {
    border-top-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-bottom,
  .card-group > .card:not(:last-child) .card-footer {
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-top,
  .card-group > .card:not(:first-child) .card-header {
    border-top-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-bottom,
  .card-group > .card:not(:first-child) .card-footer {
    border-bottom-left-radius: 0;
  }
}

.card-columns .card {
  margin-bottom: 0.75rem;
}

@media (min-width: 576px) {
  .card-columns {
    -webkit-column-count: 3;
    column-count: 3;
    -webkit-column-gap: 1.25rem;
    column-gap: 1.25rem;
    orphans: 1;
    widows: 1;
  }
  .card-columns .card {
    display: inline-block;
    width: 100%;
  }
}

.accordion {
  overflow-anchor: none;
}

.accordion > .card {
  overflow: hidden;
}

.accordion > .card:not(:last-of-type) {
  border-bottom: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.accordion > .card:not(:first-of-type) {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.accordion > .card > .card-header {
  border-radius: 0;
  margin-bottom: -1px;
}

.breadcrumb {
  display: flex;
  flex-wrap: wrap;
  padding: 0.75rem 1rem;
  margin-bottom: 1rem;
  list-style: none;
  background-color: #e9ecef;
  border-radius: 0.25rem;
}

.breadcrumb-item + .breadcrumb-item {
  padding-left: 0.5rem;
}

.breadcrumb-item + .breadcrumb-item::before {
  float: left;
  padding-right: 0.5rem;
  color: #6c757d;
  content: "/";
}

.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: underline;
}

.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: none;
}

.breadcrumb-item.active {
  color: #6c757d;
}

.pagination {
  display: flex;
  padding-left: 0;
  list-style: none;
  border-radius: 0.25rem;
}

.page-link {
  position: relative;
  display: block;
  padding: 0.5rem 0.75rem;
  margin-left: -1px;
  line-height: 1.25;
  color: #007bff;
  background-color: #fff;
  border: 1px solid #dee2e6;
}

.page-link:hover {
  z-index: 2;
  color: #0056b3;
  text-decoration: none;
  background-color: #e9ecef;
  border-color: #dee2e6;
}

.page-link:focus {
  z-index: 3;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.page-item:first-child .page-link {
  margin-left: 0;
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}

.page-item:last-child .page-link {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}

.page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}

.page-item.disabled .page-link {
  color: #6c757d;
  pointer-events: none;
  cursor: auto;
  background-color: #fff;
  border-color: #dee2e6;
}

.pagination-lg .page-link {
  padding: 0.75rem 1.5rem;
  font-size: 1.25rem;
  line-height: 1.5;
}

.pagination-lg .page-item:first-child .page-link {
  border-top-left-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem;
}

.pagination-lg .page-item:last-child .page-link {
  border-top-right-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem;
}

.pagination-sm .page-link {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
}

.pagination-sm .page-item:first-child .page-link {
  border-top-left-radius: 0.2rem;
  border-bottom-left-radius: 0.2rem;
}

.pagination-sm .page-item:last-child .page-link {
  border-top-right-radius: 0.2rem;
  border-bottom-right-radius: 0.2rem;
}

.badge {
  display: inline-block;
  padding: 0.25em 0.4em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .badge {
    transition: none;
  }
}

a.badge:hover, a.badge:focus {
  text-decoration: none;
}

.badge:empty {
  display: none;
}

.btn .badge {
  position: relative;
  top: -1px;
}

.badge-pill {
  padding-right: 0.6em;
  padding-left: 0.6em;
  border-radius: 10rem;
}

.badge-primary {
  color: #fff;
  background-color: #007bff;
}

a.badge-primary:hover, a.badge-primary:focus {
  color: #fff;
  background-color: #0062cc;
}

a.badge-primary:focus, a.badge-primary.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5);
}

.badge-secondary {
  color: #fff;
  background-color: #6c757d;
}

a.badge-secondary:hover, a.badge-secondary:focus {
  color: #fff;
  background-color: #545b62;
}

a.badge-secondary:focus, a.badge-secondary.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
}

.badge-success {
  color: #fff;
  background-color: #28a745;
}

a.badge-success:hover, a.badge-success:focus {
  color: #fff;
  background-color: #1e7e34;
}

a.badge-success:focus, a.badge-success.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5);
}

.badge-info {
  color: #fff;
  background-color: #17a2b8;
}

a.badge-info:hover, a.badge-info:focus {
  color: #fff;
  background-color: #117a8b;
}

a.badge-info:focus, a.badge-info.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
}

.badge-warning {
  color: #212529;
  background-color: #ffc107;
}

a.badge-warning:hover, a.badge-warning:focus {
  color: #212529;
  background-color: #d39e00;
}

a.badge-warning:focus, a.badge-warning.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
}

.badge-danger {
  color: #fff;
  background-color: #dc3545;
}

a.badge-danger:hover, a.badge-danger:focus {
  color: #fff;
  background-color: #bd2130;
}

a.badge-danger:focus, a.badge-danger.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5);
}

.badge-light {
  color: #212529;
  background-color: #f8f9fa;
}

a.badge-light:hover, a.badge-light:focus {
  color: #212529;
  background-color: #dae0e5;
}

a.badge-light:focus, a.badge-light.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}

.badge-dark {
  color: #fff;
  background-color: #343a40;
}

a.badge-dark:hover, a.badge-dark:focus {
  color: #fff;
  background-color: #1d2124;
}

a.badge-dark:focus, a.badge-dark.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
}

.jumbotron {
  padding: 2rem 1rem;
  margin-bottom: 2rem;
  background-color: #e9ecef;
  border-radius: 0.3rem;
}

@media (min-width: 576px) {
  .jumbotron {
    padding: 4rem 2rem;
  }
}

.jumbotron-fluid {
  padding-right: 0;
  padding-left: 0;
  border-radius: 0;
}

.alert {
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem;
}

.alert-heading {
  color: inherit;
}

.alert-link {
  font-weight: 700;
}

.alert-dismissible {
  padding-right: 4rem;
}

.alert-dismissible .close {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
  padding: 0.75rem 1.25rem;
  color: inherit;
}

.alert-primary {
  color: #004085;
  background-color: #cce5ff;
  border-color: #b8daff;
}

.alert-primary hr {
  border-top-color: #9fcdff;
}

.alert-primary .alert-link {
  color: #002752;
}

.alert-secondary {
  color: #383d41;
  background-color: #e2e3e5;
  border-color: #d6d8db;
}

.alert-secondary hr {
  border-top-color: #c8cbcf;
}

.alert-secondary .alert-link {
  color: #202326;
}

.alert-success {
  color: #155724;
  background-color: #d4edda;
  border-color: #c3e6cb;
}

.alert-success hr {
  border-top-color: #b1dfbb;
}

.alert-success .alert-link {
  color: #0b2e13;
}

.alert-info {
  color: #0c5460;
  background-color: #d1ecf1;
  border-color: #bee5eb;
}

.alert-info hr {
  border-top-color: #abdde5;
}

.alert-info .alert-link {
  color: #062c33;
}

.alert-warning {
  color: #856404;
  background-color: #fff3cd;
  border-color: #ffeeba;
}

.alert-warning hr {
  border-top-color: #ffe8a1;
}

.alert-warning .alert-link {
  color: #533f03;
}

.alert-danger {
  color: #721c24;
  background-color: #f8d7da;
  border-color: #f5c6cb;
}

.alert-danger hr {
  border-top-color: #f1b0b7;
}

.alert-danger .alert-link {
  color: #491217;
}

.alert-light {
  color: #818182;
  background-color: #fefefe;
  border-color: #fdfdfe;
}

.alert-light hr {
  border-top-color: #ececf6;
}

.alert-light .alert-link {
  color: #686868;
}

.alert-dark {
  color: #1b1e21;
  background-color: #d6d8d9;
  border-color: #c6c8ca;
}

.alert-dark hr {
  border-top-color: #b9bbbe;
}

.alert-dark .alert-link {
  color: #040505;
}

@-webkit-keyframes progress-bar-stripes {
  from {
    background-position: 1rem 0;
  }
  to {
    background-position: 0 0;
  }
}

@keyframes progress-bar-stripes {
  from {
    background-position: 1rem 0;
  }
  to {
    background-position: 0 0;
  }
}

.progress {
  display: flex;
  height: 1rem;
  overflow: hidden;
  line-height: 0;
  font-size: 0.75rem;
  background-color: #e9ecef;
  border-radius: 0.25rem;
}

.progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  background-color: #007bff;
  transition: width 0.6s ease;
}

@media (prefers-reduced-motion: reduce) {
  .progress-bar {
    transition: none;
  }
}

.progress-bar-striped {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: 1rem 1rem;
}

.progress-bar-animated {
  -webkit-animation: 1s linear infinite progress-bar-stripes;
  animation: 1s linear infinite progress-bar-stripes;
}

@media (prefers-reduced-motion: reduce) {
  .progress-bar-animated {
    -webkit-animation: none;
    animation: none;
  }
}

.media {
  display: flex;
  align-items: flex-start;
}

.media-body {
  flex: 1 1;
}

.list-group {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  border-radius: 0.25rem;
}

.list-group-item-action {
  width: 100%;
  color: #495057;
  text-align: inherit;
}

.list-group-item-action:hover, .list-group-item-action:focus {
  z-index: 1;
  color: #495057;
  text-decoration: none;
  background-color: #f8f9fa;
}

.list-group-item-action:active {
  color: #212529;
  background-color: #e9ecef;
}

.list-group-item {
  position: relative;
  display: block;
  padding: 0.75rem 1.25rem;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.125);
}

.list-group-item:first-child {
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
}

.list-group-item:last-child {
  border-bottom-right-radius: inherit;
  border-bottom-left-radius: inherit;
}

.list-group-item.disabled, .list-group-item:disabled {
  color: #6c757d;
  pointer-events: none;
  background-color: #fff;
}

.list-group-item.active {
  z-index: 2;
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}

.list-group-item + .list-group-item {
  border-top-width: 0;
}

.list-group-item + .list-group-item.active {
  margin-top: -1px;
  border-top-width: 1px;
}

.list-group-horizontal {
  flex-direction: row;
}

.list-group-horizontal > .list-group-item:first-child {
  border-bottom-left-radius: 0.25rem;
  border-top-right-radius: 0;
}

.list-group-horizontal > .list-group-item:last-child {
  border-top-right-radius: 0.25rem;
  border-bottom-left-radius: 0;
}

.list-group-horizontal > .list-group-item.active {
  margin-top: 0;
}

.list-group-horizontal > .list-group-item + .list-group-item {
  border-top-width: 1px;
  border-left-width: 0;
}

.list-group-horizontal > .list-group-item + .list-group-item.active {
  margin-left: -1px;
  border-left-width: 1px;
}

@media (min-width: 576px) {
  .list-group-horizontal-sm {
    flex-direction: row;
  }
  .list-group-horizontal-sm > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-sm > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-sm > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-sm > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-sm > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}

@media (min-width: 768px) {
  .list-group-horizontal-md {
    flex-direction: row;
  }
  .list-group-horizontal-md > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-md > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-md > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-md > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-md > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}

@media (min-width: 992px) {
  .list-group-horizontal-lg {
    flex-direction: row;
  }
  .list-group-horizontal-lg > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-lg > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-lg > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-lg > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-lg > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}

@media (min-width: 1200px) {
  .list-group-horizontal-xl {
    flex-direction: row;
  }
  .list-group-horizontal-xl > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-xl > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-xl > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-xl > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-xl > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}

.list-group-flush {
  border-radius: 0;
}

.list-group-flush > .list-group-item {
  border-width: 0 0 1px;
}

.list-group-flush > .list-group-item:last-child {
  border-bottom-width: 0;
}

.list-group-item-primary {
  color: #004085;
  background-color: #b8daff;
}

.list-group-item-primary.list-group-item-action:hover, .list-group-item-primary.list-group-item-action:focus {
  color: #004085;
  background-color: #9fcdff;
}

.list-group-item-primary.list-group-item-action.active {
  color: #fff;
  background-color: #004085;
  border-color: #004085;
}

.list-group-item-secondary {
  color: #383d41;
  background-color: #d6d8db;
}

.list-group-item-secondary.list-group-item-action:hover, .list-group-item-secondary.list-group-item-action:focus {
  color: #383d41;
  background-color: #c8cbcf;
}

.list-group-item-secondary.list-group-item-action.active {
  color: #fff;
  background-color: #383d41;
  border-color: #383d41;
}

.list-group-item-success {
  color: #155724;
  background-color: #c3e6cb;
}

.list-group-item-success.list-group-item-action:hover, .list-group-item-success.list-group-item-action:focus {
  color: #155724;
  background-color: #b1dfbb;
}

.list-group-item-success.list-group-item-action.active {
  color: #fff;
  background-color: #155724;
  border-color: #155724;
}

.list-group-item-info {
  color: #0c5460;
  background-color: #bee5eb;
}

.list-group-item-info.list-group-item-action:hover, .list-group-item-info.list-group-item-action:focus {
  color: #0c5460;
  background-color: #abdde5;
}

.list-group-item-info.list-group-item-action.active {
  color: #fff;
  background-color: #0c5460;
  border-color: #0c5460;
}

.list-group-item-warning {
  color: #856404;
  background-color: #ffeeba;
}

.list-group-item-warning.list-group-item-action:hover, .list-group-item-warning.list-group-item-action:focus {
  color: #856404;
  background-color: #ffe8a1;
}

.list-group-item-warning.list-group-item-action.active {
  color: #fff;
  background-color: #856404;
  border-color: #856404;
}

.list-group-item-danger {
  color: #721c24;
  background-color: #f5c6cb;
}

.list-group-item-danger.list-group-item-action:hover, .list-group-item-danger.list-group-item-action:focus {
  color: #721c24;
  background-color: #f1b0b7;
}

.list-group-item-danger.list-group-item-action.active {
  color: #fff;
  background-color: #721c24;
  border-color: #721c24;
}

.list-group-item-light {
  color: #818182;
  background-color: #fdfdfe;
}

.list-group-item-light.list-group-item-action:hover, .list-group-item-light.list-group-item-action:focus {
  color: #818182;
  background-color: #ececf6;
}

.list-group-item-light.list-group-item-action.active {
  color: #fff;
  background-color: #818182;
  border-color: #818182;
}

.list-group-item-dark {
  color: #1b1e21;
  background-color: #c6c8ca;
}

.list-group-item-dark.list-group-item-action:hover, .list-group-item-dark.list-group-item-action:focus {
  color: #1b1e21;
  background-color: #b9bbbe;
}

.list-group-item-dark.list-group-item-action.active {
  color: #fff;
  background-color: #1b1e21;
  border-color: #1b1e21;
}

.close {
  float: right;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: .5;
}

.close:hover {
  color: #000;
  text-decoration: none;
}

.close:not(:disabled):not(.disabled):hover, .close:not(:disabled):not(.disabled):focus {
  opacity: .75;
}

button.close {
  padding: 0;
  background-color: transparent;
  border: 0;
}

a.close.disabled {
  pointer-events: none;
}

.toast {
  flex-basis: 350px;
  max-width: 350px;
  font-size: 0.875rem;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, 0.1);
  opacity: 0;
  border-radius: 0.25rem;
}

.toast:not(:last-child) {
  margin-bottom: 0.75rem;
}

.toast.showing {
  opacity: 1;
}

.toast.show {
  display: block;
  opacity: 1;
}

.toast.hide {
  display: none;
}

.toast-header {
  display: flex;
  align-items: center;
  padding: 0.25rem 0.75rem;
  color: #6c757d;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}

.toast-body {
  padding: 0.75rem;
}

.modal-open {
  overflow: hidden;
}

.modal-open .modal {
  overflow-x: hidden;
  overflow-y: auto;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  display: none;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0;
}

.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none;
}

.modal.fade .modal-dialog {
  transition: -webkit-transform 0.3s ease-out;
  transition: transform 0.3s ease-out;
  transition: transform 0.3s ease-out, -webkit-transform 0.3s ease-out;
  -webkit-transform: translate(0, -50px);
  transform: translate(0, -50px);
}

@media (prefers-reduced-motion: reduce) {
  .modal.fade .modal-dialog {
    transition: none;
  }
}

.modal.show .modal-dialog {
  -webkit-transform: none;
  transform: none;
}

.modal.modal-static .modal-dialog {
  -webkit-transform: scale(1.02);
  transform: scale(1.02);
}

.modal-dialog-scrollable {
  display: flex;
  max-height: calc(100% - 1rem);
}

.modal-dialog-scrollable .modal-content {
  max-height: calc(100vh - 1rem);
  overflow: hidden;
}

.modal-dialog-scrollable .modal-header,
.modal-dialog-scrollable .modal-footer {
  flex-shrink: 0;
}

.modal-dialog-scrollable .modal-body {
  overflow-y: auto;
}

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - 1rem);
}

.modal-dialog-centered::before {
  display: block;
  height: calc(100vh - 1rem);
  height: -webkit-min-content;
  height: min-content;
  content: "";
}

.modal-dialog-centered.modal-dialog-scrollable {
  flex-direction: column;
  justify-content: center;
  height: 100%;
}

.modal-dialog-centered.modal-dialog-scrollable .modal-content {
  max-height: none;
}

.modal-dialog-centered.modal-dialog-scrollable::before {
  content: none;
}

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  outline: 0;
}

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000;
}

.modal-backdrop.fade {
  opacity: 0;
}

.modal-backdrop.show {
  opacity: 0.5;
}

.modal-header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 1rem 1rem;
  border-bottom: 1px solid #dee2e6;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px);
}

.modal-header .close {
  padding: 1rem 1rem;
  margin: -1rem -1rem -1rem auto;
}

.modal-title {
  margin-bottom: 0;
  line-height: 1.5;
}

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 1rem;
}

.modal-footer {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  padding: 0.75rem;
  border-top: 1px solid #dee2e6;
  border-bottom-right-radius: calc(0.3rem - 1px);
  border-bottom-left-radius: calc(0.3rem - 1px);
}

.modal-footer > * {
  margin: 0.25rem;
}

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll;
}

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto;
  }
  .modal-dialog-scrollable {
    max-height: calc(100% - 3.5rem);
  }
  .modal-dialog-scrollable .modal-content {
    max-height: calc(100vh - 3.5rem);
  }
  .modal-dialog-centered {
    min-height: calc(100% - 3.5rem);
  }
  .modal-dialog-centered::before {
    height: calc(100vh - 3.5rem);
    height: -webkit-min-content;
    height: min-content;
  }
  .modal-sm {
    max-width: 300px;
  }
}

@media (min-width: 992px) {
  .modal-lg,
  .modal-xl {
    max-width: 800px;
  }
}

@media (min-width: 1200px) {
  .modal-xl {
    max-width: 1140px;
  }
}

.tooltip {
  position: absolute;
  z-index: 1070;
  display: block;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  white-space: normal;
  word-spacing: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  opacity: 0;
}

.tooltip.show {
  opacity: 0.9;
}

.tooltip .arrow {
  position: absolute;
  display: block;
  width: 0.8rem;
  height: 0.4rem;
}

.tooltip .arrow::before {
  position: absolute;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-tooltip-top, .bs-tooltip-auto[x-placement^="top"] {
  padding: 0.4rem 0;
}

.bs-tooltip-top .arrow, .bs-tooltip-auto[x-placement^="top"] .arrow {
  bottom: 0;
}

.bs-tooltip-top .arrow::before, .bs-tooltip-auto[x-placement^="top"] .arrow::before {
  top: 0;
  border-width: 0.4rem 0.4rem 0;
  border-top-color: #000;
}

.bs-tooltip-right, .bs-tooltip-auto[x-placement^="right"] {
  padding: 0 0.4rem;
}

.bs-tooltip-right .arrow, .bs-tooltip-auto[x-placement^="right"] .arrow {
  left: 0;
  width: 0.4rem;
  height: 0.8rem;
}

.bs-tooltip-right .arrow::before, .bs-tooltip-auto[x-placement^="right"] .arrow::before {
  right: 0;
  border-width: 0.4rem 0.4rem 0.4rem 0;
  border-right-color: #000;
}

.bs-tooltip-bottom, .bs-tooltip-auto[x-placement^="bottom"] {
  padding: 0.4rem 0;
}

.bs-tooltip-bottom .arrow, .bs-tooltip-auto[x-placement^="bottom"] .arrow {
  top: 0;
}

.bs-tooltip-bottom .arrow::before, .bs-tooltip-auto[x-placement^="bottom"] .arrow::before {
  bottom: 0;
  border-width: 0 0.4rem 0.4rem;
  border-bottom-color: #000;
}

.bs-tooltip-left, .bs-tooltip-auto[x-placement^="left"] {
  padding: 0 0.4rem;
}

.bs-tooltip-left .arrow, .bs-tooltip-auto[x-placement^="left"] .arrow {
  right: 0;
  width: 0.4rem;
  height: 0.8rem;
}

.bs-tooltip-left .arrow::before, .bs-tooltip-auto[x-placement^="left"] .arrow::before {
  left: 0;
  border-width: 0.4rem 0 0.4rem 0.4rem;
  border-left-color: #000;
}

.tooltip-inner {
  max-width: 200px;
  padding: 0.25rem 0.5rem;
  color: #fff;
  text-align: center;
  background-color: #000;
  border-radius: 0.25rem;
}

.popover {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1060;
  display: block;
  max-width: 276px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  white-space: normal;
  word-spacing: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
}

.popover .arrow {
  position: absolute;
  display: block;
  width: 1rem;
  height: 0.5rem;
  margin: 0 0.3rem;
}

.popover .arrow::before, .popover .arrow::after {
  position: absolute;
  display: block;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-popover-top, .bs-popover-auto[x-placement^="top"] {
  margin-bottom: 0.5rem;
}

.bs-popover-top > .arrow, .bs-popover-auto[x-placement^="top"] > .arrow {
  bottom: calc(-0.5rem - 1px);
}

.bs-popover-top > .arrow::before, .bs-popover-auto[x-placement^="top"] > .arrow::before {
  bottom: 0;
  border-width: 0.5rem 0.5rem 0;
  border-top-color: rgba(0, 0, 0, 0.25);
}

.bs-popover-top > .arrow::after, .bs-popover-auto[x-placement^="top"] > .arrow::after {
  bottom: 1px;
  border-width: 0.5rem 0.5rem 0;
  border-top-color: #fff;
}

.bs-popover-right, .bs-popover-auto[x-placement^="right"] {
  margin-left: 0.5rem;
}

.bs-popover-right > .arrow, .bs-popover-auto[x-placement^="right"] > .arrow {
  left: calc(-0.5rem - 1px);
  width: 0.5rem;
  height: 1rem;
  margin: 0.3rem 0;
}

.bs-popover-right > .arrow::before, .bs-popover-auto[x-placement^="right"] > .arrow::before {
  left: 0;
  border-width: 0.5rem 0.5rem 0.5rem 0;
  border-right-color: rgba(0, 0, 0, 0.25);
}

.bs-popover-right > .arrow::after, .bs-popover-auto[x-placement^="right"] > .arrow::after {
  left: 1px;
  border-width: 0.5rem 0.5rem 0.5rem 0;
  border-right-color: #fff;
}

.bs-popover-bottom, .bs-popover-auto[x-placement^="bottom"] {
  margin-top: 0.5rem;
}

.bs-popover-bottom > .arrow, .bs-popover-auto[x-placement^="bottom"] > .arrow {
  top: calc(-0.5rem - 1px);
}

.bs-popover-bottom > .arrow::before, .bs-popover-auto[x-placement^="bottom"] > .arrow::before {
  top: 0;
  border-width: 0 0.5rem 0.5rem 0.5rem;
  border-bottom-color: rgba(0, 0, 0, 0.25);
}

.bs-popover-bottom > .arrow::after, .bs-popover-auto[x-placement^="bottom"] > .arrow::after {
  top: 1px;
  border-width: 0 0.5rem 0.5rem 0.5rem;
  border-bottom-color: #fff;
}

.bs-popover-bottom .popover-header::before, .bs-popover-auto[x-placement^="bottom"] .popover-header::before {
  position: absolute;
  top: 0;
  left: 50%;
  display: block;
  width: 1rem;
  margin-left: -0.5rem;
  content: "";
  border-bottom: 1px solid #f7f7f7;
}

.bs-popover-left, .bs-popover-auto[x-placement^="left"] {
  margin-right: 0.5rem;
}

.bs-popover-left > .arrow, .bs-popover-auto[x-placement^="left"] > .arrow {
  right: calc(-0.5rem - 1px);
  width: 0.5rem;
  height: 1rem;
  margin: 0.3rem 0;
}

.bs-popover-left > .arrow::before, .bs-popover-auto[x-placement^="left"] > .arrow::before {
  right: 0;
  border-width: 0.5rem 0 0.5rem 0.5rem;
  border-left-color: rgba(0, 0, 0, 0.25);
}

.bs-popover-left > .arrow::after, .bs-popover-auto[x-placement^="left"] > .arrow::after {
  right: 1px;
  border-width: 0.5rem 0 0.5rem 0.5rem;
  border-left-color: #fff;
}

.popover-header {
  padding: 0.5rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  background-color: #f7f7f7;
  border-bottom: 1px solid #ebebeb;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px);
}

.popover-header:empty {
  display: none;
}

.popover-body {
  padding: 0.5rem 0.75rem;
  color: #212529;
}

.carousel {
  position: relative;
}

.carousel.pointer-event {
  touch-action: pan-y;
}

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden;
}

.carousel-inner::after {
  display: block;
  clear: both;
  content: "";
}

.carousel-item {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-right: -100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  transition: -webkit-transform 0.6s ease-in-out;
  transition: transform 0.6s ease-in-out;
  transition: transform 0.6s ease-in-out, -webkit-transform 0.6s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .carousel-item {
    transition: none;
  }
}

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: block;
}

.carousel-item-next:not(.carousel-item-left),
.active.carousel-item-right {
  -webkit-transform: translateX(100%);
  transform: translateX(100%);
}

.carousel-item-prev:not(.carousel-item-right),
.active.carousel-item-left {
  -webkit-transform: translateX(-100%);
  transform: translateX(-100%);
}

.carousel-fade .carousel-item {
  opacity: 0;
  transition-property: opacity;
  -webkit-transform: none;
  transform: none;
}

.carousel-fade .carousel-item.active,
.carousel-fade .carousel-item-next.carousel-item-left,
.carousel-fade .carousel-item-prev.carousel-item-right {
  z-index: 1;
  opacity: 1;
}

.carousel-fade .active.carousel-item-left,
.carousel-fade .active.carousel-item-right {
  z-index: 0;
  opacity: 0;
  transition: opacity 0s 0.6s;
}

@media (prefers-reduced-motion: reduce) {
  .carousel-fade .active.carousel-item-left,
  .carousel-fade .active.carousel-item-right {
    transition: none;
  }
}

.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  padding: 0;
  color: #fff;
  text-align: center;
  background: none;
  border: 0;
  opacity: 0.5;
  transition: opacity 0.15s ease;
}

@media (prefers-reduced-motion: reduce) {
  .carousel-control-prev,
  .carousel-control-next {
    transition: none;
  }
}

.carousel-control-prev:hover, .carousel-control-prev:focus,
.carousel-control-next:hover,
.carousel-control-next:focus {
  color: #fff;
  text-decoration: none;
  outline: 0;
  opacity: 0.9;
}

.carousel-control-prev {
  left: 0;
}

.carousel-control-next {
  right: 0;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: 20px;
  height: 20px;
  background: 50% / 100% 100% no-repeat;
}

.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M5.25 0l-4 4 4 4 1.5-1.5L4.25 4l2.5-2.5L5.25 0z'/%3e%3c/svg%3e");
}

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M2.75 0l-1.5 1.5L3.75 4l-2.5 2.5L2.75 8l4-4-4-4z'/%3e%3c/svg%3e");
}

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 15;
  display: flex;
  justify-content: center;
  padding-left: 0;
  margin-right: 15%;
  margin-left: 15%;
  list-style: none;
}

.carousel-indicators li {
  box-sizing: content-box;
  flex: 0 1 auto;
  width: 30px;
  height: 3px;
  margin-right: 3px;
  margin-left: 3px;
  text-indent: -999px;
  cursor: pointer;
  background-color: #fff;
  background-clip: padding-box;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  opacity: .5;
  transition: opacity 0.6s ease;
}

@media (prefers-reduced-motion: reduce) {
  .carousel-indicators li {
    transition: none;
  }
}

.carousel-indicators .active {
  opacity: 1;
}

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 20px;
  left: 15%;
  z-index: 10;
  padding-top: 20px;
  padding-bottom: 20px;
  color: #fff;
  text-align: center;
}

@-webkit-keyframes spinner-border {
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes spinner-border {
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.spinner-border {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: -0.125em;
  border: 0.25em solid currentcolor;
  border-right-color: transparent;
  border-radius: 50%;
  -webkit-animation: .75s linear infinite spinner-border;
  animation: .75s linear infinite spinner-border;
}

.spinner-border-sm {
  width: 1rem;
  height: 1rem;
  border-width: 0.2em;
}

@-webkit-keyframes spinner-grow {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  50% {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

@keyframes spinner-grow {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  50% {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

.spinner-grow {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: -0.125em;
  background-color: currentcolor;
  border-radius: 50%;
  opacity: 0;
  -webkit-animation: .75s linear infinite spinner-grow;
  animation: .75s linear infinite spinner-grow;
}

.spinner-grow-sm {
  width: 1rem;
  height: 1rem;
}

@media (prefers-reduced-motion: reduce) {
  .spinner-border,
  .spinner-grow {
    -webkit-animation-duration: 1.5s;
    animation-duration: 1.5s;
  }
}

.align-baseline {
  vertical-align: baseline !important;
}

.align-top {
  vertical-align: top !important;
}

.align-middle {
  vertical-align: middle !important;
}

.align-bottom {
  vertical-align: bottom !important;
}

.align-text-bottom {
  vertical-align: text-bottom !important;
}

.align-text-top {
  vertical-align: text-top !important;
}

.bg-primary {
  background-color: #007bff !important;
}

a.bg-primary:hover, a.bg-primary:focus,
button.bg-primary:hover,
button.bg-primary:focus {
  background-color: #0062cc !important;
}

.bg-secondary {
  background-color: #6c757d !important;
}

a.bg-secondary:hover, a.bg-secondary:focus,
button.bg-secondary:hover,
button.bg-secondary:focus {
  background-color: #545b62 !important;
}

.bg-success {
  background-color: #28a745 !important;
}

a.bg-success:hover, a.bg-success:focus,
button.bg-success:hover,
button.bg-success:focus {
  background-color: #1e7e34 !important;
}

.bg-info {
  background-color: #17a2b8 !important;
}

a.bg-info:hover, a.bg-info:focus,
button.bg-info:hover,
button.bg-info:focus {
  background-color: #117a8b !important;
}

.bg-warning {
  background-color: #ffc107 !important;
}

a.bg-warning:hover, a.bg-warning:focus,
button.bg-warning:hover,
button.bg-warning:focus {
  background-color: #d39e00 !important;
}

.bg-danger {
  background-color: #dc3545 !important;
}

a.bg-danger:hover, a.bg-danger:focus,
button.bg-danger:hover,
button.bg-danger:focus {
  background-color: #bd2130 !important;
}

.bg-light {
  background-color: #f8f9fa !important;
}

a.bg-light:hover, a.bg-light:focus,
button.bg-light:hover,
button.bg-light:focus {
  background-color: #dae0e5 !important;
}

.bg-dark {
  background-color: #343a40 !important;
}

a.bg-dark:hover, a.bg-dark:focus,
button.bg-dark:hover,
button.bg-dark:focus {
  background-color: #1d2124 !important;
}

.bg-white {
  background-color: #fff !important;
}

.bg-transparent {
  background-color: transparent !important;
}

.border {
  border: 1px solid #dee2e6 !important;
}

.border-top {
  border-top: 1px solid #dee2e6 !important;
}

.border-right {
  border-right: 1px solid #dee2e6 !important;
}

.border-bottom {
  border-bottom: 1px solid #dee2e6 !important;
}

.border-left {
  border-left: 1px solid #dee2e6 !important;
}

.border-0 {
  border: 0 !important;
}

.border-top-0 {
  border-top: 0 !important;
}

.border-right-0 {
  border-right: 0 !important;
}

.border-bottom-0 {
  border-bottom: 0 !important;
}

.border-left-0 {
  border-left: 0 !important;
}

.border-primary {
  border-color: #007bff !important;
}

.border-secondary {
  border-color: #6c757d !important;
}

.border-success {
  border-color: #28a745 !important;
}

.border-info {
  border-color: #17a2b8 !important;
}

.border-warning {
  border-color: #ffc107 !important;
}

.border-danger {
  border-color: #dc3545 !important;
}

.border-light {
  border-color: #f8f9fa !important;
}

.border-dark {
  border-color: #343a40 !important;
}

.border-white {
  border-color: #fff !important;
}

.rounded-sm {
  border-radius: 0.2rem !important;
}

.rounded {
  border-radius: 0.25rem !important;
}

.rounded-top {
  border-top-left-radius: 0.25rem !important;
  border-top-right-radius: 0.25rem !important;
}

.rounded-right {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important;
}

.rounded-bottom {
  border-bottom-right-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important;
}

.rounded-left {
  border-top-left-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important;
}

.rounded-lg {
  border-radius: 0.3rem !important;
}

.rounded-circle {
  border-radius: 50% !important;
}

.rounded-pill {
  border-radius: 50rem !important;
}

.rounded-0 {
  border-radius: 0 !important;
}

.clearfix::after {
  display: block;
  clear: both;
  content: "";
}

.d-none {
  display: none !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex {
  display: flex !important;
}

.d-inline-flex {
  display: inline-flex !important;
}

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important;
  }
  .d-sm-inline {
    display: inline !important;
  }
  .d-sm-inline-block {
    display: inline-block !important;
  }
  .d-sm-block {
    display: block !important;
  }
  .d-sm-table {
    display: table !important;
  }
  .d-sm-table-row {
    display: table-row !important;
  }
  .d-sm-table-cell {
    display: table-cell !important;
  }
  .d-sm-flex {
    display: flex !important;
  }
  .d-sm-inline-flex {
    display: inline-flex !important;
  }
}

@media (min-width: 768px) {
  .d-md-none {
    display: none !important;
  }
  .d-md-inline {
    display: inline !important;
  }
  .d-md-inline-block {
    display: inline-block !important;
  }
  .d-md-block {
    display: block !important;
  }
  .d-md-table {
    display: table !important;
  }
  .d-md-table-row {
    display: table-row !important;
  }
  .d-md-table-cell {
    display: table-cell !important;
  }
  .d-md-flex {
    display: flex !important;
  }
  .d-md-inline-flex {
    display: inline-flex !important;
  }
}

@media (min-width: 992px) {
  .d-lg-none {
    display: none !important;
  }
  .d-lg-inline {
    display: inline !important;
  }
  .d-lg-inline-block {
    display: inline-block !important;
  }
  .d-lg-block {
    display: block !important;
  }
  .d-lg-table {
    display: table !important;
  }
  .d-lg-table-row {
    display: table-row !important;
  }
  .d-lg-table-cell {
    display: table-cell !important;
  }
  .d-lg-flex {
    display: flex !important;
  }
  .d-lg-inline-flex {
    display: inline-flex !important;
  }
}

@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important;
  }
  .d-xl-inline {
    display: inline !important;
  }
  .d-xl-inline-block {
    display: inline-block !important;
  }
  .d-xl-block {
    display: block !important;
  }
  .d-xl-table {
    display: table !important;
  }
  .d-xl-table-row {
    display: table-row !important;
  }
  .d-xl-table-cell {
    display: table-cell !important;
  }
  .d-xl-flex {
    display: flex !important;
  }
  .d-xl-inline-flex {
    display: inline-flex !important;
  }
}

@media print {
  .d-print-none {
    display: none !important;
  }
  .d-print-inline {
    display: inline !important;
  }
  .d-print-inline-block {
    display: inline-block !important;
  }
  .d-print-block {
    display: block !important;
  }
  .d-print-table {
    display: table !important;
  }
  .d-print-table-row {
    display: table-row !important;
  }
  .d-print-table-cell {
    display: table-cell !important;
  }
  .d-print-flex {
    display: flex !important;
  }
  .d-print-inline-flex {
    display: inline-flex !important;
  }
}

.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden;
}

.embed-responsive::before {
  display: block;
  content: "";
}

.embed-responsive .embed-responsive-item,
.embed-responsive iframe,
.embed-responsive embed,
.embed-responsive object,
.embed-responsive video {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}

.embed-responsive-21by9::before {
  padding-top: 42.857143%;
}

.embed-responsive-16by9::before {
  padding-top: 56.25%;
}

.embed-responsive-4by3::before {
  padding-top: 75%;
}

.embed-responsive-1by1::before {
  padding-top: 100%;
}

.flex-row {
  flex-direction: row !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  flex-direction: column-reverse !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.flex-fill {
  flex: 1 1 auto !important;
}

.flex-grow-0 {
  flex-grow: 0 !important;
}

.flex-grow-1 {
  flex-grow: 1 !important;
}

.flex-shrink-0 {
  flex-shrink: 0 !important;
}

.flex-shrink-1 {
  flex-shrink: 1 !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-baseline {
  align-items: baseline !important;
}

.align-items-stretch {
  align-items: stretch !important;
}

.align-content-start {
  align-content: flex-start !important;
}

.align-content-end {
  align-content: flex-end !important;
}

.align-content-center {
  align-content: center !important;
}

.align-content-between {
  align-content: space-between !important;
}

.align-content-around {
  align-content: space-around !important;
}

.align-content-stretch {
  align-content: stretch !important;
}

.align-self-auto {
  align-self: auto !important;
}

.align-self-start {
  align-self: flex-start !important;
}

.align-self-end {
  align-self: flex-end !important;
}

.align-self-center {
  align-self: center !important;
}

.align-self-baseline {
  align-self: baseline !important;
}

.align-self-stretch {
  align-self: stretch !important;
}

@media (min-width: 576px) {
  .flex-sm-row {
    flex-direction: row !important;
  }
  .flex-sm-column {
    flex-direction: column !important;
  }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-sm-wrap {
    flex-wrap: wrap !important;
  }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-sm-fill {
    flex: 1 1 auto !important;
  }
  .flex-sm-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-sm-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-sm-start {
    justify-content: flex-start !important;
  }
  .justify-content-sm-end {
    justify-content: flex-end !important;
  }
  .justify-content-sm-center {
    justify-content: center !important;
  }
  .justify-content-sm-between {
    justify-content: space-between !important;
  }
  .justify-content-sm-around {
    justify-content: space-around !important;
  }
  .align-items-sm-start {
    align-items: flex-start !important;
  }
  .align-items-sm-end {
    align-items: flex-end !important;
  }
  .align-items-sm-center {
    align-items: center !important;
  }
  .align-items-sm-baseline {
    align-items: baseline !important;
  }
  .align-items-sm-stretch {
    align-items: stretch !important;
  }
  .align-content-sm-start {
    align-content: flex-start !important;
  }
  .align-content-sm-end {
    align-content: flex-end !important;
  }
  .align-content-sm-center {
    align-content: center !important;
  }
  .align-content-sm-between {
    align-content: space-between !important;
  }
  .align-content-sm-around {
    align-content: space-around !important;
  }
  .align-content-sm-stretch {
    align-content: stretch !important;
  }
  .align-self-sm-auto {
    align-self: auto !important;
  }
  .align-self-sm-start {
    align-self: flex-start !important;
  }
  .align-self-sm-end {
    align-self: flex-end !important;
  }
  .align-self-sm-center {
    align-self: center !important;
  }
  .align-self-sm-baseline {
    align-self: baseline !important;
  }
  .align-self-sm-stretch {
    align-self: stretch !important;
  }
}

@media (min-width: 768px) {
  .flex-md-row {
    flex-direction: row !important;
  }
  .flex-md-column {
    flex-direction: column !important;
  }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-md-wrap {
    flex-wrap: wrap !important;
  }
  .flex-md-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-md-fill {
    flex: 1 1 auto !important;
  }
  .flex-md-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-md-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-md-start {
    justify-content: flex-start !important;
  }
  .justify-content-md-end {
    justify-content: flex-end !important;
  }
  .justify-content-md-center {
    justify-content: center !important;
  }
  .justify-content-md-between {
    justify-content: space-between !important;
  }
  .justify-content-md-around {
    justify-content: space-around !important;
  }
  .align-items-md-start {
    align-items: flex-start !important;
  }
  .align-items-md-end {
    align-items: flex-end !important;
  }
  .align-items-md-center {
    align-items: center !important;
  }
  .align-items-md-baseline {
    align-items: baseline !important;
  }
  .align-items-md-stretch {
    align-items: stretch !important;
  }
  .align-content-md-start {
    align-content: flex-start !important;
  }
  .align-content-md-end {
    align-content: flex-end !important;
  }
  .align-content-md-center {
    align-content: center !important;
  }
  .align-content-md-between {
    align-content: space-between !important;
  }
  .align-content-md-around {
    align-content: space-around !important;
  }
  .align-content-md-stretch {
    align-content: stretch !important;
  }
  .align-self-md-auto {
    align-self: auto !important;
  }
  .align-self-md-start {
    align-self: flex-start !important;
  }
  .align-self-md-end {
    align-self: flex-end !important;
  }
  .align-self-md-center {
    align-self: center !important;
  }
  .align-self-md-baseline {
    align-self: baseline !important;
  }
  .align-self-md-stretch {
    align-self: stretch !important;
  }
}

@media (min-width: 992px) {
  .flex-lg-row {
    flex-direction: row !important;
  }
  .flex-lg-column {
    flex-direction: column !important;
  }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-lg-wrap {
    flex-wrap: wrap !important;
  }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-lg-fill {
    flex: 1 1 auto !important;
  }
  .flex-lg-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-lg-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-lg-start {
    justify-content: flex-start !important;
  }
  .justify-content-lg-end {
    justify-content: flex-end !important;
  }
  .justify-content-lg-center {
    justify-content: center !important;
  }
  .justify-content-lg-between {
    justify-content: space-between !important;
  }
  .justify-content-lg-around {
    justify-content: space-around !important;
  }
  .align-items-lg-start {
    align-items: flex-start !important;
  }
  .align-items-lg-end {
    align-items: flex-end !important;
  }
  .align-items-lg-center {
    align-items: center !important;
  }
  .align-items-lg-baseline {
    align-items: baseline !important;
  }
  .align-items-lg-stretch {
    align-items: stretch !important;
  }
  .align-content-lg-start {
    align-content: flex-start !important;
  }
  .align-content-lg-end {
    align-content: flex-end !important;
  }
  .align-content-lg-center {
    align-content: center !important;
  }
  .align-content-lg-between {
    align-content: space-between !important;
  }
  .align-content-lg-around {
    align-content: space-around !important;
  }
  .align-content-lg-stretch {
    align-content: stretch !important;
  }
  .align-self-lg-auto {
    align-self: auto !important;
  }
  .align-self-lg-start {
    align-self: flex-start !important;
  }
  .align-self-lg-end {
    align-self: flex-end !important;
  }
  .align-self-lg-center {
    align-self: center !important;
  }
  .align-self-lg-baseline {
    align-self: baseline !important;
  }
  .align-self-lg-stretch {
    align-self: stretch !important;
  }
}

@media (min-width: 1200px) {
  .flex-xl-row {
    flex-direction: row !important;
  }
  .flex-xl-column {
    flex-direction: column !important;
  }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-xl-wrap {
    flex-wrap: wrap !important;
  }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-xl-fill {
    flex: 1 1 auto !important;
  }
  .flex-xl-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-xl-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-xl-start {
    justify-content: flex-start !important;
  }
  .justify-content-xl-end {
    justify-content: flex-end !important;
  }
  .justify-content-xl-center {
    justify-content: center !important;
  }
  .justify-content-xl-between {
    justify-content: space-between !important;
  }
  .justify-content-xl-around {
    justify-content: space-around !important;
  }
  .align-items-xl-start {
    align-items: flex-start !important;
  }
  .align-items-xl-end {
    align-items: flex-end !important;
  }
  .align-items-xl-center {
    align-items: center !important;
  }
  .align-items-xl-baseline {
    align-items: baseline !important;
  }
  .align-items-xl-stretch {
    align-items: stretch !important;
  }
  .align-content-xl-start {
    align-content: flex-start !important;
  }
  .align-content-xl-end {
    align-content: flex-end !important;
  }
  .align-content-xl-center {
    align-content: center !important;
  }
  .align-content-xl-between {
    align-content: space-between !important;
  }
  .align-content-xl-around {
    align-content: space-around !important;
  }
  .align-content-xl-stretch {
    align-content: stretch !important;
  }
  .align-self-xl-auto {
    align-self: auto !important;
  }
  .align-self-xl-start {
    align-self: flex-start !important;
  }
  .align-self-xl-end {
    align-self: flex-end !important;
  }
  .align-self-xl-center {
    align-self: center !important;
  }
  .align-self-xl-baseline {
    align-self: baseline !important;
  }
  .align-self-xl-stretch {
    align-self: stretch !important;
  }
}

.float-left {
  float: left !important;
}

.float-right {
  float: right !important;
}

.float-none {
  float: none !important;
}

@media (min-width: 576px) {
  .float-sm-left {
    float: left !important;
  }
  .float-sm-right {
    float: right !important;
  }
  .float-sm-none {
    float: none !important;
  }
}

@media (min-width: 768px) {
  .float-md-left {
    float: left !important;
  }
  .float-md-right {
    float: right !important;
  }
  .float-md-none {
    float: none !important;
  }
}

@media (min-width: 992px) {
  .float-lg-left {
    float: left !important;
  }
  .float-lg-right {
    float: right !important;
  }
  .float-lg-none {
    float: none !important;
  }
}

@media (min-width: 1200px) {
  .float-xl-left {
    float: left !important;
  }
  .float-xl-right {
    float: right !important;
  }
  .float-xl-none {
    float: none !important;
  }
}

.user-select-all {
  -webkit-user-select: all !important;
  user-select: all !important;
}

.user-select-auto {
  -webkit-user-select: auto !important;
  -ms-user-select: auto !important;
  user-select: auto !important;
}

.user-select-none {
  -webkit-user-select: none !important;
  -ms-user-select: none !important;
  user-select: none !important;
}

.overflow-auto {
  overflow: auto !important;
}

.overflow-hidden {
  overflow: hidden !important;
}

.position-static {
  position: static !important;
}

.position-relative {
  position: relative !important;
}

.position-absolute {
  position: absolute !important;
}

.position-fixed {
  position: fixed !important;
}

.position-sticky {
  position: -webkit-sticky !important;
  position: sticky !important;
}

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030;
}

@supports ((position: -webkit-sticky) or (position: sticky)) {
  .sticky-top {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}

.sr-only-focusable:active, .sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal;
}

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
}

.shadow {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
}

.shadow-none {
  box-shadow: none !important;
}

.w-25 {
  width: 25% !important;
}

.w-50 {
  width: 50% !important;
}

.w-75 {
  width: 75% !important;
}

.w-100 {
  width: 100% !important;
}

.w-auto {
  width: auto !important;
}

.h-25 {
  height: 25% !important;
}

.h-50 {
  height: 50% !important;
}

.h-75 {
  height: 75% !important;
}

.h-100 {
  height: 100% !important;
}

.h-auto {
  height: auto !important;
}

.mw-100 {
  max-width: 100% !important;
}

.mh-100 {
  max-height: 100% !important;
}

.min-vw-100 {
  min-width: 100vw !important;
}

.min-vh-100 {
  min-height: 100vh !important;
}

.vw-100 {
  width: 100vw !important;
}

.vh-100 {
  height: 100vh !important;
}

.m-0 {
  margin: 0 !important;
}

.mt-0,
.my-0 {
  margin-top: 0 !important;
}

.mr-0,
.mx-0 {
  margin-right: 0 !important;
}

.mb-0,
.my-0 {
  margin-bottom: 0 !important;
}

.ml-0,
.mx-0 {
  margin-left: 0 !important;
}

.m-1 {
  margin: 0.25rem !important;
}

.mt-1,
.my-1 {
  margin-top: 0.25rem !important;
}

.mr-1,
.mx-1 {
  margin-right: 0.25rem !important;
}

.mb-1,
.my-1 {
  margin-bottom: 0.25rem !important;
}

.ml-1,
.mx-1 {
  margin-left: 0.25rem !important;
}

.m-2 {
  margin: 0.5rem !important;
}

.mt-2,
.my-2 {
  margin-top: 0.5rem !important;
}

.mr-2,
.mx-2 {
  margin-right: 0.5rem !important;
}

.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important;
}

.ml-2,
.mx-2 {
  margin-left: 0.5rem !important;
}

.m-3 {
  margin: 1rem !important;
}

.mt-3,
.my-3 {
  margin-top: 1rem !important;
}

.mr-3,
.mx-3 {
  margin-right: 1rem !important;
}

.mb-3,
.my-3 {
  margin-bottom: 1rem !important;
}

.ml-3,
.mx-3 {
  margin-left: 1rem !important;
}

.m-4 {
  margin: 1.5rem !important;
}

.mt-4,
.my-4 {
  margin-top: 1.5rem !important;
}

.mr-4,
.mx-4 {
  margin-right: 1.5rem !important;
}

.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important;
}

.ml-4,
.mx-4 {
  margin-left: 1.5rem !important;
}

.m-5 {
  margin: 3rem !important;
}

.mt-5,
.my-5 {
  margin-top: 3rem !important;
}

.mr-5,
.mx-5 {
  margin-right: 3rem !important;
}

.mb-5,
.my-5 {
  margin-bottom: 3rem !important;
}

.ml-5,
.mx-5 {
  margin-left: 3rem !important;
}

.p-0 {
  padding: 0 !important;
}

.pt-0,
.py-0 {
  padding-top: 0 !important;
}

.pr-0,
.px-0 {
  padding-right: 0 !important;
}

.pb-0,
.py-0 {
  padding-bottom: 0 !important;
}

.pl-0,
.px-0 {
  padding-left: 0 !important;
}

.p-1 {
  padding: 0.25rem !important;
}

.pt-1,
.py-1 {
  padding-top: 0.25rem !important;
}

.pr-1,
.px-1 {
  padding-right: 0.25rem !important;
}

.pb-1,
.py-1 {
  padding-bottom: 0.25rem !important;
}

.pl-1,
.px-1 {
  padding-left: 0.25rem !important;
}

.p-2 {
  padding: 0.5rem !important;
}

.pt-2,
.py-2 {
  padding-top: 0.5rem !important;
}

.pr-2,
.px-2 {
  padding-right: 0.5rem !important;
}

.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important;
}

.pl-2,
.px-2 {
  padding-left: 0.5rem !important;
}

.p-3 {
  padding: 1rem !important;
}

.pt-3,
.py-3 {
  padding-top: 1rem !important;
}

.pr-3,
.px-3 {
  padding-right: 1rem !important;
}

.pb-3,
.py-3 {
  padding-bottom: 1rem !important;
}

.pl-3,
.px-3 {
  padding-left: 1rem !important;
}

.p-4 {
  padding: 1.5rem !important;
}

.pt-4,
.py-4 {
  padding-top: 1.5rem !important;
}

.pr-4,
.px-4 {
  padding-right: 1.5rem !important;
}

.pb-4,
.py-4 {
  padding-bottom: 1.5rem !important;
}

.pl-4,
.px-4 {
  padding-left: 1.5rem !important;
}

.p-5 {
  padding: 3rem !important;
}

.pt-5,
.py-5 {
  padding-top: 3rem !important;
}

.pr-5,
.px-5 {
  padding-right: 3rem !important;
}

.pb-5,
.py-5 {
  padding-bottom: 3rem !important;
}

.pl-5,
.px-5 {
  padding-left: 3rem !important;
}

.m-n1 {
  margin: -0.25rem !important;
}

.mt-n1,
.my-n1 {
  margin-top: -0.25rem !important;
}

.mr-n1,
.mx-n1 {
  margin-right: -0.25rem !important;
}

.mb-n1,
.my-n1 {
  margin-bottom: -0.25rem !important;
}

.ml-n1,
.mx-n1 {
  margin-left: -0.25rem !important;
}

.m-n2 {
  margin: -0.5rem !important;
}

.mt-n2,
.my-n2 {
  margin-top: -0.5rem !important;
}

.mr-n2,
.mx-n2 {
  margin-right: -0.5rem !important;
}

.mb-n2,
.my-n2 {
  margin-bottom: -0.5rem !important;
}

.ml-n2,
.mx-n2 {
  margin-left: -0.5rem !important;
}

.m-n3 {
  margin: -1rem !important;
}

.mt-n3,
.my-n3 {
  margin-top: -1rem !important;
}

.mr-n3,
.mx-n3 {
  margin-right: -1rem !important;
}

.mb-n3,
.my-n3 {
  margin-bottom: -1rem !important;
}

.ml-n3,
.mx-n3 {
  margin-left: -1rem !important;
}

.m-n4 {
  margin: -1.5rem !important;
}

.mt-n4,
.my-n4 {
  margin-top: -1.5rem !important;
}

.mr-n4,
.mx-n4 {
  margin-right: -1.5rem !important;
}

.mb-n4,
.my-n4 {
  margin-bottom: -1.5rem !important;
}

.ml-n4,
.mx-n4 {
  margin-left: -1.5rem !important;
}

.m-n5 {
  margin: -3rem !important;
}

.mt-n5,
.my-n5 {
  margin-top: -3rem !important;
}

.mr-n5,
.mx-n5 {
  margin-right: -3rem !important;
}

.mb-n5,
.my-n5 {
  margin-bottom: -3rem !important;
}

.ml-n5,
.mx-n5 {
  margin-left: -3rem !important;
}

.m-auto {
  margin: auto !important;
}

.mt-auto,
.my-auto {
  margin-top: auto !important;
}

.mr-auto,
.mx-auto {
  margin-right: auto !important;
}

.mb-auto,
.my-auto {
  margin-bottom: auto !important;
}

.ml-auto,
.mx-auto {
  margin-left: auto !important;
}

@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important;
  }
  .mt-sm-0,
  .my-sm-0 {
    margin-top: 0 !important;
  }
  .mr-sm-0,
  .mx-sm-0 {
    margin-right: 0 !important;
  }
  .mb-sm-0,
  .my-sm-0 {
    margin-bottom: 0 !important;
  }
  .ml-sm-0,
  .mx-sm-0 {
    margin-left: 0 !important;
  }
  .m-sm-1 {
    margin: 0.25rem !important;
  }
  .mt-sm-1,
  .my-sm-1 {
    margin-top: 0.25rem !important;
  }
  .mr-sm-1,
  .mx-sm-1 {
    margin-right: 0.25rem !important;
  }
  .mb-sm-1,
  .my-sm-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-sm-1,
  .mx-sm-1 {
    margin-left: 0.25rem !important;
  }
  .m-sm-2 {
    margin: 0.5rem !important;
  }
  .mt-sm-2,
  .my-sm-2 {
    margin-top: 0.5rem !important;
  }
  .mr-sm-2,
  .mx-sm-2 {
    margin-right: 0.5rem !important;
  }
  .mb-sm-2,
  .my-sm-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-sm-2,
  .mx-sm-2 {
    margin-left: 0.5rem !important;
  }
  .m-sm-3 {
    margin: 1rem !important;
  }
  .mt-sm-3,
  .my-sm-3 {
    margin-top: 1rem !important;
  }
  .mr-sm-3,
  .mx-sm-3 {
    margin-right: 1rem !important;
  }
  .mb-sm-3,
  .my-sm-3 {
    margin-bottom: 1rem !important;
  }
  .ml-sm-3,
  .mx-sm-3 {
    margin-left: 1rem !important;
  }
  .m-sm-4 {
    margin: 1.5rem !important;
  }
  .mt-sm-4,
  .my-sm-4 {
    margin-top: 1.5rem !important;
  }
  .mr-sm-4,
  .mx-sm-4 {
    margin-right: 1.5rem !important;
  }
  .mb-sm-4,
  .my-sm-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-sm-4,
  .mx-sm-4 {
    margin-left: 1.5rem !important;
  }
  .m-sm-5 {
    margin: 3rem !important;
  }
  .mt-sm-5,
  .my-sm-5 {
    margin-top: 3rem !important;
  }
  .mr-sm-5,
  .mx-sm-5 {
    margin-right: 3rem !important;
  }
  .mb-sm-5,
  .my-sm-5 {
    margin-bottom: 3rem !important;
  }
  .ml-sm-5,
  .mx-sm-5 {
    margin-left: 3rem !important;
  }
  .p-sm-0 {
    padding: 0 !important;
  }
  .pt-sm-0,
  .py-sm-0 {
    padding-top: 0 !important;
  }
  .pr-sm-0,
  .px-sm-0 {
    padding-right: 0 !important;
  }
  .pb-sm-0,
  .py-sm-0 {
    padding-bottom: 0 !important;
  }
  .pl-sm-0,
  .px-sm-0 {
    padding-left: 0 !important;
  }
  .p-sm-1 {
    padding: 0.25rem !important;
  }
  .pt-sm-1,
  .py-sm-1 {
    padding-top: 0.25rem !important;
  }
  .pr-sm-1,
  .px-sm-1 {
    padding-right: 0.25rem !important;
  }
  .pb-sm-1,
  .py-sm-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-sm-1,
  .px-sm-1 {
    padding-left: 0.25rem !important;
  }
  .p-sm-2 {
    padding: 0.5rem !important;
  }
  .pt-sm-2,
  .py-sm-2 {
    padding-top: 0.5rem !important;
  }
  .pr-sm-2,
  .px-sm-2 {
    padding-right: 0.5rem !important;
  }
  .pb-sm-2,
  .py-sm-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-sm-2,
  .px-sm-2 {
    padding-left: 0.5rem !important;
  }
  .p-sm-3 {
    padding: 1rem !important;
  }
  .pt-sm-3,
  .py-sm-3 {
    padding-top: 1rem !important;
  }
  .pr-sm-3,
  .px-sm-3 {
    padding-right: 1rem !important;
  }
  .pb-sm-3,
  .py-sm-3 {
    padding-bottom: 1rem !important;
  }
  .pl-sm-3,
  .px-sm-3 {
    padding-left: 1rem !important;
  }
  .p-sm-4 {
    padding: 1.5rem !important;
  }
  .pt-sm-4,
  .py-sm-4 {
    padding-top: 1.5rem !important;
  }
  .pr-sm-4,
  .px-sm-4 {
    padding-right: 1.5rem !important;
  }
  .pb-sm-4,
  .py-sm-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-sm-4,
  .px-sm-4 {
    padding-left: 1.5rem !important;
  }
  .p-sm-5 {
    padding: 3rem !important;
  }
  .pt-sm-5,
  .py-sm-5 {
    padding-top: 3rem !important;
  }
  .pr-sm-5,
  .px-sm-5 {
    padding-right: 3rem !important;
  }
  .pb-sm-5,
  .py-sm-5 {
    padding-bottom: 3rem !important;
  }
  .pl-sm-5,
  .px-sm-5 {
    padding-left: 3rem !important;
  }
  .m-sm-n1 {
    margin: -0.25rem !important;
  }
  .mt-sm-n1,
  .my-sm-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-sm-n1,
  .mx-sm-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-sm-n1,
  .my-sm-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-sm-n1,
  .mx-sm-n1 {
    margin-left: -0.25rem !important;
  }
  .m-sm-n2 {
    margin: -0.5rem !important;
  }
  .mt-sm-n2,
  .my-sm-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-sm-n2,
  .mx-sm-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-sm-n2,
  .my-sm-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-sm-n2,
  .mx-sm-n2 {
    margin-left: -0.5rem !important;
  }
  .m-sm-n3 {
    margin: -1rem !important;
  }
  .mt-sm-n3,
  .my-sm-n3 {
    margin-top: -1rem !important;
  }
  .mr-sm-n3,
  .mx-sm-n3 {
    margin-right: -1rem !important;
  }
  .mb-sm-n3,
  .my-sm-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-sm-n3,
  .mx-sm-n3 {
    margin-left: -1rem !important;
  }
  .m-sm-n4 {
    margin: -1.5rem !important;
  }
  .mt-sm-n4,
  .my-sm-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-sm-n4,
  .mx-sm-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-sm-n4,
  .my-sm-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-sm-n4,
  .mx-sm-n4 {
    margin-left: -1.5rem !important;
  }
  .m-sm-n5 {
    margin: -3rem !important;
  }
  .mt-sm-n5,
  .my-sm-n5 {
    margin-top: -3rem !important;
  }
  .mr-sm-n5,
  .mx-sm-n5 {
    margin-right: -3rem !important;
  }
  .mb-sm-n5,
  .my-sm-n5 {
    margin-bottom: -3rem !important;
  }
  .ml-sm-n5,
  .mx-sm-n5 {
    margin-left: -3rem !important;
  }
  .m-sm-auto {
    margin: auto !important;
  }
  .mt-sm-auto,
  .my-sm-auto {
    margin-top: auto !important;
  }
  .mr-sm-auto,
  .mx-sm-auto {
    margin-right: auto !important;
  }
  .mb-sm-auto,
  .my-sm-auto {
    margin-bottom: auto !important;
  }
  .ml-sm-auto,
  .mx-sm-auto {
    margin-left: auto !important;
  }
}

@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important;
  }
  .mt-md-0,
  .my-md-0 {
    margin-top: 0 !important;
  }
  .mr-md-0,
  .mx-md-0 {
    margin-right: 0 !important;
  }
  .mb-md-0,
  .my-md-0 {
    margin-bottom: 0 !important;
  }
  .ml-md-0,
  .mx-md-0 {
    margin-left: 0 !important;
  }
  .m-md-1 {
    margin: 0.25rem !important;
  }
  .mt-md-1,
  .my-md-1 {
    margin-top: 0.25rem !important;
  }
  .mr-md-1,
  .mx-md-1 {
    margin-right: 0.25rem !important;
  }
  .mb-md-1,
  .my-md-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-md-1,
  .mx-md-1 {
    margin-left: 0.25rem !important;
  }
  .m-md-2 {
    margin: 0.5rem !important;
  }
  .mt-md-2,
  .my-md-2 {
    margin-top: 0.5rem !important;
  }
  .mr-md-2,
  .mx-md-2 {
    margin-right: 0.5rem !important;
  }
  .mb-md-2,
  .my-md-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-md-2,
  .mx-md-2 {
    margin-left: 0.5rem !important;
  }
  .m-md-3 {
    margin: 1rem !important;
  }
  .mt-md-3,
  .my-md-3 {
    margin-top: 1rem !important;
  }
  .mr-md-3,
  .mx-md-3 {
    margin-right: 1rem !important;
  }
  .mb-md-3,
  .my-md-3 {
    margin-bottom: 1rem !important;
  }
  .ml-md-3,
  .mx-md-3 {
    margin-left: 1rem !important;
  }
  .m-md-4 {
    margin: 1.5rem !important;
  }
  .mt-md-4,
  .my-md-4 {
    margin-top: 1.5rem !important;
  }
  .mr-md-4,
  .mx-md-4 {
    margin-right: 1.5rem !important;
  }
  .mb-md-4,
  .my-md-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-md-4,
  .mx-md-4 {
    margin-left: 1.5rem !important;
  }
  .m-md-5 {
    margin: 3rem !important;
  }
  .mt-md-5,
  .my-md-5 {
    margin-top: 3rem !important;
  }
  .mr-md-5,
  .mx-md-5 {
    margin-right: 3rem !important;
  }
  .mb-md-5,
  .my-md-5 {
    margin-bottom: 3rem !important;
  }
  .ml-md-5,
  .mx-md-5 {
    margin-left: 3rem !important;
  }
  .p-md-0 {
    padding: 0 !important;
  }
  .pt-md-0,
  .py-md-0 {
    padding-top: 0 !important;
  }
  .pr-md-0,
  .px-md-0 {
    padding-right: 0 !important;
  }
  .pb-md-0,
  .py-md-0 {
    padding-bottom: 0 !important;
  }
  .pl-md-0,
  .px-md-0 {
    padding-left: 0 !important;
  }
  .p-md-1 {
    padding: 0.25rem !important;
  }
  .pt-md-1,
  .py-md-1 {
    padding-top: 0.25rem !important;
  }
  .pr-md-1,
  .px-md-1 {
    padding-right: 0.25rem !important;
  }
  .pb-md-1,
  .py-md-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-md-1,
  .px-md-1 {
    padding-left: 0.25rem !important;
  }
  .p-md-2 {
    padding: 0.5rem !important;
  }
  .pt-md-2,
  .py-md-2 {
    padding-top: 0.5rem !important;
  }
  .pr-md-2,
  .px-md-2 {
    padding-right: 0.5rem !important;
  }
  .pb-md-2,
  .py-md-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-md-2,
  .px-md-2 {
    padding-left: 0.5rem !important;
  }
  .p-md-3 {
    padding: 1rem !important;
  }
  .pt-md-3,
  .py-md-3 {
    padding-top: 1rem !important;
  }
  .pr-md-3,
  .px-md-3 {
    padding-right: 1rem !important;
  }
  .pb-md-3,
  .py-md-3 {
    padding-bottom: 1rem !important;
  }
  .pl-md-3,
  .px-md-3 {
    padding-left: 1rem !important;
  }
  .p-md-4 {
    padding: 1.5rem !important;
  }
  .pt-md-4,
  .py-md-4 {
    padding-top: 1.5rem !important;
  }
  .pr-md-4,
  .px-md-4 {
    padding-right: 1.5rem !important;
  }
  .pb-md-4,
  .py-md-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-md-4,
  .px-md-4 {
    padding-left: 1.5rem !important;
  }
  .p-md-5 {
    padding: 3rem !important;
  }
  .pt-md-5,
  .py-md-5 {
    padding-top: 3rem !important;
  }
  .pr-md-5,
  .px-md-5 {
    padding-right: 3rem !important;
  }
  .pb-md-5,
  .py-md-5 {
    padding-bottom: 3rem !important;
  }
  .pl-md-5,
  .px-md-5 {
    padding-left: 3rem !important;
  }
  .m-md-n1 {
    margin: -0.25rem !important;
  }
  .mt-md-n1,
  .my-md-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-md-n1,
  .mx-md-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-md-n1,
  .my-md-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-md-n1,
  .mx-md-n1 {
    margin-left: -0.25rem !important;
  }
  .m-md-n2 {
    margin: -0.5rem !important;
  }
  .mt-md-n2,
  .my-md-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-md-n2,
  .mx-md-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-md-n2,
  .my-md-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-md-n2,
  .mx-md-n2 {
    margin-left: -0.5rem !important;
  }
  .m-md-n3 {
    margin: -1rem !important;
  }
  .mt-md-n3,
  .my-md-n3 {
    margin-top: -1rem !important;
  }
  .mr-md-n3,
  .mx-md-n3 {
    margin-right: -1rem !important;
  }
  .mb-md-n3,
  .my-md-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-md-n3,
  .mx-md-n3 {
    margin-left: -1rem !important;
  }
  .m-md-n4 {
    margin: -1.5rem !important;
  }
  .mt-md-n4,
  .my-md-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-md-n4,
  .mx-md-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-md-n4,
  .my-md-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-md-n4,
  .mx-md-n4 {
    margin-left: -1.5rem !important;
  }
  .m-md-n5 {
    margin: -3rem !important;
  }
  .mt-md-n5,
  .my-md-n5 {
    margin-top: -3rem !important;
  }
  .mr-md-n5,
  .mx-md-n5 {
    margin-right: -3rem !important;
  }
  .mb-md-n5,
  .my-md-n5 {
    margin-bottom: -3rem !important;
  }
  .ml-md-n5,
  .mx-md-n5 {
    margin-left: -3rem !important;
  }
  .m-md-auto {
    margin: auto !important;
  }
  .mt-md-auto,
  .my-md-auto {
    margin-top: auto !important;
  }
  .mr-md-auto,
  .mx-md-auto {
    margin-right: auto !important;
  }
  .mb-md-auto,
  .my-md-auto {
    margin-bottom: auto !important;
  }
  .ml-md-auto,
  .mx-md-auto {
    margin-left: auto !important;
  }
}

@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0 !important;
  }
  .mt-lg-0,
  .my-lg-0 {
    margin-top: 0 !important;
  }
  .mr-lg-0,
  .mx-lg-0 {
    margin-right: 0 !important;
  }
  .mb-lg-0,
  .my-lg-0 {
    margin-bottom: 0 !important;
  }
  .ml-lg-0,
  .mx-lg-0 {
    margin-left: 0 !important;
  }
  .m-lg-1 {
    margin: 0.25rem !important;
  }
  .mt-lg-1,
  .my-lg-1 {
    margin-top: 0.25rem !important;
  }
  .mr-lg-1,
  .mx-lg-1 {
    margin-right: 0.25rem !important;
  }
  .mb-lg-1,
  .my-lg-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-lg-1,
  .mx-lg-1 {
    margin-left: 0.25rem !important;
  }
  .m-lg-2 {
    margin: 0.5rem !important;
  }
  .mt-lg-2,
  .my-lg-2 {
    margin-top: 0.5rem !important;
  }
  .mr-lg-2,
  .mx-lg-2 {
    margin-right: 0.5rem !important;
  }
  .mb-lg-2,
  .my-lg-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-lg-2,
  .mx-lg-2 {
    margin-left: 0.5rem !important;
  }
  .m-lg-3 {
    margin: 1rem !important;
  }
  .mt-lg-3,
  .my-lg-3 {
    margin-top: 1rem !important;
  }
  .mr-lg-3,
  .mx-lg-3 {
    margin-right: 1rem !important;
  }
  .mb-lg-3,
  .my-lg-3 {
    margin-bottom: 1rem !important;
  }
  .ml-lg-3,
  .mx-lg-3 {
    margin-left: 1rem !important;
  }
  .m-lg-4 {
    margin: 1.5rem !important;
  }
  .mt-lg-4,
  .my-lg-4 {
    margin-top: 1.5rem !important;
  }
  .mr-lg-4,
  .mx-lg-4 {
    margin-right: 1.5rem !important;
  }
  .mb-lg-4,
  .my-lg-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-lg-4,
  .mx-lg-4 {
    margin-left: 1.5rem !important;
  }
  .m-lg-5 {
    margin: 3rem !important;
  }
  .mt-lg-5,
  .my-lg-5 {
    margin-top: 3rem !important;
  }
  .mr-lg-5,
  .mx-lg-5 {
    margin-right: 3rem !important;
  }
  .mb-lg-5,
  .my-lg-5 {
    margin-bottom: 3rem !important;
  }
  .ml-lg-5,
  .mx-lg-5 {
    margin-left: 3rem !important;
  }
  .p-lg-0 {
    padding: 0 !important;
  }
  .pt-lg-0,
  .py-lg-0 {
    padding-top: 0 !important;
  }
  .pr-lg-0,
  .px-lg-0 {
    padding-right: 0 !important;
  }
  .pb-lg-0,
  .py-lg-0 {
    padding-bottom: 0 !important;
  }
  .pl-lg-0,
  .px-lg-0 {
    padding-left: 0 !important;
  }
  .p-lg-1 {
    padding: 0.25rem !important;
  }
  .pt-lg-1,
  .py-lg-1 {
    padding-top: 0.25rem !important;
  }
  .pr-lg-1,
  .px-lg-1 {
    padding-right: 0.25rem !important;
  }
  .pb-lg-1,
  .py-lg-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-lg-1,
  .px-lg-1 {
    padding-left: 0.25rem !important;
  }
  .p-lg-2 {
    padding: 0.5rem !important;
  }
  .pt-lg-2,
  .py-lg-2 {
    padding-top: 0.5rem !important;
  }
  .pr-lg-2,
  .px-lg-2 {
    padding-right: 0.5rem !important;
  }
  .pb-lg-2,
  .py-lg-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-lg-2,
  .px-lg-2 {
    padding-left: 0.5rem !important;
  }
  .p-lg-3 {
    padding: 1rem !important;
  }
  .pt-lg-3,
  .py-lg-3 {
    padding-top: 1rem !important;
  }
  .pr-lg-3,
  .px-lg-3 {
    padding-right: 1rem !important;
  }
  .pb-lg-3,
  .py-lg-3 {
    padding-bottom: 1rem !important;
  }
  .pl-lg-3,
  .px-lg-3 {
    padding-left: 1rem !important;
  }
  .p-lg-4 {
    padding: 1.5rem !important;
  }
  .pt-lg-4,
  .py-lg-4 {
    padding-top: 1.5rem !important;
  }
  .pr-lg-4,
  .px-lg-4 {
    padding-right: 1.5rem !important;
  }
  .pb-lg-4,
  .py-lg-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-lg-4,
  .px-lg-4 {
    padding-left: 1.5rem !important;
  }
  .p-lg-5 {
    padding: 3rem !important;
  }
  .pt-lg-5,
  .py-lg-5 {
    padding-top: 3rem !important;
  }
  .pr-lg-5,
  .px-lg-5 {
    padding-right: 3rem !important;
  }
  .pb-lg-5,
  .py-lg-5 {
    padding-bottom: 3rem !important;
  }
  .pl-lg-5,
  .px-lg-5 {
    padding-left: 3rem !important;
  }
  .m-lg-n1 {
    margin: -0.25rem !important;
  }
  .mt-lg-n1,
  .my-lg-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-lg-n1,
  .mx-lg-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-lg-n1,
  .my-lg-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-lg-n1,
  .mx-lg-n1 {
    margin-left: -0.25rem !important;
  }
  .m-lg-n2 {
    margin: -0.5rem !important;
  }
  .mt-lg-n2,
  .my-lg-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-lg-n2,
  .mx-lg-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-lg-n2,
  .my-lg-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-lg-n2,
  .mx-lg-n2 {
    margin-left: -0.5rem !important;
  }
  .m-lg-n3 {
    margin: -1rem !important;
  }
  .mt-lg-n3,
  .my-lg-n3 {
    margin-top: -1rem !important;
  }
  .mr-lg-n3,
  .mx-lg-n3 {
    margin-right: -1rem !important;
  }
  .mb-lg-n3,
  .my-lg-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-lg-n3,
  .mx-lg-n3 {
    margin-left: -1rem !important;
  }
  .m-lg-n4 {
    margin: -1.5rem !important;
  }
  .mt-lg-n4,
  .my-lg-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-lg-n4,
  .mx-lg-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-lg-n4,
  .my-lg-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-lg-n4,
  .mx-lg-n4 {
    margin-left: -1.5rem !important;
  }
  .m-lg-n5 {
    margin: -3rem !important;
  }
  .mt-lg-n5,
  .my-lg-n5 {
    margin-top: -3rem !important;
  }
  .mr-lg-n5,
  .mx-lg-n5 {
    margin-right: -3rem !important;
  }
  .mb-lg-n5,
  .my-lg-n5 {
    margin-bottom: -3rem !important;
  }
  .ml-lg-n5,
  .mx-lg-n5 {
    margin-left: -3rem !important;
  }
  .m-lg-auto {
    margin: auto !important;
  }
  .mt-lg-auto,
  .my-lg-auto {
    margin-top: auto !important;
  }
  .mr-lg-auto,
  .mx-lg-auto {
    margin-right: auto !important;
  }
  .mb-lg-auto,
  .my-lg-auto {
    margin-bottom: auto !important;
  }
  .ml-lg-auto,
  .mx-lg-auto {
    margin-left: auto !important;
  }
}

@media (min-width: 1200px) {
  .m-xl-0 {
    margin: 0 !important;
  }
  .mt-xl-0,
  .my-xl-0 {
    margin-top: 0 !important;
  }
  .mr-xl-0,
  .mx-xl-0 {
    margin-right: 0 !important;
  }
  .mb-xl-0,
  .my-xl-0 {
    margin-bottom: 0 !important;
  }
  .ml-xl-0,
  .mx-xl-0 {
    margin-left: 0 !important;
  }
  .m-xl-1 {
    margin: 0.25rem !important;
  }
  .mt-xl-1,
  .my-xl-1 {
    margin-top: 0.25rem !important;
  }
  .mr-xl-1,
  .mx-xl-1 {
    margin-right: 0.25rem !important;
  }
  .mb-xl-1,
  .my-xl-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-xl-1,
  .mx-xl-1 {
    margin-left: 0.25rem !important;
  }
  .m-xl-2 {
    margin: 0.5rem !important;
  }
  .mt-xl-2,
  .my-xl-2 {
    margin-top: 0.5rem !important;
  }
  .mr-xl-2,
  .mx-xl-2 {
    margin-right: 0.5rem !important;
  }
  .mb-xl-2,
  .my-xl-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-xl-2,
  .mx-xl-2 {
    margin-left: 0.5rem !important;
  }
  .m-xl-3 {
    margin: 1rem !important;
  }
  .mt-xl-3,
  .my-xl-3 {
    margin-top: 1rem !important;
  }
  .mr-xl-3,
  .mx-xl-3 {
    margin-right: 1rem !important;
  }
  .mb-xl-3,
  .my-xl-3 {
    margin-bottom: 1rem !important;
  }
  .ml-xl-3,
  .mx-xl-3 {
    margin-left: 1rem !important;
  }
  .m-xl-4 {
    margin: 1.5rem !important;
  }
  .mt-xl-4,
  .my-xl-4 {
    margin-top: 1.5rem !important;
  }
  .mr-xl-4,
  .mx-xl-4 {
    margin-right: 1.5rem !important;
  }
  .mb-xl-4,
  .my-xl-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-xl-4,
  .mx-xl-4 {
    margin-left: 1.5rem !important;
  }
  .m-xl-5 {
    margin: 3rem !important;
  }
  .mt-xl-5,
  .my-xl-5 {
    margin-top: 3rem !important;
  }
  .mr-xl-5,
  .mx-xl-5 {
    margin-right: 3rem !important;
  }
  .mb-xl-5,
  .my-xl-5 {
    margin-bottom: 3rem !important;
  }
  .ml-xl-5,
  .mx-xl-5 {
    margin-left: 3rem !important;
  }
  .p-xl-0 {
    padding: 0 !important;
  }
  .pt-xl-0,
  .py-xl-0 {
    padding-top: 0 !important;
  }
  .pr-xl-0,
  .px-xl-0 {
    padding-right: 0 !important;
  }
  .pb-xl-0,
  .py-xl-0 {
    padding-bottom: 0 !important;
  }
  .pl-xl-0,
  .px-xl-0 {
    padding-left: 0 !important;
  }
  .p-xl-1 {
    padding: 0.25rem !important;
  }
  .pt-xl-1,
  .py-xl-1 {
    padding-top: 0.25rem !important;
  }
  .pr-xl-1,
  .px-xl-1 {
    padding-right: 0.25rem !important;
  }
  .pb-xl-1,
  .py-xl-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-xl-1,
  .px-xl-1 {
    padding-left: 0.25rem !important;
  }
  .p-xl-2 {
    padding: 0.5rem !important;
  }
  .pt-xl-2,
  .py-xl-2 {
    padding-top: 0.5rem !important;
  }
  .pr-xl-2,
  .px-xl-2 {
    padding-right: 0.5rem !important;
  }
  .pb-xl-2,
  .py-xl-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-xl-2,
  .px-xl-2 {
    padding-left: 0.5rem !important;
  }
  .p-xl-3 {
    padding: 1rem !important;
  }
  .pt-xl-3,
  .py-xl-3 {
    padding-top: 1rem !important;
  }
  .pr-xl-3,
  .px-xl-3 {
    padding-right: 1rem !important;
  }
  .pb-xl-3,
  .py-xl-3 {
    padding-bottom: 1rem !important;
  }
  .pl-xl-3,
  .px-xl-3 {
    padding-left: 1rem !important;
  }
  .p-xl-4 {
    padding: 1.5rem !important;
  }
  .pt-xl-4,
  .py-xl-4 {
    padding-top: 1.5rem !important;
  }
  .pr-xl-4,
  .px-xl-4 {
    padding-right: 1.5rem !important;
  }
  .pb-xl-4,
  .py-xl-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-xl-4,
  .px-xl-4 {
    padding-left: 1.5rem !important;
  }
  .p-xl-5 {
    padding: 3rem !important;
  }
  .pt-xl-5,
  .py-xl-5 {
    padding-top: 3rem !important;
  }
  .pr-xl-5,
  .px-xl-5 {
    padding-right: 3rem !important;
  }
  .pb-xl-5,
  .py-xl-5 {
    padding-bottom: 3rem !important;
  }
  .pl-xl-5,
  .px-xl-5 {
    padding-left: 3rem !important;
  }
  .m-xl-n1 {
    margin: -0.25rem !important;
  }
  .mt-xl-n1,
  .my-xl-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-xl-n1,
  .mx-xl-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-xl-n1,
  .my-xl-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-xl-n1,
  .mx-xl-n1 {
    margin-left: -0.25rem !important;
  }
  .m-xl-n2 {
    margin: -0.5rem !important;
  }
  .mt-xl-n2,
  .my-xl-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-xl-n2,
  .mx-xl-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-xl-n2,
  .my-xl-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-xl-n2,
  .mx-xl-n2 {
    margin-left: -0.5rem !important;
  }
  .m-xl-n3 {
    margin: -1rem !important;
  }
  .mt-xl-n3,
  .my-xl-n3 {
    margin-top: -1rem !important;
  }
  .mr-xl-n3,
  .mx-xl-n3 {
    margin-right: -1rem !important;
  }
  .mb-xl-n3,
  .my-xl-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-xl-n3,
  .mx-xl-n3 {
    margin-left: -1rem !important;
  }
  .m-xl-n4 {
    margin: -1.5rem !important;
  }
  .mt-xl-n4,
  .my-xl-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-xl-n4,
  .mx-xl-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-xl-n4,
  .my-xl-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-xl-n4,
  .mx-xl-n4 {
    margin-left: -1.5rem !important;
  }
  .m-xl-n5 {
    margin: -3rem !important;
  }
  .mt-xl-n5,
  .my-xl-n5 {
    margin-top: -3rem !important;
  }
  .mr-xl-n5,
  .mx-xl-n5 {
    margin-right: -3rem !important;
  }
  .mb-xl-n5,
  .my-xl-n5 {
    margin-bottom: -3rem !important;
  }
  .ml-xl-n5,
  .mx-xl-n5 {
    margin-left: -3rem !important;
  }
  .m-xl-auto {
    margin: auto !important;
  }
  .mt-xl-auto,
  .my-xl-auto {
    margin-top: auto !important;
  }
  .mr-xl-auto,
  .mx-xl-auto {
    margin-right: auto !important;
  }
  .mb-xl-auto,
  .my-xl-auto {
    margin-bottom: auto !important;
  }
  .ml-xl-auto,
  .mx-xl-auto {
    margin-left: auto !important;
  }
}

.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  pointer-events: auto;
  content: "";
  background-color: rgba(0, 0, 0, 0);
}

.text-monospace {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !important;
}

.text-justify {
  text-align: justify !important;
}

.text-wrap {
  white-space: normal !important;
}

.text-nowrap {
  white-space: nowrap !important;
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.text-left {
  text-align: left !important;
}

.text-right {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

@media (min-width: 576px) {
  .text-sm-left {
    text-align: left !important;
  }
  .text-sm-right {
    text-align: right !important;
  }
  .text-sm-center {
    text-align: center !important;
  }
}

@media (min-width: 768px) {
  .text-md-left {
    text-align: left !important;
  }
  .text-md-right {
    text-align: right !important;
  }
  .text-md-center {
    text-align: center !important;
  }
}

@media (min-width: 992px) {
  .text-lg-left {
    text-align: left !important;
  }
  .text-lg-right {
    text-align: right !important;
  }
  .text-lg-center {
    text-align: center !important;
  }
}

@media (min-width: 1200px) {
  .text-xl-left {
    text-align: left !important;
  }
  .text-xl-right {
    text-align: right !important;
  }
  .text-xl-center {
    text-align: center !important;
  }
}

.text-lowercase {
  text-transform: lowercase !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.font-weight-light {
  font-weight: 300 !important;
}

.font-weight-lighter {
  font-weight: lighter !important;
}

.font-weight-normal {
  font-weight: 400 !important;
}

.font-weight-bold {
  font-weight: 700 !important;
}

.font-weight-bolder {
  font-weight: bolder !important;
}

.font-italic {
  font-style: italic !important;
}

.text-white {
  color: #fff !important;
}

.text-primary {
  color: #007bff !important;
}

a.text-primary:hover, a.text-primary:focus {
  color: #0056b3 !important;
}

.text-secondary {
  color: #6c757d !important;
}

a.text-secondary:hover, a.text-secondary:focus {
  color: #494f54 !important;
}

.text-success {
  color: #28a745 !important;
}

a.text-success:hover, a.text-success:focus {
  color: #19692c !important;
}

.text-info {
  color: #17a2b8 !important;
}

a.text-info:hover, a.text-info:focus {
  color: #0f6674 !important;
}

.text-warning {
  color: #ffc107 !important;
}

a.text-warning:hover, a.text-warning:focus {
  color: #ba8b00 !important;
}

.text-danger {
  color: #dc3545 !important;
}

a.text-danger:hover, a.text-danger:focus {
  color: #a71d2a !important;
}

.text-light {
  color: #f8f9fa !important;
}

a.text-light:hover, a.text-light:focus {
  color: #cbd3da !important;
}

.text-dark {
  color: #343a40 !important;
}

a.text-dark:hover, a.text-dark:focus {
  color: #121416 !important;
}

.text-body {
  color: #212529 !important;
}

.text-muted {
  color: #6c757d !important;
}

.text-black-50 {
  color: rgba(0, 0, 0, 0.5) !important;
}

.text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important;
}

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}

.text-decoration-none {
  text-decoration: none !important;
}

.text-break {
  word-break: break-word !important;
  word-wrap: break-word !important;
}

.text-reset {
  color: inherit !important;
}

.visible {
  visibility: visible !important;
}

.invisible {
  visibility: hidden !important;
}

@media print {
  *,
  *::before,
  *::after {
    text-shadow: none !important;
    box-shadow: none !important;
  }
  a:not(.btn) {
    text-decoration: underline;
  }
  abbr[title]::after {
    content: " (" attr(title) ")";
  }
  pre {
    white-space: pre-wrap !important;
  }
  pre,
  blockquote {
    border: 1px solid #adb5bd;
    page-break-inside: avoid;
  }
  tr,
  img {
    page-break-inside: avoid;
  }
  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3;
  }
  h2,
  h3 {
    page-break-after: avoid;
  }
  @page {
    size: a3;
  }
  body {
    min-width: 992px !important;
  }
  .container {
    min-width: 992px !important;
  }
  .navbar {
    display: none;
  }
  .badge {
    border: 1px solid #000;
  }
  .table {
    border-collapse: collapse !important;
  }
  .table td,
  .table th {
    background-color: #fff !important;
  }
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #dee2e6 !important;
  }
  .table-dark {
    color: inherit;
  }
  .table-dark th,
  .table-dark td,
  .table-dark thead th,
  .table-dark tbody + tbody {
    border-color: #dee2e6;
  }
  .table .thead-dark th {
    color: inherit;
    border-color: #dee2e6;
  }
}
/*# sourceMappingURL=bootstrap.css.map */
/**
 * Owl Carousel v2.3.4
 * Copyright 2013-2018 David Deutsch
 * Licensed under: SEE LICENSE IN https://github.com/OwlCarousel2/OwlCarousel2/blob/master/LICENSE
 */
/*
 *  Owl Carousel - Core
 */
.owl-carousel {
  display: none;
  width: 100%;
  -webkit-tap-highlight-color: transparent;
  /* position relative and z-index fix webkit rendering fonts issue */
  position: relative;
  z-index: 1; }
  .owl-carousel .owl-stage {
    position: relative;
    touch-action: manipulation;
    -moz-backface-visibility: hidden;
    /* fix firefox animation glitch */ }
  .owl-carousel .owl-stage:after {
    content: ".";
    display: block;
    clear: both;
    visibility: hidden;
    line-height: 0;
    height: 0; }
  .owl-carousel .owl-stage-outer {
    position: relative;
    overflow: hidden;
    /* fix for flashing background */
    -webkit-transform: translate3d(0px, 0px, 0px); }
  .owl-carousel .owl-wrapper,
  .owl-carousel .owl-item {
    -webkit-backface-visibility: hidden;
    -moz-backface-visibility: hidden;
    -ms-backface-visibility: hidden;
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0); }
  .owl-carousel .owl-item {
    position: relative;
    min-height: 1px;
    float: left;
    -webkit-backface-visibility: hidden;
    -webkit-tap-highlight-color: transparent;
    -webkit-touch-callout: none; }
  .owl-carousel .owl-item img {
    display: block;
    width: 100%; }
  .owl-carousel .owl-nav.disabled,
  .owl-carousel .owl-dots.disabled {
    display: none; }
  .owl-carousel .owl-nav .owl-prev,
  .owl-carousel .owl-nav .owl-next,
  .owl-carousel .owl-dot {
    cursor: pointer;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none; }
  .owl-carousel .owl-nav button.owl-prev,
  .owl-carousel .owl-nav button.owl-next,
  .owl-carousel button.owl-dot {
    background: none;
    color: inherit;
    border: none;
    padding: 0 !important;
    font: inherit; }
  .owl-carousel.owl-loaded {
    display: block; }
  .owl-carousel.owl-loading {
    opacity: 0;
    display: block; }
  .owl-carousel.owl-hidden {
    opacity: 0; }
  .owl-carousel.owl-refresh .owl-item {
    visibility: hidden; }
  .owl-carousel.owl-drag .owl-item {
    touch-action: pan-y;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none; }
  .owl-carousel.owl-grab {
    cursor: move;
    cursor: grab; }
  .owl-carousel.owl-rtl {
    direction: rtl; }
  .owl-carousel.owl-rtl .owl-item {
    float: right; }

/* No Js */
.no-js .owl-carousel {
  display: block; }

/*
 *  Owl Carousel - Animate Plugin
 */
.owl-carousel .animated {
  -webkit-animation-duration: 1000ms;
          animation-duration: 1000ms;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both; }

.owl-carousel .owl-animated-in {
  z-index: 0; }

.owl-carousel .owl-animated-out {
  z-index: 1; }

.owl-carousel .fadeOut {
  -webkit-animation-name: fadeOut;
          animation-name: fadeOut; }

@-webkit-keyframes fadeOut {
  0% {
    opacity: 1; }
  100% {
    opacity: 0; } }

@keyframes fadeOut {
  0% {
    opacity: 1; }
  100% {
    opacity: 0; } }

/*
 * 	Owl Carousel - Auto Height Plugin
 */
.owl-height {
  transition: height 500ms ease-in-out; }

/*
 * 	Owl Carousel - Lazy Load Plugin
 */
.owl-carousel .owl-item {
  /**
			This is introduced due to a bug in IE11 where lazy loading combined with autoheight plugin causes a wrong
			calculation of the height of the owl-item that breaks page layouts
		 */ }
  .owl-carousel .owl-item .owl-lazy {
    opacity: 0;
    transition: opacity 400ms ease; }
  .owl-carousel .owl-item .owl-lazy[src^=""], .owl-carousel .owl-item .owl-lazy:not([src]) {
    max-height: 0; }
  .owl-carousel .owl-item img.owl-lazy {
    -webkit-transform-style: preserve-3d;
            transform-style: preserve-3d; }

/*
 * 	Owl Carousel - Video Plugin
 */
.owl-carousel .owl-video-wrapper {
  position: relative;
  height: 100%;
  background: #000; }

.owl-carousel .owl-video-play-icon {
  position: absolute;
  height: 80px;
  width: 80px;
  left: 50%;
  top: 50%;
  margin-left: -40px;
  margin-top: -40px;
  background: url(/_next/static/media/owl.video.play.e3b43643.png) no-repeat;
  cursor: pointer;
  z-index: 1;
  -webkit-backface-visibility: hidden;
  transition: -webkit-transform 100ms ease;
  transition: transform 100ms ease;
  transition: transform 100ms ease, -webkit-transform 100ms ease; }

.owl-carousel .owl-video-play-icon:hover {
  -webkit-transform: scale(1.3, 1.3);
          transform: scale(1.3, 1.3); }

.owl-carousel .owl-video-playing .owl-video-tn,
.owl-carousel .owl-video-playing .owl-video-play-icon {
  display: none; }

.owl-carousel .owl-video-tn {
  opacity: 0;
  height: 100%;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
  transition: opacity 400ms ease; }

.owl-carousel .owl-video-frame {
  position: relative;
  z-index: 1;
  height: 100%;
  width: 100%; }

/**
 * Owl Carousel v2.3.4
 * Copyright 2013-2018 David Deutsch
 * Licensed under: SEE LICENSE IN https://github.com/OwlCarousel2/OwlCarousel2/blob/master/LICENSE
 */
/*
 * 	Default theme - Owl Carousel CSS File
 */
.owl-theme .owl-nav {
  margin-top: 10px;
  text-align: center;
  -webkit-tap-highlight-color: transparent; }
  .owl-theme .owl-nav [class*='owl-'] {
    color: #FFF;
    font-size: 14px;
    margin: 5px;
    padding: 4px 7px;
    background: #D6D6D6;
    display: inline-block;
    cursor: pointer;
    border-radius: 3px; }
    .owl-theme .owl-nav [class*='owl-']:hover {
      background: #869791;
      color: #FFF;
      text-decoration: none; }
  .owl-theme .owl-nav .disabled {
    opacity: 0.5;
    cursor: default; }

.owl-theme .owl-nav.disabled + .owl-dots {
  margin-top: 10px; }

.owl-theme .owl-dots {
  text-align: center;
  -webkit-tap-highlight-color: transparent; }
  .owl-theme .owl-dots .owl-dot {
    display: inline-block;
    zoom: 1;
    *display: inline; }
    .owl-theme .owl-dots .owl-dot span {
      width: 10px;
      height: 10px;
      margin: 5px 7px;
      background: #D6D6D6;
      display: block;
      -webkit-backface-visibility: visible;
      transition: opacity 200ms ease;
      border-radius: 30px; }
    .owl-theme .owl-dots .owl-dot.active span, .owl-theme .owl-dots .owl-dot:hover span {
      background: #869791; }

.Dropdown-root {
  position: relative;
}

.Dropdown-control {
  position: relative;
  overflow: hidden;
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 2px;
  box-sizing: border-box;
  color: #333;
  cursor: default;
  outline: none;
  padding: 8px 52px 8px 10px;
  transition: all 200ms ease;
}

.Dropdown-control:hover {
  box-shadow: 0 1px 0 rgba(0, 0, 0, 0.06);
}

.Dropdown-arrow {
  border-color: #999 transparent transparent;
  border-style: solid;
  border-width: 5px 5px 0;
  content: ' ';
  display: block;
  height: 0;
  margin-top: -ceil(2.5);
  position: absolute;
  right: 10px;
  top: 14px;
  width: 0
}

.is-open .Dropdown-arrow {
  border-color: transparent transparent #999;
  border-width: 0 5px 5px;
}

.Dropdown-menu {
  background-color: white;
  border: 1px solid #ccc;
  box-shadow: 0 1px 0 rgba(0, 0, 0, 0.06);
  box-sizing: border-box;
  margin-top: -1px;
  max-height: 200px;
  overflow-y: auto;
  position: absolute;
  top: 100%;
  width: 100%;
  z-index: 1000;
  -webkit-overflow-scrolling: touch;
}

.Dropdown-menu .Dropdown-group > .Dropdown-title{
  padding: 8px 10px;
  color: rgba(51, 51, 51, 1);
  font-weight: bold;
  text-transform: capitalize;
}

.Dropdown-option {
  box-sizing: border-box;
  color: rgba(51, 51, 51, 0.8);
  cursor: pointer;
  display: block;
  padding: 8px 10px;
}

.Dropdown-option:last-child {
  border-bottom-right-radius: 2px;
   border-bottom-left-radius: 2px;
}

.Dropdown-option:hover {
  background-color: #f2f9fc;
  color: #333;
}

.Dropdown-option.is-selected {
  background-color: #f2f9fc;
  color: #333;
}

.Dropdown-noresults {
  box-sizing: border-box;
  color: #ccc;
  cursor: default;
  display: block;
  padding: 8px 10px;
}

/* Slider */
.slick-slider
{
    position: relative;

    display: block;
    box-sizing: border-box;

    -webkit-user-select: none;
        -ms-user-select: none;
            user-select: none;

    -webkit-touch-callout: none;
    -khtml-user-select: none;
    touch-action: pan-y;
    -webkit-tap-highlight-color: transparent;
}

.slick-list
{
    position: relative;

    display: block;
    overflow: hidden;

    margin: 0;
    padding: 0;
}
.slick-list:focus
{
    outline: none;
}
.slick-list.dragging
{
    cursor: pointer;
    cursor: hand;
}

.slick-slider .slick-track,
.slick-slider .slick-list
{
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
}

.slick-track
{
    position: relative;
    top: 0;
    left: 0;

    display: block;
    margin-left: auto;
    margin-right: auto;
}
.slick-track:before,
.slick-track:after
{
    display: table;

    content: '';
}
.slick-track:after
{
    clear: both;
}
.slick-loading .slick-track
{
    visibility: hidden;
}

.slick-slide
{
    display: none;
    float: left;

    height: 100%;
    min-height: 1px;
}
[dir='rtl'] .slick-slide
{
    float: right;
}
.slick-slide img
{
    display: block;
}
.slick-slide.slick-loading img
{
    display: none;
}
.slick-slide.dragging img
{
    pointer-events: none;
}
.slick-initialized .slick-slide
{
    display: block;
}
.slick-loading .slick-slide
{
    visibility: hidden;
}
.slick-vertical .slick-slide
{
    display: block;

    height: auto;

    border: 1px solid transparent;
}
.slick-arrow.slick-hidden {
    display: none;
}

@charset 'UTF-8';
/* Slider */
.slick-loading .slick-list
{
    background: #fff url(/_next/static/media/ajax-loader.0b80f665.gif) center center no-repeat;
}

/* Icons */
@font-face
{
    font-family: 'slick';
    font-weight: normal;
    font-style: normal;

    src: url(/_next/static/media/slick.25572f22.eot);
    src: url(/_next/static/media/slick.25572f22.eot?#iefix) format('embedded-opentype'), url(/_next/static/media/slick.653a4cbb.woff) format('woff'), url(/_next/static/media/slick.6aa1ee46.ttf) format('truetype'), url(/_next/static/media/slick.f895cfdf.svg#slick) format('svg');
}
/* Arrows */
.slick-prev,
.slick-next
{
    font-size: 0;
    line-height: 0;

    position: absolute;
    top: 50%;

    display: block;

    width: 20px;
    height: 20px;
    padding: 0;
    -webkit-transform: translate(0, -50%);
    transform: translate(0, -50%);

    cursor: pointer;

    color: transparent;
    border: none;
    outline: none;
    background: transparent;
}
.slick-prev:hover,
.slick-prev:focus,
.slick-next:hover,
.slick-next:focus
{
    color: transparent;
    outline: none;
    background: transparent;
}
.slick-prev:hover:before,
.slick-prev:focus:before,
.slick-next:hover:before,
.slick-next:focus:before
{
    opacity: 1;
}
.slick-prev.slick-disabled:before,
.slick-next.slick-disabled:before
{
    opacity: .25;
}

.slick-prev:before,
.slick-next:before
{
    font-family: 'slick';
    font-size: 20px;
    line-height: 1;

    opacity: .75;
    color: white;

    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.slick-prev
{
    left: -25px;
}
[dir='rtl'] .slick-prev
{
    right: -25px;
    left: auto;
}
.slick-prev:before
{
    content: '←';
}
[dir='rtl'] .slick-prev:before
{
    content: '→';
}

.slick-next
{
    right: -25px;
}
[dir='rtl'] .slick-next
{
    right: auto;
    left: -25px;
}
.slick-next:before
{
    content: '→';
}
[dir='rtl'] .slick-next:before
{
    content: '←';
}

/* Dots */
.slick-dotted.slick-slider
{
    margin-bottom: 30px;
}

.slick-dots
{
    position: absolute;
    bottom: -25px;

    display: block;

    width: 100%;
    padding: 0;
    margin: 0;

    list-style: none;

    text-align: center;
}
.slick-dots li
{
    position: relative;

    display: inline-block;

    width: 20px;
    height: 20px;
    margin: 0 5px;
    padding: 0;

    cursor: pointer;
}
.slick-dots li button
{
    font-size: 0;
    line-height: 0;

    display: block;

    width: 20px;
    height: 20px;
    padding: 5px;

    cursor: pointer;

    color: transparent;
    border: 0;
    outline: none;
    background: transparent;
}
.slick-dots li button:hover,
.slick-dots li button:focus
{
    outline: none;
}
.slick-dots li button:hover:before,
.slick-dots li button:focus:before
{
    opacity: 1;
}
.slick-dots li button:before
{
    font-family: 'slick';
    font-size: 6px;
    line-height: 20px;

    position: absolute;
    top: 0;
    left: 0;

    width: 20px;
    height: 20px;

    content: '•';
    text-align: center;

    opacity: .25;
    color: black;

    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
.slick-dots li.slick-active button:before
{
    opacity: .75;
    color: black;
}

@font-face {
  font-family: 'lg';
  src: url(/_next/static/media/lg.baa2f3aa.ttf) format("truetype"), url(/_next/static/media/lg.59e49f5e.woff) format("woff"), url(/_next/static/media/lg.1e03a48b.svg#lg) format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

.lg-icon {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'lg' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  -webkit-font-feature-settings: normal;
          font-feature-settings: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.lg-actions .lg-next, .lg-actions .lg-prev {
  background-color: rgba(0, 0, 0, 0.45);
  border-radius: 2px;
  color: #999;
  cursor: pointer;
  display: block;
  font-size: 22px;
  margin-top: -10px;
  padding: 8px 10px 9px;
  position: absolute;
  top: 50%;
  z-index: 1080;
  outline: none;
  border: none;
  background-color: transparent;
}

.lg-actions .lg-next.disabled, .lg-actions .lg-prev.disabled {
  pointer-events: none;
  opacity: 0.5;
}

.lg-actions .lg-next:hover, .lg-actions .lg-prev:hover {
  color: #FFF;
}

.lg-actions .lg-next {
  right: 20px;
}

.lg-actions .lg-next:before {
  content: "\e095";
}

.lg-actions .lg-prev {
  left: 20px;
}

.lg-actions .lg-prev:after {
  content: "\e094";
}

@-webkit-keyframes lg-right-end {
  0% {
    left: 0;
  }
  50% {
    left: -30px;
  }
  100% {
    left: 0;
  }
}

@keyframes lg-right-end {
  0% {
    left: 0;
  }
  50% {
    left: -30px;
  }
  100% {
    left: 0;
  }
}

@-webkit-keyframes lg-left-end {
  0% {
    left: 0;
  }
  50% {
    left: 30px;
  }
  100% {
    left: 0;
  }
}

@keyframes lg-left-end {
  0% {
    left: 0;
  }
  50% {
    left: 30px;
  }
  100% {
    left: 0;
  }
}

.lg-outer.lg-right-end .lg-object {
  -webkit-animation: lg-right-end 0.3s;
  animation: lg-right-end 0.3s;
  position: relative;
}

.lg-outer.lg-left-end .lg-object {
  -webkit-animation: lg-left-end 0.3s;
  animation: lg-left-end 0.3s;
  position: relative;
}

.lg-toolbar {
  z-index: 1082;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.45);
}

.lg-toolbar .lg-icon {
  color: #999;
  cursor: pointer;
  float: right;
  font-size: 24px;
  height: 47px;
  line-height: 27px;
  padding: 10px 0;
  text-align: center;
  width: 50px;
  text-decoration: none !important;
  outline: medium none;
  background: none;
  border: none;
  box-shadow: none;
  transition: color 0.2s linear;
}

.lg-toolbar .lg-icon:hover {
  color: #FFF;
}

.lg-toolbar .lg-close:after {
  content: "\e070";
}

.lg-toolbar .lg-download:after {
  content: "\e0f2";
}

.lg-sub-html {
  background-color: rgba(0, 0, 0, 0.45);
  bottom: 0;
  color: #EEE;
  font-size: 16px;
  left: 0;
  padding: 10px 40px;
  position: fixed;
  right: 0;
  text-align: center;
  z-index: 1080;
}

.lg-sub-html h4 {
  margin: 0;
  font-size: 13px;
  font-weight: bold;
}

.lg-sub-html p {
  font-size: 12px;
  margin: 5px 0 0;
}

#lg-counter {
  color: #999;
  display: inline-block;
  font-size: 16px;
  padding-left: 20px;
  padding-top: 12px;
  vertical-align: middle;
}

.lg-toolbar, .lg-prev, .lg-next {
  opacity: 1;
  transition: opacity 0.35s cubic-bezier(0, 0, 0.25, 1) 0s, color 0.2s linear, -webkit-transform 0.35s cubic-bezier(0, 0, 0.25, 1) 0s;
  transition: transform 0.35s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.35s cubic-bezier(0, 0, 0.25, 1) 0s, color 0.2s linear;
  transition: transform 0.35s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.35s cubic-bezier(0, 0, 0.25, 1) 0s, color 0.2s linear, -webkit-transform 0.35s cubic-bezier(0, 0, 0.25, 1) 0s;
}

.lg-hide-items .lg-prev {
  opacity: 0;
  -webkit-transform: translate3d(-10px, 0, 0);
  transform: translate3d(-10px, 0, 0);
}

.lg-hide-items .lg-next {
  opacity: 0;
  -webkit-transform: translate3d(10px, 0, 0);
  transform: translate3d(10px, 0, 0);
}

.lg-hide-items .lg-toolbar {
  opacity: 0;
  -webkit-transform: translate3d(0, -10px, 0);
  transform: translate3d(0, -10px, 0);
}

body:not(.lg-from-hash) .lg-outer.lg-start-zoom .lg-object {
  -webkit-transform: scale3d(0.5, 0.5, 0.5);
  transform: scale3d(0.5, 0.5, 0.5);
  opacity: 0;
  transition: opacity 250ms cubic-bezier(0, 0, 0.25, 1), -webkit-transform 250ms cubic-bezier(0, 0, 0.25, 1) 0s !important;
  transition: transform 250ms cubic-bezier(0, 0, 0.25, 1) 0s, opacity 250ms cubic-bezier(0, 0, 0.25, 1) !important;
  transition: transform 250ms cubic-bezier(0, 0, 0.25, 1) 0s, opacity 250ms cubic-bezier(0, 0, 0.25, 1), -webkit-transform 250ms cubic-bezier(0, 0, 0.25, 1) 0s !important;
  -webkit-transform-origin: 50% 50%;
  transform-origin: 50% 50%;
}

body:not(.lg-from-hash) .lg-outer.lg-start-zoom .lg-item.lg-complete .lg-object {
  -webkit-transform: scale3d(1, 1, 1);
  transform: scale3d(1, 1, 1);
  opacity: 1;
}

.lg-outer .lg-thumb-outer {
  background-color: #0D0A0A;
  bottom: 0;
  position: absolute;
  width: 100%;
  z-index: 1080;
  max-height: 350px;
  -webkit-transform: translate3d(0, 100%, 0);
  transform: translate3d(0, 100%, 0);
  transition: -webkit-transform 0.25s cubic-bezier(0, 0, 0.25, 1) 0s;
  transition: transform 0.25s cubic-bezier(0, 0, 0.25, 1) 0s;
  transition: transform 0.25s cubic-bezier(0, 0, 0.25, 1) 0s, -webkit-transform 0.25s cubic-bezier(0, 0, 0.25, 1) 0s;
}

.lg-outer .lg-thumb-outer.lg-grab .lg-thumb-item {
  cursor: -o-grab;
  cursor: -ms-grab;
  cursor: grab;
}

.lg-outer .lg-thumb-outer.lg-grabbing .lg-thumb-item {
  cursor: move;
  cursor: -o-grabbing;
  cursor: -ms-grabbing;
  cursor: grabbing;
}

.lg-outer .lg-thumb-outer.lg-dragging .lg-thumb {
  transition-duration: 0s !important;
}

.lg-outer.lg-thumb-open .lg-thumb-outer {
  -webkit-transform: translate3d(0, 0%, 0);
  transform: translate3d(0, 0%, 0);
}

.lg-outer .lg-thumb {
  padding: 10px 0;
  height: 100%;
  margin-bottom: -5px;
}

.lg-outer .lg-thumb-item {
  border-radius: 5px;
  cursor: pointer;
  float: left;
  overflow: hidden;
  height: 100%;
  border: 2px solid #FFF;
  border-radius: 4px;
  margin-bottom: 5px;
}

@media (min-width: 1025px) {
  .lg-outer .lg-thumb-item {
    transition: border-color 0.25s ease;
  }
}

.lg-outer .lg-thumb-item.active, .lg-outer .lg-thumb-item:hover {
  border-color: #a90707;
}

.lg-outer .lg-thumb-item img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.lg-outer.lg-has-thumb .lg-item {
  padding-bottom: 120px;
}

.lg-outer.lg-can-toggle .lg-item {
  padding-bottom: 0;
}

.lg-outer.lg-pull-caption-up .lg-sub-html {
  transition: bottom 0.25s ease;
}

.lg-outer.lg-pull-caption-up.lg-thumb-open .lg-sub-html {
  bottom: 100px;
}

.lg-outer .lg-toggle-thumb {
  background-color: #0D0A0A;
  border-radius: 2px 2px 0 0;
  color: #999;
  cursor: pointer;
  font-size: 24px;
  height: 39px;
  line-height: 27px;
  padding: 5px 0;
  position: absolute;
  right: 20px;
  text-align: center;
  top: -39px;
  width: 50px;
  outline: medium none;
  border: none;
}

.lg-outer .lg-toggle-thumb:after {
  content: "\e1ff";
}

.lg-outer .lg-toggle-thumb:hover {
  color: #FFF;
}

.lg-outer .lg-video-cont {
  display: inline-block;
  vertical-align: middle;
  max-width: 1140px;
  max-height: 100%;
  width: 100%;
  padding: 0 5px;
}

.lg-outer .lg-video {
  width: 100%;
  height: 0;
  padding-bottom: 56.25%;
  overflow: hidden;
  position: relative;
}

.lg-outer .lg-video .lg-object {
  display: inline-block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100% !important;
  height: 100% !important;
}

.lg-outer .lg-video .lg-video-play {
  width: 84px;
  height: 59px;
  position: absolute;
  left: 50%;
  top: 50%;
  margin-left: -42px;
  margin-top: -30px;
  z-index: 1080;
  cursor: pointer;
}

.lg-outer .lg-has-vimeo .lg-video-play {
  background: url(/_next/static/media/vimeo-play.956c946d.png) no-repeat scroll 0 0 transparent;
}

.lg-outer .lg-has-vimeo:hover .lg-video-play {
  background: url(/_next/static/media/vimeo-play.956c946d.png) no-repeat scroll 0 -58px transparent;
}

.lg-outer .lg-has-html5 .lg-video-play {
  background: transparent url(/_next/static/media/video-play.1bb9b620.png) no-repeat scroll 0 0;
  height: 64px;
  margin-left: -32px;
  margin-top: -32px;
  width: 64px;
  opacity: 0.8;
}

.lg-outer .lg-has-html5:hover .lg-video-play {
  opacity: 1;
}

.lg-outer .lg-has-youtube .lg-video-play {
  background: url(/_next/static/media/youtube-play.ec1964f8.png) no-repeat scroll 0 0 transparent;
}

.lg-outer .lg-has-youtube:hover .lg-video-play {
  background: url(/_next/static/media/youtube-play.ec1964f8.png) no-repeat scroll 0 -60px transparent;
}

.lg-outer .lg-video-object {
  width: 100% !important;
  height: 100% !important;
  position: absolute;
  top: 0;
  left: 0;
}

.lg-outer .lg-has-video .lg-video-object {
  visibility: hidden;
}

.lg-outer .lg-has-video.lg-video-playing .lg-object, .lg-outer .lg-has-video.lg-video-playing .lg-video-play {
  display: none;
}

.lg-outer .lg-has-video.lg-video-playing .lg-video-object {
  visibility: visible;
}

.lg-progress-bar {
  background-color: #333;
  height: 5px;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 1083;
  opacity: 0;
  transition: opacity 0.08s ease 0s;
}

.lg-progress-bar .lg-progress {
  background-color: #a90707;
  height: 5px;
  width: 0;
}

.lg-progress-bar.lg-start .lg-progress {
  width: 100%;
}

.lg-show-autoplay .lg-progress-bar {
  opacity: 1;
}

.lg-autoplay-button:after {
  content: "\e01d";
}

.lg-show-autoplay .lg-autoplay-button:after {
  content: "\e01a";
}

.lg-outer.lg-css3.lg-zoom-dragging .lg-item.lg-complete.lg-zoomable .lg-img-wrap, .lg-outer.lg-css3.lg-zoom-dragging .lg-item.lg-complete.lg-zoomable .lg-image {
  transition-duration: 0s;
}

.lg-outer.lg-use-transition-for-zoom .lg-item.lg-complete.lg-zoomable .lg-img-wrap {
  transition: -webkit-transform 0.3s cubic-bezier(0, 0, 0.25, 1) 0s;
  transition: transform 0.3s cubic-bezier(0, 0, 0.25, 1) 0s;
  transition: transform 0.3s cubic-bezier(0, 0, 0.25, 1) 0s, -webkit-transform 0.3s cubic-bezier(0, 0, 0.25, 1) 0s;
}

.lg-outer.lg-use-left-for-zoom .lg-item.lg-complete.lg-zoomable .lg-img-wrap {
  transition: left 0.3s cubic-bezier(0, 0, 0.25, 1) 0s, top 0.3s cubic-bezier(0, 0, 0.25, 1) 0s;
}

.lg-outer .lg-item.lg-complete.lg-zoomable .lg-img-wrap {
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.lg-outer .lg-item.lg-complete.lg-zoomable .lg-image {
  -webkit-transform: scale3d(1, 1, 1);
  transform: scale3d(1, 1, 1);
  transition: opacity 0.15s, -webkit-transform 0.3s cubic-bezier(0, 0, 0.25, 1) 0s !important;
  transition: transform 0.3s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.15s !important;
  transition: transform 0.3s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.15s, -webkit-transform 0.3s cubic-bezier(0, 0, 0.25, 1) 0s !important;
  -webkit-transform-origin: 0 0;
  transform-origin: 0 0;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

#lg-zoom-in:after {
  content: "\e311";
}

#lg-actual-size {
  font-size: 20px;
}

#lg-actual-size:after {
  content: "\e033";
}

#lg-zoom-out {
  opacity: 0.5;
  pointer-events: none;
}

#lg-zoom-out:after {
  content: "\e312";
}

.lg-zoomed #lg-zoom-out {
  opacity: 1;
  pointer-events: auto;
}

.lg-outer .lg-pager-outer {
  bottom: 60px;
  left: 0;
  position: absolute;
  right: 0;
  text-align: center;
  z-index: 1080;
  height: 10px;
}

.lg-outer .lg-pager-outer.lg-pager-hover .lg-pager-cont {
  overflow: visible;
}

.lg-outer .lg-pager-cont {
  cursor: pointer;
  display: inline-block;
  overflow: hidden;
  position: relative;
  vertical-align: top;
  margin: 0 5px;
}

.lg-outer .lg-pager-cont:hover .lg-pager-thumb-cont {
  opacity: 1;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.lg-outer .lg-pager-cont.lg-pager-active .lg-pager {
  box-shadow: 0 0 0 2px white inset;
}

.lg-outer .lg-pager-thumb-cont {
  background-color: #fff;
  color: #FFF;
  bottom: 100%;
  height: 83px;
  left: 0;
  margin-bottom: 20px;
  margin-left: -60px;
  opacity: 0;
  padding: 5px;
  position: absolute;
  width: 120px;
  border-radius: 3px;
  transition: opacity 0.15s ease 0s, -webkit-transform 0.15s ease 0s;
  transition: opacity 0.15s ease 0s, transform 0.15s ease 0s;
  transition: opacity 0.15s ease 0s, transform 0.15s ease 0s, -webkit-transform 0.15s ease 0s;
  -webkit-transform: translate3d(0, 5px, 0);
  transform: translate3d(0, 5px, 0);
}

.lg-outer .lg-pager-thumb-cont img {
  width: 100%;
  height: 100%;
}

.lg-outer .lg-pager {
  background-color: rgba(255, 255, 255, 0.5);
  border-radius: 50%;
  box-shadow: 0 0 0 8px rgba(255, 255, 255, 0.7) inset;
  display: block;
  height: 12px;
  transition: box-shadow 0.3s ease 0s;
  width: 12px;
}

.lg-outer .lg-pager:hover, .lg-outer .lg-pager:focus {
  box-shadow: 0 0 0 8px white inset;
}

.lg-outer .lg-caret {
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 10px dashed;
  bottom: -10px;
  display: inline-block;
  height: 0;
  left: 50%;
  margin-left: -5px;
  position: absolute;
  vertical-align: middle;
  width: 0;
}

.lg-fullscreen:after {
  content: "\e20c";
}

.lg-fullscreen-on .lg-fullscreen:after {
  content: "\e20d";
}

.lg-outer #lg-dropdown-overlay {
  background-color: rgba(0, 0, 0, 0.25);
  bottom: 0;
  cursor: default;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 1081;
  opacity: 0;
  visibility: hidden;
  transition: visibility 0s linear 0.18s, opacity 0.18s linear 0s;
}

.lg-outer.lg-dropdown-active .lg-dropdown, .lg-outer.lg-dropdown-active #lg-dropdown-overlay {
  transition-delay: 0s;
  -webkit-transform: translate3d(0, 0px, 0);
  transform: translate3d(0, 0px, 0);
  opacity: 1;
  visibility: visible;
}

.lg-outer.lg-dropdown-active #lg-share {
  color: #FFF;
}

.lg-outer .lg-dropdown {
  background-color: #fff;
  border-radius: 2px;
  font-size: 14px;
  list-style-type: none;
  margin: 0;
  padding: 10px 0;
  position: absolute;
  right: 0;
  text-align: left;
  top: 50px;
  opacity: 0;
  visibility: hidden;
  -webkit-transform: translate3d(0, 5px, 0);
  transform: translate3d(0, 5px, 0);
  transition: visibility 0s linear 0.5s, opacity 0.18s linear 0s, -webkit-transform 0.18s linear 0s;
  transition: transform 0.18s linear 0s, visibility 0s linear 0.5s, opacity 0.18s linear 0s;
  transition: transform 0.18s linear 0s, visibility 0s linear 0.5s, opacity 0.18s linear 0s, -webkit-transform 0.18s linear 0s;
}

.lg-outer .lg-dropdown:after {
  content: "";
  display: block;
  height: 0;
  width: 0;
  position: absolute;
  border: 8px solid transparent;
  border-bottom-color: #FFF;
  right: 16px;
  top: -16px;
}

.lg-outer .lg-dropdown > li:last-child {
  margin-bottom: 0px;
}

.lg-outer .lg-dropdown > li:hover a, .lg-outer .lg-dropdown > li:hover .lg-icon {
  color: #333;
}

.lg-outer .lg-dropdown a {
  color: #333;
  display: block;
  white-space: pre;
  padding: 4px 12px;
  font-family: "Open Sans","Helvetica Neue",Helvetica,Arial,sans-serif;
  font-size: 12px;
}

.lg-outer .lg-dropdown a:hover {
  background-color: rgba(0, 0, 0, 0.07);
}

.lg-outer .lg-dropdown .lg-dropdown-text {
  display: inline-block;
  line-height: 1;
  margin-top: -3px;
  vertical-align: middle;
}

.lg-outer .lg-dropdown .lg-icon {
  color: #333;
  display: inline-block;
  float: none;
  font-size: 20px;
  height: auto;
  line-height: 1;
  margin-right: 8px;
  padding: 0;
  vertical-align: middle;
  width: auto;
}

.lg-outer #lg-share {
  position: relative;
}

.lg-outer #lg-share:after {
  content: "\e80d";
}

.lg-outer #lg-share-facebook .lg-icon {
  color: #3b5998;
}

.lg-outer #lg-share-facebook .lg-icon:after {
  content: "\e904";
}

.lg-outer #lg-share-twitter .lg-icon {
  color: #00aced;
}

.lg-outer #lg-share-twitter .lg-icon:after {
  content: "\e907";
}

.lg-outer #lg-share-googleplus .lg-icon {
  color: #dd4b39;
}

.lg-outer #lg-share-googleplus .lg-icon:after {
  content: "\e905";
}

.lg-outer #lg-share-pinterest .lg-icon {
  color: #cb2027;
}

.lg-outer #lg-share-pinterest .lg-icon:after {
  content: "\e906";
}

.lg-outer .lg-img-rotate {
  position: absolute;
  padding: 0 5px;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  transition: -webkit-transform 0.3s cubic-bezier(0.32, 0, 0.67, 0) 0s;
  transition: transform 0.3s cubic-bezier(0.32, 0, 0.67, 0) 0s;
  transition: transform 0.3s cubic-bezier(0.32, 0, 0.67, 0) 0s, -webkit-transform 0.3s cubic-bezier(0.32, 0, 0.67, 0) 0s;
}

.lg-rotate-left:after {
  content: "\e900";
}

.lg-rotate-right:after {
  content: "\e901";
}

.lg-icon.lg-flip-hor, .lg-icon.lg-flip-ver {
  font-size: 26px;
}

.lg-flip-hor:after {
  content: "\e902";
}

.lg-flip-ver:after {
  content: "\e903";
}

.lg-group {
  *zoom: 1;
}

.lg-group:before, .lg-group:after {
  display: table;
  content: "";
  line-height: 0;
}

.lg-group:after {
  clear: both;
}

.lg-outer {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  opacity: 0;
  outline: none;
  transition: opacity 0.15s ease 0s;
}

.lg-outer * {
  box-sizing: border-box;
}

.lg-outer.lg-visible {
  opacity: 1;
}

.lg-outer.lg-css3 .lg-item.lg-prev-slide, .lg-outer.lg-css3 .lg-item.lg-next-slide, .lg-outer.lg-css3 .lg-item.lg-current {
  transition-duration: inherit !important;
  transition-timing-function: inherit !important;
}

.lg-outer.lg-css3.lg-dragging .lg-item.lg-prev-slide, .lg-outer.lg-css3.lg-dragging .lg-item.lg-next-slide, .lg-outer.lg-css3.lg-dragging .lg-item.lg-current {
  transition-duration: 0s !important;
  opacity: 1;
}

.lg-outer.lg-grab img.lg-object {
  cursor: -o-grab;
  cursor: -ms-grab;
  cursor: grab;
}

.lg-outer.lg-grabbing img.lg-object {
  cursor: move;
  cursor: -o-grabbing;
  cursor: -ms-grabbing;
  cursor: grabbing;
}

.lg-outer .lg {
  height: 100%;
  width: 100%;
  position: relative;
  overflow: hidden;
  margin-left: auto;
  margin-right: auto;
  max-width: 100%;
  max-height: 100%;
}

.lg-outer .lg-inner {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  white-space: nowrap;
}

.lg-outer .lg-item {
  background: url(/_next/static/media/loading.24795fc7.gif) no-repeat scroll center center transparent;
  display: none !important;
}

.lg-outer.lg-css3 .lg-prev-slide, .lg-outer.lg-css3 .lg-current, .lg-outer.lg-css3 .lg-next-slide {
  display: inline-block !important;
}

.lg-outer.lg-css .lg-current {
  display: inline-block !important;
}

.lg-outer .lg-item, .lg-outer .lg-img-wrap {
  display: inline-block;
  text-align: center;
  position: absolute;
  width: 100%;
  height: 100%;
}

.lg-outer .lg-item:before, .lg-outer .lg-img-wrap:before {
  content: "";
  display: inline-block;
  height: 50%;
  width: 1px;
  margin-right: -1px;
}

.lg-outer .lg-img-wrap {
  position: absolute;
  padding: 0 5px;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}

.lg-outer .lg-item.lg-complete {
  background-image: none;
}

.lg-outer .lg-item.lg-current {
  z-index: 1060;
}

.lg-outer .lg-image {
  display: inline-block;
  vertical-align: middle;
  max-width: 100%;
  max-height: 100%;
  width: auto !important;
  height: auto !important;
}

.lg-outer.lg-show-after-load .lg-item .lg-object, .lg-outer.lg-show-after-load .lg-item .lg-video-play {
  opacity: 0;
  transition: opacity 0.15s ease 0s;
}

.lg-outer.lg-show-after-load .lg-item.lg-complete .lg-object, .lg-outer.lg-show-after-load .lg-item.lg-complete .lg-video-play {
  opacity: 1;
}

.lg-outer .lg-empty-html {
  display: none;
}

.lg-outer.lg-hide-download #lg-download {
  display: none;
}

.lg-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1040;
  background-color: #000;
  opacity: 0;
  transition: opacity 0.15s ease 0s;
}

.lg-backdrop.in {
  opacity: 1;
}

.lg-css3.lg-no-trans .lg-prev-slide, .lg-css3.lg-no-trans .lg-next-slide, .lg-css3.lg-no-trans .lg-current {
  transition: none 0s ease 0s !important;
}

.lg-css3.lg-use-css3 .lg-item {
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.lg-css3.lg-use-left .lg-item {
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.lg-css3.lg-fade .lg-item {
  opacity: 0;
}

.lg-css3.lg-fade .lg-item.lg-current {
  opacity: 1;
}

.lg-css3.lg-fade .lg-item.lg-prev-slide, .lg-css3.lg-fade .lg-item.lg-next-slide, .lg-css3.lg-fade .lg-item.lg-current {
  transition: opacity 0.1s ease 0s;
}

.lg-css3.lg-slide.lg-use-css3 .lg-item {
  opacity: 0;
}

.lg-css3.lg-slide.lg-use-css3 .lg-item.lg-prev-slide {
  -webkit-transform: translate3d(-100%, 0, 0);
  transform: translate3d(-100%, 0, 0);
}

.lg-css3.lg-slide.lg-use-css3 .lg-item.lg-next-slide {
  -webkit-transform: translate3d(100%, 0, 0);
  transform: translate3d(100%, 0, 0);
}

.lg-css3.lg-slide.lg-use-css3 .lg-item.lg-current {
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  opacity: 1;
}

.lg-css3.lg-slide.lg-use-css3 .lg-item.lg-prev-slide, .lg-css3.lg-slide.lg-use-css3 .lg-item.lg-next-slide, .lg-css3.lg-slide.lg-use-css3 .lg-item.lg-current {
  transition: opacity 0.1s ease 0s, -webkit-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s;
  transition: transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
  transition: transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s, -webkit-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s;
}

.lg-css3.lg-slide.lg-use-left .lg-item {
  opacity: 0;
  position: absolute;
  left: 0;
}

.lg-css3.lg-slide.lg-use-left .lg-item.lg-prev-slide {
  left: -100%;
}

.lg-css3.lg-slide.lg-use-left .lg-item.lg-next-slide {
  left: 100%;
}

.lg-css3.lg-slide.lg-use-left .lg-item.lg-current {
  left: 0;
  opacity: 1;
}

.lg-css3.lg-slide.lg-use-left .lg-item.lg-prev-slide, .lg-css3.lg-slide.lg-use-left .lg-item.lg-next-slide, .lg-css3.lg-slide.lg-use-left .lg-item.lg-current {
  transition: left 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
}

@charset "UTF-8";
.react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-year-read-view--down-arrow, .react-datepicker__navigation-icon::before {
  border-color: #ccc;
  border-style: solid;
  border-width: 3px 3px 0 0;
  content: "";
  display: block;
  height: 9px;
  position: absolute;
  top: 6px;
  width: 9px;
}
.react-datepicker-popper[data-placement^=top] .react-datepicker__triangle, .react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle {
  margin-left: -4px;
  position: absolute;
  width: 0;
}
.react-datepicker-popper[data-placement^=top] .react-datepicker__triangle::before, .react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::before, .react-datepicker-popper[data-placement^=top] .react-datepicker__triangle::after, .react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::after {
  box-sizing: content-box;
  position: absolute;
  border: 8px solid transparent;
  height: 0;
  width: 1px;
  content: "";
  z-index: -1;
  border-width: 8px;
  left: -8px;
}
.react-datepicker-popper[data-placement^=top] .react-datepicker__triangle::before, .react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::before {
  border-bottom-color: #aeaeae;
}

.react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle {
  top: 0;
  margin-top: -8px;
}
.react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::before, .react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::after {
  border-top: none;
  border-bottom-color: #f0f0f0;
}
.react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::after {
  top: 0;
}
.react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::before {
  top: -1px;
  border-bottom-color: #aeaeae;
}

.react-datepicker-popper[data-placement^=top] .react-datepicker__triangle {
  bottom: 0;
  margin-bottom: -8px;
}
.react-datepicker-popper[data-placement^=top] .react-datepicker__triangle::before, .react-datepicker-popper[data-placement^=top] .react-datepicker__triangle::after {
  border-bottom: none;
  border-top-color: #fff;
}
.react-datepicker-popper[data-placement^=top] .react-datepicker__triangle::after {
  bottom: 0;
}
.react-datepicker-popper[data-placement^=top] .react-datepicker__triangle::before {
  bottom: -1px;
  border-top-color: #aeaeae;
}

.react-datepicker-wrapper {
  display: inline-block;
  padding: 0;
  border: 0;
}

.react-datepicker {
  font-family: "Helvetica Neue", helvetica, arial, sans-serif;
  font-size: 0.8rem;
  background-color: #fff;
  color: #000;
  border: 1px solid #aeaeae;
  border-radius: 0.3rem;
  display: inline-block;
  position: relative;
}

.react-datepicker--time-only .react-datepicker__triangle {
  left: 35px;
}
.react-datepicker--time-only .react-datepicker__time-container {
  border-left: 0;
}
.react-datepicker--time-only .react-datepicker__time,
.react-datepicker--time-only .react-datepicker__time-box {
  border-bottom-left-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem;
}

.react-datepicker__triangle {
  position: absolute;
  left: 50px;
}

.react-datepicker-popper {
  z-index: 1;
}
.react-datepicker-popper[data-placement^=bottom] {
  padding-top: 10px;
}
.react-datepicker-popper[data-placement=bottom-end] .react-datepicker__triangle, .react-datepicker-popper[data-placement=top-end] .react-datepicker__triangle {
  left: auto;
  right: 50px;
}
.react-datepicker-popper[data-placement^=top] {
  padding-bottom: 10px;
}
.react-datepicker-popper[data-placement^=right] {
  padding-left: 8px;
}
.react-datepicker-popper[data-placement^=right] .react-datepicker__triangle {
  left: auto;
  right: 42px;
}
.react-datepicker-popper[data-placement^=left] {
  padding-right: 8px;
}
.react-datepicker-popper[data-placement^=left] .react-datepicker__triangle {
  left: 42px;
  right: auto;
}

.react-datepicker__header {
  text-align: center;
  background-color: #f0f0f0;
  border-bottom: 1px solid #aeaeae;
  border-top-left-radius: 0.3rem;
  padding: 8px 0;
  position: relative;
}
.react-datepicker__header--time {
  padding-bottom: 8px;
  padding-left: 5px;
  padding-right: 5px;
}
.react-datepicker__header--time:not(.react-datepicker__header--time--only) {
  border-top-left-radius: 0;
}
.react-datepicker__header:not(.react-datepicker__header--has-time-select) {
  border-top-right-radius: 0.3rem;
}

.react-datepicker__year-dropdown-container--select,
.react-datepicker__month-dropdown-container--select,
.react-datepicker__month-year-dropdown-container--select,
.react-datepicker__year-dropdown-container--scroll,
.react-datepicker__month-dropdown-container--scroll,
.react-datepicker__month-year-dropdown-container--scroll {
  display: inline-block;
  margin: 0 15px;
}

.react-datepicker__current-month,
.react-datepicker-time__header,
.react-datepicker-year-header {
  margin-top: 0;
  color: #000;
  font-weight: bold;
  font-size: 0.944rem;
}

.react-datepicker-time__header {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.react-datepicker__navigation {
  align-items: center;
  background: none;
  display: flex;
  justify-content: center;
  text-align: center;
  cursor: pointer;
  position: absolute;
  top: 2px;
  padding: 0;
  border: none;
  z-index: 1;
  height: 32px;
  width: 32px;
  text-indent: -999em;
  overflow: hidden;
}
.react-datepicker__navigation--previous {
  left: 2px;
}
.react-datepicker__navigation--next {
  right: 2px;
}
.react-datepicker__navigation--next--with-time:not(.react-datepicker__navigation--next--with-today-button) {
  right: 85px;
}
.react-datepicker__navigation--years {
  position: relative;
  top: 0;
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.react-datepicker__navigation--years-previous {
  top: 4px;
}
.react-datepicker__navigation--years-upcoming {
  top: -4px;
}
.react-datepicker__navigation:hover *::before {
  border-color: #a6a6a6;
}

.react-datepicker__navigation-icon {
  position: relative;
  top: -1px;
  font-size: 20px;
  width: 0;
}
.react-datepicker__navigation-icon--next {
  left: -2px;
}
.react-datepicker__navigation-icon--next::before {
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
  left: -7px;
}
.react-datepicker__navigation-icon--previous {
  right: -2px;
}
.react-datepicker__navigation-icon--previous::before {
  -webkit-transform: rotate(225deg);
          transform: rotate(225deg);
  right: -7px;
}

.react-datepicker__month-container {
  float: left;
}

.react-datepicker__year {
  margin: 0.4rem;
  text-align: center;
}
.react-datepicker__year-wrapper {
  display: flex;
  flex-wrap: wrap;
  max-width: 180px;
}
.react-datepicker__year .react-datepicker__year-text {
  display: inline-block;
  width: 4rem;
  margin: 2px;
}

.react-datepicker__month {
  margin: 0.4rem;
  text-align: center;
}
.react-datepicker__month .react-datepicker__month-text,
.react-datepicker__month .react-datepicker__quarter-text {
  display: inline-block;
  width: 4rem;
  margin: 2px;
}

.react-datepicker__input-time-container {
  clear: both;
  width: 100%;
  float: left;
  margin: 5px 0 10px 15px;
  text-align: left;
}
.react-datepicker__input-time-container .react-datepicker-time__caption {
  display: inline-block;
}
.react-datepicker__input-time-container .react-datepicker-time__input-container {
  display: inline-block;
}
.react-datepicker__input-time-container .react-datepicker-time__input-container .react-datepicker-time__input {
  display: inline-block;
  margin-left: 10px;
}
.react-datepicker__input-time-container .react-datepicker-time__input-container .react-datepicker-time__input input {
  width: auto;
}
.react-datepicker__input-time-container .react-datepicker-time__input-container .react-datepicker-time__input input[type=time]::-webkit-inner-spin-button,
.react-datepicker__input-time-container .react-datepicker-time__input-container .react-datepicker-time__input input[type=time]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.react-datepicker__input-time-container .react-datepicker-time__input-container .react-datepicker-time__input input[type=time] {
  -moz-appearance: textfield;
}
.react-datepicker__input-time-container .react-datepicker-time__input-container .react-datepicker-time__delimiter {
  margin-left: 5px;
  display: inline-block;
}

.react-datepicker__time-container {
  float: right;
  border-left: 1px solid #aeaeae;
  width: 85px;
}
.react-datepicker__time-container--with-today-button {
  display: inline;
  border: 1px solid #aeaeae;
  border-radius: 0.3rem;
  position: absolute;
  right: -87px;
  top: 0;
}
.react-datepicker__time-container .react-datepicker__time {
  position: relative;
  background: white;
  border-bottom-right-radius: 0.3rem;
}
.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box {
  width: 85px;
  overflow-x: hidden;
  margin: 0 auto;
  text-align: center;
  border-bottom-right-radius: 0.3rem;
}
.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list {
  list-style: none;
  margin: 0;
  height: calc(195px + (1.7rem / 2));
  overflow-y: scroll;
  padding-right: 0;
  padding-left: 0;
  width: 100%;
  box-sizing: content-box;
}
.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item {
  height: 30px;
  padding: 5px 10px;
  white-space: nowrap;
}
.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item:hover {
  cursor: pointer;
  background-color: #f0f0f0;
}
.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected {
  background-color: #216ba5;
  color: white;
  font-weight: bold;
}
.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected:hover {
  background-color: #216ba5;
}
.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--disabled {
  color: #ccc;
}
.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--disabled:hover {
  cursor: default;
  background-color: transparent;
}

.react-datepicker__week-number {
  color: #ccc;
  display: inline-block;
  width: 1.7rem;
  line-height: 1.7rem;
  text-align: center;
  margin: 0.166rem;
}
.react-datepicker__week-number.react-datepicker__week-number--clickable {
  cursor: pointer;
}
.react-datepicker__week-number.react-datepicker__week-number--clickable:hover {
  border-radius: 0.3rem;
  background-color: #f0f0f0;
}

.react-datepicker__day-names,
.react-datepicker__week {
  white-space: nowrap;
}

.react-datepicker__day-names {
  margin-bottom: -8px;
}

.react-datepicker__day-name,
.react-datepicker__day,
.react-datepicker__time-name {
  color: #000;
  display: inline-block;
  width: 1.7rem;
  line-height: 1.7rem;
  text-align: center;
  margin: 0.166rem;
}

.react-datepicker__day,
.react-datepicker__month-text,
.react-datepicker__quarter-text,
.react-datepicker__year-text {
  cursor: pointer;
}
.react-datepicker__day:hover,
.react-datepicker__month-text:hover,
.react-datepicker__quarter-text:hover,
.react-datepicker__year-text:hover {
  border-radius: 0.3rem;
  background-color: #f0f0f0;
}
.react-datepicker__day--today,
.react-datepicker__month-text--today,
.react-datepicker__quarter-text--today,
.react-datepicker__year-text--today {
  font-weight: bold;
}
.react-datepicker__day--highlighted,
.react-datepicker__month-text--highlighted,
.react-datepicker__quarter-text--highlighted,
.react-datepicker__year-text--highlighted {
  border-radius: 0.3rem;
  background-color: #3dcc4a;
  color: #fff;
}
.react-datepicker__day--highlighted:hover,
.react-datepicker__month-text--highlighted:hover,
.react-datepicker__quarter-text--highlighted:hover,
.react-datepicker__year-text--highlighted:hover {
  background-color: #32be3f;
}
.react-datepicker__day--highlighted-custom-1,
.react-datepicker__month-text--highlighted-custom-1,
.react-datepicker__quarter-text--highlighted-custom-1,
.react-datepicker__year-text--highlighted-custom-1 {
  color: magenta;
}
.react-datepicker__day--highlighted-custom-2,
.react-datepicker__month-text--highlighted-custom-2,
.react-datepicker__quarter-text--highlighted-custom-2,
.react-datepicker__year-text--highlighted-custom-2 {
  color: green;
}
.react-datepicker__day--holidays,
.react-datepicker__month-text--holidays,
.react-datepicker__quarter-text--holidays,
.react-datepicker__year-text--holidays {
  position: relative;
  border-radius: 0.3rem;
  background-color: #ff6803;
  color: #fff;
}
.react-datepicker__day--holidays .holiday-overlay,
.react-datepicker__month-text--holidays .holiday-overlay,
.react-datepicker__quarter-text--holidays .holiday-overlay,
.react-datepicker__year-text--holidays .holiday-overlay {
  position: absolute;
  bottom: 100%;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  background-color: #333;
  color: #fff;
  padding: 4px;
  border-radius: 4px;
  white-space: nowrap;
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s, opacity 0.3s ease-in-out;
}
.react-datepicker__day--holidays:hover,
.react-datepicker__month-text--holidays:hover,
.react-datepicker__quarter-text--holidays:hover,
.react-datepicker__year-text--holidays:hover {
  background-color: #cf5300;
}
.react-datepicker__day--holidays:hover .holiday-overlay,
.react-datepicker__month-text--holidays:hover .holiday-overlay,
.react-datepicker__quarter-text--holidays:hover .holiday-overlay,
.react-datepicker__year-text--holidays:hover .holiday-overlay {
  visibility: visible;
  opacity: 1;
}
.react-datepicker__day--selected, .react-datepicker__day--in-selecting-range, .react-datepicker__day--in-range,
.react-datepicker__month-text--selected,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--selected,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__quarter-text--in-range,
.react-datepicker__year-text--selected,
.react-datepicker__year-text--in-selecting-range,
.react-datepicker__year-text--in-range {
  border-radius: 0.3rem;
  background-color: #216ba5;
  color: #fff;
}
.react-datepicker__day--selected:hover, .react-datepicker__day--in-selecting-range:hover, .react-datepicker__day--in-range:hover,
.react-datepicker__month-text--selected:hover,
.react-datepicker__month-text--in-selecting-range:hover,
.react-datepicker__month-text--in-range:hover,
.react-datepicker__quarter-text--selected:hover,
.react-datepicker__quarter-text--in-selecting-range:hover,
.react-datepicker__quarter-text--in-range:hover,
.react-datepicker__year-text--selected:hover,
.react-datepicker__year-text--in-selecting-range:hover,
.react-datepicker__year-text--in-range:hover {
  background-color: #1d5d90;
}
.react-datepicker__day--keyboard-selected,
.react-datepicker__month-text--keyboard-selected,
.react-datepicker__quarter-text--keyboard-selected,
.react-datepicker__year-text--keyboard-selected {
  border-radius: 0.3rem;
  background-color: #bad9f1;
  color: rgb(0, 0, 0);
}
.react-datepicker__day--keyboard-selected:hover,
.react-datepicker__month-text--keyboard-selected:hover,
.react-datepicker__quarter-text--keyboard-selected:hover,
.react-datepicker__year-text--keyboard-selected:hover {
  background-color: #1d5d90;
}
.react-datepicker__day--in-selecting-range:not(.react-datepicker__day--in-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--in-range,
.react-datepicker__year-text--in-range),
.react-datepicker__month-text--in-selecting-range:not(.react-datepicker__day--in-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--in-range,
.react-datepicker__year-text--in-range),
.react-datepicker__quarter-text--in-selecting-range:not(.react-datepicker__day--in-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--in-range,
.react-datepicker__year-text--in-range),
.react-datepicker__year-text--in-selecting-range:not(.react-datepicker__day--in-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--in-range,
.react-datepicker__year-text--in-range) {
  background-color: rgba(33, 107, 165, 0.5);
}
.react-datepicker__month--selecting-range .react-datepicker__day--in-range:not(.react-datepicker__day--in-selecting-range,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__year-text--in-selecting-range), .react-datepicker__year--selecting-range .react-datepicker__day--in-range:not(.react-datepicker__day--in-selecting-range,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__year-text--in-selecting-range),
.react-datepicker__month--selecting-range .react-datepicker__month-text--in-range:not(.react-datepicker__day--in-selecting-range,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__year-text--in-selecting-range),
.react-datepicker__year--selecting-range .react-datepicker__month-text--in-range:not(.react-datepicker__day--in-selecting-range,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__year-text--in-selecting-range),
.react-datepicker__month--selecting-range .react-datepicker__quarter-text--in-range:not(.react-datepicker__day--in-selecting-range,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__year-text--in-selecting-range),
.react-datepicker__year--selecting-range .react-datepicker__quarter-text--in-range:not(.react-datepicker__day--in-selecting-range,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__year-text--in-selecting-range),
.react-datepicker__month--selecting-range .react-datepicker__year-text--in-range:not(.react-datepicker__day--in-selecting-range,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__year-text--in-selecting-range),
.react-datepicker__year--selecting-range .react-datepicker__year-text--in-range:not(.react-datepicker__day--in-selecting-range,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__year-text--in-selecting-range) {
  background-color: #f0f0f0;
  color: #000;
}
.react-datepicker__day--disabled,
.react-datepicker__month-text--disabled,
.react-datepicker__quarter-text--disabled,
.react-datepicker__year-text--disabled {
  cursor: default;
  color: #ccc;
}
.react-datepicker__day--disabled:hover,
.react-datepicker__month-text--disabled:hover,
.react-datepicker__quarter-text--disabled:hover,
.react-datepicker__year-text--disabled:hover {
  background-color: transparent;
}

.react-datepicker__input-container {
  position: relative;
  display: inline-block;
  width: 100%;
}
.react-datepicker__input-container .react-datepicker__calendar-icon {
  position: absolute;
  padding: 0.5rem;
}

.react-datepicker__view-calendar-icon input {
  padding: 6px 10px 5px 25px;
}

.react-datepicker__year-read-view,
.react-datepicker__month-read-view,
.react-datepicker__month-year-read-view {
  border: 1px solid transparent;
  border-radius: 0.3rem;
  position: relative;
}
.react-datepicker__year-read-view:hover,
.react-datepicker__month-read-view:hover,
.react-datepicker__month-year-read-view:hover {
  cursor: pointer;
}
.react-datepicker__year-read-view:hover .react-datepicker__year-read-view--down-arrow,
.react-datepicker__year-read-view:hover .react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-read-view:hover .react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-read-view:hover .react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-year-read-view:hover .react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-year-read-view:hover .react-datepicker__month-read-view--down-arrow {
  border-top-color: #b3b3b3;
}
.react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-year-read-view--down-arrow {
  -webkit-transform: rotate(135deg);
          transform: rotate(135deg);
  right: -16px;
  top: 0;
}

.react-datepicker__year-dropdown,
.react-datepicker__month-dropdown,
.react-datepicker__month-year-dropdown {
  background-color: #f0f0f0;
  position: absolute;
  width: 50%;
  left: 25%;
  top: 30px;
  z-index: 1;
  text-align: center;
  border-radius: 0.3rem;
  border: 1px solid #aeaeae;
}
.react-datepicker__year-dropdown:hover,
.react-datepicker__month-dropdown:hover,
.react-datepicker__month-year-dropdown:hover {
  cursor: pointer;
}
.react-datepicker__year-dropdown--scrollable,
.react-datepicker__month-dropdown--scrollable,
.react-datepicker__month-year-dropdown--scrollable {
  height: 150px;
  overflow-y: scroll;
}

.react-datepicker__year-option,
.react-datepicker__month-option,
.react-datepicker__month-year-option {
  line-height: 20px;
  width: 100%;
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.react-datepicker__year-option:first-of-type,
.react-datepicker__month-option:first-of-type,
.react-datepicker__month-year-option:first-of-type {
  border-top-left-radius: 0.3rem;
  border-top-right-radius: 0.3rem;
}
.react-datepicker__year-option:last-of-type,
.react-datepicker__month-option:last-of-type,
.react-datepicker__month-year-option:last-of-type {
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border-bottom-left-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem;
}
.react-datepicker__year-option:hover,
.react-datepicker__month-option:hover,
.react-datepicker__month-year-option:hover {
  background-color: #ccc;
}
.react-datepicker__year-option:hover .react-datepicker__navigation--years-upcoming,
.react-datepicker__month-option:hover .react-datepicker__navigation--years-upcoming,
.react-datepicker__month-year-option:hover .react-datepicker__navigation--years-upcoming {
  border-bottom-color: #b3b3b3;
}
.react-datepicker__year-option:hover .react-datepicker__navigation--years-previous,
.react-datepicker__month-option:hover .react-datepicker__navigation--years-previous,
.react-datepicker__month-year-option:hover .react-datepicker__navigation--years-previous {
  border-top-color: #b3b3b3;
}
.react-datepicker__year-option--selected,
.react-datepicker__month-option--selected,
.react-datepicker__month-year-option--selected {
  position: absolute;
  left: 15px;
}

.react-datepicker__close-icon {
  cursor: pointer;
  background-color: transparent;
  border: 0;
  outline: 0;
  padding: 0 6px 0 0;
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  display: table-cell;
  vertical-align: middle;
}
.react-datepicker__close-icon::after {
  cursor: pointer;
  background-color: #216ba5;
  color: #fff;
  border-radius: 50%;
  height: 16px;
  width: 16px;
  padding: 2px;
  font-size: 12px;
  line-height: 1;
  text-align: center;
  display: table-cell;
  vertical-align: middle;
  content: "×";
}

.react-datepicker__today-button {
  background: #f0f0f0;
  border-top: 1px solid #aeaeae;
  cursor: pointer;
  text-align: center;
  font-weight: bold;
  padding: 5px 0;
  clear: left;
}

.react-datepicker__portal {
  position: fixed;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.8);
  left: 0;
  top: 0;
  justify-content: center;
  align-items: center;
  display: flex;
  z-index: 2147483647;
}
.react-datepicker__portal .react-datepicker__day-name,
.react-datepicker__portal .react-datepicker__day,
.react-datepicker__portal .react-datepicker__time-name {
  width: 3rem;
  line-height: 3rem;
}
@media (max-width: 400px), (max-height: 550px) {
  .react-datepicker__portal .react-datepicker__day-name,
  .react-datepicker__portal .react-datepicker__day,
  .react-datepicker__portal .react-datepicker__time-name {
    width: 2rem;
    line-height: 2rem;
  }
}
.react-datepicker__portal .react-datepicker__current-month,
.react-datepicker__portal .react-datepicker-time__header {
  font-size: 1.44rem;
}

.react-datepicker__children-container {
  width: 13.8rem;
  margin: 0.4rem;
  padding-right: 0.2rem;
  padding-left: 0.2rem;
  height: auto;
}

.react-datepicker__aria-live {
  position: absolute;
  -webkit-clip-path: circle(0);
          clip-path: circle(0);
  border: 0;
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  width: 1px;
  white-space: nowrap;
}

.react-datepicker__calendar-icon {
  width: 1em;
  height: 1em;
  vertical-align: -0.125em;
}

.transparent-back {
  background-color: rgba(0, 0, 0, 0.2);
  background-blend-mode: multiply;
}

/*--- Custom Row ------------------------------------------*/
.row-0 {
  margin-left: 0px;
  margin-right: 0px;
}

.row-0 > [class*="col"] {
  padding-left: 0px;
  padding-right: 0px;
}

.row-1 {
  margin-left: -1px;
  margin-right: -1px;
}

.row-1 > [class*="col"] {
  padding-left: 1px;
  padding-right: 1px;
}

.row-2 {
  margin-left: -2px;
  margin-right: -2px;
}

.row-2 > [class*="col"] {
  padding-left: 2px;
  padding-right: 2px;
}

.row-3 {
  margin-left: -3px;
  margin-right: -3px;
}

.row-3 > [class*="col"] {
  padding-left: 3px;
  padding-right: 3px;
}

.row-4 {
  margin-left: -4px;
  margin-right: -4px;
}

.row-4 > [class*="col"] {
  padding-left: 4px;
  padding-right: 4px;
}

.row-5 {
  margin-left: -5px;
  margin-right: -5px;
}

.row-5 > [class*="col"] {
  padding-left: 5px;
  padding-right: 5px;
}

.row-6 {
  margin-left: -6px;
  margin-right: -6px;
}

.row-6 > [class*="col"] {
  padding-left: 6px;
  padding-right: 6px;
}

.row-7 {
  margin-left: -7px;
  margin-right: -7px;
}

.row-7 > [class*="col"] {
  padding-left: 7px;
  padding-right: 7px;
}

.row-8 {
  margin-left: -8px;
  margin-right: -8px;
}

.row-8 > [class*="col"] {
  padding-left: 8px;
  padding-right: 8px;
}

.row-9 {
  margin-left: -9px;
  margin-right: -9px;
}

.row-9 > [class*="col"] {
  padding-left: 9px;
  padding-right: 9px;
}

.row-10 {
  margin-left: -10px;
  margin-right: -10px;
}

.row-10 > [class*="col"] {
  padding-left: 10px;
  padding-right: 10px;
}

.row-11 {
  margin-left: -11px;
  margin-right: -11px;
}

.row-11 > [class*="col"] {
  padding-left: 11px;
  padding-right: 11px;
}

.row-12 {
  margin-left: -12px;
  margin-right: -12px;
}

.row-12 > [class*="col"] {
  padding-left: 12px;
  padding-right: 12px;
}

.row-13 {
  margin-left: -13px;
  margin-right: -13px;
}

.row-13 > [class*="col"] {
  padding-left: 13px;
  padding-right: 13px;
}

.row-14 {
  margin-left: -14px;
  margin-right: -14px;
}

.row-14 > [class*="col"] {
  padding-left: 14px;
  padding-right: 14px;
}

.row-15 {
  margin-left: -15px;
  margin-right: -15px;
}

.row-15 > [class*="col"] {
  padding-left: 15px;
  padding-right: 15px;
}

.row-16 {
  margin-left: -16px;
  margin-right: -16px;
}

.row-16 > [class*="col"] {
  padding-left: 16px;
  padding-right: 16px;
}

.row-17 {
  margin-left: -17px;
  margin-right: -17px;
}

.row-17 > [class*="col"] {
  padding-left: 17px;
  padding-right: 17px;
}

.row-18 {
  margin-left: -18px;
  margin-right: -18px;
}

.row-18 > [class*="col"] {
  padding-left: 18px;
  padding-right: 18px;
}

.row-19 {
  margin-left: -19px;
  margin-right: -19px;
}

.row-19 > [class*="col"] {
  padding-left: 19px;
  padding-right: 19px;
}

.row-20 {
  margin-left: -20px;
  margin-right: -20px;
}

.row-20 > [class*="col"] {
  padding-left: 20px;
  padding-right: 20px;
}

.row-21 {
  margin-left: -21px;
  margin-right: -21px;
}

.row-21 > [class*="col"] {
  padding-left: 21px;
  padding-right: 21px;
}

.row-22 {
  margin-left: -22px;
  margin-right: -22px;
}

.row-22 > [class*="col"] {
  padding-left: 22px;
  padding-right: 22px;
}

.row-23 {
  margin-left: -23px;
  margin-right: -23px;
}

.row-23 > [class*="col"] {
  padding-left: 23px;
  padding-right: 23px;
}

.row-24 {
  margin-left: -24px;
  margin-right: -24px;
}

.row-24 > [class*="col"] {
  padding-left: 24px;
  padding-right: 24px;
}

.row-25 {
  margin-left: -25px;
  margin-right: -25px;
}

.row-25 > [class*="col"] {
  padding-left: 25px;
  padding-right: 25px;
}

.row-26 {
  margin-left: -26px;
  margin-right: -26px;
}

.row-26 > [class*="col"] {
  padding-left: 26px;
  padding-right: 26px;
}

.row-27 {
  margin-left: -27px;
  margin-right: -27px;
}

.row-27 > [class*="col"] {
  padding-left: 27px;
  padding-right: 27px;
}

.row-28 {
  margin-left: -28px;
  margin-right: -28px;
}

.row-28 > [class*="col"] {
  padding-left: 28px;
  padding-right: 28px;
}

.row-29 {
  margin-left: -29px;
  margin-right: -29px;
}

.row-29 > [class*="col"] {
  padding-left: 29px;
  padding-right: 29px;
}

.row-30 {
  margin-left: -30px;
  margin-right: -30px;
}

.row-30 > [class*="col"] {
  padding-left: 30px;
  padding-right: 30px;
}

.row-31 {
  margin-left: -31px;
  margin-right: -31px;
}

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .row-31 {
    margin-left: -15px;
    margin-right: -15px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .row-31 {
    margin-left: -15px;
    margin-right: -15px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .row-31 {
    margin-left: -15px;
    margin-right: -15px;
  }
}
@media only screen and (max-width: 767px) {
  .row-31 {
    margin-left: -15px;
    margin-right: -15px;
  }
}
.row-31 > [class*="col"] {
  padding-left: 31px;
  padding-right: 31px;
}

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .row-31 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .row-31 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .row-31 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}
@media only screen and (max-width: 767px) {
  .row-31 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}
.row-32 {
  margin-left: -32px;
  margin-right: -32px;
}

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .row-32 {
    margin-left: -15px;
    margin-right: -15px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .row-32 {
    margin-left: -15px;
    margin-right: -15px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .row-32 {
    margin-left: -15px;
    margin-right: -15px;
  }
}
@media only screen and (max-width: 767px) {
  .row-32 {
    margin-left: -15px;
    margin-right: -15px;
  }
}
.row-32 > [class*="col"] {
  padding-left: 32px;
  padding-right: 32px;
}

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .row-32 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .row-32 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .row-32 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}
@media only screen and (max-width: 767px) {
  .row-32 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}
.row-33 {
  margin-left: -33px;
  margin-right: -33px;
}

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .row-33 {
    margin-left: -15px;
    margin-right: -15px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .row-33 {
    margin-left: -15px;
    margin-right: -15px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .row-33 {
    margin-left: -15px;
    margin-right: -15px;
  }
}
@media only screen and (max-width: 767px) {
  .row-33 {
    margin-left: -15px;
    margin-right: -15px;
  }
}
.row-33 > [class*="col"] {
  padding-left: 33px;
  padding-right: 33px;
}

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .row-33 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .row-33 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .row-33 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}
@media only screen and (max-width: 767px) {
  .row-33 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}
.row-34 {
  margin-left: -34px;
  margin-right: -34px;
}

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .row-34 {
    margin-left: -15px;
    margin-right: -15px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .row-34 {
    margin-left: -15px;
    margin-right: -15px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .row-34 {
    margin-left: -15px;
    margin-right: -15px;
  }
}
@media only screen and (max-width: 767px) {
  .row-34 {
    margin-left: -15px;
    margin-right: -15px;
  }
}
.row-34 > [class*="col"] {
  padding-left: 34px;
  padding-right: 34px;
}

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .row-34 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .row-34 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .row-34 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}
@media only screen and (max-width: 767px) {
  .row-34 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}
.row-35 {
  margin-left: -35px;
  margin-right: -35px;
}

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .row-35 {
    margin-left: -15px;
    margin-right: -15px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .row-35 {
    margin-left: -15px;
    margin-right: -15px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .row-35 {
    margin-left: -15px;
    margin-right: -15px;
  }
}
@media only screen and (max-width: 767px) {
  .row-35 {
    margin-left: -15px;
    margin-right: -15px;
  }
}
.row-35 > [class*="col"] {
  padding-left: 35px;
  padding-right: 35px;
}

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .row-35 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .row-35 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .row-35 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}
@media only screen and (max-width: 767px) {
  .row-35 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}
.row-36 {
  margin-left: -36px;
  margin-right: -36px;
}

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .row-36 {
    margin-left: -15px;
    margin-right: -15px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .row-36 {
    margin-left: -15px;
    margin-right: -15px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .row-36 {
    margin-left: -15px;
    margin-right: -15px;
  }
}
@media only screen and (max-width: 767px) {
  .row-36 {
    margin-left: -15px;
    margin-right: -15px;
  }
}
.row-36 > [class*="col"] {
  padding-left: 36px;
  padding-right: 36px;
}

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .row-36 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .row-36 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .row-36 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}
@media only screen and (max-width: 767px) {
  .row-36 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}
.row-37 {
  margin-left: -37px;
  margin-right: -37px;
}

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .row-37 {
    margin-left: -15px;
    margin-right: -15px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .row-37 {
    margin-left: -15px;
    margin-right: -15px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .row-37 {
    margin-left: -15px;
    margin-right: -15px;
  }
}
@media only screen and (max-width: 767px) {
  .row-37 {
    margin-left: -15px;
    margin-right: -15px;
  }
}
.row-37 > [class*="col"] {
  padding-left: 37px;
  padding-right: 37px;
}

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .row-37 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .row-37 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .row-37 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}
@media only screen and (max-width: 767px) {
  .row-37 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}
.row-38 {
  margin-left: -38px;
  margin-right: -38px;
}

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .row-38 {
    margin-left: -15px;
    margin-right: -15px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .row-38 {
    margin-left: -15px;
    margin-right: -15px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .row-38 {
    margin-left: -15px;
    margin-right: -15px;
  }
}
@media only screen and (max-width: 767px) {
  .row-38 {
    margin-left: -15px;
    margin-right: -15px;
  }
}
.row-38 > [class*="col"] {
  padding-left: 38px;
  padding-right: 38px;
}

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .row-38 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .row-38 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .row-38 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}
@media only screen and (max-width: 767px) {
  .row-38 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}
.row-39 {
  margin-left: -39px;
  margin-right: -39px;
}

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .row-39 {
    margin-left: -15px;
    margin-right: -15px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .row-39 {
    margin-left: -15px;
    margin-right: -15px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .row-39 {
    margin-left: -15px;
    margin-right: -15px;
  }
}
@media only screen and (max-width: 767px) {
  .row-39 {
    margin-left: -15px;
    margin-right: -15px;
  }
}
.row-39 > [class*="col"] {
  padding-left: 39px;
  padding-right: 39px;
}

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .row-39 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .row-39 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .row-39 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}
@media only screen and (max-width: 767px) {
  .row-39 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}
.row-40 {
  margin-left: -40px;
  margin-right: -40px;
}

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .row-40 {
    margin-left: -15px;
    margin-right: -15px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .row-40 {
    margin-left: -15px;
    margin-right: -15px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .row-40 {
    margin-left: -15px;
    margin-right: -15px;
  }
}
@media only screen and (max-width: 767px) {
  .row-40 {
    margin-left: -15px;
    margin-right: -15px;
  }
}
.row-40 > [class*="col"] {
  padding-left: 40px;
  padding-right: 40px;
}

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .row-40 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .row-40 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .row-40 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}
@media only screen and (max-width: 767px) {
  .row-40 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}
.row-41 {
  margin-left: -41px;
  margin-right: -41px;
}

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .row-41 {
    margin-left: -15px;
    margin-right: -15px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .row-41 {
    margin-left: -15px;
    margin-right: -15px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .row-41 {
    margin-left: -15px;
    margin-right: -15px;
  }
}
@media only screen and (max-width: 767px) {
  .row-41 {
    margin-left: -15px;
    margin-right: -15px;
  }
}
.row-41 > [class*="col"] {
  padding-left: 41px;
  padding-right: 41px;
}

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .row-41 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .row-41 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .row-41 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}
@media only screen and (max-width: 767px) {
  .row-41 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}
.row-42 {
  margin-left: -42px;
  margin-right: -42px;
}

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .row-42 {
    margin-left: -15px;
    margin-right: -15px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .row-42 {
    margin-left: -15px;
    margin-right: -15px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .row-42 {
    margin-left: -15px;
    margin-right: -15px;
  }
}
@media only screen and (max-width: 767px) {
  .row-42 {
    margin-left: -15px;
    margin-right: -15px;
  }
}
.row-42 > [class*="col"] {
  padding-left: 42px;
  padding-right: 42px;
}

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .row-42 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .row-42 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .row-42 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}
@media only screen and (max-width: 767px) {
  .row-42 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}
.row-43 {
  margin-left: -43px;
  margin-right: -43px;
}

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .row-43 {
    margin-left: -15px;
    margin-right: -15px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .row-43 {
    margin-left: -15px;
    margin-right: -15px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .row-43 {
    margin-left: -15px;
    margin-right: -15px;
  }
}
@media only screen and (max-width: 767px) {
  .row-43 {
    margin-left: -15px;
    margin-right: -15px;
  }
}
.row-43 > [class*="col"] {
  padding-left: 43px;
  padding-right: 43px;
}

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .row-43 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .row-43 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .row-43 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}
@media only screen and (max-width: 767px) {
  .row-43 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}
.row-44 {
  margin-left: -44px;
  margin-right: -44px;
}

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .row-44 {
    margin-left: -15px;
    margin-right: -15px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .row-44 {
    margin-left: -15px;
    margin-right: -15px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .row-44 {
    margin-left: -15px;
    margin-right: -15px;
  }
}
@media only screen and (max-width: 767px) {
  .row-44 {
    margin-left: -15px;
    margin-right: -15px;
  }
}
.row-44 > [class*="col"] {
  padding-left: 44px;
  padding-right: 44px;
}

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .row-44 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .row-44 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .row-44 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}
@media only screen and (max-width: 767px) {
  .row-44 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}
.row-45 {
  margin-left: -45px;
  margin-right: -45px;
}

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .row-45 {
    margin-left: -15px;
    margin-right: -15px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .row-45 {
    margin-left: -15px;
    margin-right: -15px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .row-45 {
    margin-left: -15px;
    margin-right: -15px;
  }
}
@media only screen and (max-width: 767px) {
  .row-45 {
    margin-left: -15px;
    margin-right: -15px;
  }
}
.row-45 > [class*="col"] {
  padding-left: 45px;
  padding-right: 45px;
}

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .row-45 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .row-45 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .row-45 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}
@media only screen and (max-width: 767px) {
  .row-45 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}
.row-46 {
  margin-left: -46px;
  margin-right: -46px;
}

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .row-46 {
    margin-left: -15px;
    margin-right: -15px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .row-46 {
    margin-left: -15px;
    margin-right: -15px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .row-46 {
    margin-left: -15px;
    margin-right: -15px;
  }
}
@media only screen and (max-width: 767px) {
  .row-46 {
    margin-left: -15px;
    margin-right: -15px;
  }
}
.row-46 > [class*="col"] {
  padding-left: 46px;
  padding-right: 46px;
}

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .row-46 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .row-46 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .row-46 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}
@media only screen and (max-width: 767px) {
  .row-46 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}
.row-47 {
  margin-left: -47px;
  margin-right: -47px;
}

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .row-47 {
    margin-left: -15px;
    margin-right: -15px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .row-47 {
    margin-left: -15px;
    margin-right: -15px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .row-47 {
    margin-left: -15px;
    margin-right: -15px;
  }
}
@media only screen and (max-width: 767px) {
  .row-47 {
    margin-left: -15px;
    margin-right: -15px;
  }
}
.row-47 > [class*="col"] {
  padding-left: 47px;
  padding-right: 47px;
}

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .row-47 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .row-47 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .row-47 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}
@media only screen and (max-width: 767px) {
  .row-47 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}
.row-48 {
  margin-left: -48px;
  margin-right: -48px;
}

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .row-48 {
    margin-left: -15px;
    margin-right: -15px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .row-48 {
    margin-left: -15px;
    margin-right: -15px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .row-48 {
    margin-left: -15px;
    margin-right: -15px;
  }
}
@media only screen and (max-width: 767px) {
  .row-48 {
    margin-left: -15px;
    margin-right: -15px;
  }
}
.row-48 > [class*="col"] {
  padding-left: 48px;
  padding-right: 48px;
}

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .row-48 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .row-48 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .row-48 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}
@media only screen and (max-width: 767px) {
  .row-48 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}
.row-49 {
  margin-left: -49px;
  margin-right: -49px;
}

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .row-49 {
    margin-left: -15px;
    margin-right: -15px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .row-49 {
    margin-left: -15px;
    margin-right: -15px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .row-49 {
    margin-left: -15px;
    margin-right: -15px;
  }
}
@media only screen and (max-width: 767px) {
  .row-49 {
    margin-left: -15px;
    margin-right: -15px;
  }
}
.row-49 > [class*="col"] {
  padding-left: 49px;
  padding-right: 49px;
}

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .row-49 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .row-49 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .row-49 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}
@media only screen and (max-width: 767px) {
  .row-49 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}
.row-50 {
  margin-left: -50px;
  margin-right: -50px;
}

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .row-50 {
    margin-left: -15px;
    margin-right: -15px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .row-50 {
    margin-left: -15px;
    margin-right: -15px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .row-50 {
    margin-left: -15px;
    margin-right: -15px;
  }
}
@media only screen and (max-width: 767px) {
  .row-50 {
    margin-left: -15px;
    margin-right: -15px;
  }
}
.row-50 > [class*="col"] {
  padding-left: 50px;
  padding-right: 50px;
}

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .row-50 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .row-50 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .row-50 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}
@media only screen and (max-width: 767px) {
  .row-50 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}
.row-51 {
  margin-left: -51px;
  margin-right: -51px;
}

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .row-51 {
    margin-left: -15px;
    margin-right: -15px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .row-51 {
    margin-left: -15px;
    margin-right: -15px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .row-51 {
    margin-left: -15px;
    margin-right: -15px;
  }
}
@media only screen and (max-width: 767px) {
  .row-51 {
    margin-left: -15px;
    margin-right: -15px;
  }
}
.row-51 > [class*="col"] {
  padding-left: 51px;
  padding-right: 51px;
}

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .row-51 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .row-51 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .row-51 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}
@media only screen and (max-width: 767px) {
  .row-51 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}
.row-52 {
  margin-left: -52px;
  margin-right: -52px;
}

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .row-52 {
    margin-left: -15px;
    margin-right: -15px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .row-52 {
    margin-left: -15px;
    margin-right: -15px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .row-52 {
    margin-left: -15px;
    margin-right: -15px;
  }
}
@media only screen and (max-width: 767px) {
  .row-52 {
    margin-left: -15px;
    margin-right: -15px;
  }
}
.row-52 > [class*="col"] {
  padding-left: 52px;
  padding-right: 52px;
}

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .row-52 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .row-52 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .row-52 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}
@media only screen and (max-width: 767px) {
  .row-52 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}
.row-53 {
  margin-left: -53px;
  margin-right: -53px;
}

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .row-53 {
    margin-left: -15px;
    margin-right: -15px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .row-53 {
    margin-left: -15px;
    margin-right: -15px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .row-53 {
    margin-left: -15px;
    margin-right: -15px;
  }
}
@media only screen and (max-width: 767px) {
  .row-53 {
    margin-left: -15px;
    margin-right: -15px;
  }
}
.row-53 > [class*="col"] {
  padding-left: 53px;
  padding-right: 53px;
}

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .row-53 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .row-53 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .row-53 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}
@media only screen and (max-width: 767px) {
  .row-53 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}
.row-54 {
  margin-left: -54px;
  margin-right: -54px;
}

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .row-54 {
    margin-left: -15px;
    margin-right: -15px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .row-54 {
    margin-left: -15px;
    margin-right: -15px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .row-54 {
    margin-left: -15px;
    margin-right: -15px;
  }
}
@media only screen and (max-width: 767px) {
  .row-54 {
    margin-left: -15px;
    margin-right: -15px;
  }
}
.row-54 > [class*="col"] {
  padding-left: 54px;
  padding-right: 54px;
}

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .row-54 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .row-54 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .row-54 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}
@media only screen and (max-width: 767px) {
  .row-54 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}
.row-55 {
  margin-left: -55px;
  margin-right: -55px;
}

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .row-55 {
    margin-left: -15px;
    margin-right: -15px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .row-55 {
    margin-left: -15px;
    margin-right: -15px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .row-55 {
    margin-left: -15px;
    margin-right: -15px;
  }
}
@media only screen and (max-width: 767px) {
  .row-55 {
    margin-left: -15px;
    margin-right: -15px;
  }
}
.row-55 > [class*="col"] {
  padding-left: 55px;
  padding-right: 55px;
}

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .row-55 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .row-55 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .row-55 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}
@media only screen and (max-width: 767px) {
  .row-55 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}
.row-56 {
  margin-left: -56px;
  margin-right: -56px;
}

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .row-56 {
    margin-left: -15px;
    margin-right: -15px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .row-56 {
    margin-left: -15px;
    margin-right: -15px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .row-56 {
    margin-left: -15px;
    margin-right: -15px;
  }
}
@media only screen and (max-width: 767px) {
  .row-56 {
    margin-left: -15px;
    margin-right: -15px;
  }
}
.row-56 > [class*="col"] {
  padding-left: 56px;
  padding-right: 56px;
}

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .row-56 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .row-56 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .row-56 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}
@media only screen and (max-width: 767px) {
  .row-56 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}
.row-57 {
  margin-left: -57px;
  margin-right: -57px;
}

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .row-57 {
    margin-left: -15px;
    margin-right: -15px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .row-57 {
    margin-left: -15px;
    margin-right: -15px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .row-57 {
    margin-left: -15px;
    margin-right: -15px;
  }
}
@media only screen and (max-width: 767px) {
  .row-57 {
    margin-left: -15px;
    margin-right: -15px;
  }
}
.row-57 > [class*="col"] {
  padding-left: 57px;
  padding-right: 57px;
}

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .row-57 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .row-57 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .row-57 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}
@media only screen and (max-width: 767px) {
  .row-57 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}
.row-58 {
  margin-left: -58px;
  margin-right: -58px;
}

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .row-58 {
    margin-left: -15px;
    margin-right: -15px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .row-58 {
    margin-left: -15px;
    margin-right: -15px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .row-58 {
    margin-left: -15px;
    margin-right: -15px;
  }
}
@media only screen and (max-width: 767px) {
  .row-58 {
    margin-left: -15px;
    margin-right: -15px;
  }
}
.row-58 > [class*="col"] {
  padding-left: 58px;
  padding-right: 58px;
}

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .row-58 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .row-58 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .row-58 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}
@media only screen and (max-width: 767px) {
  .row-58 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}
.row-59 {
  margin-left: -59px;
  margin-right: -59px;
}

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .row-59 {
    margin-left: -15px;
    margin-right: -15px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .row-59 {
    margin-left: -15px;
    margin-right: -15px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .row-59 {
    margin-left: -15px;
    margin-right: -15px;
  }
}
@media only screen and (max-width: 767px) {
  .row-59 {
    margin-left: -15px;
    margin-right: -15px;
  }
}
.row-59 > [class*="col"] {
  padding-left: 59px;
  padding-right: 59px;
}

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .row-59 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .row-59 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .row-59 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}
@media only screen and (max-width: 767px) {
  .row-59 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}
.row-60 {
  margin-left: -60px;
  margin-right: -60px;
}

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .row-60 {
    margin-left: -15px;
    margin-right: -15px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .row-60 {
    margin-left: -15px;
    margin-right: -15px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .row-60 {
    margin-left: -15px;
    margin-right: -15px;
  }
}
@media only screen and (max-width: 767px) {
  .row-60 {
    margin-left: -15px;
    margin-right: -15px;
  }
}
.row-60 > [class*="col"] {
  padding-left: 60px;
  padding-right: 60px;
}

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .row-60 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .row-60 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .row-60 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}
@media only screen and (max-width: 767px) {
  .row-60 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}
.row-61 {
  margin-left: -61px;
  margin-right: -61px;
}

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .row-61 {
    margin-left: -15px;
    margin-right: -15px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .row-61 {
    margin-left: -15px;
    margin-right: -15px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .row-61 {
    margin-left: -15px;
    margin-right: -15px;
  }
}
@media only screen and (max-width: 767px) {
  .row-61 {
    margin-left: -15px;
    margin-right: -15px;
  }
}
.row-61 > [class*="col"] {
  padding-left: 61px;
  padding-right: 61px;
}

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .row-61 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .row-61 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .row-61 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}
@media only screen and (max-width: 767px) {
  .row-61 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}
.row-62 {
  margin-left: -62px;
  margin-right: -62px;
}

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .row-62 {
    margin-left: -15px;
    margin-right: -15px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .row-62 {
    margin-left: -15px;
    margin-right: -15px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .row-62 {
    margin-left: -15px;
    margin-right: -15px;
  }
}
@media only screen and (max-width: 767px) {
  .row-62 {
    margin-left: -15px;
    margin-right: -15px;
  }
}
.row-62 > [class*="col"] {
  padding-left: 62px;
  padding-right: 62px;
}

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .row-62 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .row-62 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .row-62 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}
@media only screen and (max-width: 767px) {
  .row-62 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}
.row-63 {
  margin-left: -63px;
  margin-right: -63px;
}

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .row-63 {
    margin-left: -15px;
    margin-right: -15px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .row-63 {
    margin-left: -15px;
    margin-right: -15px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .row-63 {
    margin-left: -15px;
    margin-right: -15px;
  }
}
@media only screen and (max-width: 767px) {
  .row-63 {
    margin-left: -15px;
    margin-right: -15px;
  }
}
.row-63 > [class*="col"] {
  padding-left: 63px;
  padding-right: 63px;
}

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .row-63 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .row-63 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .row-63 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}
@media only screen and (max-width: 767px) {
  .row-63 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}
.row-64 {
  margin-left: -64px;
  margin-right: -64px;
}

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .row-64 {
    margin-left: -15px;
    margin-right: -15px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .row-64 {
    margin-left: -15px;
    margin-right: -15px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .row-64 {
    margin-left: -15px;
    margin-right: -15px;
  }
}
@media only screen and (max-width: 767px) {
  .row-64 {
    margin-left: -15px;
    margin-right: -15px;
  }
}
.row-64 > [class*="col"] {
  padding-left: 64px;
  padding-right: 64px;
}

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .row-64 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .row-64 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .row-64 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}
@media only screen and (max-width: 767px) {
  .row-64 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}
.row-65 {
  margin-left: -65px;
  margin-right: -65px;
}

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .row-65 {
    margin-left: -15px;
    margin-right: -15px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .row-65 {
    margin-left: -15px;
    margin-right: -15px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .row-65 {
    margin-left: -15px;
    margin-right: -15px;
  }
}
@media only screen and (max-width: 767px) {
  .row-65 {
    margin-left: -15px;
    margin-right: -15px;
  }
}
.row-65 > [class*="col"] {
  padding-left: 65px;
  padding-right: 65px;
}

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .row-65 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .row-65 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .row-65 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}
@media only screen and (max-width: 767px) {
  .row-65 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}
.row-66 {
  margin-left: -66px;
  margin-right: -66px;
}

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .row-66 {
    margin-left: -15px;
    margin-right: -15px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .row-66 {
    margin-left: -15px;
    margin-right: -15px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .row-66 {
    margin-left: -15px;
    margin-right: -15px;
  }
}
@media only screen and (max-width: 767px) {
  .row-66 {
    margin-left: -15px;
    margin-right: -15px;
  }
}
.row-66 > [class*="col"] {
  padding-left: 66px;
  padding-right: 66px;
}

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .row-66 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .row-66 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .row-66 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}
@media only screen and (max-width: 767px) {
  .row-66 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}
.row-67 {
  margin-left: -67px;
  margin-right: -67px;
}

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .row-67 {
    margin-left: -15px;
    margin-right: -15px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .row-67 {
    margin-left: -15px;
    margin-right: -15px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .row-67 {
    margin-left: -15px;
    margin-right: -15px;
  }
}
@media only screen and (max-width: 767px) {
  .row-67 {
    margin-left: -15px;
    margin-right: -15px;
  }
}
.row-67 > [class*="col"] {
  padding-left: 67px;
  padding-right: 67px;
}

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .row-67 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .row-67 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .row-67 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}
@media only screen and (max-width: 767px) {
  .row-67 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}
.row-68 {
  margin-left: -68px;
  margin-right: -68px;
}

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .row-68 {
    margin-left: -15px;
    margin-right: -15px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .row-68 {
    margin-left: -15px;
    margin-right: -15px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .row-68 {
    margin-left: -15px;
    margin-right: -15px;
  }
}
@media only screen and (max-width: 767px) {
  .row-68 {
    margin-left: -15px;
    margin-right: -15px;
  }
}
.row-68 > [class*="col"] {
  padding-left: 68px;
  padding-right: 68px;
}

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .row-68 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .row-68 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .row-68 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}
@media only screen and (max-width: 767px) {
  .row-68 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}
.row-69 {
  margin-left: -69px;
  margin-right: -69px;
}

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .row-69 {
    margin-left: -15px;
    margin-right: -15px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .row-69 {
    margin-left: -15px;
    margin-right: -15px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .row-69 {
    margin-left: -15px;
    margin-right: -15px;
  }
}
@media only screen and (max-width: 767px) {
  .row-69 {
    margin-left: -15px;
    margin-right: -15px;
  }
}
.row-69 > [class*="col"] {
  padding-left: 69px;
  padding-right: 69px;
}

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .row-69 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .row-69 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .row-69 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}
@media only screen and (max-width: 767px) {
  .row-69 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}
.row-70 {
  margin-left: -70px;
  margin-right: -70px;
}

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .row-70 {
    margin-left: -15px;
    margin-right: -15px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .row-70 {
    margin-left: -15px;
    margin-right: -15px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .row-70 {
    margin-left: -15px;
    margin-right: -15px;
  }
}
@media only screen and (max-width: 767px) {
  .row-70 {
    margin-left: -15px;
    margin-right: -15px;
  }
}
.row-70 > [class*="col"] {
  padding-left: 70px;
  padding-right: 70px;
}

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .row-70 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .row-70 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .row-70 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}
@media only screen and (max-width: 767px) {
  .row-70 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}
.row-71 {
  margin-left: -71px;
  margin-right: -71px;
}

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .row-71 {
    margin-left: -15px;
    margin-right: -15px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .row-71 {
    margin-left: -15px;
    margin-right: -15px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .row-71 {
    margin-left: -15px;
    margin-right: -15px;
  }
}
@media only screen and (max-width: 767px) {
  .row-71 {
    margin-left: -15px;
    margin-right: -15px;
  }
}
.row-71 > [class*="col"] {
  padding-left: 71px;
  padding-right: 71px;
}

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .row-71 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .row-71 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .row-71 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}
@media only screen and (max-width: 767px) {
  .row-71 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}
.row-72 {
  margin-left: -72px;
  margin-right: -72px;
}

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .row-72 {
    margin-left: -15px;
    margin-right: -15px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .row-72 {
    margin-left: -15px;
    margin-right: -15px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .row-72 {
    margin-left: -15px;
    margin-right: -15px;
  }
}
@media only screen and (max-width: 767px) {
  .row-72 {
    margin-left: -15px;
    margin-right: -15px;
  }
}
.row-72 > [class*="col"] {
  padding-left: 72px;
  padding-right: 72px;
}

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .row-72 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .row-72 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .row-72 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}
@media only screen and (max-width: 767px) {
  .row-72 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}
.row-73 {
  margin-left: -73px;
  margin-right: -73px;
}

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .row-73 {
    margin-left: -15px;
    margin-right: -15px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .row-73 {
    margin-left: -15px;
    margin-right: -15px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .row-73 {
    margin-left: -15px;
    margin-right: -15px;
  }
}
@media only screen and (max-width: 767px) {
  .row-73 {
    margin-left: -15px;
    margin-right: -15px;
  }
}
.row-73 > [class*="col"] {
  padding-left: 73px;
  padding-right: 73px;
}

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .row-73 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .row-73 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .row-73 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}
@media only screen and (max-width: 767px) {
  .row-73 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}
.row-74 {
  margin-left: -74px;
  margin-right: -74px;
}

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .row-74 {
    margin-left: -15px;
    margin-right: -15px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .row-74 {
    margin-left: -15px;
    margin-right: -15px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .row-74 {
    margin-left: -15px;
    margin-right: -15px;
  }
}
@media only screen and (max-width: 767px) {
  .row-74 {
    margin-left: -15px;
    margin-right: -15px;
  }
}
.row-74 > [class*="col"] {
  padding-left: 74px;
  padding-right: 74px;
}

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .row-74 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .row-74 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .row-74 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}
@media only screen and (max-width: 767px) {
  .row-74 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}
.row-75 {
  margin-left: -75px;
  margin-right: -75px;
}

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .row-75 {
    margin-left: -15px;
    margin-right: -15px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .row-75 {
    margin-left: -15px;
    margin-right: -15px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .row-75 {
    margin-left: -15px;
    margin-right: -15px;
  }
}
@media only screen and (max-width: 767px) {
  .row-75 {
    margin-left: -15px;
    margin-right: -15px;
  }
}
.row-75 > [class*="col"] {
  padding-left: 75px;
  padding-right: 75px;
}

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .row-75 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .row-75 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .row-75 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}
@media only screen and (max-width: 767px) {
  .row-75 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}
.row-76 {
  margin-left: -76px;
  margin-right: -76px;
}

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .row-76 {
    margin-left: -15px;
    margin-right: -15px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .row-76 {
    margin-left: -15px;
    margin-right: -15px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .row-76 {
    margin-left: -15px;
    margin-right: -15px;
  }
}
@media only screen and (max-width: 767px) {
  .row-76 {
    margin-left: -15px;
    margin-right: -15px;
  }
}
.row-76 > [class*="col"] {
  padding-left: 76px;
  padding-right: 76px;
}

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .row-76 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .row-76 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .row-76 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}
@media only screen and (max-width: 767px) {
  .row-76 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}
.row-77 {
  margin-left: -77px;
  margin-right: -77px;
}

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .row-77 {
    margin-left: -15px;
    margin-right: -15px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .row-77 {
    margin-left: -15px;
    margin-right: -15px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .row-77 {
    margin-left: -15px;
    margin-right: -15px;
  }
}
@media only screen and (max-width: 767px) {
  .row-77 {
    margin-left: -15px;
    margin-right: -15px;
  }
}
.row-77 > [class*="col"] {
  padding-left: 77px;
  padding-right: 77px;
}

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .row-77 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .row-77 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .row-77 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}
@media only screen and (max-width: 767px) {
  .row-77 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}
.row-78 {
  margin-left: -78px;
  margin-right: -78px;
}

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .row-78 {
    margin-left: -15px;
    margin-right: -15px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .row-78 {
    margin-left: -15px;
    margin-right: -15px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .row-78 {
    margin-left: -15px;
    margin-right: -15px;
  }
}
@media only screen and (max-width: 767px) {
  .row-78 {
    margin-left: -15px;
    margin-right: -15px;
  }
}
.row-78 > [class*="col"] {
  padding-left: 78px;
  padding-right: 78px;
}

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .row-78 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .row-78 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .row-78 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}
@media only screen and (max-width: 767px) {
  .row-78 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}
.row-79 {
  margin-left: -79px;
  margin-right: -79px;
}

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .row-79 {
    margin-left: -15px;
    margin-right: -15px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .row-79 {
    margin-left: -15px;
    margin-right: -15px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .row-79 {
    margin-left: -15px;
    margin-right: -15px;
  }
}
@media only screen and (max-width: 767px) {
  .row-79 {
    margin-left: -15px;
    margin-right: -15px;
  }
}
.row-79 > [class*="col"] {
  padding-left: 79px;
  padding-right: 79px;
}

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .row-79 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .row-79 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .row-79 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}
@media only screen and (max-width: 767px) {
  .row-79 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}
.row-80 {
  margin-left: -80px;
  margin-right: -80px;
}

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .row-80 {
    margin-left: -15px;
    margin-right: -15px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .row-80 {
    margin-left: -15px;
    margin-right: -15px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .row-80 {
    margin-left: -15px;
    margin-right: -15px;
  }
}
@media only screen and (max-width: 767px) {
  .row-80 {
    margin-left: -15px;
    margin-right: -15px;
  }
}
.row-80 > [class*="col"] {
  padding-left: 80px;
  padding-right: 80px;
}

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .row-80 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .row-80 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .row-80 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}
@media only screen and (max-width: 767px) {
  .row-80 > [class*="col"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}
/*-- 
    - Margin & Padding
-----------------------------------------*/
/*-- Margin Top --*/
.mt-0 {
  margin-top: 0px !important;
}

.mt-5 {
  margin-top: 5px !important;
}

.mt-10 {
  margin-top: 10px !important;
}

.mt-15 {
  margin-top: 15px !important;
}

.mt-20 {
  margin-top: 20px !important;
}

.mt-25 {
  margin-top: 25px !important;
}

.mt-30 {
  margin-top: 30px !important;
}

.mt-35 {
  margin-top: 35px !important;
}

.mt-40 {
  margin-top: 40px !important;
}

.mt-45 {
  margin-top: 45px !important;
}

.mt-50 {
  margin-top: 50px !important;
}

.mt-55 {
  margin-top: 55px !important;
}

.mt-60 {
  margin-top: 60px !important;
}

.mt-65 {
  margin-top: 65px !important;
}

.mt-70 {
  margin-top: 70px !important;
}

.mt-75 {
  margin-top: 75px !important;
}

.mt-80 {
  margin-top: 80px !important;
}

.mt-85 {
  margin-top: 85px !important;
}

.mt-90 {
  margin-top: 90px !important;
}

.mt-95 {
  margin-top: 95px !important;
}

.mt-100 {
  margin-top: 100px !important;
}

.mt-105 {
  margin-top: 105px !important;
}

.mt-110 {
  margin-top: 110px !important;
}

.mt-115 {
  margin-top: 115px !important;
}

.mt-120 {
  margin-top: 120px !important;
}

.mt-125 {
  margin-top: 125px !important;
}

.mt-130 {
  margin-top: 130px !important;
}

.mt-135 {
  margin-top: 135px !important;
}

.mt-140 {
  margin-top: 140px !important;
}

.mt-145 {
  margin-top: 145px !important;
}

.mt-150 {
  margin-top: 150px !important;
}

.mt-155 {
  margin-top: 155px !important;
}

.mt-160 {
  margin-top: 160px !important;
}

.mt-165 {
  margin-top: 165px !important;
}

.mt-170 {
  margin-top: 170px !important;
}

.mt-175 {
  margin-top: 175px !important;
}

.mt-180 {
  margin-top: 180px !important;
}

.mt-185 {
  margin-top: 185px !important;
}

.mt-190 {
  margin-top: 190px !important;
}

.mt-195 {
  margin-top: 195px !important;
}

.mt-200 {
  margin-top: 200px !important;
}

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .mt-lg-0 {
    margin-top: 0px !important;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .mt-lg-5 {
    margin-top: 5px !important;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .mt-lg-10 {
    margin-top: 10px !important;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .mt-lg-15 {
    margin-top: 15px !important;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .mt-lg-20 {
    margin-top: 20px !important;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .mt-lg-25 {
    margin-top: 25px !important;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .mt-lg-30 {
    margin-top: 30px !important;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .mt-lg-35 {
    margin-top: 35px !important;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .mt-lg-40 {
    margin-top: 40px !important;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .mt-lg-45 {
    margin-top: 45px !important;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .mt-lg-50 {
    margin-top: 50px !important;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .mt-lg-55 {
    margin-top: 55px !important;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .mt-lg-60 {
    margin-top: 60px !important;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .mt-lg-65 {
    margin-top: 65px !important;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .mt-lg-70 {
    margin-top: 70px !important;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .mt-lg-75 {
    margin-top: 75px !important;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .mt-lg-80 {
    margin-top: 80px !important;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .mt-lg-85 {
    margin-top: 85px !important;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .mt-lg-90 {
    margin-top: 90px !important;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .mt-lg-95 {
    margin-top: 95px !important;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .mt-lg-100 {
    margin-top: 100px !important;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .mt-lg-105 {
    margin-top: 105px !important;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .mt-lg-110 {
    margin-top: 110px !important;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .mt-lg-115 {
    margin-top: 115px !important;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .mt-lg-120 {
    margin-top: 120px !important;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .mt-lg-125 {
    margin-top: 125px !important;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .mt-lg-130 {
    margin-top: 130px !important;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .mt-lg-135 {
    margin-top: 135px !important;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .mt-lg-140 {
    margin-top: 140px !important;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .mt-lg-145 {
    margin-top: 145px !important;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .mt-lg-150 {
    margin-top: 150px !important;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .mt-lg-155 {
    margin-top: 155px !important;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .mt-lg-160 {
    margin-top: 160px !important;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .mt-lg-165 {
    margin-top: 165px !important;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .mt-lg-170 {
    margin-top: 170px !important;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .mt-lg-175 {
    margin-top: 175px !important;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .mt-lg-180 {
    margin-top: 180px !important;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .mt-lg-185 {
    margin-top: 185px !important;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .mt-lg-190 {
    margin-top: 190px !important;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .mt-lg-195 {
    margin-top: 195px !important;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .mt-lg-200 {
    margin-top: 200px !important;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .mt-md-0 {
    margin-top: 0px !important;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .mt-md-5 {
    margin-top: 5px !important;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .mt-md-10 {
    margin-top: 10px !important;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .mt-md-15 {
    margin-top: 15px !important;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .mt-md-20 {
    margin-top: 20px !important;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .mt-md-25 {
    margin-top: 25px !important;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .mt-md-30 {
    margin-top: 30px !important;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .mt-md-35 {
    margin-top: 35px !important;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .mt-md-40 {
    margin-top: 40px !important;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .mt-md-45 {
    margin-top: 45px !important;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .mt-md-50 {
    margin-top: 50px !important;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .mt-md-55 {
    margin-top: 55px !important;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .mt-md-60 {
    margin-top: 60px !important;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .mt-md-65 {
    margin-top: 65px !important;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .mt-md-70 {
    margin-top: 70px !important;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .mt-md-75 {
    margin-top: 75px !important;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .mt-md-80 {
    margin-top: 80px !important;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .mt-md-85 {
    margin-top: 85px !important;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .mt-md-90 {
    margin-top: 90px !important;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .mt-md-95 {
    margin-top: 95px !important;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .mt-md-100 {
    margin-top: 100px !important;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .mt-md-105 {
    margin-top: 105px !important;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .mt-md-110 {
    margin-top: 110px !important;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .mt-md-115 {
    margin-top: 115px !important;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .mt-md-120 {
    margin-top: 120px !important;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .mt-md-125 {
    margin-top: 125px !important;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .mt-md-130 {
    margin-top: 130px !important;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .mt-md-135 {
    margin-top: 135px !important;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .mt-md-140 {
    margin-top: 140px !important;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .mt-md-145 {
    margin-top: 145px !important;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .mt-md-150 {
    margin-top: 150px !important;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .mt-md-155 {
    margin-top: 155px !important;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .mt-md-160 {
    margin-top: 160px !important;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .mt-md-165 {
    margin-top: 165px !important;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .mt-md-170 {
    margin-top: 170px !important;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .mt-md-175 {
    margin-top: 175px !important;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .mt-md-180 {
    margin-top: 180px !important;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .mt-md-185 {
    margin-top: 185px !important;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .mt-md-190 {
    margin-top: 190px !important;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .mt-md-195 {
    margin-top: 195px !important;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .mt-md-200 {
    margin-top: 200px !important;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .mt-sm-0 {
    margin-top: 0px !important;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .mt-sm-5 {
    margin-top: 5px !important;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .mt-sm-10 {
    margin-top: 10px !important;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .mt-sm-15 {
    margin-top: 15px !important;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .mt-sm-20 {
    margin-top: 20px !important;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .mt-sm-25 {
    margin-top: 25px !important;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .mt-sm-30 {
    margin-top: 30px !important;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .mt-sm-35 {
    margin-top: 35px !important;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .mt-sm-40 {
    margin-top: 40px !important;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .mt-sm-45 {
    margin-top: 45px !important;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .mt-sm-50 {
    margin-top: 50px !important;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .mt-sm-55 {
    margin-top: 55px !important;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .mt-sm-60 {
    margin-top: 60px !important;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .mt-sm-65 {
    margin-top: 65px !important;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .mt-sm-70 {
    margin-top: 70px !important;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .mt-sm-75 {
    margin-top: 75px !important;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .mt-sm-80 {
    margin-top: 80px !important;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .mt-sm-85 {
    margin-top: 85px !important;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .mt-sm-90 {
    margin-top: 90px !important;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .mt-sm-95 {
    margin-top: 95px !important;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .mt-sm-100 {
    margin-top: 100px !important;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .mt-sm-105 {
    margin-top: 105px !important;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .mt-sm-110 {
    margin-top: 110px !important;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .mt-sm-115 {
    margin-top: 115px !important;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .mt-sm-120 {
    margin-top: 120px !important;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .mt-sm-125 {
    margin-top: 125px !important;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .mt-sm-130 {
    margin-top: 130px !important;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .mt-sm-135 {
    margin-top: 135px !important;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .mt-sm-140 {
    margin-top: 140px !important;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .mt-sm-145 {
    margin-top: 145px !important;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .mt-sm-150 {
    margin-top: 150px !important;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .mt-sm-155 {
    margin-top: 155px !important;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .mt-sm-160 {
    margin-top: 160px !important;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .mt-sm-165 {
    margin-top: 165px !important;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .mt-sm-170 {
    margin-top: 170px !important;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .mt-sm-175 {
    margin-top: 175px !important;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .mt-sm-180 {
    margin-top: 180px !important;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .mt-sm-185 {
    margin-top: 185px !important;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .mt-sm-190 {
    margin-top: 190px !important;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .mt-sm-195 {
    margin-top: 195px !important;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .mt-sm-200 {
    margin-top: 200px !important;
  }
}
@media only screen and (max-width: 767px) {
  .mt-xs-0 {
    margin-top: 0px !important;
  }
}
@media only screen and (max-width: 767px) {
  .mt-xs-5 {
    margin-top: 5px !important;
  }
}
@media only screen and (max-width: 767px) {
  .mt-xs-10 {
    margin-top: 10px !important;
  }
}
@media only screen and (max-width: 767px) {
  .mt-xs-15 {
    margin-top: 15px !important;
  }
}
@media only screen and (max-width: 767px) {
  .mt-xs-20 {
    margin-top: 20px !important;
  }
}
@media only screen and (max-width: 767px) {
  .mt-xs-25 {
    margin-top: 25px !important;
  }
}
@media only screen and (max-width: 767px) {
  .mt-xs-30 {
    margin-top: 30px !important;
  }
}
@media only screen and (max-width: 767px) {
  .mt-xs-35 {
    margin-top: 35px !important;
  }
}
@media only screen and (max-width: 767px) {
  .mt-xs-40 {
    margin-top: 40px !important;
  }
}
@media only screen and (max-width: 767px) {
  .mt-xs-45 {
    margin-top: 45px !important;
  }
}
@media only screen and (max-width: 767px) {
  .mt-xs-50 {
    margin-top: 50px !important;
  }
}
@media only screen and (max-width: 767px) {
  .mt-xs-55 {
    margin-top: 55px !important;
  }
}
@media only screen and (max-width: 767px) {
  .mt-xs-60 {
    margin-top: 60px !important;
  }
}
@media only screen and (max-width: 767px) {
  .mt-xs-65 {
    margin-top: 65px !important;
  }
}
@media only screen and (max-width: 767px) {
  .mt-xs-70 {
    margin-top: 70px !important;
  }
}
@media only screen and (max-width: 767px) {
  .mt-xs-75 {
    margin-top: 75px !important;
  }
}
@media only screen and (max-width: 767px) {
  .mt-xs-80 {
    margin-top: 80px !important;
  }
}
@media only screen and (max-width: 767px) {
  .mt-xs-85 {
    margin-top: 85px !important;
  }
}
@media only screen and (max-width: 767px) {
  .mt-xs-90 {
    margin-top: 90px !important;
  }
}
@media only screen and (max-width: 767px) {
  .mt-xs-95 {
    margin-top: 95px !important;
  }
}
@media only screen and (max-width: 767px) {
  .mt-xs-100 {
    margin-top: 100px !important;
  }
}
@media only screen and (max-width: 767px) {
  .mt-xs-105 {
    margin-top: 105px !important;
  }
}
@media only screen and (max-width: 767px) {
  .mt-xs-110 {
    margin-top: 110px !important;
  }
}
@media only screen and (max-width: 767px) {
  .mt-xs-115 {
    margin-top: 115px !important;
  }
}
@media only screen and (max-width: 767px) {
  .mt-xs-120 {
    margin-top: 120px !important;
  }
}
@media only screen and (max-width: 767px) {
  .mt-xs-125 {
    margin-top: 125px !important;
  }
}
@media only screen and (max-width: 767px) {
  .mt-xs-130 {
    margin-top: 130px !important;
  }
}
@media only screen and (max-width: 767px) {
  .mt-xs-135 {
    margin-top: 135px !important;
  }
}
@media only screen and (max-width: 767px) {
  .mt-xs-140 {
    margin-top: 140px !important;
  }
}
@media only screen and (max-width: 767px) {
  .mt-xs-145 {
    margin-top: 145px !important;
  }
}
@media only screen and (max-width: 767px) {
  .mt-xs-150 {
    margin-top: 150px !important;
  }
}
@media only screen and (max-width: 767px) {
  .mt-xs-155 {
    margin-top: 155px !important;
  }
}
@media only screen and (max-width: 767px) {
  .mt-xs-160 {
    margin-top: 160px !important;
  }
}
@media only screen and (max-width: 767px) {
  .mt-xs-165 {
    margin-top: 165px !important;
  }
}
@media only screen and (max-width: 767px) {
  .mt-xs-170 {
    margin-top: 170px !important;
  }
}
@media only screen and (max-width: 767px) {
  .mt-xs-175 {
    margin-top: 175px !important;
  }
}
@media only screen and (max-width: 767px) {
  .mt-xs-180 {
    margin-top: 180px !important;
  }
}
@media only screen and (max-width: 767px) {
  .mt-xs-185 {
    margin-top: 185px !important;
  }
}
@media only screen and (max-width: 767px) {
  .mt-xs-190 {
    margin-top: 190px !important;
  }
}
@media only screen and (max-width: 767px) {
  .mt-xs-195 {
    margin-top: 195px !important;
  }
}
@media only screen and (max-width: 767px) {
  .mt-xs-200 {
    margin-top: 200px !important;
  }
}
/*-- Margin Bottom --*/
.mb-0 {
  margin-bottom: 0px !important;
}

.mb-5 {
  margin-bottom: 5px !important;
}

.mb-10 {
  margin-bottom: 10px !important;
}

.mb-15 {
  margin-bottom: 15px !important;
}

.mb-20 {
  margin-bottom: 20px !important;
}

.mb-25 {
  margin-bottom: 25px !important;
}

.mb-30 {
  margin-bottom: 30px !important;
}

.mb-35 {
  margin-bottom: 35px !important;
}

.mb-40 {
  margin-bottom: 40px !important;
}

.mb-45 {
  margin-bottom: 45px !important;
}

.mb-50 {
  margin-bottom: 50px !important;
}

.mb-55 {
  margin-bottom: 55px !important;
}

.mb-60 {
  margin-bottom: 60px !important;
}

.mb-65 {
  margin-bottom: 65px !important;
}

.mb-70 {
  margin-bottom: 70px !important;
}

.mb-75 {
  margin-bottom: 75px !important;
}

.mb-80 {
  margin-bottom: 80px !important;
}

.mb-85 {
  margin-bottom: 85px !important;
}

.mb-90 {
  margin-bottom: 90px !important;
}

.mb-95 {
  margin-bottom: 95px !important;
}

.mb-100 {
  margin-bottom: 100px !important;
}

.mb-105 {
  margin-bottom: 105px !important;
}

.mb-110 {
  margin-bottom: 110px !important;
}

.mb-115 {
  margin-bottom: 115px !important;
}

.mb-120 {
  margin-bottom: 120px !important;
}

.mb-125 {
  margin-bottom: 125px !important;
}

.mb-130 {
  margin-bottom: 130px !important;
}

.mb-135 {
  margin-bottom: 135px !important;
}

.mb-140 {
  margin-bottom: 140px !important;
}

.mb-145 {
  margin-bottom: 145px !important;
}

.mb-150 {
  margin-bottom: 150px !important;
}

.mb-155 {
  margin-bottom: 155px !important;
}

.mb-160 {
  margin-bottom: 160px !important;
}

.mb-165 {
  margin-bottom: 165px !important;
}

.mb-170 {
  margin-bottom: 170px !important;
}

.mb-175 {
  margin-bottom: 175px !important;
}

.mb-180 {
  margin-bottom: 180px !important;
}

.mb-185 {
  margin-bottom: 185px !important;
}

.mb-190 {
  margin-bottom: 190px !important;
}

.mb-195 {
  margin-bottom: 195px !important;
}

.mb-200 {
  margin-bottom: 200px !important;
}

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .mb-lg-0 {
    margin-bottom: 0px !important;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .mb-lg-5 {
    margin-bottom: 5px !important;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .mb-lg-10 {
    margin-bottom: 10px !important;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .mb-lg-15 {
    margin-bottom: 15px !important;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .mb-lg-20 {
    margin-bottom: 20px !important;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .mb-lg-25 {
    margin-bottom: 25px !important;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .mb-lg-30 {
    margin-bottom: 30px !important;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .mb-lg-35 {
    margin-bottom: 35px !important;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .mb-lg-40 {
    margin-bottom: 40px !important;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .mb-lg-45 {
    margin-bottom: 45px !important;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .mb-lg-50 {
    margin-bottom: 50px !important;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .mb-lg-55 {
    margin-bottom: 55px !important;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .mb-lg-60 {
    margin-bottom: 60px !important;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .mb-lg-65 {
    margin-bottom: 65px !important;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .mb-lg-70 {
    margin-bottom: 70px !important;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .mb-lg-75 {
    margin-bottom: 75px !important;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .mb-lg-80 {
    margin-bottom: 80px !important;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .mb-lg-85 {
    margin-bottom: 85px !important;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .mb-lg-90 {
    margin-bottom: 90px !important;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .mb-lg-95 {
    margin-bottom: 95px !important;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .mb-lg-100 {
    margin-bottom: 100px !important;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .mb-lg-105 {
    margin-bottom: 105px !important;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .mb-lg-110 {
    margin-bottom: 110px !important;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .mb-lg-115 {
    margin-bottom: 115px !important;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .mb-lg-120 {
    margin-bottom: 120px !important;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .mb-lg-125 {
    margin-bottom: 125px !important;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .mb-lg-130 {
    margin-bottom: 130px !important;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .mb-lg-135 {
    margin-bottom: 135px !important;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .mb-lg-140 {
    margin-bottom: 140px !important;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .mb-lg-145 {
    margin-bottom: 145px !important;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .mb-lg-150 {
    margin-bottom: 150px !important;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .mb-lg-155 {
    margin-bottom: 155px !important;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .mb-lg-160 {
    margin-bottom: 160px !important;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .mb-lg-165 {
    margin-bottom: 165px !important;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .mb-lg-170 {
    margin-bottom: 170px !important;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .mb-lg-175 {
    margin-bottom: 175px !important;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .mb-lg-180 {
    margin-bottom: 180px !important;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .mb-lg-185 {
    margin-bottom: 185px !important;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .mb-lg-190 {
    margin-bottom: 190px !important;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .mb-lg-195 {
    margin-bottom: 195px !important;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .mb-lg-200 {
    margin-bottom: 200px !important;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .mb-md-0 {
    margin-bottom: 0px !important;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .mb-md-5 {
    margin-bottom: 5px !important;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .mb-md-10 {
    margin-bottom: 10px !important;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .mb-md-15 {
    margin-bottom: 15px !important;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .mb-md-20 {
    margin-bottom: 20px !important;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .mb-md-25 {
    margin-bottom: 25px !important;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .mb-md-30 {
    margin-bottom: 30px !important;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .mb-md-35 {
    margin-bottom: 35px !important;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .mb-md-40 {
    margin-bottom: 40px !important;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .mb-md-45 {
    margin-bottom: 45px !important;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .mb-md-50 {
    margin-bottom: 50px !important;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .mb-md-55 {
    margin-bottom: 55px !important;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .mb-md-60 {
    margin-bottom: 60px !important;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .mb-md-65 {
    margin-bottom: 65px !important;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .mb-md-70 {
    margin-bottom: 70px !important;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .mb-md-75 {
    margin-bottom: 75px !important;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .mb-md-80 {
    margin-bottom: 80px !important;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .mb-md-85 {
    margin-bottom: 85px !important;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .mb-md-90 {
    margin-bottom: 90px !important;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .mb-md-95 {
    margin-bottom: 95px !important;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .mb-md-100 {
    margin-bottom: 100px !important;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .mb-md-105 {
    margin-bottom: 105px !important;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .mb-md-110 {
    margin-bottom: 110px !important;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .mb-md-115 {
    margin-bottom: 115px !important;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .mb-md-120 {
    margin-bottom: 120px !important;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .mb-md-125 {
    margin-bottom: 125px !important;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .mb-md-130 {
    margin-bottom: 130px !important;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .mb-md-135 {
    margin-bottom: 135px !important;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .mb-md-140 {
    margin-bottom: 140px !important;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .mb-md-145 {
    margin-bottom: 145px !important;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .mb-md-150 {
    margin-bottom: 150px !important;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .mb-md-155 {
    margin-bottom: 155px !important;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .mb-md-160 {
    margin-bottom: 160px !important;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .mb-md-165 {
    margin-bottom: 165px !important;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .mb-md-170 {
    margin-bottom: 170px !important;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .mb-md-175 {
    margin-bottom: 175px !important;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .mb-md-180 {
    margin-bottom: 180px !important;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .mb-md-185 {
    margin-bottom: 185px !important;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .mb-md-190 {
    margin-bottom: 190px !important;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .mb-md-195 {
    margin-bottom: 195px !important;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .mb-md-200 {
    margin-bottom: 200px !important;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .mb-sm-0 {
    margin-bottom: 0px !important;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .mb-sm-5 {
    margin-bottom: 5px !important;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .mb-sm-10 {
    margin-bottom: 10px !important;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .mb-sm-15 {
    margin-bottom: 15px !important;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .mb-sm-20 {
    margin-bottom: 20px !important;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .mb-sm-25 {
    margin-bottom: 25px !important;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .mb-sm-30 {
    margin-bottom: 30px !important;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .mb-sm-35 {
    margin-bottom: 35px !important;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .mb-sm-40 {
    margin-bottom: 40px !important;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .mb-sm-45 {
    margin-bottom: 45px !important;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .mb-sm-50 {
    margin-bottom: 50px !important;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .mb-sm-55 {
    margin-bottom: 55px !important;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .mb-sm-60 {
    margin-bottom: 60px !important;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .mb-sm-65 {
    margin-bottom: 65px !important;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .mb-sm-70 {
    margin-bottom: 70px !important;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .mb-sm-75 {
    margin-bottom: 75px !important;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .mb-sm-80 {
    margin-bottom: 80px !important;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .mb-sm-85 {
    margin-bottom: 85px !important;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .mb-sm-90 {
    margin-bottom: 90px !important;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .mb-sm-95 {
    margin-bottom: 95px !important;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .mb-sm-100 {
    margin-bottom: 100px !important;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .mb-sm-105 {
    margin-bottom: 105px !important;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .mb-sm-110 {
    margin-bottom: 110px !important;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .mb-sm-115 {
    margin-bottom: 115px !important;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .mb-sm-120 {
    margin-bottom: 120px !important;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .mb-sm-125 {
    margin-bottom: 125px !important;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .mb-sm-130 {
    margin-bottom: 130px !important;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .mb-sm-135 {
    margin-bottom: 135px !important;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .mb-sm-140 {
    margin-bottom: 140px !important;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .mb-sm-145 {
    margin-bottom: 145px !important;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .mb-sm-150 {
    margin-bottom: 150px !important;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .mb-sm-155 {
    margin-bottom: 155px !important;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .mb-sm-160 {
    margin-bottom: 160px !important;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .mb-sm-165 {
    margin-bottom: 165px !important;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .mb-sm-170 {
    margin-bottom: 170px !important;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .mb-sm-175 {
    margin-bottom: 175px !important;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .mb-sm-180 {
    margin-bottom: 180px !important;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .mb-sm-185 {
    margin-bottom: 185px !important;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .mb-sm-190 {
    margin-bottom: 190px !important;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .mb-sm-195 {
    margin-bottom: 195px !important;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .mb-sm-200 {
    margin-bottom: 200px !important;
  }
}
@media only screen and (max-width: 767px) {
  .mb-xs-0 {
    margin-bottom: 0px !important;
  }
}
@media only screen and (max-width: 767px) {
  .mb-xs-5 {
    margin-bottom: 5px !important;
  }
}
@media only screen and (max-width: 767px) {
  .mb-xs-10 {
    margin-bottom: 10px !important;
  }
}
@media only screen and (max-width: 767px) {
  .mb-xs-15 {
    margin-bottom: 15px !important;
  }
}
@media only screen and (max-width: 767px) {
  .mb-xs-20 {
    margin-bottom: 20px !important;
  }
}
@media only screen and (max-width: 767px) {
  .mb-xs-25 {
    margin-bottom: 25px !important;
  }
}
@media only screen and (max-width: 767px) {
  .mb-xs-30 {
    margin-bottom: 30px !important;
  }
}
@media only screen and (max-width: 767px) {
  .mb-xs-35 {
    margin-bottom: 35px !important;
  }
}
@media only screen and (max-width: 767px) {
  .mb-xs-40 {
    margin-bottom: 40px !important;
  }
}
@media only screen and (max-width: 767px) {
  .mb-xs-45 {
    margin-bottom: 45px !important;
  }
}
@media only screen and (max-width: 767px) {
  .mb-xs-50 {
    margin-bottom: 50px !important;
  }
}
@media only screen and (max-width: 767px) {
  .mb-xs-55 {
    margin-bottom: 55px !important;
  }
}
@media only screen and (max-width: 767px) {
  .mb-xs-60 {
    margin-bottom: 60px !important;
  }
}
@media only screen and (max-width: 767px) {
  .mb-xs-65 {
    margin-bottom: 65px !important;
  }
}
@media only screen and (max-width: 767px) {
  .mb-xs-70 {
    margin-bottom: 70px !important;
  }
}
@media only screen and (max-width: 767px) {
  .mb-xs-75 {
    margin-bottom: 75px !important;
  }
}
@media only screen and (max-width: 767px) {
  .mb-xs-80 {
    margin-bottom: 80px !important;
  }
}
@media only screen and (max-width: 767px) {
  .mb-xs-85 {
    margin-bottom: 85px !important;
  }
}
@media only screen and (max-width: 767px) {
  .mb-xs-90 {
    margin-bottom: 90px !important;
  }
}
@media only screen and (max-width: 767px) {
  .mb-xs-95 {
    margin-bottom: 95px !important;
  }
}
@media only screen and (max-width: 767px) {
  .mb-xs-100 {
    margin-bottom: 100px !important;
  }
}
@media only screen and (max-width: 767px) {
  .mb-xs-105 {
    margin-bottom: 105px !important;
  }
}
@media only screen and (max-width: 767px) {
  .mb-xs-110 {
    margin-bottom: 110px !important;
  }
}
@media only screen and (max-width: 767px) {
  .mb-xs-115 {
    margin-bottom: 115px !important;
  }
}
@media only screen and (max-width: 767px) {
  .mb-xs-120 {
    margin-bottom: 120px !important;
  }
}
@media only screen and (max-width: 767px) {
  .mb-xs-125 {
    margin-bottom: 125px !important;
  }
}
@media only screen and (max-width: 767px) {
  .mb-xs-130 {
    margin-bottom: 130px !important;
  }
}
@media only screen and (max-width: 767px) {
  .mb-xs-135 {
    margin-bottom: 135px !important;
  }
}
@media only screen and (max-width: 767px) {
  .mb-xs-140 {
    margin-bottom: 140px !important;
  }
}
@media only screen and (max-width: 767px) {
  .mb-xs-145 {
    margin-bottom: 145px !important;
  }
}
@media only screen and (max-width: 767px) {
  .mb-xs-150 {
    margin-bottom: 150px !important;
  }
}
@media only screen and (max-width: 767px) {
  .mb-xs-155 {
    margin-bottom: 155px !important;
  }
}
@media only screen and (max-width: 767px) {
  .mb-xs-160 {
    margin-bottom: 160px !important;
  }
}
@media only screen and (max-width: 767px) {
  .mb-xs-165 {
    margin-bottom: 165px !important;
  }
}
@media only screen and (max-width: 767px) {
  .mb-xs-170 {
    margin-bottom: 170px !important;
  }
}
@media only screen and (max-width: 767px) {
  .mb-xs-175 {
    margin-bottom: 175px !important;
  }
}
@media only screen and (max-width: 767px) {
  .mb-xs-180 {
    margin-bottom: 180px !important;
  }
}
@media only screen and (max-width: 767px) {
  .mb-xs-185 {
    margin-bottom: 185px !important;
  }
}
@media only screen and (max-width: 767px) {
  .mb-xs-190 {
    margin-bottom: 190px !important;
  }
}
@media only screen and (max-width: 767px) {
  .mb-xs-195 {
    margin-bottom: 195px !important;
  }
}
@media only screen and (max-width: 767px) {
  .mb-xs-200 {
    margin-bottom: 200px !important;
  }
}
/*-- Margin Left --*/
.ml-0 {
  margin-left: 0px !important;
}

.ml-5 {
  margin-left: 5px !important;
}

.ml-10 {
  margin-left: 10px !important;
}

.ml-15 {
  margin-left: 15px !important;
}

.ml-20 {
  margin-left: 20px !important;
}

.ml-25 {
  margin-left: 25px !important;
}

.ml-30 {
  margin-left: 30px !important;
}

.ml-35 {
  margin-left: 35px !important;
}

.ml-40 {
  margin-left: 40px !important;
}

.ml-45 {
  margin-left: 45px !important;
}

.ml-50 {
  margin-left: 50px !important;
}

.ml-55 {
  margin-left: 55px !important;
}

.ml-60 {
  margin-left: 60px !important;
}

.ml-65 {
  margin-left: 65px !important;
}

.ml-70 {
  margin-left: 70px !important;
}

.ml-75 {
  margin-left: 75px !important;
}

.ml-80 {
  margin-left: 80px !important;
}

.ml-85 {
  margin-left: 85px !important;
}

.ml-90 {
  margin-left: 90px !important;
}

.ml-95 {
  margin-left: 95px !important;
}

.ml-100 {
  margin-left: 100px !important;
}

.ml-105 {
  margin-left: 105px !important;
}

.ml-110 {
  margin-left: 110px !important;
}

.ml-115 {
  margin-left: 115px !important;
}

.ml-120 {
  margin-left: 120px !important;
}

.ml-125 {
  margin-left: 125px !important;
}

.ml-130 {
  margin-left: 130px !important;
}

.ml-135 {
  margin-left: 135px !important;
}

.ml-140 {
  margin-left: 140px !important;
}

.ml-145 {
  margin-left: 145px !important;
}

.ml-150 {
  margin-left: 150px !important;
}

.ml-155 {
  margin-left: 155px !important;
}

.ml-160 {
  margin-left: 160px !important;
}

.ml-165 {
  margin-left: 165px !important;
}

.ml-170 {
  margin-left: 170px !important;
}

.ml-175 {
  margin-left: 175px !important;
}

.ml-180 {
  margin-left: 180px !important;
}

.ml-185 {
  margin-left: 185px !important;
}

.ml-190 {
  margin-left: 190px !important;
}

.ml-195 {
  margin-left: 195px !important;
}

.ml-200 {
  margin-left: 200px !important;
}

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .ml-lg-0 {
    margin-left: 0px !important;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .ml-lg-5 {
    margin-left: 5px !important;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .ml-lg-10 {
    margin-left: 10px !important;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .ml-lg-15 {
    margin-left: 15px !important;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .ml-lg-20 {
    margin-left: 20px !important;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .ml-lg-25 {
    margin-left: 25px !important;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .ml-lg-30 {
    margin-left: 30px !important;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .ml-lg-35 {
    margin-left: 35px !important;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .ml-lg-40 {
    margin-left: 40px !important;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .ml-lg-45 {
    margin-left: 45px !important;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .ml-lg-50 {
    margin-left: 50px !important;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .ml-lg-55 {
    margin-left: 55px !important;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .ml-lg-60 {
    margin-left: 60px !important;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .ml-lg-65 {
    margin-left: 65px !important;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .ml-lg-70 {
    margin-left: 70px !important;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .ml-lg-75 {
    margin-left: 75px !important;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .ml-lg-80 {
    margin-left: 80px !important;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .ml-lg-85 {
    margin-left: 85px !important;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .ml-lg-90 {
    margin-left: 90px !important;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .ml-lg-95 {
    margin-left: 95px !important;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .ml-lg-100 {
    margin-left: 100px !important;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .ml-lg-105 {
    margin-left: 105px !important;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .ml-lg-110 {
    margin-left: 110px !important;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .ml-lg-115 {
    margin-left: 115px !important;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .ml-lg-120 {
    margin-left: 120px !important;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .ml-lg-125 {
    margin-left: 125px !important;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .ml-lg-130 {
    margin-left: 130px !important;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .ml-lg-135 {
    margin-left: 135px !important;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .ml-lg-140 {
    margin-left: 140px !important;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .ml-lg-145 {
    margin-left: 145px !important;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .ml-lg-150 {
    margin-left: 150px !important;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .ml-lg-155 {
    margin-left: 155px !important;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .ml-lg-160 {
    margin-left: 160px !important;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .ml-lg-165 {
    margin-left: 165px !important;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .ml-lg-170 {
    margin-left: 170px !important;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .ml-lg-175 {
    margin-left: 175px !important;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .ml-lg-180 {
    margin-left: 180px !important;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .ml-lg-185 {
    margin-left: 185px !important;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .ml-lg-190 {
    margin-left: 190px !important;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .ml-lg-195 {
    margin-left: 195px !important;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .ml-lg-200 {
    margin-left: 200px !important;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .ml-md-0 {
    margin-left: 0px !important;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .ml-md-5 {
    margin-left: 5px !important;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .ml-md-10 {
    margin-left: 10px !important;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .ml-md-15 {
    margin-left: 15px !important;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .ml-md-20 {
    margin-left: 20px !important;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .ml-md-25 {
    margin-left: 25px !important;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .ml-md-30 {
    margin-left: 30px !important;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .ml-md-35 {
    margin-left: 35px !important;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .ml-md-40 {
    margin-left: 40px !important;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .ml-md-45 {
    margin-left: 45px !important;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .ml-md-50 {
    margin-left: 50px !important;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .ml-md-55 {
    margin-left: 55px !important;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .ml-md-60 {
    margin-left: 60px !important;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .ml-md-65 {
    margin-left: 65px !important;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .ml-md-70 {
    margin-left: 70px !important;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .ml-md-75 {
    margin-left: 75px !important;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .ml-md-80 {
    margin-left: 80px !important;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .ml-md-85 {
    margin-left: 85px !important;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .ml-md-90 {
    margin-left: 90px !important;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .ml-md-95 {
    margin-left: 95px !important;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .ml-md-100 {
    margin-left: 100px !important;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .ml-md-105 {
    margin-left: 105px !important;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .ml-md-110 {
    margin-left: 110px !important;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .ml-md-115 {
    margin-left: 115px !important;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .ml-md-120 {
    margin-left: 120px !important;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .ml-md-125 {
    margin-left: 125px !important;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .ml-md-130 {
    margin-left: 130px !important;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .ml-md-135 {
    margin-left: 135px !important;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .ml-md-140 {
    margin-left: 140px !important;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .ml-md-145 {
    margin-left: 145px !important;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .ml-md-150 {
    margin-left: 150px !important;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .ml-md-155 {
    margin-left: 155px !important;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .ml-md-160 {
    margin-left: 160px !important;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .ml-md-165 {
    margin-left: 165px !important;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .ml-md-170 {
    margin-left: 170px !important;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .ml-md-175 {
    margin-left: 175px !important;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .ml-md-180 {
    margin-left: 180px !important;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .ml-md-185 {
    margin-left: 185px !important;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .ml-md-190 {
    margin-left: 190px !important;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .ml-md-195 {
    margin-left: 195px !important;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .ml-md-200 {
    margin-left: 200px !important;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .ml-sm-0 {
    margin-left: 0px !important;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .ml-sm-5 {
    margin-left: 5px !important;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .ml-sm-10 {
    margin-left: 10px !important;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .ml-sm-15 {
    margin-left: 15px !important;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .ml-sm-20 {
    margin-left: 20px !important;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .ml-sm-25 {
    margin-left: 25px !important;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .ml-sm-30 {
    margin-left: 30px !important;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .ml-sm-35 {
    margin-left: 35px !important;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .ml-sm-40 {
    margin-left: 40px !important;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .ml-sm-45 {
    margin-left: 45px !important;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .ml-sm-50 {
    margin-left: 50px !important;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .ml-sm-55 {
    margin-left: 55px !important;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .ml-sm-60 {
    margin-left: 60px !important;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .ml-sm-65 {
    margin-left: 65px !important;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .ml-sm-70 {
    margin-left: 70px !important;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .ml-sm-75 {
    margin-left: 75px !important;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .ml-sm-80 {
    margin-left: 80px !important;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .ml-sm-85 {
    margin-left: 85px !important;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .ml-sm-90 {
    margin-left: 90px !important;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .ml-sm-95 {
    margin-left: 95px !important;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .ml-sm-100 {
    margin-left: 100px !important;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .ml-sm-105 {
    margin-left: 105px !important;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .ml-sm-110 {
    margin-left: 110px !important;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .ml-sm-115 {
    margin-left: 115px !important;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .ml-sm-120 {
    margin-left: 120px !important;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .ml-sm-125 {
    margin-left: 125px !important;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .ml-sm-130 {
    margin-left: 130px !important;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .ml-sm-135 {
    margin-left: 135px !important;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .ml-sm-140 {
    margin-left: 140px !important;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .ml-sm-145 {
    margin-left: 145px !important;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .ml-sm-150 {
    margin-left: 150px !important;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .ml-sm-155 {
    margin-left: 155px !important;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .ml-sm-160 {
    margin-left: 160px !important;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .ml-sm-165 {
    margin-left: 165px !important;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .ml-sm-170 {
    margin-left: 170px !important;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .ml-sm-175 {
    margin-left: 175px !important;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .ml-sm-180 {
    margin-left: 180px !important;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .ml-sm-185 {
    margin-left: 185px !important;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .ml-sm-190 {
    margin-left: 190px !important;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .ml-sm-195 {
    margin-left: 195px !important;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .ml-sm-200 {
    margin-left: 200px !important;
  }
}
@media only screen and (max-width: 767px) {
  .ml-xs-0 {
    margin-left: 0px !important;
  }
}
@media only screen and (max-width: 767px) {
  .ml-xs-5 {
    margin-left: 5px !important;
  }
}
@media only screen and (max-width: 767px) {
  .ml-xs-10 {
    margin-left: 10px !important;
  }
}
@media only screen and (max-width: 767px) {
  .ml-xs-15 {
    margin-left: 15px !important;
  }
}
@media only screen and (max-width: 767px) {
  .ml-xs-20 {
    margin-left: 20px !important;
  }
}
@media only screen and (max-width: 767px) {
  .ml-xs-25 {
    margin-left: 25px !important;
  }
}
@media only screen and (max-width: 767px) {
  .ml-xs-30 {
    margin-left: 30px !important;
  }
}
@media only screen and (max-width: 767px) {
  .ml-xs-35 {
    margin-left: 35px !important;
  }
}
@media only screen and (max-width: 767px) {
  .ml-xs-40 {
    margin-left: 40px !important;
  }
}
@media only screen and (max-width: 767px) {
  .ml-xs-45 {
    margin-left: 45px !important;
  }
}
@media only screen and (max-width: 767px) {
  .ml-xs-50 {
    margin-left: 50px !important;
  }
}
@media only screen and (max-width: 767px) {
  .ml-xs-55 {
    margin-left: 55px !important;
  }
}
@media only screen and (max-width: 767px) {
  .ml-xs-60 {
    margin-left: 60px !important;
  }
}
@media only screen and (max-width: 767px) {
  .ml-xs-65 {
    margin-left: 65px !important;
  }
}
@media only screen and (max-width: 767px) {
  .ml-xs-70 {
    margin-left: 70px !important;
  }
}
@media only screen and (max-width: 767px) {
  .ml-xs-75 {
    margin-left: 75px !important;
  }
}
@media only screen and (max-width: 767px) {
  .ml-xs-80 {
    margin-left: 80px !important;
  }
}
@media only screen and (max-width: 767px) {
  .ml-xs-85 {
    margin-left: 85px !important;
  }
}
@media only screen and (max-width: 767px) {
  .ml-xs-90 {
    margin-left: 90px !important;
  }
}
@media only screen and (max-width: 767px) {
  .ml-xs-95 {
    margin-left: 95px !important;
  }
}
@media only screen and (max-width: 767px) {
  .ml-xs-100 {
    margin-left: 100px !important;
  }
}
@media only screen and (max-width: 767px) {
  .ml-xs-105 {
    margin-left: 105px !important;
  }
}
@media only screen and (max-width: 767px) {
  .ml-xs-110 {
    margin-left: 110px !important;
  }
}
@media only screen and (max-width: 767px) {
  .ml-xs-115 {
    margin-left: 115px !important;
  }
}
@media only screen and (max-width: 767px) {
  .ml-xs-120 {
    margin-left: 120px !important;
  }
}
@media only screen and (max-width: 767px) {
  .ml-xs-125 {
    margin-left: 125px !important;
  }
}
@media only screen and (max-width: 767px) {
  .ml-xs-130 {
    margin-left: 130px !important;
  }
}
@media only screen and (max-width: 767px) {
  .ml-xs-135 {
    margin-left: 135px !important;
  }
}
@media only screen and (max-width: 767px) {
  .ml-xs-140 {
    margin-left: 140px !important;
  }
}
@media only screen and (max-width: 767px) {
  .ml-xs-145 {
    margin-left: 145px !important;
  }
}
@media only screen and (max-width: 767px) {
  .ml-xs-150 {
    margin-left: 150px !important;
  }
}
@media only screen and (max-width: 767px) {
  .ml-xs-155 {
    margin-left: 155px !important;
  }
}
@media only screen and (max-width: 767px) {
  .ml-xs-160 {
    margin-left: 160px !important;
  }
}
@media only screen and (max-width: 767px) {
  .ml-xs-165 {
    margin-left: 165px !important;
  }
}
@media only screen and (max-width: 767px) {
  .ml-xs-170 {
    margin-left: 170px !important;
  }
}
@media only screen and (max-width: 767px) {
  .ml-xs-175 {
    margin-left: 175px !important;
  }
}
@media only screen and (max-width: 767px) {
  .ml-xs-180 {
    margin-left: 180px !important;
  }
}
@media only screen and (max-width: 767px) {
  .ml-xs-185 {
    margin-left: 185px !important;
  }
}
@media only screen and (max-width: 767px) {
  .ml-xs-190 {
    margin-left: 190px !important;
  }
}
@media only screen and (max-width: 767px) {
  .ml-xs-195 {
    margin-left: 195px !important;
  }
}
@media only screen and (max-width: 767px) {
  .ml-xs-200 {
    margin-left: 200px !important;
  }
}
/*-- Margin Right --*/
.mr-0 {
  margin-right: 0px !important;
}

.mr-5 {
  margin-right: 5px !important;
}

.mr-10 {
  margin-right: 10px !important;
}

.mr-15 {
  margin-right: 15px !important;
}

.mr-20 {
  margin-right: 20px !important;
}

.mr-25 {
  margin-right: 25px !important;
}

.mr-30 {
  margin-right: 30px !important;
}

.mr-35 {
  margin-right: 35px !important;
}

.mr-40 {
  margin-right: 40px !important;
}

.mr-45 {
  margin-right: 45px !important;
}

.mr-50 {
  margin-right: 50px !important;
}

.mr-55 {
  margin-right: 55px !important;
}

.mr-60 {
  margin-right: 60px !important;
}

.mr-65 {
  margin-right: 65px !important;
}

.mr-70 {
  margin-right: 70px !important;
}

.mr-75 {
  margin-right: 75px !important;
}

.mr-80 {
  margin-right: 80px !important;
}

.mr-85 {
  margin-right: 85px !important;
}

.mr-90 {
  margin-right: 90px !important;
}

.mr-95 {
  margin-right: 95px !important;
}

.mr-100 {
  margin-right: 100px !important;
}

.mr-105 {
  margin-right: 105px !important;
}

.mr-110 {
  margin-right: 110px !important;
}

.mr-115 {
  margin-right: 115px !important;
}

.mr-120 {
  margin-right: 120px !important;
}

.mr-125 {
  margin-right: 125px !important;
}

.mr-130 {
  margin-right: 130px !important;
}

.mr-135 {
  margin-right: 135px !important;
}

.mr-140 {
  margin-right: 140px !important;
}

.mr-145 {
  margin-right: 145px !important;
}

.mr-150 {
  margin-right: 150px !important;
}

.mr-155 {
  margin-right: 155px !important;
}

.mr-160 {
  margin-right: 160px !important;
}

.mr-165 {
  margin-right: 165px !important;
}

.mr-170 {
  margin-right: 170px !important;
}

.mr-175 {
  margin-right: 175px !important;
}

.mr-180 {
  margin-right: 180px !important;
}

.mr-185 {
  margin-right: 185px !important;
}

.mr-190 {
  margin-right: 190px !important;
}

.mr-195 {
  margin-right: 195px !important;
}

.mr-200 {
  margin-right: 200px !important;
}

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .mr-lg-0 {
    margin-right: 0px !important;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .mr-lg-5 {
    margin-right: 5px !important;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .mr-lg-10 {
    margin-right: 10px !important;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .mr-lg-15 {
    margin-right: 15px !important;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .mr-lg-20 {
    margin-right: 20px !important;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .mr-lg-25 {
    margin-right: 25px !important;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .mr-lg-30 {
    margin-right: 30px !important;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .mr-lg-35 {
    margin-right: 35px !important;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .mr-lg-40 {
    margin-right: 40px !important;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .mr-lg-45 {
    margin-right: 45px !important;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .mr-lg-50 {
    margin-right: 50px !important;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .mr-lg-55 {
    margin-right: 55px !important;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .mr-lg-60 {
    margin-right: 60px !important;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .mr-lg-65 {
    margin-right: 65px !important;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .mr-lg-70 {
    margin-right: 70px !important;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .mr-lg-75 {
    margin-right: 75px !important;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .mr-lg-80 {
    margin-right: 80px !important;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .mr-lg-85 {
    margin-right: 85px !important;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .mr-lg-90 {
    margin-right: 90px !important;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .mr-lg-95 {
    margin-right: 95px !important;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .mr-lg-100 {
    margin-right: 100px !important;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .mr-lg-105 {
    margin-right: 105px !important;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .mr-lg-110 {
    margin-right: 110px !important;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .mr-lg-115 {
    margin-right: 115px !important;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .mr-lg-120 {
    margin-right: 120px !important;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .mr-lg-125 {
    margin-right: 125px !important;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .mr-lg-130 {
    margin-right: 130px !important;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .mr-lg-135 {
    margin-right: 135px !important;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .mr-lg-140 {
    margin-right: 140px !important;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .mr-lg-145 {
    margin-right: 145px !important;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .mr-lg-150 {
    margin-right: 150px !important;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .mr-lg-155 {
    margin-right: 155px !important;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .mr-lg-160 {
    margin-right: 160px !important;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .mr-lg-165 {
    margin-right: 165px !important;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .mr-lg-170 {
    margin-right: 170px !important;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .mr-lg-175 {
    margin-right: 175px !important;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .mr-lg-180 {
    margin-right: 180px !important;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .mr-lg-185 {
    margin-right: 185px !important;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .mr-lg-190 {
    margin-right: 190px !important;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .mr-lg-195 {
    margin-right: 195px !important;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .mr-lg-200 {
    margin-right: 200px !important;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .mr-md-0 {
    margin-right: 0px !important;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .mr-md-5 {
    margin-right: 5px !important;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .mr-md-10 {
    margin-right: 10px !important;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .mr-md-15 {
    margin-right: 15px !important;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .mr-md-20 {
    margin-right: 20px !important;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .mr-md-25 {
    margin-right: 25px !important;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .mr-md-30 {
    margin-right: 30px !important;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .mr-md-35 {
    margin-right: 35px !important;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .mr-md-40 {
    margin-right: 40px !important;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .mr-md-45 {
    margin-right: 45px !important;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .mr-md-50 {
    margin-right: 50px !important;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .mr-md-55 {
    margin-right: 55px !important;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .mr-md-60 {
    margin-right: 60px !important;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .mr-md-65 {
    margin-right: 65px !important;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .mr-md-70 {
    margin-right: 70px !important;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .mr-md-75 {
    margin-right: 75px !important;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .mr-md-80 {
    margin-right: 80px !important;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .mr-md-85 {
    margin-right: 85px !important;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .mr-md-90 {
    margin-right: 90px !important;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .mr-md-95 {
    margin-right: 95px !important;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .mr-md-100 {
    margin-right: 100px !important;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .mr-md-105 {
    margin-right: 105px !important;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .mr-md-110 {
    margin-right: 110px !important;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .mr-md-115 {
    margin-right: 115px !important;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .mr-md-120 {
    margin-right: 120px !important;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .mr-md-125 {
    margin-right: 125px !important;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .mr-md-130 {
    margin-right: 130px !important;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .mr-md-135 {
    margin-right: 135px !important;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .mr-md-140 {
    margin-right: 140px !important;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .mr-md-145 {
    margin-right: 145px !important;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .mr-md-150 {
    margin-right: 150px !important;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .mr-md-155 {
    margin-right: 155px !important;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .mr-md-160 {
    margin-right: 160px !important;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .mr-md-165 {
    margin-right: 165px !important;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .mr-md-170 {
    margin-right: 170px !important;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .mr-md-175 {
    margin-right: 175px !important;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .mr-md-180 {
    margin-right: 180px !important;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .mr-md-185 {
    margin-right: 185px !important;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .mr-md-190 {
    margin-right: 190px !important;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .mr-md-195 {
    margin-right: 195px !important;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .mr-md-200 {
    margin-right: 200px !important;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .mr-sm-0 {
    margin-right: 0px !important;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .mr-sm-5 {
    margin-right: 5px !important;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .mr-sm-10 {
    margin-right: 10px !important;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .mr-sm-15 {
    margin-right: 15px !important;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .mr-sm-20 {
    margin-right: 20px !important;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .mr-sm-25 {
    margin-right: 25px !important;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .mr-sm-30 {
    margin-right: 30px !important;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .mr-sm-35 {
    margin-right: 35px !important;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .mr-sm-40 {
    margin-right: 40px !important;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .mr-sm-45 {
    margin-right: 45px !important;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .mr-sm-50 {
    margin-right: 50px !important;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .mr-sm-55 {
    margin-right: 55px !important;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .mr-sm-60 {
    margin-right: 60px !important;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .mr-sm-65 {
    margin-right: 65px !important;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .mr-sm-70 {
    margin-right: 70px !important;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .mr-sm-75 {
    margin-right: 75px !important;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .mr-sm-80 {
    margin-right: 80px !important;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .mr-sm-85 {
    margin-right: 85px !important;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .mr-sm-90 {
    margin-right: 90px !important;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .mr-sm-95 {
    margin-right: 95px !important;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .mr-sm-100 {
    margin-right: 100px !important;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .mr-sm-105 {
    margin-right: 105px !important;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .mr-sm-110 {
    margin-right: 110px !important;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .mr-sm-115 {
    margin-right: 115px !important;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .mr-sm-120 {
    margin-right: 120px !important;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .mr-sm-125 {
    margin-right: 125px !important;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .mr-sm-130 {
    margin-right: 130px !important;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .mr-sm-135 {
    margin-right: 135px !important;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .mr-sm-140 {
    margin-right: 140px !important;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .mr-sm-145 {
    margin-right: 145px !important;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .mr-sm-150 {
    margin-right: 150px !important;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .mr-sm-155 {
    margin-right: 155px !important;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .mr-sm-160 {
    margin-right: 160px !important;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .mr-sm-165 {
    margin-right: 165px !important;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .mr-sm-170 {
    margin-right: 170px !important;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .mr-sm-175 {
    margin-right: 175px !important;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .mr-sm-180 {
    margin-right: 180px !important;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .mr-sm-185 {
    margin-right: 185px !important;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .mr-sm-190 {
    margin-right: 190px !important;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .mr-sm-195 {
    margin-right: 195px !important;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .mr-sm-200 {
    margin-right: 200px !important;
  }
}
@media only screen and (max-width: 767px) {
  .mr-xs-0 {
    margin-right: 0px !important;
  }
}
@media only screen and (max-width: 767px) {
  .mr-xs-5 {
    margin-right: 5px !important;
  }
}
@media only screen and (max-width: 767px) {
  .mr-xs-10 {
    margin-right: 10px !important;
  }
}
@media only screen and (max-width: 767px) {
  .mr-xs-15 {
    margin-right: 15px !important;
  }
}
@media only screen and (max-width: 767px) {
  .mr-xs-20 {
    margin-right: 20px !important;
  }
}
@media only screen and (max-width: 767px) {
  .mr-xs-25 {
    margin-right: 25px !important;
  }
}
@media only screen and (max-width: 767px) {
  .mr-xs-30 {
    margin-right: 30px !important;
  }
}
@media only screen and (max-width: 767px) {
  .mr-xs-35 {
    margin-right: 35px !important;
  }
}
@media only screen and (max-width: 767px) {
  .mr-xs-40 {
    margin-right: 40px !important;
  }
}
@media only screen and (max-width: 767px) {
  .mr-xs-45 {
    margin-right: 45px !important;
  }
}
@media only screen and (max-width: 767px) {
  .mr-xs-50 {
    margin-right: 50px !important;
  }
}
@media only screen and (max-width: 767px) {
  .mr-xs-55 {
    margin-right: 55px !important;
  }
}
@media only screen and (max-width: 767px) {
  .mr-xs-60 {
    margin-right: 60px !important;
  }
}
@media only screen and (max-width: 767px) {
  .mr-xs-65 {
    margin-right: 65px !important;
  }
}
@media only screen and (max-width: 767px) {
  .mr-xs-70 {
    margin-right: 70px !important;
  }
}
@media only screen and (max-width: 767px) {
  .mr-xs-75 {
    margin-right: 75px !important;
  }
}
@media only screen and (max-width: 767px) {
  .mr-xs-80 {
    margin-right: 80px !important;
  }
}
@media only screen and (max-width: 767px) {
  .mr-xs-85 {
    margin-right: 85px !important;
  }
}
@media only screen and (max-width: 767px) {
  .mr-xs-90 {
    margin-right: 90px !important;
  }
}
@media only screen and (max-width: 767px) {
  .mr-xs-95 {
    margin-right: 95px !important;
  }
}
@media only screen and (max-width: 767px) {
  .mr-xs-100 {
    margin-right: 100px !important;
  }
}
@media only screen and (max-width: 767px) {
  .mr-xs-105 {
    margin-right: 105px !important;
  }
}
@media only screen and (max-width: 767px) {
  .mr-xs-110 {
    margin-right: 110px !important;
  }
}
@media only screen and (max-width: 767px) {
  .mr-xs-115 {
    margin-right: 115px !important;
  }
}
@media only screen and (max-width: 767px) {
  .mr-xs-120 {
    margin-right: 120px !important;
  }
}
@media only screen and (max-width: 767px) {
  .mr-xs-125 {
    margin-right: 125px !important;
  }
}
@media only screen and (max-width: 767px) {
  .mr-xs-130 {
    margin-right: 130px !important;
  }
}
@media only screen and (max-width: 767px) {
  .mr-xs-135 {
    margin-right: 135px !important;
  }
}
@media only screen and (max-width: 767px) {
  .mr-xs-140 {
    margin-right: 140px !important;
  }
}
@media only screen and (max-width: 767px) {
  .mr-xs-145 {
    margin-right: 145px !important;
  }
}
@media only screen and (max-width: 767px) {
  .mr-xs-150 {
    margin-right: 150px !important;
  }
}
@media only screen and (max-width: 767px) {
  .mr-xs-155 {
    margin-right: 155px !important;
  }
}
@media only screen and (max-width: 767px) {
  .mr-xs-160 {
    margin-right: 160px !important;
  }
}
@media only screen and (max-width: 767px) {
  .mr-xs-165 {
    margin-right: 165px !important;
  }
}
@media only screen and (max-width: 767px) {
  .mr-xs-170 {
    margin-right: 170px !important;
  }
}
@media only screen and (max-width: 767px) {
  .mr-xs-175 {
    margin-right: 175px !important;
  }
}
@media only screen and (max-width: 767px) {
  .mr-xs-180 {
    margin-right: 180px !important;
  }
}
@media only screen and (max-width: 767px) {
  .mr-xs-185 {
    margin-right: 185px !important;
  }
}
@media only screen and (max-width: 767px) {
  .mr-xs-190 {
    margin-right: 190px !important;
  }
}
@media only screen and (max-width: 767px) {
  .mr-xs-195 {
    margin-right: 195px !important;
  }
}
@media only screen and (max-width: 767px) {
  .mr-xs-200 {
    margin-right: 200px !important;
  }
}

.fs-22 {
  font-size: 22px !important;
}
/*-- Padding Top --*/
.pt-0 {
  padding-top: 0px !important;
}

.pt-5 {
  padding-top: 5px !important;
}

.pt-10 {
  padding-top: 10px !important;
}

.pt-15 {
  padding-top: 15px !important;
}

.pt-20 {
  padding-top: 20px !important;
}

.pt-25 {
  padding-top: 25px !important;
}

.pt-30 {
  padding-top: 30px !important;
}

.pt-35 {
  padding-top: 35px !important;
}

.pt-40 {
  padding-top: 40px !important;
}

.pt-45 {
  padding-top: 45px !important;
}

.pt-50 {
  padding-top: 50px !important;
}

.pt-55 {
  padding-top: 55px !important;
}

.pt-60 {
  padding-top: 60px !important;
}

.pt-65 {
  padding-top: 65px !important;
}

.pt-70 {
  padding-top: 70px !important;
}

.pt-75 {
  padding-top: 75px !important;
}

.pt-80 {
  padding-top: 80px !important;
}

.pt-85 {
  padding-top: 85px !important;
}

.pt-90 {
  padding-top: 90px !important;
}

.pt-95 {
  padding-top: 95px !important;
}

.pt-100 {
  padding-top: 100px !important;
}

.pt-105 {
  padding-top: 105px !important;
}

.pt-110 {
  padding-top: 110px !important;
}

.pt-115 {
  padding-top: 115px !important;
}

.pt-120 {
  padding-top: 120px !important;
}

.pt-125 {
  padding-top: 125px !important;
}

.pt-130 {
  padding-top: 130px !important;
}

.pt-135 {
  padding-top: 135px !important;
}

.pt-140 {
  padding-top: 140px !important;
}

.pt-145 {
  padding-top: 145px !important;
}

.pt-150 {
  padding-top: 150px !important;
}

.pt-155 {
  padding-top: 155px !important;
}

.pt-160 {
  padding-top: 160px !important;
}

.pt-165 {
  padding-top: 165px !important;
}

.pt-170 {
  padding-top: 170px !important;
}

.pt-175 {
  padding-top: 175px !important;
}

.pt-180 {
  padding-top: 180px !important;
}

.pt-185 {
  padding-top: 185px !important;
}

.pt-190 {
  padding-top: 190px !important;
}

.pt-195 {
  padding-top: 195px !important;
}

.pt-200 {
  padding-top: 200px !important;
}

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .pt-lg-0 {
    padding-top: 0px !important;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .pt-lg-5 {
    padding-top: 5px !important;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .pt-lg-10 {
    padding-top: 10px !important;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .pt-lg-15 {
    padding-top: 15px !important;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .pt-lg-20 {
    padding-top: 20px !important;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .pt-lg-25 {
    padding-top: 25px !important;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .pt-lg-30 {
    padding-top: 30px !important;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .pt-lg-35 {
    padding-top: 35px !important;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .pt-lg-40 {
    padding-top: 40px !important;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .pt-lg-45 {
    padding-top: 45px !important;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .pt-lg-50 {
    padding-top: 50px !important;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .pt-lg-55 {
    padding-top: 55px !important;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .pt-lg-60 {
    padding-top: 60px !important;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .pt-lg-65 {
    padding-top: 65px !important;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .pt-lg-70 {
    padding-top: 70px !important;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .pt-lg-75 {
    padding-top: 75px !important;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .pt-lg-80 {
    padding-top: 80px !important;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .pt-lg-85 {
    padding-top: 85px !important;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .pt-lg-90 {
    padding-top: 90px !important;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .pt-lg-95 {
    padding-top: 95px !important;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .pt-lg-100 {
    padding-top: 100px !important;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .pt-lg-105 {
    padding-top: 105px !important;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .pt-lg-110 {
    padding-top: 110px !important;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .pt-lg-115 {
    padding-top: 115px !important;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .pt-lg-120 {
    padding-top: 120px !important;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .pt-lg-125 {
    padding-top: 125px !important;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .pt-lg-130 {
    padding-top: 130px !important;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .pt-lg-135 {
    padding-top: 135px !important;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .pt-lg-140 {
    padding-top: 140px !important;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .pt-lg-145 {
    padding-top: 145px !important;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .pt-lg-150 {
    padding-top: 150px !important;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .pt-lg-155 {
    padding-top: 155px !important;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .pt-lg-160 {
    padding-top: 160px !important;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .pt-lg-165 {
    padding-top: 165px !important;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .pt-lg-170 {
    padding-top: 170px !important;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .pt-lg-175 {
    padding-top: 175px !important;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .pt-lg-180 {
    padding-top: 180px !important;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .pt-lg-185 {
    padding-top: 185px !important;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .pt-lg-190 {
    padding-top: 190px !important;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .pt-lg-195 {
    padding-top: 195px !important;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .pt-lg-200 {
    padding-top: 200px !important;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .pt-md-0 {
    padding-top: 0px !important;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .pt-md-5 {
    padding-top: 5px !important;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .pt-md-10 {
    padding-top: 10px !important;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .pt-md-15 {
    padding-top: 15px !important;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .pt-md-20 {
    padding-top: 20px !important;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .pt-md-25 {
    padding-top: 25px !important;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .pt-md-30 {
    padding-top: 30px !important;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .pt-md-35 {
    padding-top: 35px !important;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .pt-md-40 {
    padding-top: 40px !important;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .pt-md-45 {
    padding-top: 45px !important;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .pt-md-50 {
    padding-top: 50px !important;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .pt-md-55 {
    padding-top: 55px !important;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .pt-md-60 {
    padding-top: 60px !important;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .pt-md-65 {
    padding-top: 65px !important;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .pt-md-70 {
    padding-top: 70px !important;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .pt-md-75 {
    padding-top: 75px !important;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .pt-md-80 {
    padding-top: 80px !important;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .pt-md-85 {
    padding-top: 85px !important;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .pt-md-90 {
    padding-top: 90px !important;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .pt-md-95 {
    padding-top: 95px !important;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .pt-md-100 {
    padding-top: 100px !important;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .pt-md-105 {
    padding-top: 105px !important;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .pt-md-110 {
    padding-top: 110px !important;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .pt-md-115 {
    padding-top: 115px !important;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .pt-md-120 {
    padding-top: 120px !important;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .pt-md-125 {
    padding-top: 125px !important;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .pt-md-130 {
    padding-top: 130px !important;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .pt-md-135 {
    padding-top: 135px !important;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .pt-md-140 {
    padding-top: 140px !important;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .pt-md-145 {
    padding-top: 145px !important;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .pt-md-150 {
    padding-top: 150px !important;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .pt-md-155 {
    padding-top: 155px !important;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .pt-md-160 {
    padding-top: 160px !important;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .pt-md-165 {
    padding-top: 165px !important;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .pt-md-170 {
    padding-top: 170px !important;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .pt-md-175 {
    padding-top: 175px !important;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .pt-md-180 {
    padding-top: 180px !important;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .pt-md-185 {
    padding-top: 185px !important;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .pt-md-190 {
    padding-top: 190px !important;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .pt-md-195 {
    padding-top: 195px !important;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .pt-md-200 {
    padding-top: 200px !important;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pt-sm-0 {
    padding-top: 0px !important;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pt-sm-5 {
    padding-top: 5px !important;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pt-sm-10 {
    padding-top: 10px !important;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pt-sm-15 {
    padding-top: 15px !important;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pt-sm-20 {
    padding-top: 20px !important;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pt-sm-25 {
    padding-top: 25px !important;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pt-sm-30 {
    padding-top: 30px !important;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pt-sm-35 {
    padding-top: 35px !important;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pt-sm-40 {
    padding-top: 40px !important;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pt-sm-45 {
    padding-top: 45px !important;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pt-sm-50 {
    padding-top: 50px !important;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pt-sm-55 {
    padding-top: 55px !important;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pt-sm-60 {
    padding-top: 60px !important;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pt-sm-65 {
    padding-top: 65px !important;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pt-sm-70 {
    padding-top: 70px !important;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pt-sm-75 {
    padding-top: 75px !important;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pt-sm-80 {
    padding-top: 80px !important;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pt-sm-85 {
    padding-top: 85px !important;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pt-sm-90 {
    padding-top: 90px !important;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pt-sm-95 {
    padding-top: 95px !important;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pt-sm-100 {
    padding-top: 100px !important;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pt-sm-105 {
    padding-top: 105px !important;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pt-sm-110 {
    padding-top: 110px !important;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pt-sm-115 {
    padding-top: 115px !important;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pt-sm-120 {
    padding-top: 120px !important;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pt-sm-125 {
    padding-top: 125px !important;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pt-sm-130 {
    padding-top: 130px !important;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pt-sm-135 {
    padding-top: 135px !important;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pt-sm-140 {
    padding-top: 140px !important;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pt-sm-145 {
    padding-top: 145px !important;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pt-sm-150 {
    padding-top: 150px !important;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pt-sm-155 {
    padding-top: 155px !important;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pt-sm-160 {
    padding-top: 160px !important;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pt-sm-165 {
    padding-top: 165px !important;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pt-sm-170 {
    padding-top: 170px !important;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pt-sm-175 {
    padding-top: 175px !important;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pt-sm-180 {
    padding-top: 180px !important;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pt-sm-185 {
    padding-top: 185px !important;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pt-sm-190 {
    padding-top: 190px !important;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pt-sm-195 {
    padding-top: 195px !important;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pt-sm-200 {
    padding-top: 200px !important;
  }
}
@media only screen and (max-width: 767px) {
  .pt-xs-0 {
    padding-top: 0px !important;
  }
}
@media only screen and (max-width: 767px) {
  .pt-xs-5 {
    padding-top: 5px !important;
  }
}
@media only screen and (max-width: 767px) {
  .pt-xs-10 {
    padding-top: 10px !important;
  }
}
@media only screen and (max-width: 767px) {
  .pt-xs-15 {
    padding-top: 15px !important;
  }
}
@media only screen and (max-width: 767px) {
  .pt-xs-20 {
    padding-top: 20px !important;
  }
}
@media only screen and (max-width: 767px) {
  .pt-xs-25 {
    padding-top: 25px !important;
  }
}
@media only screen and (max-width: 767px) {
  .pt-xs-30 {
    padding-top: 30px !important;
  }
}
@media only screen and (max-width: 767px) {
  .pt-xs-35 {
    padding-top: 35px !important;
  }
}
@media only screen and (max-width: 767px) {
  .pt-xs-40 {
    padding-top: 40px !important;
  }
}
@media only screen and (max-width: 767px) {
  .pt-xs-45 {
    padding-top: 45px !important;
  }
}
@media only screen and (max-width: 767px) {
  .pt-xs-50 {
    padding-top: 50px !important;
  }
}
@media only screen and (max-width: 767px) {
  .pt-xs-55 {
    padding-top: 55px !important;
  }
}
@media only screen and (max-width: 767px) {
  .pt-xs-60 {
    padding-top: 60px !important;
  }
}
@media only screen and (max-width: 767px) {
  .pt-xs-65 {
    padding-top: 65px !important;
  }
}
@media only screen and (max-width: 767px) {
  .pt-xs-70 {
    padding-top: 70px !important;
  }
}
@media only screen and (max-width: 767px) {
  .pt-xs-75 {
    padding-top: 75px !important;
  }
}
@media only screen and (max-width: 767px) {
  .pt-xs-80 {
    padding-top: 80px !important;
  }
}
@media only screen and (max-width: 767px) {
  .pt-xs-85 {
    padding-top: 85px !important;
  }
}
@media only screen and (max-width: 767px) {
  .pt-xs-90 {
    padding-top: 90px !important;
  }
}
@media only screen and (max-width: 767px) {
  .pt-xs-95 {
    padding-top: 95px !important;
  }
}
@media only screen and (max-width: 767px) {
  .pt-xs-100 {
    padding-top: 100px !important;
  }
}
@media only screen and (max-width: 767px) {
  .pt-xs-105 {
    padding-top: 105px !important;
  }
}
@media only screen and (max-width: 767px) {
  .pt-xs-110 {
    padding-top: 110px !important;
  }
}
@media only screen and (max-width: 767px) {
  .pt-xs-115 {
    padding-top: 115px !important;
  }
}
@media only screen and (max-width: 767px) {
  .pt-xs-120 {
    padding-top: 120px !important;
  }
}
@media only screen and (max-width: 767px) {
  .pt-xs-125 {
    padding-top: 125px !important;
  }
}
@media only screen and (max-width: 767px) {
  .pt-xs-130 {
    padding-top: 130px !important;
  }
}
@media only screen and (max-width: 767px) {
  .pt-xs-135 {
    padding-top: 135px !important;
  }
}
@media only screen and (max-width: 767px) {
  .pt-xs-140 {
    padding-top: 140px !important;
  }
}
@media only screen and (max-width: 767px) {
  .pt-xs-145 {
    padding-top: 145px !important;
  }
}
@media only screen and (max-width: 767px) {
  .pt-xs-150 {
    padding-top: 150px !important;
  }
}
@media only screen and (max-width: 767px) {
  .pt-xs-155 {
    padding-top: 155px !important;
  }
}
@media only screen and (max-width: 767px) {
  .pt-xs-160 {
    padding-top: 160px !important;
  }
}
@media only screen and (max-width: 767px) {
  .pt-xs-165 {
    padding-top: 165px !important;
  }
}
@media only screen and (max-width: 767px) {
  .pt-xs-170 {
    padding-top: 170px !important;
  }
}
@media only screen and (max-width: 767px) {
  .pt-xs-175 {
    padding-top: 175px !important;
  }
}
@media only screen and (max-width: 767px) {
  .pt-xs-180 {
    padding-top: 180px !important;
  }
}
@media only screen and (max-width: 767px) {
  .pt-xs-185 {
    padding-top: 185px !important;
  }
}
@media only screen and (max-width: 767px) {
  .pt-xs-190 {
    padding-top: 190px !important;
  }
}
@media only screen and (max-width: 767px) {
  .pt-xs-195 {
    padding-top: 195px !important;
  }
}
@media only screen and (max-width: 767px) {
  .pt-xs-200 {
    padding-top: 200px !important;
  }
}
/*-- Padding Bottom --*/
.pb-0 {
  padding-bottom: 0px !important;
}

.pb-5 {
  padding-bottom: 5px !important;
}

.pb-10 {
  padding-bottom: 10px !important;
}

.pb-15 {
  padding-bottom: 15px !important;
}

.pb-20 {
  padding-bottom: 20px !important;
}

.pb-25 {
  padding-bottom: 25px !important;
}

.pb-30 {
  padding-bottom: 30px !important;
}

.pb-35 {
  padding-bottom: 35px !important;
}

.pb-40 {
  padding-bottom: 40px !important;
}

.pb-45 {
  padding-bottom: 45px !important;
}

.pb-50 {
  padding-bottom: 50px !important;
}

.pb-55 {
  padding-bottom: 55px !important;
}

.pb-60 {
  padding-bottom: 60px !important;
}

.pb-65 {
  padding-bottom: 65px !important;
}

.pb-70 {
  padding-bottom: 70px !important;
}

.pb-75 {
  padding-bottom: 75px !important;
}

.pb-80 {
  padding-bottom: 80px !important;
}

.pb-85 {
  padding-bottom: 85px !important;
}

.pb-90 {
  padding-bottom: 90px !important;
}

.pb-95 {
  padding-bottom: 95px !important;
}

.pb-100 {
  padding-bottom: 100px !important;
}

.pb-105 {
  padding-bottom: 105px !important;
}

.pb-110 {
  padding-bottom: 110px !important;
}

.pb-115 {
  padding-bottom: 115px !important;
}

.pb-120 {
  padding-bottom: 120px !important;
}

.pb-125 {
  padding-bottom: 125px !important;
}

.pb-130 {
  padding-bottom: 130px !important;
}

.pb-135 {
  padding-bottom: 135px !important;
}

.pb-140 {
  padding-bottom: 140px !important;
}

.pb-145 {
  padding-bottom: 145px !important;
}

.pb-150 {
  padding-bottom: 150px !important;
}

.pb-155 {
  padding-bottom: 155px !important;
}

.pb-160 {
  padding-bottom: 160px !important;
}

.pb-165 {
  padding-bottom: 165px !important;
}

.pb-170 {
  padding-bottom: 170px !important;
}

.pb-175 {
  padding-bottom: 175px !important;
}

.pb-180 {
  padding-bottom: 180px !important;
}

.pb-185 {
  padding-bottom: 185px !important;
}

.pb-190 {
  padding-bottom: 190px !important;
}

.pb-195 {
  padding-bottom: 195px !important;
}

.pb-200 {
  padding-bottom: 200px !important;
}

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .pb-lg-0 {
    padding-bottom: 0px !important;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .pb-lg-5 {
    padding-bottom: 5px !important;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .pb-lg-10 {
    padding-bottom: 10px !important;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .pb-lg-15 {
    padding-bottom: 15px !important;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .pb-lg-20 {
    padding-bottom: 20px !important;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .pb-lg-25 {
    padding-bottom: 25px !important;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .pb-lg-30 {
    padding-bottom: 30px !important;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .pb-lg-35 {
    padding-bottom: 35px !important;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .pb-lg-40 {
    padding-bottom: 40px !important;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .pb-lg-45 {
    padding-bottom: 45px !important;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .pb-lg-50 {
    padding-bottom: 50px !important;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .pb-lg-55 {
    padding-bottom: 55px !important;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .pb-lg-60 {
    padding-bottom: 60px !important;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .pb-lg-65 {
    padding-bottom: 65px !important;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .pb-lg-70 {
    padding-bottom: 70px !important;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .pb-lg-75 {
    padding-bottom: 75px !important;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .pb-lg-80 {
    padding-bottom: 80px !important;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .pb-lg-85 {
    padding-bottom: 85px !important;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .pb-lg-90 {
    padding-bottom: 90px !important;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .pb-lg-95 {
    padding-bottom: 95px !important;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .pb-lg-100 {
    padding-bottom: 100px !important;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .pb-lg-105 {
    padding-bottom: 105px !important;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .pb-lg-110 {
    padding-bottom: 110px !important;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .pb-lg-115 {
    padding-bottom: 115px !important;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .pb-lg-120 {
    padding-bottom: 120px !important;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .pb-lg-125 {
    padding-bottom: 125px !important;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .pb-lg-130 {
    padding-bottom: 130px !important;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .pb-lg-135 {
    padding-bottom: 135px !important;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .pb-lg-140 {
    padding-bottom: 140px !important;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .pb-lg-145 {
    padding-bottom: 145px !important;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .pb-lg-150 {
    padding-bottom: 150px !important;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .pb-lg-155 {
    padding-bottom: 155px !important;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .pb-lg-160 {
    padding-bottom: 160px !important;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .pb-lg-165 {
    padding-bottom: 165px !important;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .pb-lg-170 {
    padding-bottom: 170px !important;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .pb-lg-175 {
    padding-bottom: 175px !important;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .pb-lg-180 {
    padding-bottom: 180px !important;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .pb-lg-185 {
    padding-bottom: 185px !important;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .pb-lg-190 {
    padding-bottom: 190px !important;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .pb-lg-195 {
    padding-bottom: 195px !important;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .pb-lg-200 {
    padding-bottom: 200px !important;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .pb-md-0 {
    padding-bottom: 0px !important;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .pb-md-5 {
    padding-bottom: 5px !important;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .pb-md-10 {
    padding-bottom: 10px !important;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .pb-md-15 {
    padding-bottom: 15px !important;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .pb-md-20 {
    padding-bottom: 20px !important;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .pb-md-25 {
    padding-bottom: 25px !important;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .pb-md-30 {
    padding-bottom: 30px !important;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .pb-md-35 {
    padding-bottom: 35px !important;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .pb-md-40 {
    padding-bottom: 40px !important;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .pb-md-45 {
    padding-bottom: 45px !important;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .pb-md-50 {
    padding-bottom: 50px !important;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .pb-md-55 {
    padding-bottom: 55px !important;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .pb-md-60 {
    padding-bottom: 60px !important;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .pb-md-65 {
    padding-bottom: 65px !important;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .pb-md-70 {
    padding-bottom: 70px !important;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .pb-md-75 {
    padding-bottom: 75px !important;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .pb-md-80 {
    padding-bottom: 80px !important;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .pb-md-85 {
    padding-bottom: 85px !important;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .pb-md-90 {
    padding-bottom: 90px !important;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .pb-md-95 {
    padding-bottom: 95px !important;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .pb-md-100 {
    padding-bottom: 100px !important;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .pb-md-105 {
    padding-bottom: 105px !important;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .pb-md-110 {
    padding-bottom: 110px !important;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .pb-md-115 {
    padding-bottom: 115px !important;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .pb-md-120 {
    padding-bottom: 120px !important;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .pb-md-125 {
    padding-bottom: 125px !important;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .pb-md-130 {
    padding-bottom: 130px !important;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .pb-md-135 {
    padding-bottom: 135px !important;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .pb-md-140 {
    padding-bottom: 140px !important;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .pb-md-145 {
    padding-bottom: 145px !important;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .pb-md-150 {
    padding-bottom: 150px !important;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .pb-md-155 {
    padding-bottom: 155px !important;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .pb-md-160 {
    padding-bottom: 160px !important;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .pb-md-165 {
    padding-bottom: 165px !important;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .pb-md-170 {
    padding-bottom: 170px !important;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .pb-md-175 {
    padding-bottom: 175px !important;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .pb-md-180 {
    padding-bottom: 180px !important;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .pb-md-185 {
    padding-bottom: 185px !important;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .pb-md-190 {
    padding-bottom: 190px !important;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .pb-md-195 {
    padding-bottom: 195px !important;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .pb-md-200 {
    padding-bottom: 200px !important;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pb-sm-0 {
    padding-bottom: 0px !important;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pb-sm-5 {
    padding-bottom: 5px !important;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pb-sm-10 {
    padding-bottom: 10px !important;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pb-sm-15 {
    padding-bottom: 15px !important;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pb-sm-20 {
    padding-bottom: 20px !important;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pb-sm-25 {
    padding-bottom: 25px !important;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pb-sm-30 {
    padding-bottom: 30px !important;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pb-sm-35 {
    padding-bottom: 35px !important;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pb-sm-40 {
    padding-bottom: 40px !important;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pb-sm-45 {
    padding-bottom: 45px !important;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pb-sm-50 {
    padding-bottom: 50px !important;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pb-sm-55 {
    padding-bottom: 55px !important;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pb-sm-60 {
    padding-bottom: 60px !important;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pb-sm-65 {
    padding-bottom: 65px !important;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pb-sm-70 {
    padding-bottom: 70px !important;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pb-sm-75 {
    padding-bottom: 75px !important;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pb-sm-80 {
    padding-bottom: 80px !important;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pb-sm-85 {
    padding-bottom: 85px !important;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pb-sm-90 {
    padding-bottom: 90px !important;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pb-sm-95 {
    padding-bottom: 95px !important;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pb-sm-100 {
    padding-bottom: 100px !important;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pb-sm-105 {
    padding-bottom: 105px !important;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pb-sm-110 {
    padding-bottom: 110px !important;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pb-sm-115 {
    padding-bottom: 115px !important;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pb-sm-120 {
    padding-bottom: 120px !important;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pb-sm-125 {
    padding-bottom: 125px !important;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pb-sm-130 {
    padding-bottom: 130px !important;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pb-sm-135 {
    padding-bottom: 135px !important;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pb-sm-140 {
    padding-bottom: 140px !important;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pb-sm-145 {
    padding-bottom: 145px !important;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pb-sm-150 {
    padding-bottom: 150px !important;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pb-sm-155 {
    padding-bottom: 155px !important;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pb-sm-160 {
    padding-bottom: 160px !important;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pb-sm-165 {
    padding-bottom: 165px !important;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pb-sm-170 {
    padding-bottom: 170px !important;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pb-sm-175 {
    padding-bottom: 175px !important;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pb-sm-180 {
    padding-bottom: 180px !important;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pb-sm-185 {
    padding-bottom: 185px !important;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pb-sm-190 {
    padding-bottom: 190px !important;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pb-sm-195 {
    padding-bottom: 195px !important;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pb-sm-200 {
    padding-bottom: 200px !important;
  }
}
@media only screen and (max-width: 767px) {
  .pb-xs-0 {
    padding-bottom: 0px !important;
  }
}
@media only screen and (max-width: 767px) {
  .pb-xs-5 {
    padding-bottom: 5px !important;
  }
}
@media only screen and (max-width: 767px) {
  .pb-xs-10 {
    padding-bottom: 10px !important;
  }
}
@media only screen and (max-width: 767px) {
  .pb-xs-15 {
    padding-bottom: 15px !important;
  }
}
@media only screen and (max-width: 767px) {
  .pb-xs-20 {
    padding-bottom: 20px !important;
  }
}
@media only screen and (max-width: 767px) {
  .pb-xs-25 {
    padding-bottom: 25px !important;
  }
}
@media only screen and (max-width: 767px) {
  .pb-xs-30 {
    padding-bottom: 30px !important;
  }
}
@media only screen and (max-width: 767px) {
  .pb-xs-35 {
    padding-bottom: 35px !important;
  }
}
@media only screen and (max-width: 767px) {
  .pb-xs-40 {
    padding-bottom: 40px !important;
  }
}
@media only screen and (max-width: 767px) {
  .pb-xs-45 {
    padding-bottom: 45px !important;
  }
}
@media only screen and (max-width: 767px) {
  .pb-xs-50 {
    padding-bottom: 50px !important;
  }
}
@media only screen and (max-width: 767px) {
  .pb-xs-55 {
    padding-bottom: 55px !important;
  }
}
@media only screen and (max-width: 767px) {
  .pb-xs-60 {
    padding-bottom: 60px !important;
  }
}
@media only screen and (max-width: 767px) {
  .pb-xs-65 {
    padding-bottom: 65px !important;
  }
}
@media only screen and (max-width: 767px) {
  .pb-xs-70 {
    padding-bottom: 70px !important;
  }
}
@media only screen and (max-width: 767px) {
  .pb-xs-75 {
    padding-bottom: 75px !important;
  }
}
@media only screen and (max-width: 767px) {
  .pb-xs-80 {
    padding-bottom: 80px !important;
  }
}
@media only screen and (max-width: 767px) {
  .pb-xs-85 {
    padding-bottom: 85px !important;
  }
}
@media only screen and (max-width: 767px) {
  .pb-xs-90 {
    padding-bottom: 90px !important;
  }
}
@media only screen and (max-width: 767px) {
  .pb-xs-95 {
    padding-bottom: 95px !important;
  }
}
@media only screen and (max-width: 767px) {
  .pb-xs-100 {
    padding-bottom: 100px !important;
  }
}
@media only screen and (max-width: 767px) {
  .pb-xs-105 {
    padding-bottom: 105px !important;
  }
}
@media only screen and (max-width: 767px) {
  .pb-xs-110 {
    padding-bottom: 110px !important;
  }
}
@media only screen and (max-width: 767px) {
  .pb-xs-115 {
    padding-bottom: 115px !important;
  }
}
@media only screen and (max-width: 767px) {
  .pb-xs-120 {
    padding-bottom: 120px !important;
  }
}
@media only screen and (max-width: 767px) {
  .pb-xs-125 {
    padding-bottom: 125px !important;
  }
}
@media only screen and (max-width: 767px) {
  .pb-xs-130 {
    padding-bottom: 130px !important;
  }
}
@media only screen and (max-width: 767px) {
  .pb-xs-135 {
    padding-bottom: 135px !important;
  }
}
@media only screen and (max-width: 767px) {
  .pb-xs-140 {
    padding-bottom: 140px !important;
  }
}
@media only screen and (max-width: 767px) {
  .pb-xs-145 {
    padding-bottom: 145px !important;
  }
}
@media only screen and (max-width: 767px) {
  .pb-xs-150 {
    padding-bottom: 150px !important;
  }
}
@media only screen and (max-width: 767px) {
  .pb-xs-155 {
    padding-bottom: 155px !important;
  }
}
@media only screen and (max-width: 767px) {
  .pb-xs-160 {
    padding-bottom: 160px !important;
  }
}
@media only screen and (max-width: 767px) {
  .pb-xs-165 {
    padding-bottom: 165px !important;
  }
}
@media only screen and (max-width: 767px) {
  .pb-xs-170 {
    padding-bottom: 170px !important;
  }
}
@media only screen and (max-width: 767px) {
  .pb-xs-175 {
    padding-bottom: 175px !important;
  }
}
@media only screen and (max-width: 767px) {
  .pb-xs-180 {
    padding-bottom: 180px !important;
  }
}
@media only screen and (max-width: 767px) {
  .pb-xs-185 {
    padding-bottom: 185px !important;
  }
}
@media only screen and (max-width: 767px) {
  .pb-xs-190 {
    padding-bottom: 190px !important;
  }
}
@media only screen and (max-width: 767px) {
  .pb-xs-195 {
    padding-bottom: 195px !important;
  }
}
@media only screen and (max-width: 767px) {
  .pb-xs-200 {
    padding-bottom: 200px !important;
  }
}
/*-- Padding Left --*/
.pl-0 {
  padding-left: 0px !important;
}

.pl-5 {
  padding-left: 5px !important;
}

.pl-10 {
  padding-left: 10px !important;
}

.pl-15 {
  padding-left: 15px !important;
}

.pl-20 {
  padding-left: 20px !important;
}

.pl-25 {
  padding-left: 25px !important;
}

.pl-30 {
  padding-left: 30px !important;
}

.pl-35 {
  padding-left: 35px !important;
}

.pl-40 {
  padding-left: 40px !important;
}

.pl-45 {
  padding-left: 45px !important;
}

.pl-50 {
  padding-left: 50px !important;
}

.pl-55 {
  padding-left: 55px !important;
}

.pl-60 {
  padding-left: 60px !important;
}

.pl-65 {
  padding-left: 65px !important;
}

.pl-70 {
  padding-left: 70px !important;
}

.pl-75 {
  padding-left: 75px !important;
}

.pl-80 {
  padding-left: 80px !important;
}

.pl-85 {
  padding-left: 85px !important;
}

.pl-90 {
  padding-left: 90px !important;
}

.pl-95 {
  padding-left: 95px !important;
}

.pl-100 {
  padding-left: 100px !important;
}

.pl-105 {
  padding-left: 105px !important;
}

.pl-110 {
  padding-left: 110px !important;
}

.pl-115 {
  padding-left: 115px !important;
}

.pl-120 {
  padding-left: 120px !important;
}

.pl-125 {
  padding-left: 125px !important;
}

.pl-130 {
  padding-left: 130px !important;
}

.pl-135 {
  padding-left: 135px !important;
}

.pl-140 {
  padding-left: 140px !important;
}

.pl-145 {
  padding-left: 145px !important;
}

.pl-150 {
  padding-left: 150px !important;
}

.pl-155 {
  padding-left: 155px !important;
}

.pl-160 {
  padding-left: 160px !important;
}

.pl-165 {
  padding-left: 165px !important;
}

.pl-170 {
  padding-left: 170px !important;
}

.pl-175 {
  padding-left: 175px !important;
}

.pl-180 {
  padding-left: 180px !important;
}

.pl-185 {
  padding-left: 185px !important;
}

.pl-190 {
  padding-left: 190px !important;
}

.pl-195 {
  padding-left: 195px !important;
}

.pl-200 {
  padding-left: 200px !important;
}

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .pl-lg-0 {
    padding-left: 0px !important;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .pl-lg-5 {
    padding-left: 5px !important;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .pl-lg-10 {
    padding-left: 10px !important;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .pl-lg-15 {
    padding-left: 15px !important;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .pl-lg-20 {
    padding-left: 20px !important;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .pl-lg-25 {
    padding-left: 25px !important;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .pl-lg-30 {
    padding-left: 30px !important;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .pl-lg-35 {
    padding-left: 35px !important;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .pl-lg-40 {
    padding-left: 40px !important;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .pl-lg-45 {
    padding-left: 45px !important;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .pl-lg-50 {
    padding-left: 50px !important;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .pl-lg-55 {
    padding-left: 55px !important;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .pl-lg-60 {
    padding-left: 60px !important;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .pl-lg-65 {
    padding-left: 65px !important;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .pl-lg-70 {
    padding-left: 70px !important;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .pl-lg-75 {
    padding-left: 75px !important;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .pl-lg-80 {
    padding-left: 80px !important;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .pl-lg-85 {
    padding-left: 85px !important;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .pl-lg-90 {
    padding-left: 90px !important;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .pl-lg-95 {
    padding-left: 95px !important;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .pl-lg-100 {
    padding-left: 100px !important;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .pl-lg-105 {
    padding-left: 105px !important;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .pl-lg-110 {
    padding-left: 110px !important;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .pl-lg-115 {
    padding-left: 115px !important;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .pl-lg-120 {
    padding-left: 120px !important;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .pl-lg-125 {
    padding-left: 125px !important;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .pl-lg-130 {
    padding-left: 130px !important;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .pl-lg-135 {
    padding-left: 135px !important;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .pl-lg-140 {
    padding-left: 140px !important;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .pl-lg-145 {
    padding-left: 145px !important;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .pl-lg-150 {
    padding-left: 150px !important;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .pl-lg-155 {
    padding-left: 155px !important;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .pl-lg-160 {
    padding-left: 160px !important;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .pl-lg-165 {
    padding-left: 165px !important;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .pl-lg-170 {
    padding-left: 170px !important;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .pl-lg-175 {
    padding-left: 175px !important;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .pl-lg-180 {
    padding-left: 180px !important;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .pl-lg-185 {
    padding-left: 185px !important;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .pl-lg-190 {
    padding-left: 190px !important;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .pl-lg-195 {
    padding-left: 195px !important;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .pl-lg-200 {
    padding-left: 200px !important;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .pl-md-0 {
    padding-left: 0px !important;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .pl-md-5 {
    padding-left: 5px !important;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .pl-md-10 {
    padding-left: 10px !important;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .pl-md-15 {
    padding-left: 15px !important;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .pl-md-20 {
    padding-left: 20px !important;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .pl-md-25 {
    padding-left: 25px !important;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .pl-md-30 {
    padding-left: 30px !important;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .pl-md-35 {
    padding-left: 35px !important;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .pl-md-40 {
    padding-left: 40px !important;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .pl-md-45 {
    padding-left: 45px !important;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .pl-md-50 {
    padding-left: 50px !important;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .pl-md-55 {
    padding-left: 55px !important;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .pl-md-60 {
    padding-left: 60px !important;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .pl-md-65 {
    padding-left: 65px !important;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .pl-md-70 {
    padding-left: 70px !important;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .pl-md-75 {
    padding-left: 75px !important;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .pl-md-80 {
    padding-left: 80px !important;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .pl-md-85 {
    padding-left: 85px !important;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .pl-md-90 {
    padding-left: 90px !important;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .pl-md-95 {
    padding-left: 95px !important;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .pl-md-100 {
    padding-left: 100px !important;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .pl-md-105 {
    padding-left: 105px !important;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .pl-md-110 {
    padding-left: 110px !important;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .pl-md-115 {
    padding-left: 115px !important;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .pl-md-120 {
    padding-left: 120px !important;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .pl-md-125 {
    padding-left: 125px !important;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .pl-md-130 {
    padding-left: 130px !important;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .pl-md-135 {
    padding-left: 135px !important;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .pl-md-140 {
    padding-left: 140px !important;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .pl-md-145 {
    padding-left: 145px !important;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .pl-md-150 {
    padding-left: 150px !important;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .pl-md-155 {
    padding-left: 155px !important;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .pl-md-160 {
    padding-left: 160px !important;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .pl-md-165 {
    padding-left: 165px !important;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .pl-md-170 {
    padding-left: 170px !important;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .pl-md-175 {
    padding-left: 175px !important;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .pl-md-180 {
    padding-left: 180px !important;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .pl-md-185 {
    padding-left: 185px !important;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .pl-md-190 {
    padding-left: 190px !important;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .pl-md-195 {
    padding-left: 195px !important;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .pl-md-200 {
    padding-left: 200px !important;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pl-sm-0 {
    padding-left: 0px !important;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pl-sm-5 {
    padding-left: 5px !important;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pl-sm-10 {
    padding-left: 10px !important;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pl-sm-15 {
    padding-left: 15px !important;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pl-sm-20 {
    padding-left: 20px !important;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pl-sm-25 {
    padding-left: 25px !important;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pl-sm-30 {
    padding-left: 30px !important;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pl-sm-35 {
    padding-left: 35px !important;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pl-sm-40 {
    padding-left: 40px !important;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pl-sm-45 {
    padding-left: 45px !important;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pl-sm-50 {
    padding-left: 50px !important;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pl-sm-55 {
    padding-left: 55px !important;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pl-sm-60 {
    padding-left: 60px !important;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pl-sm-65 {
    padding-left: 65px !important;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pl-sm-70 {
    padding-left: 70px !important;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pl-sm-75 {
    padding-left: 75px !important;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pl-sm-80 {
    padding-left: 80px !important;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pl-sm-85 {
    padding-left: 85px !important;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pl-sm-90 {
    padding-left: 90px !important;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pl-sm-95 {
    padding-left: 95px !important;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pl-sm-100 {
    padding-left: 100px !important;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pl-sm-105 {
    padding-left: 105px !important;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pl-sm-110 {
    padding-left: 110px !important;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pl-sm-115 {
    padding-left: 115px !important;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pl-sm-120 {
    padding-left: 120px !important;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pl-sm-125 {
    padding-left: 125px !important;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pl-sm-130 {
    padding-left: 130px !important;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pl-sm-135 {
    padding-left: 135px !important;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pl-sm-140 {
    padding-left: 140px !important;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pl-sm-145 {
    padding-left: 145px !important;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pl-sm-150 {
    padding-left: 150px !important;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pl-sm-155 {
    padding-left: 155px !important;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pl-sm-160 {
    padding-left: 160px !important;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pl-sm-165 {
    padding-left: 165px !important;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pl-sm-170 {
    padding-left: 170px !important;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pl-sm-175 {
    padding-left: 175px !important;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pl-sm-180 {
    padding-left: 180px !important;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pl-sm-185 {
    padding-left: 185px !important;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pl-sm-190 {
    padding-left: 190px !important;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pl-sm-195 {
    padding-left: 195px !important;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pl-sm-200 {
    padding-left: 200px !important;
  }
}
@media only screen and (max-width: 767px) {
  .pl-xs-0 {
    padding-left: 0px !important;
  }
}
@media only screen and (max-width: 767px) {
  .pl-xs-5 {
    padding-left: 5px !important;
  }
}
@media only screen and (max-width: 767px) {
  .pl-xs-10 {
    padding-left: 10px !important;
  }
}
@media only screen and (max-width: 767px) {
  .pl-xs-15 {
    padding-left: 15px !important;
  }
}
@media only screen and (max-width: 767px) {
  .pl-xs-20 {
    padding-left: 20px !important;
  }
}
@media only screen and (max-width: 767px) {
  .pl-xs-25 {
    padding-left: 25px !important;
  }
}
@media only screen and (max-width: 767px) {
  .pl-xs-30 {
    padding-left: 30px !important;
  }
}
@media only screen and (max-width: 767px) {
  .pl-xs-35 {
    padding-left: 35px !important;
  }
}
@media only screen and (max-width: 767px) {
  .pl-xs-40 {
    padding-left: 40px !important;
  }
}
@media only screen and (max-width: 767px) {
  .pl-xs-45 {
    padding-left: 45px !important;
  }
}
@media only screen and (max-width: 767px) {
  .pl-xs-50 {
    padding-left: 50px !important;
  }
}
@media only screen and (max-width: 767px) {
  .pl-xs-55 {
    padding-left: 55px !important;
  }
}
@media only screen and (max-width: 767px) {
  .pl-xs-60 {
    padding-left: 60px !important;
  }
}
@media only screen and (max-width: 767px) {
  .pl-xs-65 {
    padding-left: 65px !important;
  }
}
@media only screen and (max-width: 767px) {
  .pl-xs-70 {
    padding-left: 70px !important;
  }
}
@media only screen and (max-width: 767px) {
  .pl-xs-75 {
    padding-left: 75px !important;
  }
}
@media only screen and (max-width: 767px) {
  .pl-xs-80 {
    padding-left: 80px !important;
  }
}
@media only screen and (max-width: 767px) {
  .pl-xs-85 {
    padding-left: 85px !important;
  }
}
@media only screen and (max-width: 767px) {
  .pl-xs-90 {
    padding-left: 90px !important;
  }
}
@media only screen and (max-width: 767px) {
  .pl-xs-95 {
    padding-left: 95px !important;
  }
}
@media only screen and (max-width: 767px) {
  .pl-xs-100 {
    padding-left: 100px !important;
  }
}
@media only screen and (max-width: 767px) {
  .pl-xs-105 {
    padding-left: 105px !important;
  }
}
@media only screen and (max-width: 767px) {
  .pl-xs-110 {
    padding-left: 110px !important;
  }
}
@media only screen and (max-width: 767px) {
  .pl-xs-115 {
    padding-left: 115px !important;
  }
}
@media only screen and (max-width: 767px) {
  .pl-xs-120 {
    padding-left: 120px !important;
  }
}
@media only screen and (max-width: 767px) {
  .pl-xs-125 {
    padding-left: 125px !important;
  }
}
@media only screen and (max-width: 767px) {
  .pl-xs-130 {
    padding-left: 130px !important;
  }
}
@media only screen and (max-width: 767px) {
  .pl-xs-135 {
    padding-left: 135px !important;
  }
}
@media only screen and (max-width: 767px) {
  .pl-xs-140 {
    padding-left: 140px !important;
  }
}
@media only screen and (max-width: 767px) {
  .pl-xs-145 {
    padding-left: 145px !important;
  }
}
@media only screen and (max-width: 767px) {
  .pl-xs-150 {
    padding-left: 150px !important;
  }
}
@media only screen and (max-width: 767px) {
  .pl-xs-155 {
    padding-left: 155px !important;
  }
}
@media only screen and (max-width: 767px) {
  .pl-xs-160 {
    padding-left: 160px !important;
  }
}
@media only screen and (max-width: 767px) {
  .pl-xs-165 {
    padding-left: 165px !important;
  }
}
@media only screen and (max-width: 767px) {
  .pl-xs-170 {
    padding-left: 170px !important;
  }
}
@media only screen and (max-width: 767px) {
  .pl-xs-175 {
    padding-left: 175px !important;
  }
}
@media only screen and (max-width: 767px) {
  .pl-xs-180 {
    padding-left: 180px !important;
  }
}
@media only screen and (max-width: 767px) {
  .pl-xs-185 {
    padding-left: 185px !important;
  }
}
@media only screen and (max-width: 767px) {
  .pl-xs-190 {
    padding-left: 190px !important;
  }
}
@media only screen and (max-width: 767px) {
  .pl-xs-195 {
    padding-left: 195px !important;
  }
}
@media only screen and (max-width: 767px) {
  .pl-xs-200 {
    padding-left: 200px !important;
  }
}
/*-- Padding Right --*/
.pr-0 {
  padding-right: 0px !important;
}

.pr-5 {
  padding-right: 5px !important;
}

.pr-10 {
  padding-right: 10px !important;
}

.pr-15 {
  padding-right: 15px !important;
}

.pr-20 {
  padding-right: 20px !important;
}

.pr-25 {
  padding-right: 25px !important;
}

.pr-30 {
  padding-right: 30px !important;
}

.pr-35 {
  padding-right: 35px !important;
}

.pr-40 {
  padding-right: 40px !important;
}

.pr-45 {
  padding-right: 45px !important;
}

.pr-50 {
  padding-right: 50px !important;
}

.pr-55 {
  padding-right: 55px !important;
}

.pr-60 {
  padding-right: 60px !important;
}

.pr-65 {
  padding-right: 65px !important;
}

.pr-70 {
  padding-right: 70px !important;
}

.pr-75 {
  padding-right: 75px !important;
}

.pr-80 {
  padding-right: 80px !important;
}

.pr-85 {
  padding-right: 85px !important;
}

.pr-90 {
  padding-right: 90px !important;
}

.pr-95 {
  padding-right: 95px !important;
}

.pr-100 {
  padding-right: 100px !important;
}

.pr-105 {
  padding-right: 105px !important;
}

.pr-110 {
  padding-right: 110px !important;
}

.pr-115 {
  padding-right: 115px !important;
}

.pr-120 {
  padding-right: 120px !important;
}

.pr-125 {
  padding-right: 125px !important;
}

.pr-130 {
  padding-right: 130px !important;
}

.pr-135 {
  padding-right: 135px !important;
}

.pr-140 {
  padding-right: 140px !important;
}

.pr-145 {
  padding-right: 145px !important;
}

.pr-150 {
  padding-right: 150px !important;
}

.pr-155 {
  padding-right: 155px !important;
}

.pr-160 {
  padding-right: 160px !important;
}

.pr-165 {
  padding-right: 165px !important;
}

.pr-170 {
  padding-right: 170px !important;
}

.pr-175 {
  padding-right: 175px !important;
}

.pr-180 {
  padding-right: 180px !important;
}

.pr-185 {
  padding-right: 185px !important;
}

.pr-190 {
  padding-right: 190px !important;
}

.pr-195 {
  padding-right: 195px !important;
}

.pr-200 {
  padding-right: 200px !important;
}

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .pr-lg-0 {
    padding-right: 0px !important;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .pr-lg-5 {
    padding-right: 5px !important;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .pr-lg-10 {
    padding-right: 10px !important;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .pr-lg-15 {
    padding-right: 15px !important;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .pr-lg-20 {
    padding-right: 20px !important;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .pr-lg-25 {
    padding-right: 25px !important;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .pr-lg-30 {
    padding-right: 30px !important;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .pr-lg-35 {
    padding-right: 35px !important;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .pr-lg-40 {
    padding-right: 40px !important;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .pr-lg-45 {
    padding-right: 45px !important;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .pr-lg-50 {
    padding-right: 50px !important;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .pr-lg-55 {
    padding-right: 55px !important;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .pr-lg-60 {
    padding-right: 60px !important;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .pr-lg-65 {
    padding-right: 65px !important;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .pr-lg-70 {
    padding-right: 70px !important;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .pr-lg-75 {
    padding-right: 75px !important;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .pr-lg-80 {
    padding-right: 80px !important;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .pr-lg-85 {
    padding-right: 85px !important;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .pr-lg-90 {
    padding-right: 90px !important;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .pr-lg-95 {
    padding-right: 95px !important;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .pr-lg-100 {
    padding-right: 100px !important;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .pr-lg-105 {
    padding-right: 105px !important;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .pr-lg-110 {
    padding-right: 110px !important;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .pr-lg-115 {
    padding-right: 115px !important;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .pr-lg-120 {
    padding-right: 120px !important;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .pr-lg-125 {
    padding-right: 125px !important;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .pr-lg-130 {
    padding-right: 130px !important;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .pr-lg-135 {
    padding-right: 135px !important;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .pr-lg-140 {
    padding-right: 140px !important;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .pr-lg-145 {
    padding-right: 145px !important;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .pr-lg-150 {
    padding-right: 150px !important;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .pr-lg-155 {
    padding-right: 155px !important;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .pr-lg-160 {
    padding-right: 160px !important;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .pr-lg-165 {
    padding-right: 165px !important;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .pr-lg-170 {
    padding-right: 170px !important;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .pr-lg-175 {
    padding-right: 175px !important;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .pr-lg-180 {
    padding-right: 180px !important;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .pr-lg-185 {
    padding-right: 185px !important;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .pr-lg-190 {
    padding-right: 190px !important;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .pr-lg-195 {
    padding-right: 195px !important;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .pr-lg-200 {
    padding-right: 200px !important;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .pr-md-0 {
    padding-right: 0px !important;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .pr-md-5 {
    padding-right: 5px !important;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .pr-md-10 {
    padding-right: 10px !important;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .pr-md-15 {
    padding-right: 15px !important;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .pr-md-20 {
    padding-right: 20px !important;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .pr-md-25 {
    padding-right: 25px !important;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .pr-md-30 {
    padding-right: 30px !important;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .pr-md-35 {
    padding-right: 35px !important;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .pr-md-40 {
    padding-right: 40px !important;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .pr-md-45 {
    padding-right: 45px !important;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .pr-md-50 {
    padding-right: 50px !important;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .pr-md-55 {
    padding-right: 55px !important;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .pr-md-60 {
    padding-right: 60px !important;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .pr-md-65 {
    padding-right: 65px !important;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .pr-md-70 {
    padding-right: 70px !important;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .pr-md-75 {
    padding-right: 75px !important;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .pr-md-80 {
    padding-right: 80px !important;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .pr-md-85 {
    padding-right: 85px !important;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .pr-md-90 {
    padding-right: 90px !important;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .pr-md-95 {
    padding-right: 95px !important;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .pr-md-100 {
    padding-right: 100px !important;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .pr-md-105 {
    padding-right: 105px !important;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .pr-md-110 {
    padding-right: 110px !important;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .pr-md-115 {
    padding-right: 115px !important;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .pr-md-120 {
    padding-right: 120px !important;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .pr-md-125 {
    padding-right: 125px !important;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .pr-md-130 {
    padding-right: 130px !important;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .pr-md-135 {
    padding-right: 135px !important;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .pr-md-140 {
    padding-right: 140px !important;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .pr-md-145 {
    padding-right: 145px !important;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .pr-md-150 {
    padding-right: 150px !important;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .pr-md-155 {
    padding-right: 155px !important;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .pr-md-160 {
    padding-right: 160px !important;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .pr-md-165 {
    padding-right: 165px !important;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .pr-md-170 {
    padding-right: 170px !important;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .pr-md-175 {
    padding-right: 175px !important;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .pr-md-180 {
    padding-right: 180px !important;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .pr-md-185 {
    padding-right: 185px !important;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .pr-md-190 {
    padding-right: 190px !important;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .pr-md-195 {
    padding-right: 195px !important;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .pr-md-200 {
    padding-right: 200px !important;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pr-sm-0 {
    padding-right: 0px !important;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pr-sm-5 {
    padding-right: 5px !important;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pr-sm-10 {
    padding-right: 10px !important;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pr-sm-15 {
    padding-right: 15px !important;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pr-sm-20 {
    padding-right: 20px !important;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pr-sm-25 {
    padding-right: 25px !important;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pr-sm-30 {
    padding-right: 30px !important;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pr-sm-35 {
    padding-right: 35px !important;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pr-sm-40 {
    padding-right: 40px !important;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pr-sm-45 {
    padding-right: 45px !important;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pr-sm-50 {
    padding-right: 50px !important;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pr-sm-55 {
    padding-right: 55px !important;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pr-sm-60 {
    padding-right: 60px !important;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pr-sm-65 {
    padding-right: 65px !important;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pr-sm-70 {
    padding-right: 70px !important;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pr-sm-75 {
    padding-right: 75px !important;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pr-sm-80 {
    padding-right: 80px !important;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pr-sm-85 {
    padding-right: 85px !important;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pr-sm-90 {
    padding-right: 90px !important;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pr-sm-95 {
    padding-right: 95px !important;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pr-sm-100 {
    padding-right: 100px !important;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pr-sm-105 {
    padding-right: 105px !important;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pr-sm-110 {
    padding-right: 110px !important;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pr-sm-115 {
    padding-right: 115px !important;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pr-sm-120 {
    padding-right: 120px !important;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pr-sm-125 {
    padding-right: 125px !important;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pr-sm-130 {
    padding-right: 130px !important;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pr-sm-135 {
    padding-right: 135px !important;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pr-sm-140 {
    padding-right: 140px !important;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pr-sm-145 {
    padding-right: 145px !important;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pr-sm-150 {
    padding-right: 150px !important;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pr-sm-155 {
    padding-right: 155px !important;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pr-sm-160 {
    padding-right: 160px !important;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pr-sm-165 {
    padding-right: 165px !important;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pr-sm-170 {
    padding-right: 170px !important;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pr-sm-175 {
    padding-right: 175px !important;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pr-sm-180 {
    padding-right: 180px !important;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pr-sm-185 {
    padding-right: 185px !important;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pr-sm-190 {
    padding-right: 190px !important;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pr-sm-195 {
    padding-right: 195px !important;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pr-sm-200 {
    padding-right: 200px !important;
  }
}
@media only screen and (max-width: 767px) {
  .pr-xs-0 {
    padding-right: 0px !important;
  }
}
@media only screen and (max-width: 767px) {
  .pr-xs-5 {
    padding-right: 5px !important;
  }
}
@media only screen and (max-width: 767px) {
  .pr-xs-10 {
    padding-right: 10px !important;
  }
}
@media only screen and (max-width: 767px) {
  .pr-xs-15 {
    padding-right: 15px !important;
  }
}
@media only screen and (max-width: 767px) {
  .pr-xs-20 {
    padding-right: 20px !important;
  }
}
@media only screen and (max-width: 767px) {
  .pr-xs-25 {
    padding-right: 25px !important;
  }
}
@media only screen and (max-width: 767px) {
  .pr-xs-30 {
    padding-right: 30px !important;
  }
}
@media only screen and (max-width: 767px) {
  .pr-xs-35 {
    padding-right: 35px !important;
  }
}
@media only screen and (max-width: 767px) {
  .pr-xs-40 {
    padding-right: 40px !important;
  }
}
@media only screen and (max-width: 767px) {
  .pr-xs-45 {
    padding-right: 45px !important;
  }
}
@media only screen and (max-width: 767px) {
  .pr-xs-50 {
    padding-right: 50px !important;
  }
}
@media only screen and (max-width: 767px) {
  .pr-xs-55 {
    padding-right: 55px !important;
  }
}
@media only screen and (max-width: 767px) {
  .pr-xs-60 {
    padding-right: 60px !important;
  }
}
@media only screen and (max-width: 767px) {
  .pr-xs-65 {
    padding-right: 65px !important;
  }
}
@media only screen and (max-width: 767px) {
  .pr-xs-70 {
    padding-right: 70px !important;
  }
}
@media only screen and (max-width: 767px) {
  .pr-xs-75 {
    padding-right: 75px !important;
  }
}
@media only screen and (max-width: 767px) {
  .pr-xs-80 {
    padding-right: 80px !important;
  }
}
@media only screen and (max-width: 767px) {
  .pr-xs-85 {
    padding-right: 85px !important;
  }
}
@media only screen and (max-width: 767px) {
  .pr-xs-90 {
    padding-right: 90px !important;
  }
}
@media only screen and (max-width: 767px) {
  .pr-xs-95 {
    padding-right: 95px !important;
  }
}
@media only screen and (max-width: 767px) {
  .pr-xs-100 {
    padding-right: 100px !important;
  }
}
@media only screen and (max-width: 767px) {
  .pr-xs-105 {
    padding-right: 105px !important;
  }
}
@media only screen and (max-width: 767px) {
  .pr-xs-110 {
    padding-right: 110px !important;
  }
}
@media only screen and (max-width: 767px) {
  .pr-xs-115 {
    padding-right: 115px !important;
  }
}
@media only screen and (max-width: 767px) {
  .pr-xs-120 {
    padding-right: 120px !important;
  }
}
@media only screen and (max-width: 767px) {
  .pr-xs-125 {
    padding-right: 125px !important;
  }
}
@media only screen and (max-width: 767px) {
  .pr-xs-130 {
    padding-right: 130px !important;
  }
}
@media only screen and (max-width: 767px) {
  .pr-xs-135 {
    padding-right: 135px !important;
  }
}
@media only screen and (max-width: 767px) {
  .pr-xs-140 {
    padding-right: 140px !important;
  }
}
@media only screen and (max-width: 767px) {
  .pr-xs-145 {
    padding-right: 145px !important;
  }
}
@media only screen and (max-width: 767px) {
  .pr-xs-150 {
    padding-right: 150px !important;
  }
}
@media only screen and (max-width: 767px) {
  .pr-xs-155 {
    padding-right: 155px !important;
  }
}
@media only screen and (max-width: 767px) {
  .pr-xs-160 {
    padding-right: 160px !important;
  }
}
@media only screen and (max-width: 767px) {
  .pr-xs-165 {
    padding-right: 165px !important;
  }
}
@media only screen and (max-width: 767px) {
  .pr-xs-170 {
    padding-right: 170px !important;
  }
}
@media only screen and (max-width: 767px) {
  .pr-xs-175 {
    padding-right: 175px !important;
  }
}
@media only screen and (max-width: 767px) {
  .pr-xs-180 {
    padding-right: 180px !important;
  }
}
@media only screen and (max-width: 767px) {
  .pr-xs-185 {
    padding-right: 185px !important;
  }
}
@media only screen and (max-width: 767px) {
  .pr-xs-190 {
    padding-right: 190px !important;
  }
}
@media only screen and (max-width: 767px) {
  .pr-xs-195 {
    padding-right: 195px !important;
  }
}
@media only screen and (max-width: 767px) {
  .pr-xs-200 {
    padding-right: 200px !important;
  }
}

/*-----------------------------------------------------------------------------------

    Template Name: FastBuy - Mega Shop eCommerce Bootstrap 4 Template
    Version: 1.0

-----------------------------------------------------------------------------------

    CSS INDEX
    ===================

    01. Theme Default CSS
        01.1 Common Style
        01.2 Common Classes
        01.3 Input Placeholder
        01.4 Background Color
        01.5 Tab Content & Pane Fix
        01.6 Nice Select Customize
        01.7 FB's Section Title
        01.8 FB's Breadcrumb
        01.9 FB's Owl Carousel Navigation
        1.20 FB's Dot Direction
        1.21 FB's Default Button
        1.22 FB's Image Hover Effect
        1.23 FB's Background Image
        1.24 FB's Animation & Keyframes
        1.25 FB's Countdown
        1.26 FB's Mobile Menu Customization
        1.27 FB's Modal Area
        1.28 FB's Header Sticky
        1.29 FB's Scroll Up
    02. Home Page One
        02.1 Header
        02.2 Slider
        02.3 FB's Customer Support
        02.4 FB's Product Area
        02.5 FB's Banner With List Product
        02.6 FB's Banner With New Product
        02.7 FB's Branding
        02.8 FB's Footer
    03. Home Page Two
    04. Home Page Three
    05. Home Page Four
    06. FB's Shop Page
    07. FB's Frequently Asked Question(FAQ)
    08. FB's Compare Page
    09. FB's Login Register
    10. FB's Blog
    11. FB's Error 404 Page
    12. FB's Product Details
    13. FB's Wishlist Page
    14. FB's Checkout Page
    15. FB's Cart
    16 FB's Contact Page
    17. FB's About Us

-----------------------------------------------------------------------------------*/
/*----------------------------------------*/
/*  01. Theme Default CSS
/*----------------------------------------*/
/*-- Google Font --*/
@import url('https://fonts.googleapis.com/css?family=Open+Sans:300,300i|Poppins:100,100i,200,300,300i,400,400i,500,500i,600i,700,700i,800,800i,900,900i');
/*----------------------------------------*/
/*  01.1 Common Style
/*----------------------------------------*/
body {
  background-color: #f1f1f1;
  font-size: 16px;
  line-height: 28px;
  font-style: normal;
  font-weight: 400;
  visibility: visible;
  font-family: 'Poppins', sans-serif;
  color: #222222;
  position: relative;
  overflow-x: hidden;
}

[class*='col-'] {
  padding-right: 7px !important;
  padding-left: 7px !important;
}

@media only screen and (max-width: 1080px) {
  .hm-minicart:first-child {
    padding-left: 0 !important;
  }
  .nav-item-text {
    font-size: 13px !important;
  }
}

@media (min-width: 992px) {
  .container {
    max-width: 1080px;
  }
}

@media (max-width: 991px) {
  .header-middle-right {
    display: flex;
    flex-flow: column;
  }
  .hm-searchbox {
    min-width: 100% !important;
    max-width: 100% !important;
  }
  .hm-menu {
    display: flex !important;
    text-align: center !important;
    margin-top: 20px;
    padding: unset !important;
    justify-content: space-around !important;
  }
  .hm-minicart {
    margin: unset !important;
  }
  .hm-minicart:first-child {
    padding-left: 0 !important;
  }
  .hm-minicart:last-child {
    padding-right: 0 !important;
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Poppins', sans-serif;
  color: #222222;
  font-weight: 400;
  margin-top: 0;
  line-height: 1.2;
}
h1 {
  font-size: 36px;
}
@media only screen and (max-width: 767px) {
  h1 {
    font-size: 32px;
  }
}
@media only screen and (max-width: 575px) {
  h1 {
    font-size: 30px;
  }
}
@media only screen and (max-width: 479px) {
  h1 {
    font-size: 24px;
  }
}
h2 {
  font-size: 30px;
}
@media only screen and (max-width: 767px) {
  h2 {
    font-size: 26px;
  }
}
@media only screen and (max-width: 575px) {
  h2 {
    font-size: 24px;
  }
}
@media only screen and (max-width: 479px) {
  h2 {
    font-size: 18px;
  }
}
h3 {
  font-size: 24px;
}
@media only screen and (max-width: 767px) {
  h3 {
    font-size: 20px;
  }
}
@media only screen and (max-width: 575px) {
  h3 {
    font-size: 18px;
  }
}
@media only screen and (max-width: 479px) {
  h3 {
    font-size: 16px;
  }
}
h4 {
  font-size: 18px;
}
@media only screen and (max-width: 767px) {
  h4 {
    font-size: 16px;
  }
}
@media only screen and (max-width: 479px) {
  h4 {
    font-size: 14px;
  }
  h5 {
    font-size: 14px;
  }

  h6 {
    font-size: 12px;
  }
}
p:last-child {
  margin-bottom: 0;
}
a,
button {
  color: inherit;
  display: inline-block;
  line-height: inherit;
  text-decoration: none;
  cursor: pointer;
}
a,
button,
img,
input,
span {
  transition: all 0.3s ease 0s;
}
*:focus {
  outline: none !important;
}
a:focus {
  color: inherit;
  outline: none;
  text-decoration: none;
}
a:hover {
  text-decoration: none;
  color: #e23e57;
}
button,
input[type='submit'] {
  cursor: pointer;
}
ul {
  list-style: outside none none;
  margin: 0;
  padding: 0;
}
img {
  max-width: 100%;
}
.img-full {
  width: 100%;
  overflow: hidden;
}
iframe {
  width: 100%;
  height: 300px;
}
/*----------------------------------------*/
/*  01.2 Common Classes
/*----------------------------------------*/
.fix {
  overflow: hidden;
}
.hidden {
  display: none;
}
.clear {
  clear: both;
}
.section,
.main-wrapper {
  float: left;
  width: 100%;
}

.home-bg {
  height: 400px;
  background-image: url(https://ibuilt-eu-production.s3.eu-central-1.amazonaws.com/homepage-hero-image.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.contact-us-bg {
  height: 400px;
  background-image: url(/_next/static/media/contact-us.cf466297.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.home-bg.electrical-bg {
  background-image: url('/images/Electricity.jpg');
}

.product-img img {
  max-height: 320px;
  min-height: 275px;
}

#list-view .product-img img {
  max-height: none;
}

.breadcrumb-area .product-category {
  display: flex;
  align-items: center;
  background: white;
  cursor: pointer;
  height: 100%;
  border: 1px solid #c3c3c3;
  border-radius: 3px;
  padding: 10px 25px 10px 10px;
}

.breadcrumb-area #product-category-href {
  margin-bottom: 10px;
}

.breadcrumb-area .product-category:hover {
  border: 1px solid #828282;
}

.breadcrumb-area .product-category .product-category-img {
  width: 40px;
  height: 40px;
  margin-right: 15px;
}

.breadcrumb-area .cover-img {
  min-height: 300px;
  max-height: 350px;
  width: 100%;
  object-fit: cover;
}

/* .cover-img-col {
  padding-left: 5px;
  padding-right: 5px;
} */

.category-owl .owl-next {
  margin-right: 20px;
}

.category-owl .owl-next,
.category-owl .owl-prev {
  margin-top: 5px;
}

.breadcrumb-area .product-category h3 {
  font-size: 16px;
  margin-top: 10px;
  text-align: center;
  font-weight: 300;
}

@media (max-width: 1200px) {
  .owl-pt-50 {
    padding-top: 50px !important;
  }
}

.category-owl .owl-item img {
  width: 90px !important;
  height: 90px !important;
  min-height: 90px !important;
  object-fit: cover !important;
}

@media (max-width: 767px) {
  .product-img img {
    max-height: none;
  }
}

@media (max-width: 767px) {
  .home-bg {
    height: 300px;
  }
}

@media (max-width: 500px) {
  .home-bg {
    height: 250px;
  }
}

@media (min-width: 1200px) {
  .container {
    max-width: 1170px;
  }
}
@media only screen and (max-width: 575px) {
  .container {
    max-width: 450px;
  }
}
@media only screen and (max-width: 479px) {
  .container {
    max-width: 400px;
  }
}
.no-gutters {
  margin-left: 0;
  margin-right: 0;
}
.no-gutters > .col,
.no-gutters > [class*='col-'] {
  padding-right: 0;
  padding-left: 0;
  margin: 0;
}
/*----------------------------------------*/
/*  01.3 Input Placeholder
/*----------------------------------------*/
input::-webkit-input-placeholder,
textarea::-webkit-input-placeholder {
  opacity: 1;
  -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=100)';
}
input:-ms-input-placeholder,
textarea:-ms-input-placeholder {
  opacity: 1;
  -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=100)';
}
input::-webkit-input-placeholder, textarea::-webkit-input-placeholder {
  opacity: 1;
  -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=100)';
}
input:-ms-input-placeholder, textarea:-ms-input-placeholder {
  opacity: 1;
  -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=100)';
}
input::placeholder,
textarea::placeholder {
  opacity: 1;
  -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=100)';
}
/*----------------------------------------*/
/*  01.4 Background Color
/*----------------------------------------*/
.bg-white {
  background-color: #ffffff !important;
}
.bg-gray {
  background-color: #f8f8f8 !important;
}
.bg-dark {
  background-color: #222222 !important;
}
.bg-theme {
  background-color: #d0a97e !important;
}
.bg-midnight {
  background-color: #232f3e !important;
}
.bg-polo-blue {
  background-color: #303d4e !important;
}
.bg-white-smoke {
  background-color: #f1f1f1 !important;
}
.bg-royal-blue {
  background: #2874f0 !important;
}
.bg-dark-grayishblue {
  background: #192431;
}
/*----------------------------------------*/
/*  01.5 Tab Content & Pane Fix
/*----------------------------------------*/
.tab-content {
  width: 100%;
}
.tab-content .tab-pane {
  display: block;
  height: 0;
  max-width: 100%;
  visibility: hidden;
  overflow: hidden;
  opacity: 0;
}
.tab-content .tab-pane.active {
  height: auto;
  visibility: visible;
  opacity: 1;
  /* overflow: visible; */
}
@media (max-width: 460px) {
  .customOwl .owl-item {
    width: 300px;
  }
}
/*----------------------------------------*/
/*  01.6 Nice Select Customize
/*----------------------------------------*/
/*nice Select Style One*/
.nice-select .option,
.nice-select .option.focus,
.nice-select .option.selected.focus {
  color: #333333;
}
.nice-select .option.selected.focus {
  background: #e23e57;
  color: #ffffff;
}

.nice-select .list:hover .option:not(:hover),
.nice-select-2 .list:hover .option:not(:hover) {
  color: #333333;
}
.nice-select .option:hover,
.nice-select .option.focus:hover,
.nice-select .option.selected.focus:hover {
  background-color: #e23e57;
  color: #ffffff;
}
/*----------------------------------------*/
/*  01.7 FB's Section Title
/*----------------------------------------*/
.fb-section_title,
.fb-section_title-2 {
  position: relative;
  display: block;
}
.fb-section_title > h2 {
  padding: 6px 0;
  padding-top: 0;
  color: #2c2c2c;
  font-size: 24px;
  text-transform: uppercase;
  font-weight: 600;
  margin-bottom: 0;
}
/*FB's Section Title Two*/
.fb-section_title-2 > h2 {
  padding-left: 70px;
  min-width: auto;
  display: block;
  background: #303d4e;
  color: #ffffff;
  height: 54px;
  line-height: 55px;
  width: 100%;
  font-size: 18px;
  margin: -2px 0 -1px;
  font-weight: 600;
}
.single-slide-wrap-2 .fb-section_title-2 > h2 {
  padding-left: 45px;
}
.fb-section_title > h2::before {
  content: '\f2ab';
  font-family: 'Ionicons';
  font-size: 24px;
  margin-right: 5px;
}
.fb-section_title-2 > h2::before {
  content: '\f1f8';
  font-family: fontawesome;
  background: #232f3e;
  color: #ffffff;
  height: 55px;
  width: 55px;
  position: absolute;
  left: 0;
  top: 0px;
  text-align: center;
  line-height: 55px;
  font-size: 24px;
  margin: -2px 0;
}
.large-appliances_product .fb-section_title-2 > h2::before {
  content: '\f26c';
}
.small-appliances_product .fb-section_title-2 > h2::before {
  content: '\f0f5';
}
.drinkware_product .fb-section_title-2 > h2::before {
  content: '\f000';
}
/*FB's Section Title Three*/
.fb-section_title-3 > h2::before {
  content: '\f349';
}
.fb-section_title-3 > h2,
.fb-section_title-4 > h2 {
  margin: 0;
}
/*FB's Section Title Three*/
.fb-section_title-4 > h2::before {
  content: '\f356';
}
/*----------------------------------------*/
/*  1.8 FB's Sticker
/*----------------------------------------*/
.sticker,
.sticker-2 {
  position: absolute;
  top: 0;
  background: #94c341;
  min-width: 50px;
  height: 25px;
  line-height: 25px;
  text-align: center;
  color: #ffffff;
  font-size: 12px;
  font-weight: 400;
  border-radius: 3px;
  left: 0;
  transition: all 0.3s ease-in-out;
}
.sticker-2 {
  right: 0;
  left: auto;
  background: #e23e57;
}
.fb-featured-pro_with_banner .sticker-2,
.hot-deals-product .sticker-2 {
  background: #ff6d02;
}
/*----------------------------------------*/
/*  01.8 FB's Breadcrumb
/*----------------------------------------*/
.breadcrumb-content {
  padding: 5px 0;
}
.breadcrumb-content li {
  display: inline-block;
  font-size: 16px;
  margin-right: 10px;
  position: relative;
}
.breadcrumb-content li:first-child {
  margin-right: 15px;
}

/* @media (max-width: 991px) {
  .slick-track {
    width: 95% !important;
    margin: 0 auto !important;
  }
} */

.breadcrumb-content li.active {
  color: #e23e57;
}

@media (max-width: 1270px) {
  .breadcrumb-content li {
    font-size: 13px;
  }
}

.breadcrumb-content li a {
  color: #333333;
  transition: all 0.3s ease-in-out;
}
.active-breadcrumb a {
  color: #e23e57 !important;
}
.breadcrumb-content li a:hover {
  color: #e23e57;
}
.breadcrumb-content li::before {
  content: '\f125';
  position: absolute;
  right: -9px;
  top: 1px;
  font-family: 'Ionicons';
  font-size: 8px;
}
.breadcrumb-content li:last-child::before {
  display: none;
}
.header-middle .logo img {
  width: 100%;
  max-width: 240px;
}
/*----------------------------------------*/
/*  01.9 FB's Owl Carousel Navigation
/*----------------------------------------*/
.slider-active .owl-nav div,
.fb-product_active .owl-nav div,
.fb-list_product_active .owl-nav div,
.fb-list_product_active-2 .owl-nav div,
.fb-new_product_active .owl-nav div,
.new-product_active-2 .owl-nav div,
.fb-branding_active .owl-nav div,
.fb-single-product_active .owl-nav div,
.fb-product_active-3 .owl-nav div,
.fb-product_active-4 .owl-nav div,
.featured-product-active-4 .owl-nav div,
.fb-product-list_active-3 .owl-nav div,
.latest-blog_active .owl-nav div,
.fb-other-product_active .owl-nav div {
  color: rgba(36, 36, 36, 0.15);
  display: block;
  left: 0;
  opacity: 0;
  position: absolute;
  text-align: center;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  transition: all 0.5s ease-in-out 0s;
  z-index: 8;
  background: rgba(51, 51, 51, 0.3);
  width: 40px;
  height: 40px;
  line-height: 40px;
  border-radius: 50%;
}
.slider-active .owl-nav div:hover,
.fb-branding_active .owl-nav div:hover {
  background: #333333;
}
.slider-active:hover .owl-nav div,
.fb-branding_active:hover .owl-nav div {
  opacity: 1;
}
.slider-active:hover .owl-nav div.owl-prev {
  left: 10px;
}
.slider-active:hover .owl-nav div.owl-next {
  right: 10px;
}
.slider-active .owl-nav div.owl-next,
.fb-branding_active .owl-nav div.owl-next {
  right: 0;
  left: auto;
}
.slider-active .owl-nav > .owl-prev > i,
.slider-active .owl-nav > .owl-next > i {
  font-size: 18px;
  color: #ffffff;
}
/*FB's Product Navigation*/
.fb-product_active .owl-nav div,
.fb-list_product_active .owl-nav div,
.fb-list_product_active-2 .owl-nav div,
.fb-new_product_active .owl-nav div,
.new-product_active-2 .owl-nav div,
.fb-single-product_active .owl-nav div,
.fb-product_active-3 .owl-nav div,
.fb-product_active-4 .owl-nav div,
.featured-product-active-4 .owl-nav div,
.fb-product-list_active-3 .owl-nav div,
.latest-blog_active .owl-nav div,
.fb-other-product_active .owl-nav div {
  opacity: 1;
  top: 0;
  background: #2c2c2c;
  color: #ffffff;
  border-radius: 5px;
  height: 30px;
  line-height: 30px;
  width: 30px;
  -webkit-transform: translate(0, -46px);
          transform: translate(0, -46px);
}
.fb-list_product_active .owl-nav div,
.fb-list_product_active-2 .owl-nav div {
  -webkit-transform: translate(-20px, -44px);
          transform: translate(-20px, -44px);
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .fb-list_product_active .owl-nav div {
    -webkit-transform: translate(0, -99px);
            transform: translate(0, -99px);
  }
}
@media only screen and (max-width: 991px) {
  .fb-list_product_active .owl-nav div {
    -webkit-transform: translate(-20px, -35px);
            transform: translate(-20px, -35px);
    width: 25px;
    height: 25px;
    line-height: 25px;
  }
}
/*New product 2*/
.new-product_active-2 .owl-nav div {
  -webkit-transform: translate(-15px, -42px);
          transform: translate(-15px, -42px);
}
@media only screen and (max-width: 991px) {
  .new-product_active-2 .owl-nav div {
    -webkit-transform: translate(-15px, -102px);
            transform: translate(-15px, -102px);
  }
}
/*FB's Product Active*/
@media only screen and (max-width: 991px) {
  .fb-product_active .owl-nav div {
    -webkit-transform: translate(0, -45px);
            transform: translate(0, -45px);
  }
}
@media only screen and (max-width: 767px) {
  .fb-product_active .owl-nav div {
    display: none;
  }
}
.fb-product_active .owl-nav div.owl-prev,
.fb-list_product_active .owl-nav div.owl-prev,
.fb-list_product_active-2 .owl-nav div.owl-prev,
.fb-new_product_active .owl-nav div.owl-prev,
.new-product_active-2 .owl-nav div.owl-prev,
.fb-single-product_active .owl-nav div.owl-prev,
.fb-product-list_active-3 .owl-nav div.owl-prev,
.fb-product_active-4 .owl-nav div.owl-prev,
.featured-product-active-4 .owl-nav div.owl-prev,
.latest-blog_active .owl-nav div.owl-prev,
.fb-other-product_active .owl-nav div.owl-prev {
  left: auto;
  right: 35px;
}
.fb-product_active .owl-nav div.owl-next,
.fb-list_product_active .owl-nav div.owl-next,
.fb-list_product_active-2 .owl-nav div.owl-next,
.fb-new_product_active .owl-nav div.owl-next,
.new-product_active-2 .owl-nav div.owl-next,
.fb-single-product_active .owl-nav div.owl-next,
.fb-product-list_active-3 .owl-nav div.owl-next,
.fb-product_active-4 .owl-nav div.owl-next,
.featured-product-active-4 .owl-nav div.owl-next,
.latest-blog_active .owl-nav div.owl-next,
.fb-other-product_active .owl-nav div.owl-next {
  left: auto;
  right: 0;
}
.fb-product_active .owl-nav div.owl-prev:hover,
.fb-product_active .owl-nav div.owl-next:hover,
.fb-list_product_active .owl-nav div.owl-prev:hover,
.fb-list_product_active .owl-nav div.owl-next:hover,
.fb-new_product_active .owl-nav div.owl-prev:hover,
.fb-new_product_active .owl-nav div.owl-next:hover,
.fb-branding_active .owl-nav div.owl-prev:hover,
.fb-branding_active .owl-nav div.owl-next:hover,
.fb-product_active-4 .owl-nav div.owl-prev:hover,
.fb-product_active-4 .owl-nav div.owl-next:hover,
.featured-product-active-4 .owl-nav div.owl-prev:hover,
.featured-product-active-4 .owl-nav div.owl-next:hover,
.latest-blog_active .owl-nav div.owl-prev:hover,
.latest-blog_active .owl-nav div.owl-next:hover,
.fb-other-product_active .owl-nav div.owl-prev:hover,
.fb-other-product_active .owl-nav div.owl-next:hover {
  background: #e23e57;
}
.fb-new_product_active .owl-nav div {
  -webkit-transform: translate(20px, -235px);
          transform: translate(20px, -235px);
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .fb-new_product_active .owl-nav div {
    -webkit-transform: translate(20px, -205px);
            transform: translate(20px, -205px);
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .fb-new_product_active .owl-nav div {
    -webkit-transform: translate(20px, -281px);
            transform: translate(20px, -281px);
  }
}
/*FB's Branding Navigation*/
.fb-branding_active .owl-nav div {
  background: #2c2c2c;
  color: #ffffff;
  border-radius: 5px;
  height: 30px;
  line-height: 30px;
  width: 30px;
  top: 37%;
}
.fb-branding_active .owl-nav div {
  -webkit-transform: scale(0);
          transform: scale(0);
}
.fb-branding_active:hover .owl-nav div {
  -webkit-transform: scale(1);
          transform: scale(1);
}
/*----------------------------------------*/
/*  1.20 FB's Dot Direction
/*----------------------------------------*/
.owl-dots {
  bottom: -55px;
  left: 0;
  position: absolute;
  right: 0;
  text-align: center;
}
.owl-dot {
  background: black;
  display: inline-block;
  height: 10px;
  border-radius: 10px;
  font-size: 0;
  width: 10px;
  margin: 0 4px;
  text-align: center;
  cursor: pointer;
  position: relative;
  z-index: 8;
  transition: all 0.3s ease-in-out;
}
.owl-dot.active,
.slider-area-2 .owl-dot.active {
  background: #e23e57 none repeat scroll 0 0;
  width: 32px;
}
.slider-area-2 .owl-dot.active {
  background: #ff6d02;
}
.slider-area-3 .owl-dot.active {
  background: #fdda1b;
}
.owl-dot:hover,
.slider-area-2 .owl-dot:hover {
  background: #e23e57 none repeat scroll 0 0;
}
.slider-area-2 .owl-dot:hover {
  background: #ff6d02;
}
.slider-area-3 .owl-dot:hover {
  background: #fdda1b;
}
/*----------------------------------------*/
/*  1.21 FB's Default Button
/*----------------------------------------*/
/*Button Group*/
.fb-btn {
  border: none;
  font-size: 14px;
  color: #ffffff !important;
  position: relative;
  background: #232f3e;
  cursor: pointer;
  font-weight: 500;
  text-transform: capitalize;
  height: 45px;
  line-height: 45px;
  width: 136px;
  text-align: center;
  border-radius: 2px;
  transition: all 0.3s ease-in-out;
}
.fb-btn:hover {
  background: #e23e57;
  color: #ffffff;
}
/*FB's Search Button*/
.fb-search_btn {
  position: absolute;
  top: 6px;
  right: 6px;
  height: 36px;
  line-height: 36px;
  text-align: center;
  width: 36px;
  text-align: center;
  color: #2c2c2c;
  background: #febd69;
  padding: 0;
  border: 0;
  font-size: 16px;
  border-radius: 100%;
  transition: all 300ms ease-in;
}
.fb-search_btn:hover {
  background: #e23e57;
  color: #ffffff;
}
.fb-links,
.fb-links-round {
  background: #ffffff;
  width: 120px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  transition: all 0.3s ease-in-out;
}
.fb-links-round {
  border-radius: 3px;
}
.fb-links:hover {
  background: #e23e57;
  color: #ffffff;
}
/*FB's Default Button Sizes/*/
.btn-xs {
  font-size: 11px;
  height: 25px;
  padding: 0 8px;
}
.btn-xs i {
  font-size: 15px;
  margin-right: 5px;
}
.btn-xs .btn-icon-right i {
  margin-left: 5px;
  margin-right: 0;
}
.btn-xs.btn-box {
  width: 26px;
  padding: 1px 0;
}
.btn-xs.btn-box i {
  margin: 0;
}
.btn-sm {
  font-size: 13px;
  height: 32px;
  padding: 2px 13px;
}
.btn-sm i {
  font-size: 19px;
  margin-right: 8px;
}
.btn-sm .btn-icon-right i {
  margin-left: 8px;
  margin-right: 0;
}
.btn-sm.btn-box {
  width: 32px;
  padding: 3px 0;
}
.btn-sm.btn-box i {
  margin: 0;
}
.btn-lg {
  font-size: 18px;
  height: 46px;
  padding: 9px 30px;
}
.btn-lg i {
  font-size: 24px;
}
.btn-lg.btn-box {
  width: 44px;
  padding: 9px 0;
}
.btn-lg.btn-box i {
  margin: 0;
}
.btn-xl {
  font-size: 19px;
  height: 50px;
  padding: 11px 31px;
}
.btn-xl i {
  font-size: 24px;
}
.btn-xl.btn-box {
  width: 50px;
  padding: 12px 0;
}
.btn-xl.btn-box i {
  margin: 0;
}
/*----------------------------------------*/
/*  1.22 FB's Image Hover Effect
/*----------------------------------------*/
.fb-img-hover-effect {
  position: relative;
  display: block;
}
.fb-img-hover-effect a {
  overflow: hidden;
  display: block;
}
.fb-img-hover-effect a::before {
  background: rgba(255, 255, 255, 0.2);
  bottom: 0;
  content: '';
  left: 50%;
  position: absolute;
  right: 51%;
  top: 0;
  opacity: 1;
}
.fb-img-hover-effect:hover a::before {
  left: 0;
  right: 0;
  opacity: 0;
  transition: all 900ms ease-in;
}
.fb-img-hover-effect a::after {
  background: rgba(255, 255, 255, 0.2);
  bottom: 50%;
  content: '';
  left: 0;
  position: absolute;
  right: 0;
  top: 50%;
  opacity: 1;
}
.fb-img-hover-effect:hover a::after {
  top: 0;
  bottom: 0;
  opacity: 0;
  transition: all 900ms ease-in;
}
.fb-img-hover-effect a img {
  width: 100%;
  height: 180px;
}
/*----------------------------------------*/
/*  1.23 FB's Background Image
/*----------------------------------------*/
.bg-1,
.bg-2 {
  background-image: url('/assets/images/slider/1_1.jpg');
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  min-height: 506px;
  width: 100%;
}
.bg-2 {
  background-image: url('/assets/images/slider/1_2.jpg');
}
@media only screen and (max-width: 1199px) and (min-width: 992px) {
  .bg-1,
  .bg-2 {
    min-height: 410px;
  }
}
@media only screen and (max-width: 767px) {
  .bg-1,
  .bg-2 {
    min-height: 330px;
  }
}
/*Home Two | Slider Background Image*/
.bg-3,
.bg-4 {
  background-image: url('/assets/images/slider/2_1.jpg');
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  min-height: 506px;
  width: 100%;
}
.bg-4 {
  background-image: url('/assets/images/slider/2_2.jpg');
}
@media only screen and (max-width: 767px) {
  .bg-3,
  .bg-4 {
    min-height: 330px;
  }
}
/*Home Three | Slider Background Image*/
.bg-5,
.bg-6 {
  background-image: url('/assets/images/slider/3_1.jpg');
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  min-height: 526px;
  width: 100%;
}
.bg-6 {
  background-image: url('/assets/images/slider/3_2.jpg');
}
@media only screen and (max-width: 767px) {
  .bg-5,
  .bg-6 {
    min-height: 330px;
  }
}
/*Home Three | Slider Background Image*/
.bg-7,
.bg-8 {
  background-image: url('/assets/images/slider/4_1.jpg');
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  min-height: 518px;
  width: 100%;
}
.bg-8 {
  background-image: url('/assets/images/slider/4_2.jpg');
}
@media only screen and (max-width: 1199px) and (min-width: 992px) {
  .bg-7,
  .bg-8 {
    min-height: 410px;
  }
}
@media only screen and (max-width: 767px) {
  .bg-7,
  .bg-8 {
    min-height: 325px !important;
  }
}
/*----------------------------------------*/
/*  1.24 FB's Animation & Keyframes
/*----------------------------------------*/
/* Slider Progress Bar */
.slider-active .owl-item.active .slider-progress {
  -webkit-animation: 10000ms ease-in-out 1 running timebar;
  animation: 10000ms ease-in-out 1 running timebar;
  background: rgba(0, 0, 0, 0.3);
  height: 5px;
  left: 0;
  opacity: 0.8;
  position: absolute;
  top: 0;
  z-index: 4;
}
/* animation-style-01 */
.owl-item.active .animation-style-01 .slider-content h5,
.owl-item.active .animation-style-01 .slider-content-2 h5 {
  -webkit-animation: 800ms ease-in-out 0s normal none 1 running zoomInRight;
  animation: 800ms ease-in-out 0s normal none 1 running zoomInRight;
}
.owl-item.active .animation-style-01 .slider-content h2 {
  -webkit-animation: 1200ms ease-in-out 0s normal none 1 running zoomInRight;
  animation: 1200ms ease-in-out 0s normal none 1 running zoomInRight;
}
.owl-item.active .animation-style-01 .slider-content h3,
.owl-item.active .animation-style-01 .slider-content-2 h3 {
  -webkit-animation: 1600ms ease-in-out 0s normal none 1 running zoomInRight;
  animation: 1600ms ease-in-out 0s normal none 1 running zoomInRight;
}

.owl-item.active .animation-style-01 .slider-content .slide-btn,
.owl-item.active .animation-style-01 .slider-content-2 .slide-btn {
  -webkit-animation: 2000ms ease-in-out 0s normal none 1 running zoomInRight;
  animation: 2000ms ease-in-out 0s normal none 1 running zoomInRight;
}
.owl-item.active .animation-style-01 .slider-content-2 .img-slide {
  -webkit-animation: 1000ms ease-in-out 0s normal none 1 running slideInUp;
  animation: 1000ms ease-in-out 0s normal none 1 running slideInUp;
}
.owl-item.active .animation-style-02 .slider-content-2 .img-slide {
  -webkit-animation: 1000ms ease-in-out 0s normal none 1 running slideInUp;
  animation: 1000ms ease-in-out 0s normal none 1 running slideInUp;
}
/* animation-style-02 */
.owl-item.active .animation-style-02 .slider-content h5,
.owl-item.active .animation-style-02 .slider-content-2 h5 {
  -webkit-animation: 800ms ease-in-out 0s normal none 1 running zoomInUp;
  animation: 800ms ease-in-out 0s normal none 1 running zoomInUp;
}
.owl-item.active .animation-style-02 .slider-content h2 {
  -webkit-animation: 1200ms ease-in-out 0s normal none 1 running zoomInUp;
  animation: 1200ms ease-in-out 0s normal none 1 running zoomInUp;
}
.owl-item.active .animation-style-02 .slider-content h3,
.owl-item.active .animation-style-02 .slider-content-2 h3 {
  -webkit-animation: 1600ms ease-in-out 0s normal none 1 running zoomInUp;
  animation: 1600ms ease-in-out 0s normal none 1 running zoomInUp;
}
.owl-item.active .animation-style-02 .slider-content .slide-btn,
.owl-item.active .animation-style-02 .slider-content-2 .slide-btn {
  -webkit-animation: 2000ms ease-in-out 0s normal none 1 running zoomInUp;
  animation: 2000ms ease-in-out 0s normal none 1 running zoomInUp;
}
@-webkit-keyframes timebar {
  0% {
    width: 0;
  }
  100% {
    width: 100%;
  }
}
@keyframes timebar {
  0% {
    width: 0;
  }
  100% {
    width: 100%;
  }
}
@keyframes timebar {
  0% {
    width: 0;
  }
  100% {
    width: 100%;
  }
}
/*Top Up Keyframes*/
@-webkit-keyframes liSlideInUp {
  0% {
    -webkit-transform: translateY(250px);
    transform: translateY(250px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
}
@keyframes liSlideInUp {
  0% {
    -webkit-transform: translateY(250px);
    transform: translateY(250px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
}
/*----------------------------------------*/
/*  1.25 FB's Countdown
/*----------------------------------------*/
/*Style One*/
.countersection {
  display: block;
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
}
.count {
  background: #f2f2f2;
  padding: 10px 5px;
  display: inline-block;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .count {
    padding: 10px 4px;
  }
}
.count .count-period {
  display: block;
  font-size: 10px;
  font-weight: 400;
  color: #979797;
  line-height: 1;
  margin-top: 3px;
  text-transform: capitalize;
}
.count .count-amount {
  display: block;
  font-size: 13px;
  color: #333;
  font-weight: 600;
  position: relative;
  line-height: 1;
  margin-bottom: 5px;
  text-align: center;
}
/*Style Two*/
.countersection-2 .count {
  display: inline-block;
  border-radius: 5px;
  background: transparent;
  position: relative;
  padding: 0 0 10px 7px;
}
.countersection-2 .count:first-child {
  padding-left: 0 !important;
}
.countersection-2 .count::before {
  content: ':';
  position: absolute;
  right: -5.5px;
  color: #2874f0;
  font-size: 18px;
}
.countersection-2 .count.last-child::before {
  content: none;
}
.countersection-2 .count .count-amount {
  display: inline-block;
  background: #666666;
  color: #ffffff;
  margin: 0 -4px 0 0;
  border-radius: 3px 0 0 3px;
  height: 35px;
  line-height: 35px;
  padding: 0 4px;
}
.countersection-2 .count .count-period {
  display: inline-block;
  background: #2c2c2c;
  margin: 0;
  color: #ffffff;
  border-radius: 0 3px 3px 0;
  height: 35px;
  line-height: 37px;
  padding: 0 7px;
}
/*----------------------------------------*/
/*  1.26 FB's Mobile Menu Customization
/*----------------------------------------*/
.mobile-menu .mean-nav > ul {
  overflow-y: auto;
  max-height: 400px;
}
.mobile-menu {
  flex: 1 0 100% !important;
}
.mobile-menu .mean-bar {
  position: relative;
  /*---- Mean Nav ----*/
}
.mobile-menu-area .mobile-menu .mean-bar .meanmenu-reveal {
  position: absolute;
  top: -52px;
  left: auto !important;
  right: -12px !important;
  color: #ffffff;
}
.mobile-menu-area-2 .mobile-menu .mean-bar .meanmenu-reveal {
  top: -104px;
}
@media only screen and (max-width: 767px) {
  .mobile-menu-area-2 .mobile-menu .mean-bar .meanmenu-reveal {
    top: -148px;
  }
}
.mobile-menu-area-3 .mobile-menu .mean-bar .meanmenu-reveal {
  top: -104px;
}
@media only screen and (max-width: 767px) {
  .mobile-menu-area-3 .mobile-menu .mean-bar .meanmenu-reveal {
    top: -140px;
  }
}
/*Begin Mobile Menu Area Four*/
.mobile-menu-area-4 .mobile-menu .mean-bar .meanmenu-reveal {
  top: -81px;
  color: #2c2c2c;
}
.mobile-menu-area-4 .mobile-menu .mean-bar .mean-nav {
  background-color: #2c2c2c;
  position: absolute;
  top: 31px;
}
.header-4 .header-middle.header-sticky.stick.sticky .mobile-menu-area-4 .mobile-menu .mean-bar .mean-nav {
  top: 15px;
}
.mobile-menu-area-4 .mobile-menu .mean-bar .mean-nav > ul li a {
  color: #ffffff;
}
.mobile-menu-area-4 .mobile-menu .mean-bar .mean-nav > ul li .sub-menu li a,
.mobile-menu-area-4 .mobile-menu .mean-bar .mean-nav > ul li .mega-menu li a,
.mobile-menu-area-4 .mobile-menu .mean-bar .mean-nav > ul li ul li a {
  color: #ffffff;
}
.mobile-menu-area-4 .mobile-menu .mean-bar .mean-nav > ul li .sub-menu li a:hover,
.mobile-menu-area-4 .mobile-menu .mean-bar .mean-nav > ul li .mega-menu li a:hover,
.mobile-menu-area-4 .mobile-menu .mean-bar .mean-nav > ul li ul li a:hover {
  color: #e23e57;
}
.mobile-menu-area-4 .mobile-menu .mean-bar .mean-nav > ul li a.mean-expand {
  color: #ffffff;
}
.mobile-menu-area-4 .mobile-menu .mean-bar .mean-nav > ul li .sub-menu,
.mobile-menu-area-4 .mobile-menu .mean-bar .mean-nav > ul li .mega-menu,
.mobile-menu-area-4 .mobile-menu .mean-bar .mean-nav > ul li ul {
  background-color: #2c2c2c;
}
/*Mobile Menu Area Four End Here*/
.mobile-menu .mean-bar .meanmenu-reveal span {
  position: relative;
  background: #ffffff;
}
.mobile-menu-area-2 .mobile-menu .mean-bar .meanmenu-reveal span {
  background: #2c2c2c;
}
.mobile-menu-area-4 .mobile-menu .mean-bar .meanmenu-reveal span {
  background: #2c2c2c;
}
.mean-container a.meanmenu-reveal span {
  display: block;
  background: #282828;
  height: 3px;
  margin-top: 3px;
}
.mean-container .mean-bar {
  z-index: 5;
}
.mobile-menu .mean-bar .meanmenu-reveal span.menu-bar:before,
.mobile-menu .mean-bar .meanmenu-reveal span.menu-bar:after {
  content: '';
  position: absolute;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #202020;
}
.mean-container a.meanmenu-reveal {
  color: #282828;
}
.mean-container .mean-bar {
  padding: 0;
  min-height: 0;
}
.mean-container .mean-nav {
  background: transparent;
  margin-top: 0;
}
.mobile-menu .mean-bar .meanmenu-reveal span.menu-bar:before {
  top: -8px;
}
.mobile-menu .mean-bar .meanmenu-reveal span.menu-bar:after {
  bottom: -8px;
}
.mobile-menu .mean-bar .meanmenu-reveal span.menu-close {
  height: 2px;
  width: 26px;
  background-color: transparent;
  display: block;
  margin: 8px 0;
}
.mobile-menu .mean-bar .meanmenu-reveal span.menu-close:before,
.mobile-menu .mean-bar .meanmenu-reveal span.menu-close:after {
  content: '';
  position: absolute;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #202020;
}
.mobile-menu .mean-bar .meanmenu-reveal span.menu-close:before {
  top: 0;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}
.mobile-menu .mean-bar .meanmenu-reveal span.menu-close:after {
  bottom: 0;
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
}
.mobile-menu .mean-bar .mean-nav {
  background-color: #ffffff;
}
.mobile-menu .mean-bar .mean-nav > ul {
  overflow-x: hidden;
}
@media only screen and (max-width: 767px) {
  .mobile-menu .mean-bar .mean-nav > ul {
    max-height: 180px;
    overflow-y: auto;
  }
}
@media only screen and (max-width: 479px) {
  .mobile-menu .mean-bar .mean-nav > ul {
    max-height: 220px;
    overflow-y: auto;
  }
}
.mobile-menu .mean-bar .mean-nav > ul li {
  position: relative;
  display: block;
  float: left;
  width: 100%;
  /*---- Sub Menu & Mega Menu ----*/
}
.mobile-menu .mean-bar .mean-nav > ul li a {
  font-size: 13px;
  display: block;
  color: #444444;
  font-weight: 600;
  text-transform: uppercase;
  line-height: 44px;
  position: relative;
  border-top: 1px solid #eeeeee;
  padding: 0 40px 0 20px;
  width: 100%;
}
.mobile-menu .mean-bar .mean-nav > ul li a:hover {
  color: #e23e57;
  padding-left: 25px;
}
.mobile-menu-area-2 .mobile-menu .mean-bar .mean-nav > ul li a:hover {
  color: #ff6d02;
}
.mobile-menu .mean-bar .mean-nav > ul li a.mean-expand {
  border: 1px solid #eeeeee;
  position: absolute;
  right: -1px;
  top: 0;
  font-size: 20px !important;
  color: #444444;
  line-height: 44px;
  height: 46px;
  width: 40px;
  text-align: center;
  padding: 0 !important;
  background-color: transparent;
}
.mobile-menu .mean-bar .mean-nav > ul li a.mean-expand.mean-clicked {
  line-height: 40px;
}
.mobile-menu .mean-bar .mean-nav > ul li .sub-menu,
.mobile-menu .mean-bar .mean-nav > ul li .mega-menu,
.mobile-menu .mean-bar .mean-nav > ul li ul {
  position: static;
  background-color: #fff;
  margin: 0;
  padding: 0 !important;
  width: 100%;
  box-shadow: none;
  display: none;
  float: left;
  opacity: 1;
  -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=100)';
  -webkit-transform: perspective(0px) rotateX(0deg);
  transform: perspective(0px) rotateX(0deg);
  transition: none;
}
.mobile-menu .mean-bar .mean-nav > ul li .sub-menu li,
.mobile-menu .mean-bar .mean-nav > ul li .mega-menu li,
.mobile-menu .mean-bar .mean-nav > ul li ul li {
  padding: 0;
  margin: 0;
  flex: 0 0 100%;
  border-right: 0px solid transparent;
  width: 100%;
  display: block !important;
  float: left;
  width: 100%;
}
.mobile-menu .mean-bar .mean-nav > ul li .sub-menu li a,
.mobile-menu .mean-bar .mean-nav > ul li .mega-menu li a,
.mobile-menu .mean-bar .mean-nav > ul li ul li a {
  font-size: 12px;
}
.mobile-menu .mean-bar .mean-nav > ul li .sub-menu li a:before,
.mobile-menu .mean-bar .mean-nav > ul li .mega-menu li a:before,
.mobile-menu .mean-bar .mean-nav > ul li ul li a:before {
  display: none;
}
.mobile-menu .mean-bar .mean-nav > ul li .sub-menu li .sub-menu,
.mobile-menu .mean-bar .mean-nav > ul li .sub-menu li ul,
.mobile-menu .mean-bar .mean-nav > ul li .mega-menu li .sub-menu,
.mobile-menu .mean-bar .mean-nav > ul li .mega-menu li ul,
.mobile-menu .mean-bar .mean-nav > ul li ul li .sub-menu,
.mobile-menu .mean-bar .mean-nav > ul li ul li ul {
  background-color: rgba(0, 0, 0, 0.04);
}
.mobile-menu .mean-bar .mean-nav > ul li .sub-menu li .sub-menu li a,
.mobile-menu .mean-bar .mean-nav > ul li .sub-menu li ul li a,
.mobile-menu .mean-bar .mean-nav > ul li .mega-menu li .sub-menu li a,
.mobile-menu .mean-bar .mean-nav > ul li .mega-menu li ul li a,
.mobile-menu .mean-bar .mean-nav > ul li ul li .sub-menu li a,
.mobile-menu .mean-bar .mean-nav > ul li ul li ul li a {
  border-top: 1px solid #dddddd;
}
.mobile-menu .mean-bar .mean-nav > ul li .sub-menu li .sub-menu li a.mean-expand,
.mobile-menu .mean-bar .mean-nav > ul li .sub-menu li ul li a.mean-expand,
.mobile-menu .mean-bar .mean-nav > ul li .mega-menu li .sub-menu li a.mean-expand,
.mobile-menu .mean-bar .mean-nav > ul li .mega-menu li ul li a.mean-expand,
.mobile-menu .mean-bar .mean-nav > ul li ul li .sub-menu li a.mean-expand,
.mobile-menu .mean-bar .mean-nav > ul li ul li ul li a.mean-expand {
  border: 1px solid #dddddd;
}
.mobile-menu .mean-bar .mean-nav > ul li .sub-menu li .sub-menu .sub-menu,
.mobile-menu .mean-bar .mean-nav > ul li .sub-menu li .sub-menu ul,
.mobile-menu .mean-bar .mean-nav > ul li .sub-menu li ul .sub-menu,
.mobile-menu .mean-bar .mean-nav > ul li .sub-menu li ul ul,
.mobile-menu .mean-bar .mean-nav > ul li .mega-menu li .sub-menu .sub-menu,
.mobile-menu .mean-bar .mean-nav > ul li .mega-menu li .sub-menu ul,
.mobile-menu .mean-bar .mean-nav > ul li .mega-menu li ul .sub-menu,
.mobile-menu .mean-bar .mean-nav > ul li .mega-menu li ul ul,
.mobile-menu .mean-bar .mean-nav > ul li ul li .sub-menu .sub-menu,
.mobile-menu .mean-bar .mean-nav > ul li ul li .sub-menu ul,
.mobile-menu .mean-bar .mean-nav > ul li ul li ul .sub-menu,
.mobile-menu .mean-bar .mean-nav > ul li ul li ul ul {
  background-color: rgba(0, 0, 0, 0.05);
}
.mobile-menu .mean-bar .mean-nav > ul li .sub-menu li .sub-menu .sub-menu li a,
.mobile-menu .mean-bar .mean-nav > ul li .sub-menu li .sub-menu ul li a,
.mobile-menu .mean-bar .mean-nav > ul li .sub-menu li ul .sub-menu li a,
.mobile-menu .mean-bar .mean-nav > ul li .sub-menu li ul ul li a,
.mobile-menu .mean-bar .mean-nav > ul li .mega-menu li .sub-menu .sub-menu li a,
.mobile-menu .mean-bar .mean-nav > ul li .mega-menu li .sub-menu ul li a,
.mobile-menu .mean-bar .mean-nav > ul li .mega-menu li ul .sub-menu li a,
.mobile-menu .mean-bar .mean-nav > ul li .mega-menu li ul ul li a,
.mobile-menu .mean-bar .mean-nav > ul li ul li .sub-menu .sub-menu li a,
.mobile-menu .mean-bar .mean-nav > ul li ul li .sub-menu ul li a,
.mobile-menu .mean-bar .mean-nav > ul li ul li ul .sub-menu li a,
.mobile-menu .mean-bar .mean-nav > ul li ul li ul ul li a {
  border-top: 1px solid #eeeeee;
}
.mobile-menu .mean-bar .mean-nav > ul li .sub-menu li .sub-menu .sub-menu li a.mean-expand,
.mobile-menu .mean-bar .mean-nav > ul li .sub-menu li .sub-menu ul li a.mean-expand,
.mobile-menu .mean-bar .mean-nav > ul li .sub-menu li ul .sub-menu li a.mean-expand,
.mobile-menu .mean-bar .mean-nav > ul li .sub-menu li ul ul li a.mean-expand,
.mobile-menu .mean-bar .mean-nav > ul li .mega-menu li .sub-menu .sub-menu li a.mean-expand,
.mobile-menu .mean-bar .mean-nav > ul li .mega-menu li .sub-menu ul li a.mean-expand,
.mobile-menu .mean-bar .mean-nav > ul li .mega-menu li ul .sub-menu li a.mean-expand,
.mobile-menu .mean-bar .mean-nav > ul li .mega-menu li ul ul li a.mean-expand,
.mobile-menu .mean-bar .mean-nav > ul li ul li .sub-menu .sub-menu li a.mean-expand,
.mobile-menu .mean-bar .mean-nav > ul li ul li .sub-menu ul li a.mean-expand,
.mobile-menu .mean-bar .mean-nav > ul li ul li ul .sub-menu li a.mean-expand,
.mobile-menu .mean-bar .mean-nav > ul li ul li ul ul li a.mean-expand {
  border: 1px solid #eeeeee;
}
/*----------------------------------------*/
/* 1.27 FB's Modal Area
/*----------------------------------------*/
.modal-wrapper {
  display: block !important;
}
.modal-wrapper.modal {
  visibility: hidden;
  opacity: 0;
  -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=0)';
  transition: all 0.3s ease-in-out;
  z-index: -9;
}
.modal-wrapper.modal.show {
  visibility: visible;
  opacity: 1;
  -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=100)';
  z-index: 1050;
}
.modal-wrapper .modal-dialog {
  max-width: 880px;
}
.modal-wrapper .close {
  color: #333333;
  font-size: 30px;
  font-weight: 400;
  opacity: 1;
  /*-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";*/
  position: absolute;
  right: 15px;
  top: 5px;
  transition: all 0.3s ease-in-out;
  z-index: 99;
}
.modal-wrapper .close:hover {
  color: #e23e57 !important;
  /* background-color: #e23e57 !important; */
}
.modal-wrapper .slider-thumbs-1 {
  margin-top: 10px;
  margin-right: -10px;
}
.modal-wrapper .slider-thumbs-1 .sm-image {
  margin-right: 10px;
}
.product-details-view-content {
  padding-right: 30px;
}
.product-details-images .lg-image img,
.product-details-thumbs .sm-image img {
  width: 100%;
}
.modal-body .sm-image {
  border: 1px solid #ededed;
  margin: 0 13px;
}
.product-details-thumbs .sm-image {
  cursor: pointer;
}
.product-details-view-content .product-info h2 {
  color: #666;
  text-transform: capitalize;
  font-size: 24px;
  line-height: 1;
  font-weight: 500;
  margin: 0 0 15px 0;
}
@media only screen and (max-width: 991px) {
  .fb-img-hover-effect a img {
    height: auto;
  }

  .product-details-view-content {
    padding: 0 10px;
  }
  .product-details-view-content .product-info h2 {
    font-size: 18px;
  }
}
.modal-body .product-details-view-content .price-box {
  padding: 0;
  display: inline-block;
}
.product-details-view-content .content-center .product-variants {
  display: flex;
  justify-content: center;
}
.modal-body .sticker-2 {
  width: 80px;
  height: 30px;
  line-height: 30px;
  position: relative;
  display: inline-block;
  margin-left: 15px;
}
.product-variants .produt-variants-size {
  margin-right: 30px;
}
.product-variants .produt-variants-size label {
  display: block;
  font-size: 14px;
  font-weight: 400;
}
.product-variants .produt-variants-size .form-control-select {
  width: 60px;
  border-radius: 0px;
  border: 1px solid #ddd;
  height: 30px;
}
.product-variants .produt-variants-color label {
  display: block;
  font-size: 16px;
  font-weight: 600;
}
.product-variants .produt-variants-color .color-list li {
  display: inline-block;
  margin-right: 10px;
}
.product-variants .produt-variants-color .color-list li a {
  border: 2px solid rgba(0, 0, 0, 0);
  display: block;
  height: 28px;
  transition: all 0.3s ease-in-out 0s;
  width: 28px;
}
.product-variants .produt-variants-color .color-list li a:hover {
  border: 2px solid #333333;
}
.product-variants .produt-variants-color .color-list li a.orange-color {
  background: #f39c11;
}
.product-variants .produt-variants-color .color-list li a.orange-color.active {
  border: 2px solid #333333;
}
.product-variants .produt-variants-color .color-list li a.paste-color {
  background: #5d9cec;
}
.cart-quantity {
  margin-top: 25px;
  overflow: hidden;
  float: left;
  width: 100%;
}
.cart-quantity label {
  display: block;
  font-size: 16px;
  font-weight: 600;
}
.cart-plus-minus {
  float: left;
  margin-right: 15px;
  position: relative;
  width: 76px;
  text-align: left;
  display: flex;
}
.price-span {
  position: relative;
  left: 10px;
  color: #e23e57 !important;
  font-size: 20px;
}
.quantity.cart-table-quantity .qtybutton {
  left: 46px;
}
.quantity.cart-table-quantity .package-span {
  display: flex;
  align-items: center;
  width: 40px;
  justify-content: center;
}
.quantity.cart-table-quantity .relative-btn {
  left: 183px;
}
.quantity.cart-table-quantity .price-span {
  font-size: 15px;
  color: #414141 !important;
  display: flex;
  align-items: center;
}
.cart-plus-minus .relative-btn {
  left: 185px;
}
.cart-plus-minus .quantity-span {
  position: relative;
  left: 76px;
  /* border: 1px solid #e1e1e1; */
  height: 46px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 4px;
  padding-left: 10px !important;
}
.order-button-payment button {
  font-size: 16px !important;
}
.cart-plus-minus .package-span {
  position: relative;
  left: 40px;
  width: 40px;
}
.cart-plus-minus-box-relative {
  position: relative;
  left: 50px;
}
.cart-plus-minus .cart-plus-minus-box {
  border: 1px solid #e1e1e1;
  color: #242424;
  height: 46px;
  text-align: center;
  width: 48px;
  width: 48px;
  width: 3rem;
  background: #fff;
}
.cart-plus-minus .dec.qtybutton,
.cart-plus-minus .inc.qtybutton {
  border-bottom: 1px solid #e1e1e1;
  border-right: 1px solid #e1e1e1;
  border-top: 1px solid #e1e1e1;
  color: #333333;
  cursor: pointer;
  height: 23px;
  line-height: 20px;
  position: absolute;
  text-align: center;
  transition: all 0.3s ease-in-out 0s;
  width: 28px;
}
#cartMini .dec.qtybutton,
#cartMini .inc.qtybutton {
  background: #232f3e;
  color: white;
  border-bottom: 1px solid #e1e1e1;
  border-right: 1px solid #232f3e;
  border-top: 1px solid #232f3e;
}
.cart-plus-minus .dec.qtybutton {
  top: 23px;
  right: 0;
}
.cart-plus-minus .inc.qtybutton {
  border-bottom: none;
  top: 0;
  right: 0;
}
.product-details-thumbs .slick-arrow,
.tab-style-right .slick-arrow,
.tab-style-left .slick-arrow {
  font-size: 20px;
  position: absolute;
  top: 50%;
  left: 0;
  right: auto;
  background: #242424;
  color: #ffffff;
  border: none;
  width: 30px;
  height: 30px;
  line-height: 30px;
  text-align: center;
  border-radius: 5px;
  z-index: 5;
  visibility: hidden;
  opacity: 0;
  -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=0)';
  transition: 0.3s ease-in-out;
  cursor: pointer;
}
.tab-style-right .slick-arrow,
.tab-style-left .slick-arrow {
  top: 16px;
  left: 40px;
  right: auto;
}
.product-details-thumbs .slick-arrow:hover {
  background: #fed700;
  color: #ffffff;
}
.product-details-thumbs .slick-arrow.slick-next,
.tab-style-right .slick-arrow.slick-next {
  right: 10px;
  left: auto;
}
.tab-style-right .slick-arrow.slick-next,
.tab-style-left .slick-arrow.slick-next {
  right: 40px;
  left: auto;
  bottom: 0;
  top: auto;
}
.product-details-thumbs:hover .slick-arrow,
.tab-style-right:hover .slick-arrow,
.tab-style-left:hover .slick-arrow {
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  visibility: visible;
  opacity: 1;
  -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=100)';
}
.modal-social-link {
  margin: 20px 0 0 !important;
  width: 100%;
  float: left;
}
.modal-social-link span {
  display: inline-block;
  padding-right: 15px;
  font-weight: 700;
  color: #a4a4a4;
}
.modal-social-link ul {
  display: inline-block;
}
.footer-widget-social-link ul li:last-child {
  margin-right: 0;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .footer-widget-social-link ul li {
    margin: 0 20px 0 0 !important;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .footer-widget-social-link ul li {
    margin: 0 10px 0 0;
  }
}
/*----------------------------------------*/
/* 1.28 FB's Header Sticky
/*----------------------------------------*/
/*Home One*/
.header-sticky.sticky {
  background: rgba(48, 61, 78, 0.9) !important;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  transition: all 300ms ease-in 0s;
  z-index: 1049;
}
.sticky {
  -webkit-animation: 800ms ease-in-out 0s normal none 1 running slideInDown;
  animation: 800ms ease-in-out 0s normal none 1 running slideInDown;
}
.header-sticky:hover {
  background: #303d4e !important;
}
/*Home Two*/
.header-2 .header-sticky.sticky {
  background: rgba(40, 116, 240, 0.9) !important;
}
.header-2 .header-sticky:hover {
  background: #2874f0 !important;
}
@media only screen and (max-width: 991px) {
  .header-2 .header-sticky:hover {
    background: transparent !important;
  }
}
/*Home Three*/
.header-3 .header-sticky.sticky {
  background: rgba(25, 36, 49, 0.9) !important;
}
.header-3 .header-sticky:hover {
  background: #192431 !important;
}
/*Home Four*/
.header-4 .header-middle {
  background: #ffffff;
}
.header-4 .header-middle:hover {
  background: #ffffff !important;
}
.header-4 .header-sticky.sticky {
  background: rgba(48, 61, 78, 0.9) !important;
  padding: 5px 0;
}
.header-4 .header-sticky.sticky:hover {
  background: #303d4e !important;
}
.header-4 .header-sticky.sticky .fb-navigation > ul > li > a,
.header-4 .header-sticky.sticky .item-text,
.header-4 .header-sticky.sticky .item-icon::before,
.header-4 .header-sticky.sticky .item-total {
  color: #ffffff;
}
.header-4 .header-sticky.sticky .fb-navigation > ul > li:hover > a {
  color: #e23e57;
}
.header-4 .header-sticky.sticky .fb-navigation > ul > li .hb-dropdown {
  top: 108%;
}
.header-4 .header-sticky.sticky .fb-navigation > ul > li .megamenu {
  top: 108%;
}
.header-4 .header-middle .logo .sticky {
  display: none;
}
.header-4 .header-sticky.sticky .logo .no-sticky {
  display: none;
}
.header-4 .header-sticky.sticky .logo .sticky {
  display: block;
}
@media only screen and (max-width: 991px) {
  .header-4 .header-sticky.sticky {
    padding: 14px 0 14px;
  }
  .header-4 .header-sticky.sticky .mobile-menu-area-4 .mobile-menu .mean-bar .meanmenu-reveal {
    top: -43px;
    color: #ffffff;
  }
  .header-4 .header-sticky.sticky .mobile-menu-area-4 .mobile-menu .mean-bar .meanmenu-reveal span {
    background: #ffffff;
  }
}
/*----------------------------------------*/
/*  1.29 FB's Scroll Up
/*----------------------------------------*/
#scrollUp {
  right: 25px;
  bottom: 25px;
  height: 40px;
  width: 40px;
  line-height: 40px;
  text-align: center;
  background: #242424 none repeat scroll 0 0;
  border: 1px solid #ffffff;
  -webkit-animation: liSlideInUp 0.8s ease-in-out 1 both;
  animation: liSlideInUp 0.8s ease-in-out 1 both;
  z-index: 1000 !important;
  overflow: hidden;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
}
#scrollUp:hover {
  background: #e23e57 none repeat scroll 0 0;
}
#scrollUp i {
  color: #ffffff;
  font-size: 25px;
  line-height: 40px;
  display: block;
}
#scrollUp:hover i {
  animation: liSlideInUp 1s infinite;
  -webkit-animation: liSlideInUp 1s infinite;
}
/*----------------------------------------*/
/*  02.1 Header
/*----------------------------------------*/
/*Header Top Menu*/
.header-top-nav {
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  padding-left: 0;
  padding-right: 0;
  padding-top: 5px;
  padding-bottom: 5px;
}
@media only screen and (max-width: 767px) {
  .top-selector-wrapper {
    text-align: center;
  }
}
.language {
  position: relative;
  margin-right: 20px !important;
}
.language::after {
  content: '';
  position: absolute;
  top: 9px;
  right: -13px;
  background: rgba(255, 255, 255, 0.4);
  height: 12px;
  width: 1px;
}
.single-top-selector > li > span {
  color: #9b9b9b;
  font-size: 13px;
}
/*Begin Currency Area*/
.currency .dropdown-menu,
.language .dropdown-menu {
  max-height: 0;
  display: block;
  border: 0;
  padding: 0;
  right: 0;
  left: 0;
  z-index: 999;
  transition: all 0.5s ease-in-out;
  border-radius: 0;
  min-width: 75px;
  background: transparent;
  top: 32px;
}
.currency .dropdown-menu.open,
.language .dropdown-menu.open {
  max-height: 200px;
  width: 75px;
}
.currency .dropdown-menu ul,
.language .dropdown-menu ul {
  background: #fff;
  border: 1px solid #e5e5e5;
  border-top-color: #e5e5e5;
  border-top-style: solid;
  border-top-width: 1px;
  left: 7px;
  margin: 5px 0 0;
  padding: 10px;
  box-shadow: none;
  min-width: 75px;
  list-style: none;
}
/*Currency Area End Here*/
/*Begin Language Area*/
.language .dropdown-menu {
  min-width: 110px;
}
.dropdown-menu ul > li > a {
  font-size: 13px;
  display: block;
}
.dropdown-menu ul > li > a img {
  margin-right: 5px;
}
@media only screen and (max-width: 479px) {
  .currency .dropdown-menu {
    right: auto;
    left: 0;
  }
}
.dropdown-menu {
  overflow: hidden;
}
.dropdown-toggle {
  margin-left: 5px;
  padding: 0;
}
@media only screen and (max-width: 479px) {
  .dropdown-toggle {
    margin-left: 15px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .dropdown-toggle {
    margin-left: 15px;
  }
}
.dropdown-toggle:last-child {
  margin-left: 0;
}
.dropdown-toggle.open {
  width: auto !important;
  display: inline-block;
}
.dropdown-toggle:after {
  display: none;
}
.top-selector-wrapper ul > li button {
  font-size: 13px;
  text-transform: capitalize;
  cursor: pointer;
  text-decoration: none;
  height: auto;
  box-shadow: none;
  border: none;
  font-weight: 400;
  background: transparent;
  color: #fff;
}
.top-selector-wrapper ul > li button img {
  margin-right: 5px;
  margin-top: -2px;
}
.top-selector-wrapper ul > li button i {
  margin-left: 5px;
  transition: all 0.3s ease-in-out;
  font-size: 12px;
}
.single-top-selector-left {
  margin: 30px 0;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .header-area .single-top-selector-left {
    justify-content: center;
  }
}
.header-area .single-top-selector-left .dropdown-toggle {
  margin-right: 24px;
  margin-left: 0;
}
.header-area .single-top-selector-left .dropdown-menu {
  right: auto;
  left: 0;
}
/*Begin Logo Area*/
@media only screen and (max-width: 767px) {
  .logo {
    margin: 10px 0 0 0;
    text-align: center;
  }
  .header-top-right {
    text-align: center !important;
    justify-content: center !important;
  }
}
/*Logo Area End Here*/
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .container-box .logo {
    margin: 5px 0 0 0;
  }
  .container-box .container-box-inner {
    padding: 30px 0;
    margin: 0 0 0 0;
  }
}
@media only screen and (max-width: 767px) {
  .container-box .logo {
    margin: 10px 0 0 0;
  }
  .container-box .single-top-selector-left {
    margin: 15px 0;
    justify-content: center;
    display: flex;
  }
  .container-box .header-bottom-right {
    margin: 0px 0px 22px 0;
    justify-content: left;
  }
  .container-box .container-box-inner {
    padding: 20px 0;
    margin: 0 0 0 0;
  }
}
@media only screen and (max-width: 767px) {
  .container-box .header-bottom-right {
    margin: 0 0 15px 0;
  }
  .container-box .container-box-inner {
    padding: 30px 0 20px 0;
    margin: 0 0 0 0;
  }
}
.header-top-three .header-top-three-inner {
  display: flex;
  justify-content: space-between;
  padding: 20px 0;
}
/*Header Top Right*/
.user-block {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
@media only screen and (max-width: 767px) {
  .user-block {
    display: flex;
    justify-content: center;
  }
}
.user-block > li {
  display: inline-block;
}
.user-block > li:last-child > a {
  padding-right: 0;
  border-right: none;
}
.user-block > li > a {
  display: inline-block;
  font-weight: 400;
  font-size: 13px;
  text-decoration: none;
  color: #fff;
  text-transform: capitalize;
  line-height: 1;
  padding: 0 15px;
  border-right: 1px solid rgba(255, 255, 255, 0.4);
}
.user-block > li > a:hover {
  text-decoration: underline;
}
/*Header Middle*/
.header-middle {
  padding: 7px;
}
@media only screen and (max-width: 991px) {
  .header-4 .header-middle {
    padding: 30px 0;
  }
  .header-4 .logo {
    padding: 0;
    text-align: unset;
    margin: 0;
  }
  .mobile-menu-area-4 .mobile-menu .mean-bar .meanmenu-reveal {
    top: -43px;
    color: #2c2c2c;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .logo {
    padding: 0;
    text-align: center;
  }
}
@media only screen and (max-width: 767px) {
  .header-middle {
    padding: 0 30px 0;
  }
}
.hm-searchbox {
  display: flex;
  position: relative;
  background: #fff;
  border-radius: 36px;
  border: 1px solid #e1e1e1;
  min-width: 540px;
  margin-top: 13px;
  height: 48px;
  float: left;
}
@media only screen and (min-width: 768px) and (max-width: 1199px) {
  .hm-searchbox {
    min-width: 405px;
  }
}
@media only screen and (max-width: 991px) {
  .header-middle-right {
    display: flex;
    flex-direction: column-reverse;
  }
  .hm-searchbox {
    min-width: 76% !important;
    max-width: 76% !important;
    margin-left: 8%;
  }
  .mobile-delivery {
    display: flex !important;
    float: right;
  }
  .mobile-delivery .fa {
    font-size: 40px;
    color: white;
    margin-right: 5px;
  }
  .responsive-nav {
    margin-top: 10px;
  }
  .mobile-delivery .delivery {
    display: flex;
    float: right;
    align-items: center;
  }
  .mobile-delivery p {
    margin-bottom: 0;
    line-height: 20px;
  }
  .mobile-menu-area .mobile-menu .mean-bar .meanmenu-reveal {
    top: -72px;
    left: 0 !important;
  }
  .hm-searchbox {
    margin-top: 0;
    margin-bottom: 20px;
  }
}
@media only screen and (max-width: 767px) {
  .mobile-menu-area .mobile-menu .mean-bar .meanmenu-reveal {
    top: -69px;
    left: 15px !important;
  }
  .container {
    max-width: 730px;
  }
  .hm-searchbox {
    min-width: 73% !important;
  }
}

@media (max-width: 650px) {
  .hm-searchbox {
    min-width: 70% !important;
  }
}

@media only screen and (max-width: 575px) {
  .hm-searchbox {
    min-width: 60% !important;
    margin-left: 15%;
  }
}
@media only screen and (max-width: 545px) {
  .hm-searchbox {
    min-width: 60% !important;
    margin-left: 10%;
  }
  .mobile-delivery .fa {
    font-size: 30px;
  }
  .mobile-delivery p {
    font-size: 13px;
  }
}

@media only screen and (max-width: 480px) {
  .hm-searchbox {
    max-width: 55% !important;
  }
}

@media only screen and (max-width: 420px) {
  .hm-searchbox {
    min-width: 56% !important;
    max-width: 56% !important;
  }
}
@media only screen and (max-width: 370px) {
  .hm-searchbox {
    min-width: 50% !important;
    max-width: 50% !important;
  }
}
@media (max-width: 991px) {
  td.fb-product-add-cart.wishlist-btn a {
    width: 100px !important;
  }
}
.hm-searchbox .nice-select.select-search-category {
  width: auto;
  line-height: 43px;
  height: 43px;
  margin: 0;
  border: 0;
  padding: 0 28px 0 25px;
  color: #777;
  font-size: 13px;
  font-family: 'Poppins', sans-serif;
  font-weight: 400;
  position: relative;
  border-radius: 36px;
}
@media only screen and (max-width: 479px) {
  .hm-searchbox .nice-select.select-search-category {
    display: none;
  }
}
.select-search-category.nice-select::after {
  border-bottom: 2px solid #000;
  border-right: 2px solid #000;
}
.select-search-category ul.list {
  height: 440px;
  overflow-y: auto;
  overflow-x: unset;
}
.hm-searchbox .current::after {
  content: '';
  position: absolute;
  display: block;
  height: 22px;
  width: 1px;
  background: #7f7f7f;
  top: 10px;
  right: -10px;
  font-size: 13px;
}
.hm-searchbox .nice-select.select-search-category::before {
  right: 30px;
  top: 25px;
  color: #7e7e7e;
}
.nav-item-text {
  color: #fff;
  position: relative;
  top: -8px;
  cursor: pointer;
  font-size: 15px;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */ /* Konqueror HTML */ /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none !important; /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */
}
/*.hm-searchbox input {
    padding: 0 60px 0 165px;
    width: 100%;
    height: 48px;
    font-size: 13px;
    background: white;
    color: #a9a9a9;
    border: 0;
    -webkit-border-radius: 36px;
    -moz-border-radius: 36px;
    -ms-border-radius: 36px;
    -o-border-radius: 36px;
    border-radius: 36px;
}*/
.hm-searchbox input {
  font-size: 13px;
  color: #777;
  height: 45px;
  background: transparent;
  border: none;
  width: 100%;
  padding: 0 60px 0 20px;
}
/* Minicart*/
.hm-menu {
  display: flex;
}
.minicart#account {
  width: 180px;
  text-align: center;
}
@media only screen and (max-width: 991px) {
  .hm-menu {
    display: flex;
    justify-content: flex-end;
    padding-bottom: 30px;
  }
  .hm-searchbox {
    max-width: 445px;
  }
}
.hm-minicart {
  position: relative;
  z-index: 9;
  margin-right: 10px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 7px;
  display: flex !important;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */ /* Konqueror HTML */ /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */
}
.hm-minicart:last-child {
  margin-right: 0;
}
.hm-minicart-trigger,
.subcategories-trigger {
  border-radius: 2px;
  color: #fff;
  height: 45px;
  cursor: pointer;
  display: block;
  position: relative;
  padding: 0 0 0 55px;
  top: -12px;
  left: 12px;
}
.item-icon.content::before {
  content: '\f141' !important;
}
.item-icon.content2::before {
  content: '\f47e' !important;
  font-size: 38px !important;
}
/* .owl-nav {
  display: none !important;
} */
.specialProducts .owl-item {
  width: 50% !important;
}
.hm-minicart-trigger:first-child {
  padding: 0px 0 0 47px;
}
.owl-carousel .owl-item img {
  min-height: 275px;
}
.specialProducts .owl-item img {
  min-height: unset !important;
}
.newProducts .owl-item img,
.featuredProducts .owl-item img {
  min-height: 251px;
}
@media (min-width: 991px) {
  .newProducts .owl-item img,
  .featuredProducts .owl-item img {
    max-height: 251px;
  }
}
@media (max-width: 500px) {
  .single-add-to-cart .cart-plus-minus .price-span {
    display: none !important;
    font-size: 15px;
  }
  .mobile-price-span {
    display: block !important;
    color: #e23e57 !important;
    font-size: 20px;
  }
}
.shop-products-wrapper img {
  min-height: 360px;
}
.similarProducts .owl-item img,
.hotDeals .owl-item img {
  min-height: 200px;
}
.hotDeals .owl-nav.disabled {
  display: block !important;
}
.cursor-pointer {
  cursor: pointer !important;
}
.wishlist-btn a {
  font-size: 12px !important;
  padding: 8px !important;
}
@media (max-width: 991px) {
  .shop-products-wrapper img {
    min-height: 220px;
  }
  .similarProducts .owl-item img,
  .hotDeals .owl-item img {
    min-height: 220px;
  }
}
@media (max-width: 767px) {
  .shop-products-wrapper img {
    min-height: 450px;
  }
}
@media (max-width: 575px) {
  .similarProducts .owl-item img,
  .hotDeals .owl-item img {
    min-height: 350px;
  }
  .newProducts .owl-item img,
  .featuredProducts .owl-item img {
    min-height: 350px;
  }
  .shop-products-wrapper img {
    min-height: unset !important;
  }
}
.item-icon::before {
  content: '\f110';
  position: absolute;
  font-family: 'Ionicons';
  left: 0;
  top: 16px;
  display: block;
  font-size: 25px;
}
.item-text {
  font-size: 13px;
  line-height: 20px;
}
.item-total {
  display: block;
  font-size: 15px;
  font-weight: 500;
  line-height: 14px;
  line-height: 20px;
}
.cart-item-count {
  position: absolute;
  top: 15px;
  left: 22px;
  display: block;
  overflow: hidden;
  min-width: 20px;
  line-height: 20px;
  font-size: 9px;
  font-weight: 400;
  color: #ffffff;
  background: #e23e57;
  text-align: center;
  border-radius: 100%;
}
.minicart,
.subcategories-list {
  position: absolute;
  right: 0;
  top: 65px;
  z-index: 999;
  display: none;
  border: 1px solid #e5e5e5;
  padding: 20px;
  width: 350px;
  background: #ffffff;
  text-align: left;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
}

.minicart .minicart-product-list {
  padding-left: 0;
  margin-bottom: 0;
}

.minicart-product-list h6 {
  margin-bottom: 0;
}

.minicart-product-list-item a:hover {
  color: #e23e57;
}

.minicart-product-list a {
  font-family: 'Poppins', sans-serif;
  color: #222222;
  font-weight: 400;
  margin-top: 0;
  line-height: 1.2;
}

.minicart .minicart-product-list p {
  color: #666;
  text-transform: capitalize;
  font-size: 16px;
  line-height: 20px;
  transition: all 0.3s ease-in-out;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.minicart .minicart-product-list li {
  display: flex;
  justify-content: space-between;
  list-style: none;
  border-bottom: 1px solid #e5e5e5;
  padding: 12px 0px 18px 3px;
  overflow: hidden;
}
.minicart .minicart-product-list li:first-child {
  padding-top: 0;
}
.minicart .minicart-product-list li .minicart-product-image {
  display: inline-block;
  position: relative;
  width: 50px;
  flex: 0 0 50px;
  margin-right: 10px;
  align-self: flex-start;
}
.minicart .minicart-product-list li .minicart-product-image .product-quantity {
  position: absolute;
  background: #e23e57;
  width: 25px;
  height: 25px;
  line-height: 25px;
  text-align: center;
  color: #ffffff;
  font-size: 12px;
  border-radius: 100%;
  left: 0;
  top: 0;
}
.minicart .minicart-product-list li .minicart-product-image span.quantity {
  background: linear-gradient(#626262, #2f2f2f);
  border-radius: 40px;
  color: #fff;
  font-family: Carrois Gothic, Arial, Helvetica, sans-serif;
  font-size: 11px;
  font-weight: bold;
  height: 20px;
  left: -10px;
  line-height: 20px;
  overflow: visible;
  position: absolute;
  text-align: center;
  top: -10px;
  width: 20px;
  z-index: 1;
}
.minicart .minicart-product-list li .minicart-product-details {
  flex-shrink: 100;
  width: 100%;
  margin-right: 10px;
}
.minicart .minicart-product-list li .minicart-product-details h6 {
  color: #333333;
  font-size: 14px;
  font-weight: normal;
  line-height: 20px;
  margin: 0 0 5px;
  text-align: left;
}
.minicart-product-details span {
  color: #e23e57;
  display: block;
  margin: 16px 0 0;
  font-size: 14px;
}
@media (max-width: 450px) {
  .minicart-product-details {
    display: flex;
    flex-flow: column;
  }
  .minicart-product-details br {
    display: none;
  }
}
.minicart .minicart-product-list li .minicart-product-details h6 a {
  display: block;
  color: #666;
  text-transform: capitalize;
  font-size: 16px;
  line-height: 20px;
  transition: all 0.3s ease-in-out;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.minicart .minicart-product-list li .minicart-product-details h6 a:hover {
  color: #e23e57;
}
.minicart > ul > li > a > img {
  width: 100%;
}
.minicart .minicart-product-list li button.close {
  flex-grow: 100;
  background: none;
  color: #777777;
  display: block;
  font-size: 14px;
  z-index: 1;
}
.minicart .minicart-product-list li button.close:hover {
  color: #e23e57;
}
.minicart .price-content {
  border-bottom: 1px solid #ededed;
  padding: 15px 0;
}
.minicart .minicart-total {
  border: none;
  color: #333;
  font-size: 14px;
  margin: 0;
  padding: 0 10px;
  text-transform: uppercase;
  width: 100%;
  font-weight: 500;
}
.minicart .minicart-total span {
  font-size: 16px;
  float: right;
  color: #e23e57;
  font-weight: 500;
}
.minicart .minicart-button {
  padding: 10px 0 0;
}
.minicart .minicart-button h3 {
  margin-bottom: 0;
  font-size: 16px;
  font-family: 'Poppins', sans-serif;
  font-weight: 500;
}
/*Category Menu*/
.category-menu {
  position: relative;
}
.category-heading {
  position: relative;
  cursor: pointer;
  background: #e23e57;
  height: 59px;
}
.category-heading > h2 {
  margin: 0;
  color: #242424;
  line-height: 59px;
  padding: 0;
  text-transform: uppercase;
  font-size: 16px;
  font-weight: 500;
  position: relative;
}
.categories-toggle > span {
  padding-left: 15px;
  color: #ffffff;
  font-size: 18px;
  font-weight: 600;
}
.category-heading > h2::before {
  font-size: 20px;
  vertical-align: top;
  content: '\f0c9';
  font-family: fontawesome;
  font-weight: normal;
  text-align: center;
  line-height: 55px;
  color: #ffffff;
  float: left;
  display: block;
  padding-left: 15px;
}
.category-heading > h2 > i {
  float: right;
  padding-right: 20px;
  font-size: 12px;
}
.header-category > h2 > i {
  font-size: 13px;
}
.category-menu-list {
  position: absolute;
  left: 0;
  top: calc(100% - 1px);
  display: block;
  width: 100%;
  border: unset !important;
  z-index: 3;
}
.css-2b097c-container {
  z-index: 2 !important;
}
.category-menu-2 .category-menu-list > ul {
  background: #fff;
  border-radius: 0px 0px 4px 4px;
}
.category-menu-list > ul > li {
  list-style: none;
  padding: 3.2px 20px 3.2px 55px;
  position: relative;
  background: #fff;
  overflow: visible;
}
.category-menu-2 .category-menu-list > ul > li {
  padding: 0.5px 20px 0.5px 29px;
}
.header-bottom-area.header-style-3 .category-menu-list > ul > li:first-child {
  border-top: 1px solid #ebebeb;
}
.category-menu-list > ul > li > a,
.cat-dropdown > li > a {
  display: block;
  text-transform: capitalize;
  color: #2c2c2c;
  overflow: hidden;
  font-size: 14px;
  font-weight: 400;
  padding: 3.2px 0;
}
.category-menu-list > ul > li:hover > a,
.cat-dropdown > li:hover > a {
  color: #e23e57;
}
.category-menu-list > ul > li img {
  position: absolute;
  left: 20px;
  top: 4px;
  height: auto;
  display: block;
}
.category-menu-list > ul > li:last-child > a:before {
  display: none;
}
.category-menu-list > ul > li.right-menu > a::after {
  content: '\f107';
  font-family: FontAwesome;
  float: right;
  vertical-align: top;
  transition: all 0.3s ease-in-out;
}
.category-menu-list > ul > li.right-menu:hover > a::after {
  -webkit-transform: rotate(-90deg);
          transform: rotate(-90deg);
}
.category-menu-list > ul > li.rx-parent > a,
.category-menu-list > ul > li.rx-show a {
  font-size: 14px;
  font-weight: 400;
  color: #242424;
}
.rx-parent {
  cursor: pointer;
}
.category-menu-list > ul > li.rx-parent:hover {
  color: #e23e57;
}
.rx-parent a.rx-default i,
.rx-parent a.rx-show i {
  position: absolute;
  left: 0;
  top: 12px;
}
li.rx-parent a.rx-default::after {
  content: '\f055';
  font-family: fontawesome;
  position: absolute;
  left: 29px;
  top: 7px;
}
li.rx-parent a.rx-show::after {
  content: '\f056';
  font-family: fontawesome;
  position: absolute;
  left: 29px;
  top: 4px;
}
.category-menu-list ul > li span.fa {
  float: left;
  padding-top: 13px;
  margin-left: -15px;
  margin-right: 15px;
}
.rx-child,
.category-menu-list ul li a.rx-show {
  display: none;
}
.category-menu-list ul li.rx-change a.rx-show {
  display: block;
}
.category-menu-list ul li.rx-change a.rx-default {
  display: none;
}
.cat-dropdown,
.cat-mega-menu {
  position: absolute;
  top: 0;
  left: 100%;
  background: #ffffff;
  z-index: 999;
  width: 220px;
  border: 1px solid #e5e5e5;
  box-shadow: none;
  border: 1px solid #ededed;
  box-shadow: 4px 4px 4px -3px #808080;
  opacity: 0;
  -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=0)';
  visibility: hidden;
  transition: all 0.3s ease-in-out;
}
.cat-dropdown li,
.cat-mega-menu ul li {
  list-style: none;
}
.cat-dropdown > li > a {
  font-size: 14px;
}
.cat-dropdown > li > a:hover {
  color: #e23e57;
}
.cat-mega-menu {
  width: 440px;
  padding: 30px;
  overflow: hidden;
}
li.right-menu .cat-mega-menu > li,
li.right-menu .cat-mega-menu-2 > li {
  width: 50%;
  float: left;
  list-style: none;
}
li.right-menu .cat-mega-menu-2 > li {
  width: 50%;
}
.cat-mega-menu > li.cat-mega-title > a {
  font-weight: 500;
  text-transform: uppercase;
  font-size: 16px;
  margin-bottom: 15px;
  display: block;
  line-height: 20px;
  position: relative;
}
.cat-mega-menu > li > ul > li > a {
  font-size: 14px;
  display: block;
  line-height: 30px;
  font-weight: 400;
  color: #666;
  text-transform: capitalize;
}
.cat-mega-menu > li > ul > li > a:hover {
  color: #e23e57;
}
.category-menu-list > ul > li:hover .cat-dropdown,
.category-menu-list > ul > li:hover .cat-mega-menu {
  opacity: 1;
  -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=100)';
  visibility: visible;
}
.header-top-search {
  position: relative;
}
.search-categories > form {
  position: relative;
  width: 100%;
  float: left;
  height: 50px;
  color: #a9a9a9;
}
@media only screen and (max-width: 991px) {
  /*Category MenuHome | Two & Three Style*/
  .category-menu-list > ul > li.right-menu > a:after {
    display: none;
  }
  .category-menu {
    width: 100%;
    position: relative;
  }
  @media only screen and (max-width: 575px) {
    .category-menu {
      width: 85%;
    }
    .slider-with-category-menu .category-menu {
      width: 100% !important;
    }
  }
  .cat-mega-menu,
  .cat-dropdown {
    position: static;
    z-index: 9;
    opacity: 1;
    -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=100)';
    visibility: visible;
    background-color: transparent;
    padding: 0;
    box-shadow: none;
    border: 0;
    width: 100%;
    transition: none;
  }
  .category-menu-list > ul > li i {
    position: absolute;
    right: 0;
    top: 0;
    cursor: pointer;
    height: 40px;
    width: 40px;
    line-height: 40px;
    z-index: 9;
  }
  .cat-mega-menu > li > a::before {
    content: '';
    position: absolute;
    top: 20px;
    left: 0;
    width: 6px;
    height: 2px;
    background: #606060;
  }
  .category-menu-list > ul > li i:before {
    background-color: #606060;
    width: 8px;
    height: 2px;
    content: '';
    position: absolute;
    left: 50%;
    margin-left: -4px;
    margin-top: -1px;
    top: 50%;
  }
  .category-menu-list > ul > li i:after {
    background-color: #606060;
    width: 2px;
    height: 8px;
    content: '';
    position: absolute;
    left: 50%;
    margin-top: -4px;
    margin-left: -1px;
    top: 50%;
    transition: all 0.3s ease 0s;
    -webkit-transform: scaleY(0);
    transform: scaleY(0);
  }
  .category-menu-list > ul > li i.expand:after {
    -webkit-transform: scaleY(1);
    transform: scaleY(1);
  }
  li.right-menu .cat-mega-menu > li {
    flex: 0 0 100%;
    width: 100%;
    padding: 0;
    position: relative;
  }
  .cat-mega-menu > li.cat-mega-title > a:after {
    display: none;
  }
  .cat-mega-menu > li.cat-mega-title > a,
  .cat-mega-menu > li > ul > li > a,
  .cat-dropdown > li > a {
    padding: 10px 25px 10px 35px;
    line-height: 18px;
    font-size: 13px;
    color: #7a7a7a;
    font-weight: normal;
    position: relative;
    margin: 0;
    border-bottom: 1px solid #e8e8e8;
    display: block;
    text-transform: inherit;
  }
  .cat-mega-menu > li.cat-mega-title:last-child > a,
  .cat-mega-menu > li > ul > li:last-child > a,
  .cat-dropdown > li:last-child > a {
    border-bottom: 0;
  }
}
/*Begin Header Bottom Menu Area*/
.fb-navigation {
  position: relative;
  z-index: 5;
}

.fb-navigation > ul > li .hb-dropdown,
.fb-navigation > ul > li .hb-dropdown li ul {
  -webkit-box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  visibility: hidden;
  opacity: 0;
  z-index: 0;
}

.fb-navigation ul {
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
  white-space: nowrap;
}

.fb-navigation ul li {
  list-style: none;
}

.fb-navigation > ul > li {
  display: inline-block;
  font-size: 14px;
  position: relative;
  padding-right: 24px;
}

.fb-navigation > ul > li > a {
  display: block;
  padding: 10px 0 11px;
  font-size: 16px;
  font-weight: 500;
  color: #ffffff;
  text-transform: uppercase;
  position: relative;
}

.fb-navigation > ul > li.dropdown-holder > a::after,
.fb-navigation > ul > li.megamenu-holder > a::after {
  content: '\f3d0';
  font-family: Ionicons;
  font-weight: 400;
  position: absolute;
  left: 58px;
  top: 15px;
  transition: all 0.3s ease-in-out;
}
.fb-navigation > ul > li:hover > a::after {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
}

.fb-navigation > ul > li.megamenu-holder {
  position: inherit;
}

.fb-navigation > ul > li:hover > a {
  color: #e23e57;
}

.fb-navigation > ul > li .hb-dropdown {
  position: absolute;
  left: 0;
  top: 100%;
  background: #fff;
  width: 250px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  padding: 10px 0;
  transition: all 0.3s ease-in-out 0s;
  -webkit-transform: translateY(30px);
  transform: translateY(30px);
}

.fb-navigation > ul > li .hb-dropdown li {
  display: block;
  position: relative;
}
.fb-navigation > ul > li .hb-dropdown-2 > li:nth-child(-n + 2)::before {
  content: '\f3d0';
  position: absolute;
  font-family: 'Ionicons';
  right: 15px;
  top: 5px;
  transition: all 0.3s ease-in-out;
}

.fb-navigation > ul > li .hb-dropdown-2 > li:hover:nth-child(-n + 2)::before {
  -webkit-transform: rotate(-90deg);
          transform: rotate(-90deg);
  color: #e23e57;
}
.header-2 .fb-navigation > ul > li .hb-dropdown-2 > li:hover:nth-child(-n + 2)::before {
  color: #ff6d02;
}
.fb-navigation > ul > li .hb-dropdown li ul,
.fb-navigation > ul > li .megamenu {
  -ms-transform: translateY(30px);
  position: absolute;
  background: #fff;
}

.fb-navigation > ul > li .hb-dropdown li a {
  color: #2c2c2c;
  display: block;
  padding: 5px 20px;
}

.fb-navigation > ul > li .hb-dropdown li:hover > a,
.fb-navigation > ul > li .megamenu > li > a:hover,
.fb-navigation > ul > li .hb-dropdown li.active > a {
  color: #e23e57;
}

.fb-navigation > ul > li .hb-dropdown li a i {
  font-size: 16px;
  margin-right: 5px;
}

.fb-navigation > ul > li .hb-dropdown li ul {
  left: 100%;
  top: 0;
  width: 250px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  padding: 10px 0;
  transition: all 0.3s ease-in-out 0s;
  -webkit-transform: translateY(30px);
  transform: translateY(30px);
}

.fb-navigation > ul > li .hb-dropdown li:hover > ul {
  -webkit-transform: scaleY(1);
  transform: scaleY(1);
  visibility: visible;
  opacity: 1;
  z-index: 1;
}

.fb-navigation > ul > li .megamenu {
  display: flex;
  justify-content: space-between;
  transition: all 0.3s ease-in-out 0s;
  visibility: hidden;
  opacity: 0;
  -webkit-transform: translateY(30px);
  transform: translateY(30px);
  z-index: 0;
  right: auto;
  padding: 30px 15px;
  left: 0;
  top: 100%;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  width: 100%;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .fb-navigation > ul > li .megamenu {
    padding: 30px 5px;
  }
}
.fb-navigation > ul > li .megamenu > li {
  padding: 0 15px;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .fb-navigation > ul > li .megamenu > li {
    padding: 0 12px;
  }
}
.fb-navigation > ul > li .megamenu > li > a {
  font-weight: 700;
  color: #242424;
  text-transform: uppercase;
  padding: 8px 0 5px;
  display: block;
  border-bottom: 1px solid #242424;
  margin-bottom: 10px;
}
.fb-navigation > ul > li .megamenu li ul {
  padding-left: 0;
  margin-bottom: 0;
}
.fb-navigation > ul > li .megamenu li ul li {
  list-style: none;
}
.fb-navigation > ul > li .megamenu li ul li a {
  color: #2c2c2c;
  font-size: 14px;
  padding: 7px 0;
  display: block;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .fb-navigation > ul > li .megamenu li ul li a {
    font-size: 13px;
  }
}
.fb-navigation > ul > li .megamenu li ul li a:hover {
  color: #e23e57;
}
.header-2 .fb-navigation > ul > li .megamenu li ul li a:hover {
  color: #ff6d02;
}
.fb-navigation > ul > li .megamenu.is-visible {
  -webkit-transform: scaleY(1);
  transform: scaleY(1);
  visibility: visible;
  z-index: 1;
}
.fb-navigation > ul > li:hover .hb-dropdown,
.fb-navigation > ul > li:hover .megamenu {
  -webkit-transform: scaleY(1);
  transform: scaleY(1);
  visibility: visible;
  opacity: 1;
  z-index: 1;
}

.fb-navigation-2 ul li a,
.fb-navigation-3 ul li a {
  color: #454545;
}

.fb-navigation-2 ul li a:hover,
.fb-navigation-3 ul li a:hover {
  color: #0b88ee;
}

.fb-navigation-2 {
  float: left;
}

.fb-navigation-3 {
  z-index: 70;
}

.fb-navigation-3 ul li:first-child a {
  padding-left: 0;
}
/*----------------------------------------*/
/*  02.2 Slider
/*----------------------------------------*/
.single-slide.align-center-left {
  align-items: center;
  display: flex;
}
.slider-content {
  position: absolute;
  padding-left: 58px;
}
.slider-content > h2,
.slider-content > h3 {
  color: #ffffff;
  text-transform: uppercase;
  font-size: 45px;
  font-weight: 600;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .slider-content > h2,
  .slider-content > h3 {
    font-size: 36px;
    margin-bottom: 15px;
  }
  .slider-content > h5 {
    font-size: 22px !important;
  }
}
.slider-content > h3 {
  margin-bottom: 15px;
}
.slider-content > h5 {
  color: #ffffff;
  text-transform: uppercase;
  font-weight: 400;
  font-size: 25px;
  letter-spacing: 1.5px;
  margin-bottom: 25px;
}
@media only screen and (max-width: 767px) {
  .slider-active .owl-nav {
    display: none;
  }
  .slider-content {
    padding-left: 30px;
  }
}
@media only screen and (max-width: 767px) {
  .slider-content > h2 {
    font-size: 30px !important;
    margin-bottom: 18px;
  }
  .slider-content > h3 {
    font-size: 25px !important;
    margin-bottom: 20px;
  }
  .slider-content > h5 {
    font-size: 18px !important;
  }
}
@media only screen and (max-width: 991px) {
  .slider-content > h2 {
    font-size: 40px;
    margin-bottom: 10px;
  }
  .slider-content > h3 {
    font-size: 35px;
  }
  .slider-content > h5 {
    font-size: 28px;
  }
}
@media only screen and (max-width: 767px) {
  .slider-content > h2 {
    font-size: 40px;
    margin-bottom: 18px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .slider-area .owl-dots {
    right: 421px;
    left: auto;
  }
}
@media only screen and (max-width: 575px) {
  .slider-area .owl-dots {
    right: 332px;
    left: auto;
  }
  .slider-area .owl-dots {
    right: 185px;
    left: auto;
  }
}
/*Slider Area Four*/
@media only screen and (max-width: 479px) {
  .slider-area-4 .slider-content {
    padding-left: 30px !important;
  }
  .slider-area-4 .slider-content > h2 {
    font-size: 28px !important;
  }
  .slider-area-4 .slider-content > h3 {
    font-size: 25px !important;
  }
  .slider-area-4 .slider-content > h5 {
    font-size: 13px !important;
  }
}
/*----------------------------------------*/
/*  02.3 FB's Customer Support
/*----------------------------------------*/
.customer-support_info {
  padding: 35px 30px;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .customer-support_info {
    padding: 35px 0;
  }
  .customer-support_text > i {
    font-size: 45px !important;
  }
}
@media only screen and (min-width: 768px) and (max-width: 992px) {
  .customer-support_info {
    padding: 35px 0;
    text-align: center;
  }
  .customer-support_text > i {
    color: #e23e57;
    font-size: 45px !important;
    float: unset !important;
    padding-right: 0;
  }
  .customer-support_date {
    font-size: 16px !important;
  }
}
@media only screen and (max-width: 767px) {
  .customer-support_info {
    padding: 0;
    text-align: center;
  }
}
.customer-support_text > i,
.fb-customer_support-2 .customer-support_text > i,
.fb-customer_support-3 .customer-support_text > i {
  color: #e23e57;
  font-size: 48px;
  display: block;
  float: left;
  padding-right: 15px;
  line-height: 50px;
}
.fb-customer_support-2 .customer-support_text > i {
  color: #ff6d02;
}
.customer-support_text-3 > i {
  color: #2c2c2c;
}
@media only screen and (max-width: 767px) {
  .customer-support_text > i,
  .fb-customer_support-2 .customer-support_text > i {
    float: unset;
    padding-right: 0;
  }
}
.customer-support_date {
  color: #2c2c2c;
  font-size: 18px;
  font-weight: 600;
  display: block;
}
.customer-support_service {
  font-size: 14px;
  font-weight: 400;
  color: #777;
}
/*----------------------------------------*/
/*  02.4 FB's Product Area
/*----------------------------------------*/
.single-product {
  padding: 30px 20px 31px;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .single-product {
    padding: 30px 20px 31px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  /*FB's Product With Banner*/
  .fb-product_with_banner .fb-product_active .single-product {
    padding: 30px 8.6px 21px;
  }
  /*FB's Featured Product With Banner*/
  .fb-featured-pro_with_banner .fb-product_active .single-product {
    padding: 30px 8.6px 34px;
  }
}
.single-product:hover .sticker,
.single-product:hover .sticker-2 {
  opacity: 0;
}
.product-img {
  position: relative;
  overflow: hidden;
}
.product-img > a {
  display: block;
}
.secondary-img {
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
  transition: all 0.5s ease-in-out;
  width: 100%;
}
.product-img:hover .secondary-img,
.list-single_product:hover .secondary-img {
  opacity: 1;
}
.product-content > h2 {
  color: #666666;
  margin-top: 10px;
  display: block;
  line-height: 20px;
  text-transform: capitalize;
  font-size: 14px;
  font-weight: 400;
  height: 50px;
}
#list-view .product-content > h2 {
  height: auto;
}
/*Rating Box*/
.rating-box > ul > li {
  display: inline-block;
  font-size: 14px;
}
.rating-box > ul > li > a {
  color: #999;
}
.rating-box > ul > li > a:hover {
  color: #e23e57;
}
.rating-box > ul > li i {
  color: #ffc315;
}
.rating-box > ul > li.no-star i {
  color: #e9e9e9;
}
/*Price Box*/
.price-box > .new-price {
  color: #e23e57 !important;
  font-size: 18px;
  padding-right: 5px;
  font-weight: 600;
}
.price-box > .old-price {
  font-size: 14px;
  color: #999;
  font-weight: 400;
  text-decoration: line-through;
}

.price-box #detail-price {
  font-size: 18px !important;
}

/*Product Action*/
.product-action {
  position: absolute;
  top: 40px;
  left: 27px;
}
.product-action li {
  background: #333;
  height: 36px;
  width: 36px;
  line-height: 36px;
  text-align: center;
  margin-bottom: 5px;
  border-radius: 5px;
  transition: all 0.3s ease-in-out;
}
.product-action li:hover {
  background: #e23e57;
}
.product-action li {
  display: block;
  -webkit-transform: scale(0);
          transform: scale(0);
}
.product-action li.shopping-cart_link {
  transition: all 300ms ease-in-out;
}
.product-action li.quick-view-btn {
  transition: all 400ms ease-in-out;
}
.product-action li.single-product_link {
  transition: all 500ms ease-in-out;
}
.single-product:hover .product-action li {
  -webkit-transform: scale(1);
          transform: scale(1);
}
.product-action li > a > i {
  color: #ffffff;
  display: block;
}
/*----------------------------------------*/
/* 02.5 FB's Banner With List Product
/*----------------------------------------*/
.fb-banner_with_list-product .btn-group {
  width: 100%;
}
.fb-banner_with_list-product .subcategories-trigger {
  background: transparent;
  border: none;
  color: #333;
  padding: 0;
  position: relative;
  left: 20px;
  font-size: 20px;
  display: none;
}
.fb-banner_with_list-product .subcategories-list {
  left: 18px;
  width: 190px;
  font-size: 14px;
  top: 45px;
}
.fb-banner_with_list-product .subcategories-trigger.is-active::after {
  content: '\f2d3';
  font-family: fontawesome;
}
.fb-banner_with_list-product .subcategories-trigger.is-active i {
  font-size: 0;
}
@media only screen and (max-width: 991px) {
  .fb-banner_with_list-product .subcategories-trigger {
    display: block;
  }
}
/* FB's List Product Menu*/
.list-product_menu > li {
  display: inline-block;
  margin: 0 30px 0 0;
}
.list-product_menu > li:first-child {
  margin-left: 21px !important;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .list-product_menu > li {
    display: inline-block;
    margin: 0 16px 0 0 !important;
  }
}
@media only screen and (max-width: 991px) {
  .list-product_menu {
    display: none;
  }
}
.list-product_menu > li > a {
  font-size: 14px;
  padding: 15px 0 12px;
  line-height: 25px;
  z-index: 1;
  display: block;
  position: relative;
}
/* FB's List Product*/
.fb-product_list_nav {
  border-top: 2px solid #232f3e;
  background: #ffffff;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
}
.list-single_product {
  position: relative;
  border-right: 1px solid #ededed;
  width: 100%;
  border-top: 1px solid #ededed;
  padding: 17px 20px 0;
  height: 100%;
}
.list-product_img {
  float: right;
  width: 41%;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .list-product_img {
    width: 51%;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .list-product_img {
    width: 54%;
  }
}
.list-product_content {
  padding: 63.5px 0 73.8px;
}
@media only screen and (max-width: 767px) {
  .list-product_img {
    float: unset;
    width: 100%;
  }
  .list-product_content {
    padding: 0 22px 35px;
  }
}
.list-product_content > h2 {
  margin: 0 0 15px;
}
.list-single_product .rating-box,
.list-single_product .price-box {
  transition: all 0.3s ease-in-out;
  padding-bottom: 13px;
}
.list-product_content .rating-box > ul > li > i {
  font-size: 12px;
}
.list-product_content .price-box {
  padding: 0;
}
.list-product_content .new-price {
  color: #333333;
  font-weight: 500;
}
.list-product_action {
  top: 120px;
  left: 20px;
}
.list-product_action > ul > li {
  display: inline-block;
}
/*----------------------------------------*/
/* 02.6 FB's Banner With New Product
/*----------------------------------------*/
.fb-new_product_wrap {
  padding: 20px;
}
.fb-new_product_wrap .owl-item {
  padding: 0 10px;
}
.fb-new_product_wrap .single-product {
  padding: 0;
}
.fb-new_product_wrap .product-content > h2 {
  margin: 0 0 13px;
}
.fb-new_product_wrap .product-action {
  top: 0;
  left: 15px;
}
/*----------------------------------------*/
/* 02.7 FB's Branding
/*----------------------------------------*/
.fb-branding {
  position: relative;
  background: white;
  padding: 20px;
  border: 1px solid #ededed;
}
.fb-branding img {
  transition: all 0.5s ease-in-out;
}
.fb-branding img:hover {
  -webkit-transform: scale3d(1.05, 1.05, 1.05);
  transform: scale3d(1.05, 1.05, 1.05);
}
/*----------------------------------------*/
/* 02.8 FB's Footer
/*----------------------------------------*/
/*FB's Footer Top*/
.fb-footer_top {
  position: relative;
  background: #e23e57;
  padding: 18px 0;
}
.fb-footer_top-2 {
  background: #2874f0;
}
.fb-footer_top-3 {
  background: #232f3e;
}
@media only screen and (max-width: 767px) {
  .fb-newsletters {
    text-align: center;
  }
}
.fb-newsletters h2.newsletters-headeing {
  margin: 0 0 15px 0;
  line-height: 20px;
  color: #ffffff;
  position: relative;
  display: block;
  font-size: 24px;
  font-weight: 600;
  text-transform: uppercase;
}
@media only screen and (max-width: 479px) {
  .fb-newsletters h2.newsletters-headeing {
    font-size: 20px;
  }
}
.fb-newsletters p.short-desc {
  color: #ffffff;
  font-size: 16px;
  font-weight: 300;
  line-height: 16px;
  display: block;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .fb-newsletters p.short-desc {
    font-size: 14px;
  }
}
@media only screen and (max-width: 479px) {
  .fb-newsletters p.short-desc {
    font-size: 14px;
  }
}
.fb-newsletters_form {
  position: relative;
  float: left;
  width: 100%;
}
.fb-newsletters_form input {
  height: 50px;
  background: #ffffff;
  border: 0;
  color: #666666;
  padding: 10px 140px 10px 10px;
  display: block;
  width: 100%;
  border-radius: 25px;
  font-size: 13px;
}
.fb-newsletters_form .form-group {
  margin-bottom: 0;
}
.fb-newsletters_form .btn {
  position: absolute;
  top: 0;
  right: 0;
  height: 50px;
  background: #232f3e;
  border: 0;
  padding: 0;
  color: white;
  padding: 0 20px;
  font-size: 14px;
  font-weight: 700;
  display: inline-block;
  vertical-align: top;
  line-height: 50px;
  box-shadow: none;
  text-transform: uppercase;
  border-radius: 25px;
  transition: all 0.3s ease-in-out;
}
@media only screen and (max-width: 479px) {
  .fb-newsletters_form .btn {
    font-size: 10px;
  }
}
.fb-newsletters_form-3 .btn {
  background: #fdda1b;
  top: -1px;
  right: -1px;
  height: 52px;
  color: #2c2c2c;
}
.fb-newsletters_form .btn:hover {
  background: #333333;
  color: #ffffff;
}
/*FB's Footer Middle Top*/
.footer-middle_top {
  border-bottom: 1px solid #ededed;
}
.fb-footer_widget h3.fb-footer-widget-headeing {
  position: relative;
  display: block;
  font-size: 16px;
  font-weight: 600;
  line-height: 34px;
  color: #333333;
  margin: 0 0 9px 0;
  text-transform: uppercase;
}
.fb-footer_widget ul {
  margin-bottom: 15px;
  background: none;
}
.fb-footer_widget ul > li {
  display: block;
  margin: 0;
  line-height: 30px;
  border: 0;
  padding: 0;
}
.fb-footer_widget ul > li a {
  font-size: 14px;
  color: #666666;
  text-transform: capitalize;
  position: relative;
  display: inline-block;
  padding-left: 15px;
  font-weight: 400;
  transition: all 0.3s ease-in-out;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .fb-footer_widget ul > li a {
    font-size: 13px;
  }
}
.fb-footer_widget ul > li a:hover {
  color: #e23e57;
  padding: 0;
}
.fb-footer_widget-2 ul > li a:hover {
  color: #2874f0 !important;
}
.fb-footer_widget-3 ul > li a:hover {
  color: #fdda1b;
}
.fb-footer_widget ul > li a::before {
  display: block;
  content: '\f105';
  font-family: 'fontawesome';
  position: absolute;
  font-size: 14px;
  top: -1px;
  left: 0;
  line-height: 30px;
  transition: all 0.3s ease-in-out;
}
@media (max-width: 991px) {
  .fb-footer_middle {
    text-align: center;
  }
}
.fb-footer_widget ul > li a:hover::before {
  opacity: 0;
}
.footer-widget_short-desc {
  font-size: 14px;
  line-height: 30px;
  margin-bottom: 20px;
  color: #666666;
  text-transform: capitalize;
}
.footer-widget_address h2 {
  font-size: 16px;
  color: #333;
  margin-bottom: 17px;
  font-weight: 500;
}
.footer-widget_address p {
  line-height: 30px;
  color: #666666;
  font-size: 14px;
}
/*Footer Widget Social Link*/
.footer-widget-social-link {
  position: relative;
  margin: 15px 0 21px;
}
.footer-widget-social-link ul li {
  display: inline-block;
  margin: 0 25px 0 0;
  line-height: 32px;
}
.footer-widget-social-link ul li a {
  position: relative;
  display: inline-block;
  color: #b2b2b1;
  font-size: 18px;
  line-height: 30px;
  z-index: 1;
  transition: all 0.3s ease-in-out;
}
.footer-widget-social-link ul li a:hover {
  color: #e23e57;
}
.footer-widget-social-link-2 ul li a:hover {
  color: #e23e57 !important;
}
.footer-widget-social-link-3 ul li a:hover {
  color: #fdda1b;
}
/*Footer Tag Links*/
.footer-tag-links > ul > li {
  display: inline-block;
}
.footer-tag-links > ul > li:last-child > a {
  border-right: none;
}
.footer-tag-links a {
  display: inline-block;
  margin-bottom: 5px;
  padding: 0 5px;
  border-right: 1px solid #adadad;
  line-height: 1;
  font-weight: 400;
  color: #666666;
  font-size: 14px;
  transition: all 0.3s ease-in-out;
}
.footer-tag-links a:hover,
.footer-tag-links-2 a:hover {
  color: #e23e57;
}
.footer-tag-links-2 a:hover {
  color: #2874f0 !important;
}
.footer-tag-links-3 a:hover {
  color: #fdda1b;
}
/*Footer Bottom*/
.footer-bottom {
  background: #f7f7f7;
  padding: 10px 0;
}
.footer-bottom .copyright {
  color: #666;
  font-size: 14px;
  line-height: 30px;
}
@media only screen and (max-width: 767px) {
  .footer-bottom .copyright {
    text-align: center;
    padding-bottom: 10px;
  }
  .fotter-bottom_menu {
    text-align: center !important;
  }
}
.footer-bottom .copyright a {
  color: #e23e57;
}
.footer-bottom .copyright-2 a {
  color: #2874f0;
}
.footer-bottom .copyright-3 a {
  color: #fdda1b;
}
.footer-bottom .copyright a:hover {
  text-decoration: underline;
}
.fotter-bottom_menu {
  text-align: right;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .fotter-bottom_menu {
    text-align: center;
  }
}
.fotter-bottom_menu > ul > li {
  display: inline-block;
  padding-left: 15px;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .fotter-bottom_menu {
    text-align: center !important;
  }
}
.fotter-bottom_menu > ul > li > a {
  font-size: 14px;
  color: #666666;
  text-transform: uppercase;
  transition: all 0.3s ease-in-out;
}
.fotter-bottom_menu > ul > li > a:hover {
  color: #e23e57;
}
.fotter-bottom_menu-2 > ul > li > a:hover {
  color: #2874f0;
}
.fotter-bottom_menu-3 > ul > li > a:hover {
  color: #fdda1b;
}
/*----------------------------------------*/
/*  03. Home Page Two
/*----------------------------------------*/
/*Header Top*/
.header-2 .header-top-nav {
  border-bottom: 1px solid #e8e8e8;
}
.header-2 .dropdown-menu ul > li > a:hover {
  color: #ff6d02;
}
.header-2 .single-top-selector > li > span,
.header-2 .top-selector-wrapper ul > li button,
.header-2 .user-block > li > a {
  color: #2c2c2c;
}
/* Header Middle*/
.header-2 .hm-searchbox {
  border: 1px solid #ff6d02;
  border-radius: 15px;
}
.header-2 .fb-search_btn {
  color: #ffffff;
  background: #ff6d02;
}
.header-2 .fb-search_btn:hover {
  color: #ffffff;
  background: #2874f0;
}
.header-2 .item-text,
.header-2 .item-total,
.header-2 .item-icon::before {
  color: #2c2c2c;
}
.header-2 .cart-item-count,
.header-2 .minicart .minicart-product-list li .minicart-product-image .product-quantity {
  background: #ff6d02;
}
.header-2 .minicart-product-details span,
.header-2.minicart .minicart-total span,
.header-2 .minicart .minicart-total span {
  color: #ff6d02;
}
.header-2 .minicart .minicart-product-list li .minicart-product-details h6 a:hover,
.header-2 .minicart .minicart-product-list li button.close:hover {
  color: #ff6d02;
}
.header-2 .fb-btn:hover {
  background: #ff6d02;
}

.header-bottom .delivery {
  align-items: center;
  float: right;
  padding-right: 0;
  margin-top: 4px;
}

@media (max-width: 991px) {
  .header-bottom .delivery {
    display: none !important;
  }
}

.header-bottom .delivery img,
.mobile-delivery .delivery img {
  width: 70px;
  margin-right: 5px;
}

.header-bottom .delivery p {
  margin-bottom: 0;
  font-size: 16px;
  line-height: 20px;
}
/*Header Bottom*/
@media only screen and (max-width: 991px) {
  .header-2 .header-bottom {
    background: transparent !important;
  }
}
.header-2 .fb-navigation > ul > li {
  padding: 0;
  margin-left: -3px;
}
.header-2 .fb-navigation > ul > li > a {
  padding: 15px 30px 15px 20px;
  color: #ffffff;
  text-transform: uppercase;
}

.header-2 .fb-navigation > ul > li.active > a,
.header-2 .fb-navigation > ul > li:hover > a {
  background: #1b66e2;
  color: #ffffff;
}
.header-2 .fb-navigation > ul > li ul li:hover > a,
.header-2 .fb-navigation > ul > li ul li.active > a {
  color: #ff6d02;
}
.header-2 .fb-navigation > ul > li.dropdown-holder > a::after,
.header-2 .fb-navigation > ul > li.megamenu-holder > a::after {
  right: 10px;
  top: 15px;
  left: auto;
}
/*Slider With Category Menu*/
.slider-with-category-menu .category-heading {
  background: #ff6d02;
}
.slider-with-category-menu .category-menu-list > ul > li:hover > a,
.slider-with-category-menu .cat-dropdown > li:hover > a,
.slider-with-category-menu .cat-mega-menu > li.cat-mega-title > a:hover,
.slider-with-category-menu .cat-mega-menu > li > ul > li > a:hover {
  color: #ff6d02;
}
.slider-with-category-menu .category-menu-list > ul > li > a,
.slider-with-category-menu .cat-dropdown > li > a {
  padding: 3px 0;
}
@media only screen and (max-width: 991px) {
  .slider-with-category-menu .category-menu-list {
    display: none;
  }
}
/*Slider Two*/
.slider-area-2 .fb-links:hover:hover {
  background: #ff6d02;
}
/*FB's Featured Product With Banner*/
.fb-featured-pro_with_banner .fb-section_title,
.fb-section_title-2 {
  border-top: 1px solid #ededed;
  border-bottom: 1px solid #ededed;
}
.fb-featured-pro_with_banner .fb-section_title-2 > h2,
.fb-featured-pro_with_banner .fb-section_title-2 > h2::before {
  background: #ffffff;
  color: #2c2c2c;
  margin: 0;
}
.fb-featured-pro_with_banner .fb-section_title-2 > h2 {
  padding-left: 40px;
  font-weight: 600;
}
.fb-featured-pro_with_banner .fb-section_title-2 > h2::before {
  content: '\f091';
  font-family: fontawesome;
  width: 40px;
}
.fb-featured-pro_with_banner .single-product {
  padding: 40px 20px 32.9px;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .fb-featured-pro_with_banner .single-product {
    padding: 53px 20px 60.6px;
  }
}
.fb-featured-pro_with_banner .product-action li:hover {
  background: #ff6d02;
}
.fb-featured-pro_with_banner .fb-product_active .owl-nav div.owl-prev:hover,
.fb-featured-pro_with_banner .fb-product_active .owl-nav div.owl-next:hover,
.fb-banner_with-new_product-2 .owl-nav div.owl-prev:hover,
.fb-banner_with-new_product-2 .owl-nav div.owl-next:hover,
.hot-deals-product .fb-list_product_active .owl-nav div.owl-prev:hover,
.hot-deals-product .fb-list_product_active .owl-nav div.owl-next:hover,
.new-product-2 .new-product_active-2 .owl-nav div.owl-prev:hover,
.new-product-2 .new-product_active-2 .owl-nav div.owl-next:hover,
.fb-branding-wrap-2 .fb-branding_active .owl-nav div.owl-prev:hover,
.fb-branding-wrap-2
  .fb-branding_active
  .owl-nav
  div.owl-next:hover
  .fb-list_product_active-2
  .owl-nav
  div.owl-prev:hover,
.fb-list_product_active-2 .owl-nav div.owl-next:hover {
  background: #ff6d02;
}
.fb-featured-pro_with_banner .fb-product_active .owl-nav div {
  -webkit-transform: translate(-18px, -43px);
          transform: translate(-18px, -43px);
}
.fb-featured-pro_with_banner .product-content > h2 > a:hover,
.fb-banner_with-new_product-2 .product-content > h2 > a:hover,
.hot-deals-product .product-content > h2 > a:hover,
.new-product-2 .product-content > h2 > a:hover {
  color: #ff6d02;
}
.fb-featured-pro_with_banner .price-box-2 .new-price,
.fb-featured-pro_with_banner .price-box-2 .new-price-2,
.hot-deals-product .price-box-2 .new-price-2 {
  font-size: 18px;
  color: #2c2c2c;
  padding-right: 5px;
  font-weight: 600;
}
.fb-featured-pro_with_banner .price-box-2 .new-price-2,
.hot-deals-product .price-box-2 .new-price-2 {
  color: #2874f0;
}
.fb-featured-pro_with_banner .price-box-2 > .old-price,
.hot-deals-product .price-box-2 .old-price {
  font-size: 14px;
  color: #999;
  font-weight: 400;
  text-decoration: line-through;
}
/*FB's Banner With New Product Two*/
.fb-banner_with-new_product-2 .video-games > h2 {
  background: #ffffff;
  color: #ea7954;
  border-top: 2px solid #ea7954;
  margin: -2px 0;
}
/*Video Game*/
.fb-banner_with-new_product-2 .video-games > h2::before {
  content: '\f11b';
  background: #ea7954;
}
.fb-banner_with-new_product-2 .fb-new_product_active .owl-nav div {
  -webkit-transform: translate(0, -235px);
          transform: translate(0, -235px);
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .fb-banner_with-new_product-2 .fb-new_product_active .owl-nav div {
    -webkit-transform: translate(0, -203px);
            transform: translate(0, -203px);
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .fb-banner_with-new_product-2 .fb-new_product_active .owl-nav div {
    -webkit-transform: translate(0, -278px);
            transform: translate(0, -278px);
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .fb-banner_with-new_product-2 .fb-new_product_active .owl-nav div {
    -webkit-transform: translate(0, -223px);
            transform: translate(0, -223px);
  }
}
@media only screen and (max-width: 479px) {
  .fb-banner_with-new_product-2 .fb-new_product_active .owl-nav div {
    -webkit-transform: translate(20px, -202px);
            transform: translate(20px, -202px);
  }
}
@media only screen and (min-width: 480px) and (max-width: 575px) {
  .fb-banner_with-new_product-2 .fb-new_product_active .owl-nav div {
    -webkit-transform: translate(0, -194px);
            transform: translate(0, -194px);
  }
}
.fb-banner_with-new_product-2 .product-action li:hover {
  background: #ff6d02;
}
/*Electronics*/
.fb-banner_with-new_product-2 .electronics > h2 {
  background: #ffffff;
  color: #3ebd81;
  border-top: 2px solid #3ebd81;
  margin: -2px 0;
  position: relative;
}
.fb-banner_with-new_product-2 .electronics > h2::before {
  content: '\f109';
  background: #3ebd81;
}
/*Phones & Tablets*/
.fb-banner_with-new_product-2 .phones-tablets > h2 {
  background: #ffffff;
  color: #a71053;
  border-top: 2px solid #a71053;
  margin: -2px 0;
}
.fb-banner_with-new_product-2 .phones-tablets > h2::before {
  content: '\f109';
  background: #a71053;
}
/*Computer & Laptop*/
.fb-banner_with-new_product-2 .computer-laptop > h2 {
  background: #ffffff;
  color: #118aa3;
  border-top: 2px solid #118aa3;
  margin: -2px 0;
  position: relative;
}
.fb-banner_with-new_product-2 .computer-laptop > h2::before {
  content: '\f109';
  background: #118aa3;
}
/*Hot Deals product*/
.hot-deals-product .fb-product_list_nav {
  border-top: none;
}
.hot-deals-product .fb-section_title > h2 {
  margin: 0;
  padding: 9px 15px 14px;
  font-size: 18px;
  font-weight: 600;
}
.hot-deals-product .list-product_img {
  float: left;
  width: 35%;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .hot-deals-product .list-product_img {
    width: 45%;
  }
}
@media only screen and (min-width: 576px) and (max-width: 991px) {
  .hot-deals-product .list-product_img {
    width: 100%;
  }
}
.hot-deals-product .list-product_content {
  padding: 0px 0 20px;
  width: 60%;
  float: right;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .hot-deals-product .list-product_content {
    width: 50%;
  }
}
@media only screen and (min-width: 576px) and (max-width: 991px) {
  .hot-deals-product .list-product_content {
    width: 100%;
  }
}
@media only screen and (max-width: 575px) {
  .hot-deals-product .list-product_img {
    width: 100%;
  }
  .hot-deals-product .list-product_content {
    width: 100% !important;
  }
  .hot-deals-product .list-product_action > ul > li {
    display: block;
  }
}
.hot-deals-product:hover .list-product_content .rating-box {
  opacity: 1;
}
.hot-deals-product .product-action li:hover {
  background: #ff6d02;
}
/*New Product 2*/
.new-product-2 .fb-section_title {
  border-bottom: 1px solid #ededed;
}
.new-product-2 .price-box > .new-price {
  color: #2c2c2c;
}
/*----------------------------------------*/
/*  04. Home Page Three
/*----------------------------------------*/
/*Header Top Contact Information*/
.header-3 .fb-contact-info > ul > li {
  display: inline-block;
  position: relative;
}
.header-3 .fb-contact-info > ul > li.email {
  padding-left: 30px;
}
.header-3 .top-selector-wrapper {
  text-align: right;
}
@media only screen and (max-width: 991px) {
  .header-3 .fb-contact-info {
    text-align: center;
  }
  .header-3 .top-selector-wrapper {
    text-align: center !important;
  }
}
.header-3 .fb-contact-info > ul > li.email::before {
  content: '';
  position: absolute;
  top: 9px;
  left: 14px;
  background: rgba(255, 255, 255, 0.4);
  width: 1px;
  height: 12px;
}
.header-3 .fb-contact-info > ul > li > span,
.header-3 .fb-contact-info > ul > li > a {
  color: #bdbdbd;
  font-size: 13px;
  transition: all 0.3s ease-in-out;
}
.header-3 .fb-contact-info > ul > li.email > a:hover {
  color: #fdda1b;
}
/*Header Dropdown | Language | Currency | My Account*/
.header-3 .dropdown-menu ul > li:hover > a {
  text-decoration: underline;
  color: unset;
}
.header-3 .top-selector-wrapper ul > li button {
  color: #dbdbdb;
}
.header-3 .single-top-selector > li > span {
  color: #bdbdbd;
}
.header-3 .single-top-selector li:last-child {
  margin-right: 0 !important;
}
.header-3 .language.last-child::after {
  content: none;
}
.header-3 .currency {
  position: relative;
  padding-right: 10px;
}
.header-3 .currency::after {
  content: '';
  position: absolute;
  top: 9px;
  right: 0;
  background: rgba(255, 255, 255, 0.4);
  height: 12px;
  width: 1px;
}
/*Header Middle*/
.header-3 .fb-search_btn,
.header-3 .minicart .minicart-product-list li .minicart-product-image .product-quantity {
  background: #fdda1b;
}
.header-3 .fb-search_btn:hover {
  color: #ffffff;
}
.header-3 .minicart .minicart-product-list li .minicart-product-details h6 a:hover,
.header-3 .minicart .minicart-product-list li button.close:hover,
.header-3 .minicart .minicart-total span,
.header-3 .minicart-product-details span {
  color: #fdda1b;
}
.header-3 .minicart-product-details span.item-size,
.header-3 .minicart-product-details span.item-color {
  margin: 0;
  color: #666666;
  font-size: 14px;
}
.header-3 .fb-btn:hover {
  background: #fdda1b;
  color: #ffffff;
}
.header-3 .cart-item-count {
  background: #fdda1b;
  color: #2c2c2c;
}
/*Header Bottom*/
.header-3 .fb-navigation > ul > li:hover > a,
.header-3 .fb-navigation > ul > li ul li.active > a,
.header-3 .fb-navigation > ul > li .hb-dropdown li:hover > a,
.header-3 .fb-navigation > ul > li .megamenu > li > a:hover,
.header-3 .fb-navigation > ul > li .megamenu li ul li a:hover {
  color: #fdda1b;
}
/*Slider With Banner Two*/
.slider-with-banner-2 .fb-section_title-2 > h2,
.fb-product_wrap-3 .fb-section_title-2 > h2 {
  margin: 0;
  padding-left: 50px;
  text-transform: uppercase;
  background: #3a3f5a;
  font-weight: 600;
}
.slider-with-banner-2 .fb-section_title-2 > h2 {
  height: 35px;
  line-height: 35px;
}
.slider-with-banner-2 .fb-section_title-2 > h2::before,
.fb-product_wrap-3 .fb-section_title-2 > h2::before {
  content: '\f2ab';
  font-family: 'Ionicons';
  background: transparent;
}
.slider-with-banner-2 .fb-section_title-2 > h2::before {
  top: -8px;
  font-size: 20px;
}
/*Fb's Single Product Slide*/
.fb-single-product_active .single-product {
  padding: 30px 20px 30px;
  margin: -1px 0 0;
}
.fb-single-product_active .sticker,
.sticker-2 {
  top: -3px;
}
.fb-single-product_active .product-content > h2 {
  margin: 0 0 10px;
}
.fb-single-product_active .price-box {
  padding: 10px 0 5px;
}
.fb-single-product_active .sticker-2,
.fb-product_active-3 .sticker-2 {
  background: #fdda1b;
}
.fb-single-product_active .product-action li:hover,
.fb-product_active-3 .product-action li:hover {
  background: #fdda1b;
}
.fb-single-product_active .product-content > h2 > a:hover {
  color: #fdda1b;
}
.fb-single-product_active .price-box > .new-price {
  color: #2c2c2c;
}
.fb-single-product_active .owl-nav div {
  background: transparent;
  color: #ffffff;
  font-size: 30px;
  -webkit-transform: translate(0, -35px);
          transform: translate(0, -35px);
}
.single-slide-wrap-2 .fb-single-product_active .owl-nav div {
  -webkit-transform: translate(0, -42px);
          transform: translate(0, -42px);
}
@media only screen and (max-width: 479px) {
  .fb-single-product_active .owl-nav div {
    color: #ffffff;
    -webkit-transform: translate(10px, -35px);
            transform: translate(10px, -35px);
  }
}
.fb-single-product_active .owl-nav div:hover {
  color: #fdda1b;
}
.fb-single-product_active .owl-nav div.owl-prev,
.fb-product_active-3 .owl-nav div.owl-prev {
  left: auto;
  right: 40px;
}
.fb-single-product_active .owl-nav div.owl-next,
.fb-product_active-3 .owl-nav div.owl-next {
  left: auto;
  right: 15px;
}
/*Countdown Three*/
.fb-single-product_active .count-period-list {
  background: #cdcdcd;
  text-align: center;
}
.fb-single-product_active .count-period-list > span {
  font-size: 11px;
  margin: 0 6px;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .bg-5,
  .bg-6 {
    min-height: 433px;
  }
  .fb-single-product_active .count-period-list > span {
    margin: 0 3px;
  }
  .fb-single-product_active .owl-nav div.owl-prev {
    right: 20px;
  }
  .fb-single-product_active .owl-nav div.owl-next {
    right: 0;
  }
  .fb-single-product_active .single-product {
    padding: 15px 20px 15px;
    margin: -1px 0 0;
  }
}
.fb-single-product_active .count-amount_list {
  background: #f2f2f2;
  text-align: center;
}
.fb-single-product_active .count-amount_list > span {
  margin: 0 10px;
  font-size: 13px;
}
.fb-single-product_active .count-amount_list > span:first-child {
  margin-left: 0;
}
/*Slider Area 2*/
.slider-area-3 .slider-content > h2 {
  font-size: 35px;
}
.slider-area-3 .slider-content > h3 {
  max-width: 250px;
  font-size: 30px;
}
.slider-area-3 .slider-content > h5 {
  font-size: 18px;
}
.slider-area-3 .fb-links:hover {
  background: #fdda1b;
}
/*FB's Product Three*/
.fb-product_wrap-3 .fb-section_title-2 {
  border: none;
}
.fb-product_wrap-3 .fb-section_title-2 > h2 {
  margin: 0;
  padding-left: 0;
  background: transparent;
  color: #2c2c2c;
}
.fb-product_wrap-3 .fb-section_title-2 > h2::before {
  display: none;
}
.fb-product_active-3 .product-content > h2 > a:hover,
.fb-product_active-3 .owl-nav div:hover {
  color: #fdda1b;
}
.fb-product_active-3 .price-box > .new-price {
  color: #2c2c2c;
}
.fb-product_active-3 .owl-nav div,
.children-books .owl-nav div,
.fb-product-list_active-3 .owl-nav div {
  background: transparent;
  font-size: 30px;
  color: #2c2c2c;
  -webkit-transform: translate(25px, -46px);
          transform: translate(25px, -46px);
}
/*Children's Books*/
.children-books .fb-section_title-2 {
  border: none;
}
.children-books .fb-section_title-2 > h2 {
  padding-left: 0;
  background: transparent;
  color: #2c2c2c;
  font-weight: 600;
}
.children-books .fb-section_title-2 > h2::before {
  content: none;
}
.children-books .fb-product_list_nav {
  border-top: none;
}
.children-books .owl-nav div.owl-prev:hover,
.children-books .owl-nav div.owl-next:hover {
  background: transparent;
  color: #fdda1b;
}
.children-books .owl-nav div {
  -webkit-transform: translate(10px, -46px);
          transform: translate(10px, -46px);
}
@media only screen and (max-width: 767px) {
  .children-books .owl-nav div {
    display: none;
  }
}
.children-books .owl-nav div.owl-prev {
  left: auto;
  right: 25px;
}
.children-books .sticker-2,
.children-books .product-action li:hover {
  background: #fdda1b;
}
.children-books .list-product_content > h2 > a:hover {
  color: #fdda1b;
}
/*FB's Product List 3*/
.fb-product-list_active-3 .single-product {
  padding: 0 20px 0;
}
.fb-product-list_active-3 .product-content > h2 {
  margin: 10px 0 13px;
}
.fb-product-list_active-3 .product-img {
  float: left;
  width: 30%;
}
.fb-product-list_active-3 .product-content {
  float: right;
  width: 60%;
}
.fb-product-list_active-3 .owl-nav div {
  -webkit-transform: translate(10px, -40px);
          transform: translate(10px, -40px);
}
.fb-product-list_active-3 .owl-nav div:hover {
  color: #fdda1b;
}
.fb-product-list_active-3 .owl-nav div.owl-prev {
  right: 25px;
}
.fb-product-list_active-3 .price-box > .new-price {
  color: #2c2c2c;
}
.fb-product-list_active-3 .product-name a:hover {
  color: #fdda1b;
}
/*----------------------------------------*/
/*  05. Home Page Four
/*----------------------------------------*/
/*Header Top*/
.header-4 .header-top-nav {
  border-bottom: 1px solid #ededed;
}
.header-4 .single-top-selector > li > span {
  color: #666666;
}
.header-4 .top-selector-wrapper ul > li button,
.header-4 .user-block > li > a {
  color: #2c2c2c;
}
/*Header Middle*/
.header-4 .fb-navigation > ul > li > a {
  color: #2c2c2c;
  font-weight: 500;
}
.header-4 .fb-navigation > ul > li:hover > a,
.header-4 .fb-navigation > ul > li .hb-dropdown li.active > a {
  color: #e23e57;
}
.header-4 .fb-navigation {
  float: left;
  width: 100%;
}
.header-4 .header-middle-mini-cart {
  display: flex;
  justify-content: flex-end;
}
.header-4 .item-text,
.header-4 .item-icon::before,
.header-4 .item-total {
  color: #2c2c2c;
}
/*Header Bottom*/
.header-4 .hb-searchbox {
  margin-top: 5px;
}
@media only screen and (max-width: 991px) {
  .header-4 .hb-searchbox {
    margin-top: 30px;
    margin-bottom: 30px;
  }
  .header-4 .hb-contact-info ul > li {
    padding: 0 !important;
    margin-top: 40px;
  }
}
.header-4 .hb-contact-info ul > li {
  color: #ffffff;
  padding: 15px 0;
  float: right;
}
@media only screen and (max-width: 575px) {
  .header-4 .hb-contact-info ul > li {
    float: unset;
    text-align: center;
  }
}
.header-4 .fb-search_btn {
  color: #ffffff;
  background: #2c2c2c;
}
.header-4 .fb-search_btn:hover {
  background: #e23e57;
}
/*Slider Area 3*/
.slider-area-4 .slider-content > h5 {
  font-size: 15px !important;
  max-width: 520px;
  text-transform: capitalize;
}
.slider-area-4 .slider-content > .slider-desc {
  font-size: 15px !important;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
@media only screen and (max-width: 767px) {
  .slider-area-4 .slider-content > h5.slider-desc {
    font-size: 13px !important;
    max-width: 350px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
}
@media only screen and (max-width: 479px) {
  .bg-7,
  .bg-8 {
    min-height: 310px !important;
  }
  .slider-area-4 .slider-content {
    padding-left: 30px !important;
    float: left;
    width: 100%;
  }
  .slider-area-4 .slider-content > h3 {
    font-size: 18px !important;
  }
}
/*List Single Slide Product*/
.list-single-slide_product .list-product-nav {
  border: 1px solid #ededed;
  padding: 10px;
}
.list-single-slide-active .list-product_img {
  float: left;
  width: 50%;
}
.list-single-slide-active .list-product_img img {
  -webkit-transform: scale3d(1, 1, 1);
  transform: scale3d(1, 1, 1);
  transition: all 1s ease-in-out;
  width: 100%;
}
.list-single-slide-active .list-product:hover .list-product_img img {
  -webkit-transform: scale3d(1.1, 1.1, 1.1);
  transform: scale3d(1.1, 1.1, 1.1);
  transition: all 1s ease-in-out;
}
.list-single-slide-active .product-content {
  float: right;
  width: 40%;
}
.list-single-slide-active .product-content p.product-List {
  text-transform: capitalize;
  color: #666;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  display: block;
}
/*Our Products*/
.our-products .fb-product_list_nav {
  border-top: transparent;
  background: #f3f3f3;
  box-shadow: none;
  border: 1px solid #ededed;
}
.our-products .fb-section_title-2 > h2::before {
  content: '\f356';
  font-family: 'Ionicons';
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .our-products .fb-section_title-2 > h2::before {
    height: 53px;
    line-height: 53px;
  }
}
.fb-product_active-4 .owl-nav div {
  -webkit-transform: translate(-15px, -43px);
          transform: translate(-15px, -43px);
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .fb-product_active-4 .owl-nav div {
    -webkit-transform: translate(-15px, -37px);
            transform: translate(-15px, -37px);
  }
}
@media only screen and (max-width: 576px) {
  .fb-product_active-4 .owl-nav div {
    -webkit-transform: translate(-15px, -38px);
            transform: translate(-15px, -38px);
  }
}
.single-slide-wrap-2 .single-product {
  border: 1px solid #ededed;
}
.fb-product_active-4 .price-box > .new-price-2 {
  color: #2c2c2c;
  font-size: 18px;
  padding-right: 5px;
  font-weight: 600;
}
.single-slide-wrap-2 .sticker {
  background: #94c341;
}
.single-slide-wrap-2 .sticker-2 {
  background: #e23e57;
}
.single-slide-wrap-2 .product-action li:hover {
  background: #e23e57;
}
.single-slide-wrap-2 .product-content > h2 > a:hover {
  color: #e23e57;
}
.single-slide-wrap-2 .owl-nav div:hover {
  color: #e23e57;
}
/*Single Slide Wrap 2*/
.single-slide-wrap-2 .fb-section_title-2 > h2::before {
  content: '\f349';
  font-family: 'Ionicons';
  background: transparent;
}
/*Featured Products*/
.featured-products-4 .fb-product_list_nav {
  box-shadow: none;
  border: 1px solid #ededed;
}
.featured-products-4 .fb-section_title-2 {
  float: left;
  width: 261px;
}
.featured-product-active-4 .owl-nav div {
  -webkit-transform: translate(-15px, -42px);
          transform: translate(-15px, -42px);
}
@media only screen and (max-width: 479px) {
  .featured-product-active-4 .owl-nav div {
    -webkit-transform: translate(0px, -98px);
            transform: translate(0px, -98px);
  }
  .featured-products-4 .fb-section_title-2 {
    width: 100%;
  }
}
/*Latest Blog | Home Four Style*/
@media only screen and (max-width: 575px) {
  .header-4 .category-menu {
    width: 100%;
  }
}
.latest-blog-wrap .fb-section_title-2 > h2 {
  text-transform: uppercase;
  font-weight: 600;
}
.latest-blog-wrap .fb-section_title-2 > h2::before {
  content: '\f127';
  font-family: 'Ionicons';
}
.fb-single-product-slide_wrap-2 {
  padding: 20px !important;
}
.latest-blog-wrap .single-product {
  padding: 0;
}
.latest-blog-wrap .latest-blog-post .latest-blog-post_title {
  line-height: 30px;
  padding: 10px 0;
  color: #2c2c2c;
  font-size: 16px;
  text-transform: capitalize;
  margin-bottom: 15px;
  font-weight: 600;
  position: relative;
  display: block;
}
.latest-blog-wrap .latest-blog-post .latest-blog-post_title::before {
  position: absolute;
  bottom: 0;
  left: 0;
  content: '';
  width: 55px;
  height: 1px;
  background: #ededed;
}
.latest-blog-wrap .latest-blog-post .post-meta span {
  color: #666;
  font-size: 13px;
  line-height: 25px;
  text-transform: capitalize;
  display: inline-block;
  margin-right: 20px;
}
.latest-blog-wrap .latest-blog-post .post-meta span:last-child {
  margin: 0;
}
.latest-blog-wrap .latest-blog-post .post-meta span > i {
  margin-right: 5px;
}
.latest-blog_active .owl-nav div {
  -webkit-transform: translate(0, -61px);
          transform: translate(0, -61px);
}
@media only screen and (max-width: 479px) {
  .latest-blog_active .owl-nav div {
    -webkit-transform: translate(20px, -119px);
            transform: translate(20px, -119px);
  }
}
/*FB's Customer Support | Home Style Four*/
.fb-customer_support-nav-4 {
  border: 1px solid #ededed;
}
/*----------------------------------------*/
/*  06. FB's Shop Page
/*----------------------------------------*/
.sidebar-title {
  border-bottom: 1px solid #e1e1e1;
}
.sidebar-title > h2 {
  position: relative;
  margin: 0;
  color: #ffffff;
  background: #e23e57;
  display: block;
  line-height: 25px;
  padding: 15px;
  text-transform: uppercase;
  font-size: 18px;
  font-weight: 600;
  margin: 0 0 -1px;
}
.sidebar-title-2 > h2 {
  font-size: 18px;
  color: #2c2c2c;
  font-weight: 600;
  text-transform: uppercase;
  margin-bottom: 27px !important;
}

@media (max-width: 991px) {
  .sidebar-title-2 > h2 {
    margin-bottom: 0 !important;
  }
  .sidebar-title-2:first-child {
    margin-top: 30px !important;
    margin-bottom: 0 !important;
  }
}
.shop-product-area .single-product {
  padding: 30px 20px 21px;
}
.sidebar-categories-box {
  background: #ffffff;
  border: 1px solid #e1e1e1;
  box-shadow: none;
  margin-bottom: 25px;
  padding: 22.1px 15px 8px;
}
.search-filter {
  background: transparent;
  border: none;
  padding: 0;
}
.search-filter > .sidebar-title {
  border-bottom: none;
}
.search-filter > .sidebar-title h2 {
  background: transparent;
  padding: 0 0 10px 0;
  color: #333333;
  font-size: 20px;
  border-bottom: 2px solid #ddd;
}
.btn-clear-all {
  background: #dddddd none repeat scroll 0 0;
  border-color: rgba(0, 0, 0, 0);
  box-shadow: 0.0625rem 0.0625rem 0.0625rem 0 rgba(0, 0, 0, 0.1);
  color: #333333;
  cursor: pointer;
  font-size: 14px;
  font-size: 0.875rem;
  line-height: 15px;
  padding: 5px;
  transition: all 0.3s ease-in-out 0s;
  margin-top: 15px;
}
.btn-clear-all::before {
  content: '\f00d';
  font-family: 'fontawesome';
  margin-right: 15px;
}
.btn-clear-all:hover {
  background: #e23e57 none repeat scroll 0 0;
  color: #ffffff;
}
.category-sub-menu ul li ul {
  display: none;
  margin-top: 16px;
}
.category-sub-menu > ul > li {
  padding: 10px 0;
  transition: all 0.3s ease-in-out;
}
.category-sub-menu .has-sub.open > a,
.category-sub-menu .has-sub.open > a:after {
  color: #e23e57;
}
.category-sub-menu ul li:hover a {
  color: #e23e57;
}
.category-sub-menu > ul > li:first-child {
  padding-top: 0;
}
.category-sub-menu li.has-sub > a {
  color: #2c2c2c;
  cursor: pointer;
  display: block;
  line-height: 20px;
  position: relative;
  font-size: 14px;
}
.category-sub-menu li.has-sub li a {
  border: 0 none;
  display: block;
  font-size: 14px;
  padding: 0 10px;
  color: #333;
}
.category-sub-menu li.has-sub li a:hover {
  color: #e23e57;
}
.category-sub-menu li.has-sub > a::after {
  color: #242424;
  content: '\f067';
  font-family: fontawesome;
  font-size: 12px;
  position: absolute;
  right: 5px;
}
.category-sub-menu li.has-sub.open > a::after {
  content: '\f068';
}
.category-sub-menu > ul > li ul > li:first-child {
  padding: 0 0 10px;
}
.category-sub-menu > ul > li ul > li {
  padding: 0 0 13px;
}
.sidebar-categories-box .filter-sub-area h5 {
  color: #2f333a;
  display: block;
  font-size: 14px;
  font-weight: 500;
  line-height: 1;
  margin: 30px 0 10px;
  padding: 0 0 10px;
  position: relative;
  text-transform: uppercase;
}
.price-checkbox li,
.size-checkbox li,
.color-categoriy ul li,
.sidebar-categories-checkbox ul li {
  margin: 0 0 14px;
}
.price-checkbox li a,
.size-checkbox li a,
.color-categoriy ul li a,
.sidebar-categories-checkbox ul li a,
.sidebar-categories-checkbox ul li label {
  color: #363f4d;
  font-size: 14px;
  margin-left: 15px;
  margin-top: 0;
  transition: all 0.3s ease-in-out;
  line-height: 1;
}
.sidebar-categories-checkbox ul li label {
  cursor: pointer !important;
}
.price-checkbox li a:hover,
.size-checkbox li a:hover,
.color-categoriy ul li a:hover,
.sidebar-categories-checkbox ul li a:hover {
  color: #e23e57;
}
.price-checkbox input,
.size-checkbox input,
.sidebar-categories-checkbox input {
  width: auto !important;
  height: auto !important;
}
.price-checkbox li a:hover {
  color: #e23e57;
}
.color-categoriy ul li span {
  box-shadow: -1px 1px 8px 0 rgba(0, 0, 0, 0.2);
  display: block;
  height: 15px;
  margin-top: 5px;
  width: 15px;
}
.color-categoriy ul li span.white {
  background: #ffffff;
}
.color-categoriy ul li span.black {
  background: #333;
}
.color-categoriy ul li span.Orange {
  background: #f39c11;
}
.color-categoriy ul li span.Blue {
  background: #5d9cec;
}
.color-categoriy li {
  display: flex;
}
.shop-top-bar {
  display: flex;
  justify-content: space-between;
}
.shop-bar-inner {
  display: flex;
}
@media only screen and (max-width: 575px) {
  .shop-bar-inner {
    float: left;
    width: 100%;
  }
}
@media only screen and (max-width: 479px) {
  .shop-bar-inner {
    margin-bottom: 10px;
  }
  .product-short .nice-select {
    margin-bottom: 5px;
  }
}
@media only screen and (max-width: 575px) {
  .product-select-box {
    padding-top: 10px;
  }
}
.shop-item-filter-list > li {
  background: #2c2c2c;
  width: 30px;
  height: 30px;
  line-height: 30px;
  text-align: center;
  margin-right: 5px;
  border-radius: 4px;
  transition: all 0.3s ease-in-out;
}
.shop-item-filter-list > li:hover {
  background: #e23e57;
}
.shop-item-filter-list > li a.active {
  background: #e23e57;
  border-radius: 4px;
}
.shop-item-filter-list > li > a {
  color: #ffffff;
  display: block;
  font-size: 14px;
}
.toolbar-amount {
  font-size: 14px;
  margin-left: 30px;
  margin-top: 5px;
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .toolbar-amount {
    margin-left: 5px;
    margin-top: 0;
  }
}
@media only screen and (max-width: 479px) {
  .toolbar-amount {
    margin-left: 10px;
  }
  .product-short > p {
    margin: 0 5px 0 0 !important;
  }
}
.product-short {
  display: flex;
  align-items: center;
}
.product-short > p {
  font-size: 14px;
  margin: 0 10px 0 0;
}
.single-banner img {
  width: 100%;
}
.product-short .nice-select {
  border-radius: 0;
  color: #242424;
  font-size: 14px;
  height: 30px;
  line-height: 30px;
  width: 295px;
  z-index: 1;
}
@media only screen and (max-width: 767px) {
  .product-short .nice-select {
    width: 180px;
  }
}
.product-short .nice-select .list {
  border-radius: 0px;
  width: 100%;
  z-index: 99;
}
.shoptopbar-heading > h2 {
  font-weight: 600;
  text-transform: uppercase;
  font-size: 22px;
  line-height: 1;
}
.shop-top-bar {
  padding: 15px;
  border: 1px solid #ededed;
  background: #ffffff;
}
@media only screen and (max-width: 767px) {
  .shop-top-bar {
    display: flex;
    flex-wrap: wrap;
  }
}
.product-short .nice-select .option {
  line-height: 30px;
  min-height: 30px;
}
.product-image > a img {
  width: 100%;
}
.shop-products-wrapper .tab-content .tab-pane.active {
  display: block;
  padding: 15px;
}
#list-view {
  padding: unset;
}
#list-view .owl-item {
  width: 100% !important;
}
.shop-products-wrapper .tab-content .tab-pane {
  display: none;
  height: auto;
}
.category-tags > ul > li {
  background: #fff;
  display: inline-block;
  padding: 2px 10px;
  border: 1px solid #ebebeb;
  transition: all 0.3s ease-in-out;
  margin: 0 2px 5px;
}
.category-tags > ul > li:first-child {
  margin-top: 30px;
}
.category-tags > ul > li:hover {
  background: #242424;
}
.category-tags > ul > li:hover a {
  color: #ffffff;
}
/*Shop List Vew*/
.product-content-list_view {
  padding: 0 20px 22px;
}
.product-content-list_view p {
  font-size: 14px;
  color: #666;
  line-height: 25px;
  padding: 0;
  margin: 15px 0 25px 0;
  display: block;
}
.product-content-list_view .price-box > .new-price {
  color: #2c2c2c;
  font-size: 24px;
  padding-right: 5px;
  font-weight: 600;
}
.product-content-list_view .product-action {
  position: relative;
  top: 0;
  left: 0;
}
.product-content-list_view .product-action li {
  -webkit-transform: scale(1);
          transform: scale(1);
  display: inline-block;
  margin-right: 10px;
}
/*----- pagination-area  css -----*/
.pagination-area {
  background: #ffffff none repeat scroll 0 0;
  font-size: 14px;
  font-weight: 400;
  /* margin: 30px 0 0; */
  padding: 24px 30px;
  border-bottom: 1px solid #ededed;
}
@media only screen and (max-width: 479px) {
  .pagination-area {
    margin: 0;
    padding-left: 22px;
  }
  .pagination-area p {
    line-height: 1 !important;
    margin-bottom: 30px !important;
  }
}
.pagination-area p {
  margin: 0;
  font-size: 13px;
  line-height: 30.4px;
}
.pagination-box {
  background: #ffffff;
  margin-bottom: 0;
  box-shadow: 2px 2px 4px 0 rgba(0, 0, 0, 0.2);
  text-align: center;
  padding: 0 12px;
}
.pagination-box > li {
  cursor: pointer;
  display: inline-block;
}
.pagination-box > li:first-child {
  float: left;
}
.pagination-box > li:last-child {
  float: right;
}
.pagination-box > li.active a {
  color: #e23e57;
}
.pagination-box > li a {
  color: #333333;
  display: block;
  padding: 0 10px;
}
@media only screen and (max-width: 479px) {
  .pagination-box > li a {
    padding: 0 5px;
  }
}
.pagination-box > li a:hover {
  color: #e23e57;
}
.pagination-box > li > a > i {
  font-size: 12px;
}
.product-layout-list .product_desc .product_desc_info .rating-box {
  border-bottom: medium none;
  padding-bottom: 0;
  text-align: left;
}
/*----------------------------------------*/
/* 07. FB's Frequently Asked Question(FAQ)
/*----------------------------------------*/
.frequently-desc {
  margin-bottom: 21px;
}
.frequently-desc h3 {
  color: #242424;
  font-size: 14px;
  font-weight: 400;
  text-transform: capitalize;
  line-height: 1;
}
.frequently-desc p {
  margin: 0;
  font-size: 13px;
}
.card-body {
  font-size: 13px;
}
.frequently-accordion .card.actives {
  border: 1px solid #e23e57;
}
.frequently-accordion .card {
  border-radius: 0;
  margin-bottom: 15px;
}
.frequently-accordion .card-header {
  padding: 0;
  margin-bottom: 0;
  background-color: #f5f5f5;
  border: 0;
}
.frequently-accordion .card-header a.collapsed:before,
.frequently-accordion .card-header a:after {
  content: '\f067';
  font-family: fontawesome;
  position: absolute;
  right: 15px;
  top: 15px;
  color: #b9b9b9;
}
.frequently-accordion .card-header a:after {
  content: '\f068 ';
}
.frequently-accordion .card-header a {
  display: block;
  padding: 15px 10px 15px 25px;
  cursor: pointer;
  font-size: 14px;
  font-weight: 400;
  text-transform: capitalize;
}
.frequently-accordion .card-header a:hover {
  color: #e23e57 !important;
}
/*----------------------------------------*/
/* 08. FB's Compare Page
/*----------------------------------------*/
.compare-table .table td,
.compare-table .table th {
  vertical-align: middle;
  text-align: center;
}
.compare-table .table thead th {
  border-width: 1px;
  padding: 15px 10px;
}
.compare-table .table-hover tbody tr {
  transition: all 0.3s ease-in-out;
}
.compare-table .table-hover tbody tr:hover {
  background-color: rgba(0, 0, 0, 0.02);
}
.compare-table .table tbody th {
  min-width: 200px;
}
.compare-table .table tbody td {
  min-width: 250px;
  font-size: 14px;
}
.compare-pdoduct-image {
  max-width: 200px;
  display: inline-block;
  padding: 20px 0;
}
.compare-pdoduct-image a {
  display: inline-block;
}
.compare-pdoduct-image .ho-button {
  margin-top: 25px;
}
h5.compare-product-name {
  font-size: 14px;
  font-weight: 700;
  margin-bottom: 0;
  color: #555;
}
h5.compare-product-name a {
  color: #555;
  transition-property: color;
}
h5.compare-product-name a:hover {
  color: #e23e57;
}
/*----------------------------------------*/
/* 09. FB's Login Register
/*----------------------------------------*/
.login-title {
  font-size: 20px;
  line-height: 23px;
  text-transform: capitalize;
  font-weight: 700;
  margin-bottom: 30px;
}
.login-form {
  background-color: #ffffff;
  padding: 30px;
  box-shadow: 0px 5px 4px 0px rgba(0, 0, 0, 0.1);
}
.login-form a {
  font-size: 14px;
}
@media (max-width: 991px) {
  .login-form a {
    font-size: 13px;
  }
}
.login-form label {
  display: block;
  font-size: 14px;
  margin-bottom: 12px;
  font-weight: 500;
  text-transform: capitalize;
}
.login-form .nice-select {
  width: 100%;
  background-color: transparent;
  border: 1px solid #999999;
  border-radius: 0;
  line-height: 23px;
  padding: 10px 20px;
  font-size: 14px;
  height: 45px;
  color: #7a7a7a;
  margin-bottom: 15px;
}
.login-form .nice-select::after {
  width: 6px;
  height: 6px;
  border-width: 1px;
  right: 20px;
  border-color: #7a7a7a;
}
.login-form .nice-select .current {
  color: #7a7a7a;
  display: block;
  line-height: 23px;
}
.login-form .nice-select .list {
  width: 100%;
}
.login-form input {
  width: 100%;
  background-color: transparent;
  border: 1px solid #999999;
  border-radius: 0;
  line-height: 23px;
  padding: 10px 20px;
  font-size: 14px;
  color: #7a7a7a;
  margin-bottom: 15px;
}
.login-form input[type='checkbox'] {
  width: auto;
}
.login-form .check-box {
  float: left;
}
.login-form .check-box:last-child {
  margin-right: 0;
}
.login-form .check-box input[type='checkbox'] {
  display: none;
}
.login-form .check-box input[type='checkbox'] + label {
  position: relative;
  padding-left: 30px;
  line-height: 20px;
  font-size: 14px;
  font-weight: 400;
  color: #363f4d;
  margin: 0;
}
.login-form .check-box input[type='checkbox'] + label::before {
  position: absolute;
  left: 0;
  top: 0;
  width: 20px;
  height: 20px;
  display: block;
  border: 2px solid #999999;
  content: '';
  transition: all 0.3s ease 0s;
}
.login-form .check-box input[type='checkbox'] + label::after {
  position: absolute;
  left: 0;
  top: 0;
  display: block;
  content: '\f00c';
  font-family: Fontawesome;
  font-size: 12px;
  line-height: 20px;
  opacity: 0;
  color: #363f4d;
  width: 20px;
  text-align: center;
  transition: all 0.3s ease 0s;
}
.login-form .check-box input[type='checkbox']:checked + label::before {
  border: 2px solid #363f4d;
}
.login-form .check-box input[type='checkbox']:checked + label::after {
  opacity: 1;
}
/*-- Place Order --*/
.register-button {
  display: block;
  margin-top: 40px;
  width: 140px;
  border-radius: 0;
  height: 36px;
  border: none;
  line-height: 24px;
  padding: 6px 20px;
  float: left;
  font-weight: 400;
  text-transform: uppercase;
  color: #ffffff;
  background-color: #363f4d;
  border-radius: 3px;
  transition: all 0.3s ease-in-out;
}
.register-button:hover {
  background: #e23e57;
}
/*----------------------------------------*/
/* 10. FB's Blog
/*----------------------------------------*/
.fb-sidebar-search-form .fb-search-field {
  width: calc(100% - 50px);
  border: 1px solid #ccc;
  padding: 0 10px;
  color: #666666;
  font-size: 14px;
  height: 50px;
  float: left;
  text-transform: capitalize;
}
.fb-sidebar-search-form .fb-search-field {
  width: calc(100% - 50px);
  border: 1px solid #e1e1e1;
  padding: 0 10px;
  color: #242424;
  font-size: 13px;
  height: 50px;
  float: left;
  text-transform: capitalize;
  background: #ffffff;
}
.fb-sidebar-search-form .fb-search-btn {
  width: 50px;
  height: 50px;
  margin-left: -4px;
  text-align: center;
  cursor: pointer;
  background-color: #232f3e;
  border: 1px solid #232f3e;
}
.fb-sidebar-search-form .fb-search-btn i {
  font-size: 20px;
  line-height: 50px;
  color: #ffffff;
  transition: all 0.3s ease-in-out;
}
.fb-sidebar-search-form .fb-search-btn:hover {
  background: #e23e57;
  border-color: #e23e57;
}
.fb-blog-sidebar .fb-blog-sidebar-title {
  color: #242424;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  text-transform: uppercase;
  position: relative;
  margin-bottom: 45px;
  font-family: Poppins;
}
.fb-blog-sidebar .fb-blog-sidebar-title::before {
  width: 55px;
  height: 2px;
  bottom: -26px;
  left: 0;
  background-color: #e23e57;
  content: '';
  position: absolute;
}
.fb-blog-sidebar .fb-blog-archive li {
  margin-bottom: 10px;
}
.fb-blog-sidebar .fb-blog-archive li:last-child {
  margin-bottom: 15px;
}
.fb-blog-sidebar .fb-blog-archive li > a {
  font-size: 14px;
}
.fb-blog-sidebar .fb-recent-post {
  display: flex;
}
.fb-blog-sidebar .fb-recent-post .fb-recent-post-thumb {
  width: 65px;
  border: 1px solid #e1e1e1;
  height: 100%;
}
.fb-blog-sidebar .fb-recent-post .fb-recent-post-des {
  padding-left: 10px;
}
.fb-blog-sidebar .fb-recent-post .fb-recent-post-des span {
  display: block;
  font-size: 13px;
  font-weight: 600;
  text-transform: capitalize;
}
.fb-blog-sidebar .fb-recent-post .fb-recent-post-des span a {
  color: #242424;
  font-weight: 400;
}
.fb-blog-sidebar .fb-recent-post .fb-recent-post-des span a:hover {
  color: #e23e57;
}
.fb-blog-sidebar .fb-recent-post .fb-recent-post-des span.fb-post-date {
  color: #242424;
  font-size: 13px;
  font-weight: 400;
}
.fb-blog-sidebar .fb-blog-tags li {
  display: inline-block;
  margin-bottom: 15px;
}
.fb-blog-sidebar .fb-blog-tags li a {
  color: #242424;
  font-size: 13px;
  border: 1px solid #e1e1e1;
  padding: 5px 15px;
  display: inline-block;
  text-transform: capitalize;
}
.fb-blog-sidebar .fb-blog-tags li a:hover {
  color: #ffffff;
  background-color: #e23e57;
  border-color: #e23e57;
}
.fb-blog-banner > a {
  display: block;
}
.fb-blog-single-item .fb-blog-content .fb-blog-details h3 {
  color: #242424;
  font-size: 22px;
  line-height: 30px;
  font-weight: 600;
  text-transform: capitalize;
  font-family: Poppins;
  padding-bottom: 7px;
  margin: 0;
}
.fb-blog-single-item .fb-blog-content .fb-blog-details .fb-blog-meta {
  padding: 5px 0 14px;
}
.fb-blog-content p {
  margin-bottom: 13px;
  font-size: 14px;
}
.fb-blog-single-item .fb-blog-content .fb-blog-details .fb-blog-meta a {
  color: #a4a4a4;
  font-size: 12px;
  text-transform: uppercase;
  position: relative;
  margin-right: 10px;
  transition: all 0.3s ease-in-out;
}
.fb-blog-single-item .fb-blog-content .fb-blog-details .fb-blog-meta a:hover {
  color: #e23e57;
}
.fb-blog-single-item .fb-blog-content .fb-blog-details .fb-blog-meta a i {
  font-size: 16px;
  vertical-align: middle;
  margin-right: 5px;
}
.fb-blog-single-item .fb-blog-content .fb-blog-details .fb-blog-meta p {
  font-size: 14px;
  font-weight: normal;
  line-height: 24px;
  color: #898989;
  margin-bottom: 10px;
}
.slick-dot-style ul.slick-dots {
  bottom: 20px;
  left: 50%;
  position: absolute;
  -webkit-transform: translate(-50%, 0);
  transform: translate(-50%, 0);
}
.slick-dot-style ul.slick-dots li {
  display: inline-block;
  margin: 0 5px;
}
.fb-blog-gallery-slider .slick-slide a {
  display: block;
}
.slick-dot-style ul.slick-dots li button {
  width: 12px;
  height: 12px;
  line-height: 12px;
  padding: 0;
  background-color: #ffffff;
  border: none;
  border-radius: 50%;
  display: block;
  text-indent: -5000px;
  cursor: pointer;
  transition: all 0.4s ease-out;
}
.slick-dot-style ul.slick-dots li.slick-active button {
  background-color: #e23e57;
  width: 30px;
  border-radius: 10px;
}
.slick-dot-style ul.slick-dots li button:hover {
  background-color: #e23e57;
}
.fb-pagination-area {
  border-top: 1px solid #e1e1e1;
}
.fb-pagination-area .fb-pagination-box li {
  display: inline-block;
}
.fb-pagination-area .fb-pagination-box li a {
  color: #242424;
  padding: 10px;
}
.fb-pagination-area .fb-pagination-box li.active a {
  color: #e23e57;
}
.fb-pagination-area .fb-pagination-box li a:hover {
  color: #e23e57;
}
/*BLog Details*/
.fb-blog-blockquote blockquote {
  border: 1px solid #e1e1e1;
  padding: 40px;
  position: relative;
  margin: 25px 0;
  font-style: italic;
  background: #f7f7f7;
}
.fb-blog-blockquote {
  padding: 0 0 0 40px;
}
.fb-blog-blockquote blockquote > p::before {
  content: '';
  background: #e23e57;
  position: absolute;
  width: 5px;
  height: 100%;
  top: 0;
  left: 0;
}
.fb-tag-line {
  padding: 25px 0 18px;
  margin-top: 20px;
  border-top: 1px solid #e1e1e1;
  border-bottom: 1px solid #e1e1e1;
}
.fb-tag-line h4 {
  color: #242424;
  font-size: 14px;
  font-weight: 600;
  text-transform: capitalize;
  display: inline-block;
  padding-right: 5px;
}
.fb-tag-line a {
  font-style: italic;
  text-transform: capitalize;
  color: #242424;
  transition: all 0.3s ease-in-out;
  font-size: 13px;
}
.fb-tag-line a:hover {
  color: #e23e57;
}
.fb-blog-sharing h4 {
  color: #242424;
  font-size: 14px;
  font-weight: 600;
  text-transform: capitalize;
  display: block;
  padding-bottom: 26px;
  margin-bottom: 0;
}
.fb-blog-sharing a {
  color: #ffffff;
  font-size: 16px;
  height: 36px;
  width: 36px;
  text-align: center;
  line-height: 36px;
  display: inline-block;
  margin-right: 5px;
  background-color: #232f3e;
  border: 1px solid #232f3e;
  border-radius: 8px;
}
.fb-blog-sharing a:hover {
  background-color: #e23e57;
  border-color: #e23e57;
}
.fb-comment-section h3 {
  color: #242424;
  font-size: 18px;
  font-weight: 600;
  padding-bottom: 25px;
  text-transform: uppercase;
  margin-bottom: 0;
}
.fb-comment-section ul li {
  display: flex;
  padding: 10px 20px;
  margin-bottom: 30px;
  border: 1px solid #e1e1e1;
}
.fb-comment-section ul li .author-avatar {
  flex-basis: 70px;
}
.fb-comment-section ul li .comment-body {
  flex-basis: 100%;
}
.fb-comment-section ul li .comment-body h5 {
  color: #242424;
  font-size: 14px;
  font-weight: 600;
  padding-bottom: 5px;
  text-transform: capitalize;
}
.comment-body p {
  font-size: 14px;
}
.fb-comment-section ul li .comment-body .comment-post-date {
  color: #888888;
  padding-bottom: 10px;
}
.fb-comment-section ul li .comment-body .reply-btn {
  float: right;
}
.fb-comment-section ul li .comment-body .reply-btn a {
  color: #e23e57;
  font-size: 12px;
  display: inline-block;
  padding: 3px 15px;
  border-radius: 20px;
  text-transform: capitalize;
  border: 1px solid #e23e57;
}
.fb-comment-section ul li .comment-body .reply-btn a:hover {
  color: #ffffff;
  background-color: #e23e57;
}
.fb-comment-section ul li.comment-children {
  margin-left: 40px;
}
.fb-blog-comment-wrapper h3 {
  color: #242424;
  font-size: 18px;
  font-weight: 600;
  padding: 0 0 26px;
  text-transform: uppercase;
  border-bottom: 1px solid #e1e1e1;
  margin-bottom: 0;
}
.fb-blog-comment-wrapper p {
  padding: 25px 0 3px;
  font-size: 14px;
}
.fb-blog-comment-wrapper .comment-post-box label {
  color: #242424;
  font-weight: 600;
  margin-bottom: 25px;
  display: block;
  text-transform: capitalize;
}
.fb-blog-comment-wrapper .comment-post-box textarea {
  height: 130px;
  margin-bottom: 20px;
  padding: 10px;
  width: 100%;
  background: #f8f8f8;
  border: none;
  font-size: 14px;
}
.fb-blog-comment-wrapper .comment-post-box .coment-field {
  background: #f8f8f8;
  border: none;
  color: #666666;
  padding: 8px 10px;
  width: 100%;
}
.fb-btn-2 {
  color: #ffffff;
  font-size: 16px;
  font-weight: 400;
  line-height: 40px;
  border: none;
  cursor: pointer;
  border-radius: 5px;
  background-color: #e23e57;
  padding: 0 20px;
  text-transform: uppercase;
  display: inline-block;
}
.fb-btn-2:focus {
  background: #242424;
}
.fb-btn-2:hover {
  color: #ffffff;
  background-color: #242424;
}
/*----------------------------------------*/
/* 11. FB's Error 404 Page
/*----------------------------------------*/
.search-error-wrapper h1 {
  color: #e23e57;
  font-size: 120px;
  line-height: 86px;
  margin-bottom: 60px;
}
@media only screen and (max-width: 767px) {
  .search-error-wrapper h1 {
    font-size: 70px;
    line-height: 50px;
    margin-bottom: 40px;
  }
}
.search-error-wrapper h2 {
  font-size: 28px;
}
@media only screen and (max-width: 767px) {
  .search-error-wrapper h2 {
    font-size: 24px;
  }
}
.error-form-input {
  position: relative;
  width: 355px;
  margin: 10px auto;
}
.Toastify__toast-body {
  font-family: 'Poppins', sans-serif !important;
  padding: 5px;
}
@media (max-width: 480px) {
  .Toastify__toast-container {
    padding: 10px !important;
  }
  .Toastify__toast {
    margin-bottom: 5px !important;
  }
}
@media (max-width: 575px) {
  #orderIdTable {
    table-layout: fixed;
  }
  #orderIdTable .fb-product-name {
    width: 250px;
  }
  #orderIdTable .fb-product-thumbnail {
    width: 150px;
  }
  #orderIdTable .fb-product-price {
    width: 120px;
  }
}
.error-form-input input {
  border: 1px solid #dddddd;
  font-size: 15px;
  padding: 5px 45px 6px 10px;
  position: relative;
  width: 275px;
  border-radius: 35px;
}
.error-form-input .error-s-button {
  background: rgba(0, 0, 0, 0) none repeat scroll 0 0;
  border: medium none;
  padding: 7px 15px;
  position: absolute;
  right: 0;
  top: 0;
}
.error-form-input .error-s-button:hover {
  color: #e23e57;
}
.search-error-wrapper .home-back-button {
  background: #e23e57;
  border-radius: 35px;
  box-shadow: none;
  color: #ffffff;
  display: inline-block;
  font-size: 12px;
  font-weight: bold;
  height: 40px;
  line-height: 40px;
  padding: 0 30px;
  text-transform: uppercase;
  transition: 0.4;
  border: unset !important;
}
.search-error-wrapper .home-back-button:hover {
  background: #333;
}
/*----------------------------------------*/
/* 12. FB's Product Details
/*----------------------------------------*/
.product-slider {
  margin-left: -15px;
  margin-right: -15px;
}
.pdetails-images {
  position: relative;
  cursor: pointer;
}
@media (max-width: 991px) {
  .fb-product-name {
    min-width: 215px;
  }
  .fb-product-name a {
    font-size: 13px;
  }
  ::-webkit-scrollbar {
    -webkit-appearance: none;
  }

  ::-webkit-scrollbar:vertical {
    width: 9px;
  }

  ::-webkit-scrollbar:horizontal {
    height: 12px;
  }

  ::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.5);
    border-radius: 10px;
    border: 2px solid #ffffff;
  }

  ::-webkit-scrollbar-track {
    border-radius: 10px;
    background-color: #ffffff;
  }
}
.slick-slide img {
  width: 100%;
}
.pdetails-images::before {
  content: '\f00e';
  font-family: fontawesome;
  position: absolute;
  z-index: 9;
  font-size: 40px;
  color: #e23e57;
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s ease-in-out;
  pointer-events: none;
  right: auto;
  top: 40%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}
.pdetails-images-vertical::before {
  top: 175px;
  left: 65%;
}
.pdetails-images:hover::before {
  opacity: 1;
  visibility: visible;
}
.product-slider .slick-list {
  padding-left: 15px;
  padding-right: 15px;
}
.product-slider .product-slider-col {
  margin-top: 7px;
  margin-bottom: 7px;
}
.pdetails-largeimages {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.07);
}
.pdetails-singleimage:before {
  transition: all 0.3s ease-in-out;
}
.pdetails-singleimage:hover::before {
  content: '';
  position: absolute;
  background: rgba(255, 255, 255, 0.2);
  position: absolute;
  width: 100%;
  height: 100%;
}
.pdetails-thumbs .slick-slide {
  margin: 10px;
}
.pdetails-singlethumb {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.07);
}
.pdetails-singlethumb img {
  height: 96px;
  width: 96px;
  object-fit: cover;
  -o-object-fit: cover;
}

@media (max-width: 991px) {
  .pdetails-singlethumb img {
    width: 160px;
  }
}

@media (max-width: 991px) {
  .pdetails-singlethumb img {
    height: 160px;
  }
}
.pdetails .sticker {
  top: 20px;
  left: 20px;
}
.product-details-view-content .review-item > a::before {
  content: '\f040';
  font-family: fontawesome;
  margin-right: 5px;
}
.pdetails .product-details-ref {
  font-size: 14px;
  color: #999;
}
@media (min-width: 991px) {
  .product-details-padding-section {
    padding-left: 30px !important;
  }
}
.pdetails .price-box > .new-price {
  color: #2c2c2c;
  font-size: 18px;
  padding-right: 5px;
  font-weight: 600;
  font-size: 30px;
  line-height: 1;
}
.product-details-view-content .product-desc {
  border-bottom: 1px solid #e1e1e1;
  margin-bottom: 25px;
}
.product-details-view-content .product-desc p {
  margin-bottom: 20px;
  font-size: 14px;
  color: #999;
}
.color-variation {
  float: left;
  width: 100%;
}
.color-variation ul li {
  position: relative;
}
.color-variation ul li:nth-child(2)::before {
  background: #f1c40f;
  left: 35px;
}
.color-variation ul li::before {
  content: '';
  border: 1px solid #ededed;
  position: absolute;
  top: 0;
  left: 0px;
  height: 18px;
  width: 18px;
  background: #ffffff;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.2);
  transition: all 0.3s ease-in-out;
}
.color-variation ul li:hover::before {
  border: 2px solid #242424;
}
.single-add-to-cart {
  float: left;
  width: 100%;
}
.product-details-view-content .cart-quantity a {
  color: #ffffff;
}
.product-availability {
  float: left;
  color: #999;
}
.product-availability::before {
  content: '\f00c';
  font-family: fontawesome;
  font-size: 18px;
  color: #4cbb6c;
  margin-right: 5px;
}
.product-not-availability {
  float: left;
  color: #999;
}
.product-not-availability::before {
  content: '\f00d';
  font-family: fontawesome;
  font-size: 18px;
  color: red;
  margin-right: 5px;
}

.footer-widget-social-link-2 {
  position: relative;
  margin: 15px 0 0;
  float: left;
  width: 100%;
}
.footer-widget-social-link-2 .social-link button {
  margin-right: 15px;
}
.footer-widget-social-link-2 > span {
  margin-right: 15px;
  color: #999;
  float: left;
}
.footer-widget-social-link-2 ul li a:hover {
  color: #e23e57;
}
.block-reassurance {
  float: left;
  width: 100%;
}
.block-reassurance > ul > li {
  border-bottom: 1px solid #e1e1e1;
}
.block-reassurance > ul > li:last-child {
  border-bottom: none;
}
.reassurance-item {
  padding: 15px 0;
}
.reassurance-item p {
  margin: 0;
  color: #999;
  font-size: 14px;
}
.reassurance-icon {
  float: left;
  padding-right: 10px;
}
.reassurance-icon i {
  font-size: 18px;
}
.nav.fb-product-menu > li {
  margin-right: 5px;
}
.nav.fb-product-menu > li a.active {
  background: #232f3e;
  color: #ffffff;
}
.nav.fb-product-menu > li > a {
  color: #242424;
  display: block;
  text-transform: uppercase;
  font-size: 18px;
  text-align: center;
  padding: 10px 20px;
}
.product-description,
.product-details-manufacturer,
.product-reviews {
  padding: 20px;
  background: #ffffff;
  border: 1px solid #ededed;
  color: #666666;
  font-size: 14px;
}
.product-details-manufacturer a img {
  padding: 0.25rem;
  background-color: #fff;
  border: 1px solid #e1e1e1;
  border-radius: 0;
  transition: all 0.2s ease-in-out;
  margin-bottom: 20px;
}
.pdetails-features ul li {
  background: #f1f1f1;
  margin-bottom: 20px;
  padding: 0 10px;
  font-size: 13px;
}
.pdetails-features > h3 {
  font-size: 15px;
  font-weight: 600;
  margin-bottom: 20px;
  line-height: 1;
}
.review-btn > a {
  background: #333333;
  color: #fff;
  padding: 5px 15px;
  font-size: 15px;
  transition: all 0.3s ease-in-out;
}
.review-btn > a:hover {
  background: #e23e57;
}
.product-details-manufacturer p {
  margin: 0;
}
.product-details-manufacturer p span {
  color: #232323;
  font-weight: 700;
}
.product-details-comment-block .comment-review span {
  display: inline-block;
  font-size: 14px;
  font-weight: 600;
  color: #6c6c6c;
}
.comment-author-infos span {
  color: #333;
  font-weight: 600;
}
.product-details-comment-block .comment-review .rating {
  display: inline-block;
  padding-left: 10px;
  font-size: 18px;
}
.comment-author-infos em {
  display: block;
  color: #adadad;
}
.comment-details {
  overflow: hidden;
  margin-top: 25px;
}
.comment-details h4 {
  font-weight: 600;
  text-transform: capitalize;
  font-size: 14px;
  color: #6c6c6c;
}
/* Begin Review Content*/
.review-content {
  position: relative;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  outline: 0;
}
.review-body {
  position: relative;
  padding: 15px;
}
#fb-comment-wrap {
  overflow: hidden;
  color: #333;
  text-align: left;
}
.review-page-title {
  padding: 10px;
  font-size: 13px;
  color: #ffffff;
  text-transform: uppercase;
  background: #333;
}
.fb-review-product {
  padding: 15px;
}
.fb-review-product img {
  border: 1px solid #ebebeb;
}
.fb-review-product-desc {
  line-height: 18px;
  color: #6f6f6f;
}
.fb-review-product-desc .fb-product-name {
  padding: 10px 0 5px;
  font-size: 13px;
  color: #000;
  font-weight: 700;
}
@media only screen and (max-width: 991px) {
  .fb-review-content {
    padding: 0 15px;
  }
}
.feedback-area .br-theme-fontawesome-stars .br-widget a {
  font-size: 14px;
}
.feedback-area .br-theme-fontawesome-stars .br-widget a.br-active:after {
  color: #fed700;
}
.feedback-area .br-theme-fontawesome-stars .br-widget a.br-selected::after {
  color: #fed700;
}
.feedback-area textarea,
.feedback-area input {
  background: #fff;
  border: 1px solid #ebebeb;
  font-size: 14px;
  width: 100%;
}
.feedback-btn {
  position: relative;
}
.feedback-btn .close,
.feedback-btn a {
  background: #242424;
  color: #fff !important;
  width: 80px;
  font-size: 14px;
  height: 30px;
  line-height: 30px;
  text-align: center;
  left: 110px;
  right: auto;
  top: 0;
  display: block;
  transition: all 0.3s ease-in-out;
}
.feedback-btn .close:hover,
.feedback-btn a:hover {
  background: #e23e57;
}
/* Review Content End Here*/
/* Begin Other Product*/
.other-product .fb-section_title-2 > h2 {
  background: #232f3e;
  color: #ffffff;
  margin: -1px 0;
  width: 350px;
  padding: 0 0 0 15px;
}
@media only screen and (max-width: 575px) {
  .other-product .fb-section_title-2 > h2 {
    width: 295px;
  }
}
@media only screen and (max-width: 479px) {
  .other-product .fb-section_title-2 > h2 {
    width: 235px;
  }
}
.other-product .fb-section_title-2 > h2:before {
  content: none;
}
.other-product .product-action li:hover {
  background: #e23e57;
}
.other-product .sticker-2 {
  background: #e23e57;
}
.fb-other-product_active .owl-nav div {
  -webkit-transform: translate(-15px, -41px);
          transform: translate(-15px, -41px);
}
@media only screen and (min-width: 480px) and (max-width: 991px) {
  .fb-other-product_active .owl-nav div {
    -webkit-transform: translate(-15px, -102px);
            transform: translate(-15px, -102px);
  }
}
@media only screen and (max-width: 479px) {
  .fb-other-product_active .owl-nav div {
    -webkit-transform: translate(0, -159px);
            transform: translate(0, -159px);
  }
}
/*Other Product End Here*/
/* Begin Product Details Vertical*/
.pdetails-images-vertical .pdetails-largeimages {
  max-width: 335px;
  float: right;
}
@media only screen and (max-width: 575px) {
  .pdetails-images-vertical .pdetails-largeimages {
    max-width: 100%;
    float: unset;
  }
}
@media only screen and (max-width: 767px) {
  .pdetails-images-vertical .pdetails-largeimages {
    max-width: 390px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pdetails-images-vertical .pdetails-largeimages {
    max-width: 570px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .pdetails-images-vertical .pdetails-largeimages {
    max-width: 250px;
  }
}
.pdetails-images-vertical .pdetails-thumbs {
  float: left;
  max-width: 110px;
  margin-top: -5px;
  margin-left: -5px;
}
@media only screen and (max-width: 575px) {
  .pdetails-images-vertical .pdetails-largeimages {
    max-width: 100%;
    float: unset;
  }
  .pdetails-images-vertical .pdetails-thumbs {
    float: unset;
    max-width: 100%;
  }
}
.pdetails-images-vertical .pdetails-thumbs .slick-list {
  padding: 0 !important;
}
.pdetails-images-vertical .pdetails-thumbs .slick-slide {
  margin: 15px 5px;
}
/*Product Details Vertical End Here*/
/*Product Details Gallery*/
.pdetails-imagesgallery::before {
  content: none;
}
.pdetails-images-vertical .pdetails-images::after,
.pdetails-imagesgallery::after {
  content: '';
  clear: both;
  display: table;
}
.pdetails-imagesgallery {
  margin-left: -15px;
  margin-top: -15px;
  margin-right: 16px;
}
.pdetails-imagesgallery .pdetails-singleimage {
  margin-left: 15px;
  margin-top: 15px;
  max-width: calc(50% - 17px);
  float: left;
  border: 1px solid #e5e5e5;
}
/* Begin Product Details Group*/
.pdetails-group-content .product-desc {
  border-bottom: none;
  margin-bottom: 0;
}
.pdetails-group-content .table-responsive > .table-bordered {
  margin-bottom: 30px;
}
/*Product Details Group End Here*/
/*Begin Product Details Slider*/
.pdetails-sliderimages {
  margin-left: -15px;
  margin-right: -15px;
}
.pdetails-sliderimages .slick-slide {
  padding: 0 15px;
}
.pdetails-slider .quantity {
  display: inline-block;
}
.pdetails-slider .cart-quantity label {
  display: inline-block;
  margin-right: 10px;
}
.pdetails-slider .cart-plus-minus {
  float: unset;
  display: inline-block;
}
.pdetails-slider .pdetails-images::before {
  content: none;
}
/*Product Details Slider End Here*/
/*----------------------------------------*/
/* 13. FB's Wishlist Page
/*----------------------------------------*/
.table-content table {
  background: #fff none repeat scroll 0 0;
  border-color: #ebebeb;
  border-radius: 0;
  border-style: solid;
  border-width: 1px 0 0 1px;
  text-align: center;
  width: 100%;
  margin-bottom: 0;
}
.table-content table th,
.table-content table td {
  border-bottom: 1px solid #ebebeb;
  border-right: 1px solid #ebebeb;
}
.table-content table th {
  border-top: medium none;
  font-size: 15px;
  text-transform: capitalize;
  padding: 20px 10px;
  text-align: center;
  font-weight: 500;
  vertical-align: middle;
  white-space: nowrap;
}
.table-content table td {
  border-top: medium none;
  padding: 20px 20px;
  vertical-align: middle;
  font-size: 13px;
}
.table-content table td.fb-product-remove {
  font-size: 20px;
}
.fb-product-remove > a > i {
  font-size: 14px;
  font-weight: normal;
  color: #242424;
  transition: all 0.3s ease-in-out;
}
.fb-product-remove > a:hover i {
  color: #e23e57;
}
.table-content table td.fb-product-name {
  font-size: 16px;
  font-weight: 500;
  text-transform: capitalize;
}
.table-content table td.fb-product-price {
  font-size: 16px;
  font-weight: 700;
}
.table-content table td.fb-product-stock-status span.in-stock,
.table-content table td.fb-product-stock-status span.out-stock {
  color: #e23e57;
  font-size: 12px;
  text-transform: capitalize;
}
.table-content table td.fb-product-stock-status span.out-stock {
  color: #ff0000;
}
.table-content table td.fb-product-add-cart a {
  font-size: 14px;
  text-transform: uppercase;
  background: #242424;
  color: #fff;
  padding: 10px 20px;
  font-weight: 700;
  display: inline-block;
}

.wishlistBtn {
  background-color: #232f3e !important;
}

.wishlistBtn:hover {
  background-color: rgb(226, 62, 87) !important;
}
@media only screen and (max-width: 767px) {
  .table-content table td.fb-product-add-cart a {
    font-size: 10px;
    padding: 7px 7px;
  }
}
.table-content table td.fb-product-add-cart a:hover {
  background: #e23e57 none repeat scroll 0 0;
  color: #fff;
}
/*----------------------------------------*/
/* 14. FB's Checkout Page
/*----------------------------------------*/
.coupon-accordion h3 {
  background-color: #f7f6f7;
  border-top: 3px solid #e23e57;
  color: #515151;
  font-size: 14px;
  font-weight: 400;
  list-style: outside none none;
  margin: 0 0 25px;
  padding: 16px 32px 16px 56px;
  position: relative;
  width: auto;
  text-transform: none;
}
.coupon-accordion h3::before {
  color: #e23e57;
  content: '\f133';
  display: inline-block;
  font-family: 'fontawesome';
  left: 24px;
  position: absolute;
  top: 16px;
}
.coupon-accordion span {
  color: #6f6f6f;
  cursor: pointer;
  transition: all 0.3s ease-in-out 0s;
  text-transform: none;
}
.coupon-accordion span:hover {
  color: #e23e57;
}
.coupon-content {
  border: 1px solid #e5e5e5;
  margin-bottom: 20px;
  display: none;
  padding: 20px;
}
.coupon-info p {
  margin-bottom: 0;
  font-size: 14px;
  color: #a4a4a4;
}
.coupon-info p.form-row-first label,
.coupon-info p.form-row-last label {
  display: block;
}
.coupon-info p.form-row-first label span.required,
.coupon-info p.form-row-last label span.required {
  color: red;
  font-weight: 700;
  font-size: 14px;
}
.coupon-info p.form-row-first input,
.coupon-info p.form-row-last input {
  border: 1px solid #e5e5e5;
  height: 36px;
  margin: 0 0 14px;
  border-radius: 0;
  max-width: 100%;
  padding: 0 0 0 10px;
  width: 370px;
  background-color: transparent;
  color: #a4a4a4;
}
.coupon-info p.form-row input[type='submit'] {
  background: #252525 none repeat scroll 0 0;
  border: medium none;
  border-radius: 0;
  box-shadow: none;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  float: left;
  font-size: 12px;
  height: 40px;
  line-height: 40px;
  margin-right: 15px;
  padding: 0 30px;
  text-shadow: none;
  text-transform: uppercase;
  transition: all 0.3s ease 0s;
  white-space: nowrap;
  width: inherit;
}
.coupon-info p.form-row input[type='submit']:hover,
p.checkout-coupon input[type='submit']:hover {
  background: #e23e57;
}
.form-row > label {
  margin-top: 7px;
}
.coupon-info p.form-row input[type='checkbox'] {
  height: inherit;
  position: relative;
  top: 2px;
  width: inherit;
}
p.lost-password {
  margin-top: 15px;
}
p.lost-password a {
  color: #6f6f6f;
}
p.lost-password a:hover {
  color: #e23e57;
}
.coupon-checkout-content {
  margin-bottom: 30px;
  display: none;
}
p.checkout-coupon input[type='text'] {
  background-color: transparent;
  border: 1px solid #ddd;
  border-radius: 0;
  height: 36px;
  padding-left: 10px;
  width: 170px;
  color: #a4a4a4;
}
p.checkout-coupon input[type='submit'] {
  background: #333 none repeat scroll 0 0;
  border: medium none;
  border-radius: 0;
  color: #fff;
  height: 36px;
  cursor: pointer;
  margin-left: 6px;
  transition: all 0.3s ease 0s;
  width: 110px;
  font-size: 13px;
  line-height: 36px;
}
.coupon-info p.form-row input[type='submit']:hover,
p.checkout-coupon input[type='submit']:hover {
  background: #e23e57;
}
.checkbox-form h3 {
  border-bottom: 1px solid #e5e5e5;
  font-size: 25px;
  margin: 0 0 5px;
  padding-bottom: 10px;
  text-transform: uppercase;
  width: 100%;
}
.country-select {
  margin-bottom: 20px;
}
.checkout-form-list {
  margin-bottom: 20px;
}
.country-select label,
.checkout-form-list label {
  color: #333;
  margin: 0 0 5px;
  display: block;
}
.country-select label span.required,
.checkout-form-list label span.required {
  color: #e23e57;
  display: inline-block;
  font-size: 24px;
  line-height: 16px;
  position: relative;
  top: 5px;
}
.checkout-form-list input[type='text'],
.checkout-form-list input[type='password'],
.checkout-form-list input[type='email'],
.checkout-form-list input[type='number'],
.checkout-form-list select {
  background: #fff none repeat scroll 0 0;
  border: 1px solid #e5e5e5;
  border-radius: 0;
  height: 42px;
  width: 100%;
  padding: 0 0 0 10px;
}
.checkout-form-list input[type='checkbox'] {
  display: inline-block;
  height: inherit;
  margin-right: 10px;
  position: relative;
  top: 2px;
  width: inherit;
}
.create-acc label {
  color: #333;
  display: inline-block;
}
.ship-different-title h3 label {
  display: inline-block;
  margin-right: 20px;
  font-size: 25px;
  color: #363636;
  margin-bottom: 4px;
}
.ship-different-title input {
  height: inherit;
  line-height: normal;
  margin: 4px 0 0;
  position: relative;
  top: 1px;
  width: auto;
}
.order-notes textarea {
  border: 1px solid #ddd;
  height: 90px;
  padding: 15px;
  width: 100%;
}
.create-account,
#ship-box-info {
  display: none;
}
.your-order {
  background: #f2f2f2 none repeat scroll 0 0;
  padding: 0 40px 45px;
}
@media (max-width: 991px) {
  .your-order {
    padding: 0;
  }
}
.your-order h3 {
  font-size: 25px;
  text-transform: uppercase;
  width: 100%;
}
@media (max-width: 991px) {
  .your-order h3 {
    font-size: 22px;
    margin-top: 30px;
  }
  .your-order .modify-order {
    margin-top: 30px;
  }
}
.your-order .border-bottom {
  border-bottom: 1px solid #d8d8d8 !important;
}
.your-order .modify-order {
  text-decoration: underline;
}
.your-order-table table {
  background: rgba(0, 0, 0, 0) none repeat scroll 0 0;
  border: medium none;
  width: 100%;
  margin-bottom: 0;
}
.cart-subtotal th,
.cart-subtotal td,
.cart-vat th,
.cart-vat td,
.order-total th,
.order-total td {
  padding: 10px 0 !important;
}
.order-total {
  margin-bottom: 30px;
}
.payment-methods img {
  width: 270px;
  margin-bottom: 20px;
}
thead {
  background-color: #f6f6f6;
}
.your-order-table table th {
  border-top: medium none;
  font-weight: normal;
  text-align: center;
  text-transform: uppercase;
  vertical-align: middle;
  white-space: nowrap;
  width: 250px;
}
.your-order-table table th,
.your-order-table table td {
  border-bottom: 1px solid #d8d8d8;
  border-right: medium none;
  font-size: 14px;
  font-family: 'Poppins', sans-serif;
  padding: 22px 0 22px;
  text-align: center;
}
.your-order-table table tr.cart_item:hover {
  background: #f9f9f9;
}
.your-order-table table .order-total td {
  border-bottom: medium none;
}
.your-order-table table tr.order-total td span {
  color: #464646;
  font-size: 20px;
}
.your-order-table table .order-total th {
  border-bottom: medium none;
  font-size: 18px;
}
.payment-accordion .card {
  border: 0;
  border-radius: 0;
  background: #f2f2f2;
}
.payment-accordion .card-header {
  padding: 0;
  margin-bottom: 0;
  background-color: #f2f2f2;
  border-bottom: 0;
}
.payment-accordion .card-body {
  padding: 0;
}
.payment-accordion .panel.panel-default {
  margin-bottom: 20px;
}
.payment-accordion h5.panel-title {
  color: #444;
  font-weight: 600;
  font-size: 18px;
  cursor: pointer;
  margin-bottom: 17px;
}
.payment-accordion h5.panel-title > a:hover {
  color: #e23e57 !important;
}
.payment-accordion .card-body > p {
  color: #515151;
}
.order-button-payment input {
  background: #434343 none repeat scroll 0 0;
  border: medium none;
  color: #fff;
  font-size: 17px;
  font-weight: 600;
  height: 50px;
  margin: 8px 0 0;
  padding: 0;
  text-transform: uppercase;
  transition: all 0.3s ease 0s;
  width: 100%;
  border: 1px solid transparent;
  cursor: pointer;
}
.order-button-payment input:hover {
  background: #e23e57 none repeat scroll 0 0;
}
/*----------------------------------------*/
/*  15. FB's Cart
/*----------------------------------------*/
.fb-product-quantity input {
  border: none;
  color: #222555;
  font-size: 14px;
  font-weight: normal;
  border: 1px solid #ededed;
  height: 40px;
  width: 55px;
}
.fb-product-quantity > input {
  width: 80px;
  border-radius: 3px;
}
.fb-product-quantity > input:focus {
  background: #eceff8;
}
.table-content table td.product-subtotal {
  font-size: 16px;
  font-weight: 700;
  font-family: 'Poppins', sans-serif;
  color: #414141;
}
.table-content table td .cart-plus-minus {
  margin: 0 auto;
  float: left;
}
.product-metric-quantity {
  float: left;
  height: 46px;
  border: 1px solid #e1e1e1;
  border-left: 0;
  display: flex;
  align-items: center;
}
.product-metric-quantity span {
  padding: 5px;
}
.coupon-all {
  margin-top: 30px;
}
.coupon {
  float: left;
}
.coupon input {
  background-color: transparent;
  border: 1px solid #ddd;
  color: #333;
  font-size: 13px;
  border-radius: 0;
  height: 42px;
  width: 120px;
  padding-left: 10px;
}
.coupon-all input.button {
  background-color: #333;
  border: 0 none;
  border-radius: 2px;
  color: #fff;
  display: inline-block;
  font-size: 13px;
  font-weight: 700;
  cursor: pointer;
  height: 42px;
  letter-spacing: 1px;
  line-height: 42px;
  padding: 0 25px;
  text-transform: uppercase;
  transition: all 0.3s ease-in-out 0s;
  width: inherit;
}
@media only screen and (max-width: 575px) {
  .coupon-all input.button {
    padding: 0 14px;
  }
}
@media only screen and (max-width: 479px) {
  .coupon2 {
    float: left !important;
    padding-top: 30px;
  }
}
.coupon-all input.button:hover {
  background: #e23e57;
}
.coupon2 {
  float: right;
}
.cart-page-total {
  padding-top: 30px;
}
.cart-page-total > h2 {
  font-size: 25px;
  font-weight: 700;
  margin-bottom: 20px;
  text-transform: capitalize;
}
.cart-page-total > ul {
  border: 1px solid #ddd;
}
.cart-page-total > ul > li {
  list-style: none;
  font-size: 15px;
  color: #252525;
  padding: 10px 30px;
  border-bottom: 1px solid #ebebeb;
  font-family: 'Poppins', sans-serif;
}
.cart-page-total ul > li > span {
  float: right;
}
.cart-page-total li:last-child {
  border-bottom: 0;
}
.cart-page-total > a {
  background-color: #333;
  border: 1px solid #333;
  color: #fff;
  display: inline-block;
  margin-top: 30px;
  padding: 10px 20px;
  text-transform: capitalize;
  transition: all 0.3s ease 0s;
}
.cart-page-total > a:hover {
  background: #e23e57;
  border-color: #e23e57;
}
/*----------------------------------------*/
/* 16 FB's Contact Page
/*----------------------------------------*/
#google-map {
  height: 400px;
}
h3.contact-page-title {
  font-size: 32px;
  line-height: 32px;
  font-weight: 500;
  margin-bottom: 20px;
}
@media only screen and (max-width: 479px) {
  h3.contact-page-title {
    font-size: 20px;
    margin-bottom: 10px;
  }
}
.single-contact-block {
  padding-bottom: 8px;
  margin-bottom: 15px;
  border-bottom: 1px solid #e1e1e1;
}
@media only screen and (max-width: 479px) {
  .single-contact-block {
    padding-bottom: 20px;
    margin-bottom: 20px;
  }
}
.single-contact-block > h4 {
  margin-bottom: 10px;
}
.single-contact-block.last-child {
  border-bottom: none;
}
span.required {
  color: #ff0000;
}
/*----------  Contact form  ----------*/
.contact-form .form-group {
  margin-bottom: 10px;
}
.contact-form label {
  display: block;
  font-size: 15px;
  line-height: 29px;
  font-weight: 400;
  color: #888888;
}
.contact-form input {
  background-color: #ffffff;
  width: 100%;
  height: 50px;
  line-height: 50px;
  outline: none;
  border: 1px solid #a4a4a4;
  padding-left: 20px;
  color: #a4a4a4;
}
.contact-form textarea {
  background-color: #ffffff;
  width: 100%;
  outline: none;
  border: 1px solid #e0e0e0;
  padding-left: 20px;
  line-height: 50px;
  height: 200px;
  color: #a4a4a4;
}
.contact-form .li-btn-3 {
  background: #f2f2f2;
  line-height: 12px;
  display: inline-block;
  padding: 16px 30px;
  border-radius: 3px;
  color: #363f4d;
  font-weight: 500;
  font-size: 12px;
  background-color: #242424;
  border: 1px solid #242424;
  color: #ffffff;
  text-transform: uppercase;
  transition: all 0.3s ease-in-out;
}
.contact-form .li-btn-3:hover {
  background-color: #e23e57;
  color: #ffffff;
  background-color: #e23e57;
  border-color: #e23e57;
}
.contact-page-side-content {
  background-color: #f2f2f2;
  padding: 0 30px 19px;
}
@media only screen and (max-width: 479px) {
  .contact-page-side-content {
    padding: 0 30px 10px;
  }
}
.contact-page-side-content p {
  font-size: 14px;
}
/*----------------------------------------*/
/* 17. FB's About Us
/*----------------------------------------*/
.about-content h2 {
  margin-bottom: 14px;
  margin-top: -8px;
}
.about-content h2 span {
  color: #e23e57;
}
.about-content p {
  margin-bottom: 20px;
  font-size: 15px;
}
.about-content .sf-button {
  margin-top: 20px;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .about-content h2 {
    margin-bottom: 20px;
  }
  .about-content p {
    margin-bottom: 15px;
  }
  .about-content .sf-button {
    margin-top: 15px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .about-content {
    padding-top: 40px;
  }
  .about-content h2 {
    margin-bottom: 20px;
  }
  .about-content p {
    margin-bottom: 15px;
  }
  .about-content .sf-button {
    margin-top: 15px;
  }
}
@media only screen and (max-width: 767px) {
  .about-content {
    padding-top: 40px;
  }
  .about-content h2 {
    margin-bottom: 20px;
  }
  .about-content p {
    margin-bottom: 15px;
  }
  .about-content .sf-button {
    margin-top: 15px;
  }
}
.about-thumb img {
  width: 100%;
}
/*Progress Bar*/
.fb-progress-wrap {
  margin-top: -30px;
}
.fb-progress {
  position: relative;
  margin-left: 30px;
  padding: 15px 0;
  margin-top: 30px;
}
.fb-progress .progress-bar-count {
  position: absolute;
  display: block;
  background: #222222;
  border-radius: 100%;
  height: 60px;
  width: 60px;
  left: 0;
  top: 50%;
  line-height: 60px;
  margin-top: -30px;
  margin-left: -30px;
  text-align: center;
  font-size: 14px;
  color: #ffffff;
  z-index: 1;
}
.fb-progress .progress {
  background: #f2f2f2;
  height: 30px;
  line-height: 30px;
  border-radius: 0;
}
.fb-progress .progress h6 {
  color: #ffffff;
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  font-size: 14px;
  margin-bottom: 0;
  padding-left: 45px;
}
.fb-progress .progress-bar {
  background: #e23e57;
  text-align: left;
  -webkit-transform: translateX(-100%);
  transform: translateX(-100%);
  transition: all 1s ease-out 0s;
}
.fb-progress .progress-bar.fill {
  -webkit-transform: translateX(0);
  transform: translateX(0);
}
/*Team Member*/
.single-team {
  overflow: hidden;
  position: relative;
}
@media only screen and (min-width: 576px) and (max-width: 991px) {
  .single-team {
    margin-bottom: 30px;
  }
}
@media only screen and (max-width: 575px) {
  .single-team {
    margin-bottom: 30px;
  }
}
.single-team-thumb {
  overflow: hidden;
}
.single-team-thumb img {
  width: 100%;
  -webkit-transform: scale(1);
  transform: scale(1);
  transition: all 0.4s cubic-bezier(0.63, 0.01, 0.36, 0.99) 0s;
}
.single-team-content {
  position: absolute;
  overflow: hidden;
  padding: 20px;
  text-align: center;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: transparent;
  transition: all 0.4s ease-in-out 0s;
}
.single-team-content h4 {
  margin-bottom: 8px;
  -webkit-transform: translateX(-40px);
  transform: translateX(-40px);
  opacity: 0;
  visibility: hidden;
  transition: all 0.4s ease-in-out 0s;
}
.single-team-content h6 {
  margin-bottom: 0;
  -webkit-transform: translateX(40px);
  transform: translateX(40px);
  opacity: 0;
  visibility: hidden;
  transition: all 0.4s ease-in-out 0s;
}
.single-team-socialicons {
  margin-top: 15px;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
  -webkit-transform: translateY(30px);
  transform: translateY(30px);
  opacity: 0;
  visibility: hidden;
  transition: all 0.4s ease-in-out 0s;
}
.single-team-socialicons li {
  list-style: none;
  display: inline-block;
}
.single-team-socialicons li:not(:last-child) {
  margin-right: 10px;
}
.single-team-socialicons li a {
  display: block;
  color: #353535;
  height: 32px;
  width: 32px;
  line-height: 1;
  border: 1px solid #353535;
  border-radius: 100px;
  text-align: center;
}
.single-team-socialicons li a i {
  line-height: 30px;
}
.single-team-socialicons li a:hover {
  background: #e23e57;
  color: #ffffff;
  border-color: #e23e57;
}
.single-team:hover {
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
}
.single-team:hover .single-team-content {
  background: rgba(255, 255, 255, 0.85);
}
.single-team:hover .single-team-content h4,
.single-team:hover .single-team-content h6 {
  -webkit-transform: translate(0);
  transform: translate(0);
  opacity: 1;
  visibility: visible;
}
.single-team:hover .single-team-socialicons {
  -webkit-transform: translate(0);
  transform: translate(0);
  opacity: 1;
  visibility: visible;
}
@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (max-width: 767px) {
  .single-team {
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  }
  .single-team-content {
    position: relative;
  }
  .single-team-content h4,
  .single-team-content h6 {
    -webkit-transform: translate(0);
    transform: translate(0);
    opacity: 1;
    visibility: visible;
  }
  .single-team-socialicons {
    -webkit-transform: translate(0);
    transform: translate(0);
    opacity: 1;
    visibility: visible;
  }
}

#owl-main .owl-next,
#owl-main .owl-prev {
  position: absolute;
  bottom: 50%;
  font-size: 30px;
  color: white;

  width: 35px;
  height: 35px;
  border-radius: 50%;
}

#owl-main .owl-next {
  right: 0;
}

#owl-main .owl-prev {
  left: 0;
}

#owl-main .owl-next:hover,
#owl-main .owl-prev:hover {
  background-color: rgba(226, 62, 87, 0.7);
}

#owl-main .owl-dots {
  position: absolute;
  bottom: 5%;
}

#owl-main .owl-dots .owl-dot {
  background: white;
}

#owl-main .owl-dots .active {
  background-color: rgba(226, 62, 87);
}

#owl-main .owl-dots .owl-dot span {
  display: none;
}

.map-marker {
  font-size: 35px;
  color: #e24057;
}

.form-group textarea {
  border: 1px solid #a4a4a4;
  outline: 0;
}

.dv-star-rating-star i {
  font-size: 25px;
}

p.your-opinion {
  display: grid;
}

button:disabled,
button[disabled] {
  cursor: not-allowed;
}

.reassurance-item {
  cursor: pointer;
}
.stars-table i {
  cursor: default;
}

.search-error-wrapper #nav {
  color: #e24057;
}

.search-error-wrapper .custom-header-message {
  font-size: 50px;
  margin-bottom: 0;
}

@media (max-width: 991px) {
  .search-error-wrapper .custom-header-message {
    font-size: 30px;
  }
}

.search-error-wrapper .custom-sub-header-message {
  font-size: 20px;
  margin-bottom: 0;
}

.css-zk88xw-singleValue {
  margin-left: 10px !important;
}

.capitalize-text {
  text-transform: capitalize;
}

.uppercase-text {
  text-transform: uppercase;
}

.fb-product-remove button {
  border: unset;
  background: transparent;
}

.product-carousel .owl-prev {
  top: 144px !important;
  right: 60px !important;
}

.product-carousel .owl-next {
  top: 144px !important;
  right: 20px !important;
}

.slick-dots {
  bottom: unset !important;
}
.slick-dots li {
  width: 100px !important;
  height: 100px !important;
}

.pdetails-images .slick-prev,
.pdetails-images .slick-next {
  display: none !important;
}

.fb-footer_widget-custom ul > li a::before {
  content: '';
}

.fb-footer_widget-custom h3.fb-footer-widget-headeing {
  position: relative;
  display: block;
  font-size: 16px;
  font-weight: 600;
  line-height: 34px;
  color: #333333;
  margin: 0 0 9px 0;
  text-transform: uppercase;
}

.special-product .new-price {
  color: #e23e57;
  font-size: 18px;
  padding-right: 5px;
  font-weight: 600;
}

.custom-modal-header {
  border-bottom: unset !important;
}

.close-text-modal {
  cursor: pointer;
}

@media (max-width: 1199px) {
  .list-product_content {
    padding: 30px 0;
  }
  .fb-banner.fb-img-hover-effect.special > a > img {
    height: 610px !important;
  }
}

@media (max-width: 991px) {
  .fb-nf-product-title.second-child {
    padding-top: 60px !important;
    padding-bottom: 0 !important;
  }
  .details-slick {
    display: none !important;
  }
  .product-content.list-product_content.special > h2 {
    width: 170px !important;
  }
  .list-product_img {
    width: 100% !important;
  }
  .fb-banner.fb-img-hover-effect.special > a > img {
    height: 600px !important;
  }
  .special .btn-group {
    display: none;
  }
}

@media (max-width: 767px) {
  .product-select-box {
    margin-top: 15px;
  }
  #list-view .owl-stage {
    width: 100% !important;
  }
  .fb-banner.fb-img-hover-effect.special > a > img {
    height: auto !important;
  }
  .contact-form label {
    margin-bottom: unset;
  }
  .hm-menu {
    justify-content: center;
  }
  .hm-minicart {
    padding-left: 30px;
    padding-right: 30px;
  }
}

@media (max-width: 575px) {
  .nav.fb-product-menu {
    display: block;
  }
  .dv-star-rating-star i {
    font-size: 22px;
  }
  .hm-minicart {
    padding-left: 0;
    padding-right: 0;
  }
}

@media (max-width: 500px) {
  .special-product .new-price {
    font-size: 15px;
  }
  .product-content.list-product_content.special > h2 {
    width: unset !important;
  }
}

@media only screen and (max-width: 575px) {
  .container {
    max-width: 500px;
  }
}

@media (max-width: 430px) {
  .mob-price {
    display: block !important;
    font-size: 13px;
    height: 10px;
    color: #999;
    font-weight: 400;
    text-decoration: line-through;
  }
  .price-box.special-product .old-price {
    display: none !important;
  }
  .fb-footer_widget h3.fb-footer-widget-headeing {
    font-size: 15px;
  }
  .fb-footer_widget ul > li a {
    font-size: 13px;
  }
  .hm-minicart {
    padding-right: 0 !important;
  }
}

.disabled-ion-bag {
  cursor: not-allowed;
}

.text-red {
  color: #e23e57;
  font-weight: bold;
}

input.autofill-field {
  color: rgb(164, 164, 164) !important;
}

.end-purchase-header {
  color: #e23e57;
  font-size: 30px !important;
  margin-bottom: 10px;
}

.stars-purchase i {
  font-size: 40px !important;
}

.custom-textarea {
  background-color: #ffffff;
  width: 100%;
  outline: none;
  border: 1px solid #e0e0e0;
  padding-left: 10px;
  line-height: 50px;
  height: 200px;
  color: #a4a4a4;
}

@media (max-width: 430px) {
  .error-form-input {
    width: 100% !important;
  }
  .end-purchase-header {
    font-size: 30px !important;
  }
}

#user-profile-table .loader {
  padding: 10px !important;
  margin: unset;
  width: unset;
}

.select .form-control {
  height: calc(2.694rem + 2px);
  border: 1px solid #999999;
  border-radius: unset;
}

#autocomplete_google_input {
  margin-bottom: 0 !important;
}

.form-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;
}

@media (max-width: 1270px) {
  .fb-navigation > ul > li > a {
    font-size: 13px !important;
  }
  .fb-navigation > ul > li {
    padding-right: 20px !important;
  }
}

@media (max-width: 450px) {
  #account {
    left: -25px !important;
  }
  .minicart,
  .subcategories-list {
    width: 280px;
  }
}

.App-map {
  height: 450px;
  width: 100%;
}

/* Slider */
.slick-slider
{
    position: relative;

    display: block;
    box-sizing: border-box;

    -webkit-user-select: none;
        -ms-user-select: none;
            user-select: none;

    -webkit-touch-callout: none;
    -khtml-user-select: none;
    touch-action: pan-y;
    -webkit-tap-highlight-color: transparent;
}

.slick-list
{
    position: relative;

    display: block;
    overflow: hidden;

    margin: 0;
    padding: 0;
}
.slick-list:focus
{
    outline: none;
}
.slick-list.dragging
{
    cursor: pointer;
    cursor: hand;
}

.slick-slider .slick-track,
.slick-slider .slick-list
{
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
}

.slick-track
{
    position: relative;
    top: 0;
    left: 0;

    display: block;
    margin-left: auto;
    margin-right: auto;
}
.slick-track:before,
.slick-track:after
{
    display: table;

    content: '';
}
.slick-track:after
{
    clear: both;
}
.slick-loading .slick-track
{
    visibility: hidden;
}

.slick-slide
{
    display: none;
    float: left;

    height: 100%;
    min-height: 1px;
}
[dir='rtl'] .slick-slide
{
    float: right;
}
.slick-slide img
{
    display: block;
}
.slick-slide.slick-loading img
{
    display: none;
}
.slick-slide.dragging img
{
    pointer-events: none;
}
.slick-initialized .slick-slide
{
    display: block;
}
.slick-loading .slick-slide
{
    visibility: hidden;
}
.slick-vertical .slick-slide
{
    display: block;

    height: auto;

    border: 1px solid transparent;
}
.slick-arrow.slick-hidden {
    display: none;
}

@charset 'UTF-8';
/* Slider */
.slick-loading .slick-list
{
    background: #fff url(/_next/static/media/ajax-loader.0b80f665.gif) center center no-repeat;
}

/* Icons */
@font-face
{
    font-family: 'slick';
    font-weight: normal;
    font-style: normal;

    src: url(/_next/static/media/slick.25572f22.eot);
    src: url(/_next/static/media/slick.25572f22.eot?#iefix) format('embedded-opentype'), url(/_next/static/media/slick.653a4cbb.woff) format('woff'), url(/_next/static/media/slick.6aa1ee46.ttf) format('truetype'), url(/_next/static/media/slick.f895cfdf.svg#slick) format('svg');
}
/* Arrows */
.slick-prev,
.slick-next
{
    font-size: 0;
    line-height: 0;

    position: absolute;
    top: 50%;

    display: block;

    width: 20px;
    height: 20px;
    padding: 0;
    -webkit-transform: translate(0, -50%);
    transform: translate(0, -50%);

    cursor: pointer;

    color: transparent;
    border: none;
    outline: none;
    background: transparent;
}
.slick-prev:hover,
.slick-prev:focus,
.slick-next:hover,
.slick-next:focus
{
    color: transparent;
    outline: none;
    background: transparent;
}
.slick-prev:hover:before,
.slick-prev:focus:before,
.slick-next:hover:before,
.slick-next:focus:before
{
    opacity: 1;
}
.slick-prev.slick-disabled:before,
.slick-next.slick-disabled:before
{
    opacity: .25;
}

.slick-prev:before,
.slick-next:before
{
    font-family: 'slick';
    font-size: 20px;
    line-height: 1;

    opacity: .75;
    color: white;

    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.slick-prev
{
    left: -25px;
}
[dir='rtl'] .slick-prev
{
    right: -25px;
    left: auto;
}
.slick-prev:before
{
    content: '←';
}
[dir='rtl'] .slick-prev:before
{
    content: '→';
}

.slick-next
{
    right: -25px;
}
[dir='rtl'] .slick-next
{
    right: auto;
    left: -25px;
}
.slick-next:before
{
    content: '→';
}
[dir='rtl'] .slick-next:before
{
    content: '←';
}

/* Dots */
.slick-dotted.slick-slider
{
    margin-bottom: 30px;
}

.slick-dots
{
    position: absolute;
    bottom: -25px;

    display: block;

    width: 100%;
    padding: 0;
    margin: 0;

    list-style: none;

    text-align: center;
}
.slick-dots li
{
    position: relative;

    display: inline-block;

    width: 20px;
    height: 20px;
    margin: 0 5px;
    padding: 0;

    cursor: pointer;
}
.slick-dots li button
{
    font-size: 0;
    line-height: 0;

    display: block;

    width: 20px;
    height: 20px;
    padding: 5px;

    cursor: pointer;

    color: transparent;
    border: 0;
    outline: none;
    background: transparent;
}
.slick-dots li button:hover,
.slick-dots li button:focus
{
    outline: none;
}
.slick-dots li button:hover:before,
.slick-dots li button:focus:before
{
    opacity: 1;
}
.slick-dots li button:before
{
    font-family: 'slick';
    font-size: 6px;
    line-height: 20px;

    position: absolute;
    top: 0;
    left: 0;

    width: 20px;
    height: 20px;

    content: '•';
    text-align: center;

    opacity: .25;
    color: black;

    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
.slick-dots li.slick-active button:before
{
    opacity: .75;
    color: black;
}

.nav-icon-container {
  position: relative;
}
.nav-icon-container > .fa {
  font-size: 20px;
  margin-right: 20px;
}

.nav-icon-container > .no-counting {
  font-size: 20px;
  margin-right: 10px;
}

.nav-icon-container > .item-count {
  position: absolute;
  top: 10px;
  left: 16px;
  background-color: var(--red);
  display: block;
  overflow: hidden;
  min-width: 20px;
  line-height: 20px;
  font-size: 9px;
  font-weight: 400;
  color: #ffffff;
  background: #e23e57;
  text-align: center;
  border-radius: 100%;
}

.icon-container {
  position: relative;
  width: 105px;
}

.icon-container > .fa {
  font-size: 30px;
  margin-bottom: 12px;
}

.icon-container > .item-count {
  position: absolute;
  top: 0;
  right: 18px;
  background-color: #e23e57;
  display: block;
  overflow: hidden;
  min-width: 20px;
  line-height: 20px;
  font-size: 9px;
  font-weight: 400;
  color: #ffffff;
  background: #e23e57;
  text-align: center;
  border-radius: 100%;
}

@media (max-width: 990px) {
  .nav-icon-container > .item-count {
    top: 0px;
    left: 38px;
  }
}

.ware-stage {
  -webkit-transform: none !important;
          transform: none !important;
  width: 100% !important;
}

.owl-carousel .ware-stage-outer {
  overflow: visible;
}

#ProductThumbs > .ware-stage-outer > .ware-stage > .owl-item {
  display: inline-grid;
  float: none;
}

.pagination.active {
  color: #e23e57 !important;
}

.product-content.list-product_content.special > h2 {
  font-size: small;
  word-break: break-all;
  width: 200px;
}

.fb-banner.fb-img-hover-effect.special > a > img {
  height: 518px;
}

.product-img.list-product_img > a {
  padding: 0px !important;
}

.email-notifier {
  background: #fff none repeat scroll 0 0;
  border: 1px solid #e5e5e5;
  border-radius: 0;
  height: 42px;
  padding: 0 0 0 10px;
  color: #a4a4a4;
}



/* Make clicks pass-through */
#nprogress {
  pointer-events: none;
}

#nprogress .bar {
  background: #e23e57;

  position: fixed;
  z-index: 9999;
  top: 0;
  left: 0;

  width: 100%;
  height: 2px;
}

/* Fancy blur effect */
#nprogress .peg {
  display: block;
  position: absolute;
  right: 0px;
  width: 100px;
  height: 100%;
  box-shadow: 0 0 10px #e23e57, 0 0 5px #e23e57;
  opacity: 1;

  -webkit-transform: rotate(3deg) translate(0px, -4px);
  transform: rotate(3deg) translate(0px, -4px);
}

/* Remove these to get rid of the spinner */
#nprogress .spinner {
  display: block;
  position: fixed;
  z-index: 1031;
  top: 15px;
  right: 15px;
}

#nprogress .spinner-icon {
  width: 18px;
  height: 18px;
  box-sizing: border-box;

  border: solid 2px transparent;
  border-top-color: #e23e57;
  border-left-color: #e23e57;
  border-radius: 50%;

  -webkit-animation: nprogress-spinner 400ms linear infinite;
  animation: nprogress-spinner 400ms linear infinite;
}

.nprogress-custom-parent {
  overflow: hidden;
  position: relative;
}

.nprogress-custom-parent #nprogress .spinner,
.nprogress-custom-parent #nprogress .bar {
  position: absolute;
}

@-webkit-keyframes nprogress-spinner {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes nprogress-spinner {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

body {
  margin: 0;
  padding: 0;
  /* font-family: sans-serif; */
}

.hm-searchbox .Dropdown-control {
  height: auto;
  border: none;
  color: #777;
}
.hm-searchbox .Dropdown-placeholder {
  line-height: 30px;
}
.hm-searchbox .select-search-category.nice-select::after {
  content: none;
}
.hm-searchbox .Dropdown-arrow {
  top: 20px;
  right: 25px;
}
.hm-searchbox .nice-select.select-search-category {
  line-height: 47px;
  height: 47px;
  padding: 0 20px 0 25px;
}
.hm-searchbox .Dropdown-control:before {
  content: '';
  position: absolute;
  right: 0px;
  height: 45%;
  background: rgba(0, 0, 0, 0.49);
  width: 1px;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}
.hm-searchbox .Dropdown-menu {
  left: 0;
  overflow-x: hidden;
  overflow-y: auto;
  border-radius: 4px;
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0 0 0 1px rgba(68, 68, 68, 0.11);
  border: none;
}
.hm-searchbox .Dropdown-option {
  padding: 0px 10px;
  color: #777;
  line-height: 40px;
}
.hm-searchbox .Dropdown-option:hover {
  background-color: #e23e57;
  color: #fff;
}

.hm-searchbox .Dropdown-option.is-selected {
  background-color: #e23e57;
  color: #fff;
  font-weight: 600;
}
.hm-searchbox .Dropdown-arrow {
  border-color: inherit;
  border-style: solid;
  border-width: inherit;
  width: 5px;
  border-bottom: 2px solid #000;
  border-right: 2px solid #000;
  height: 5px;
  -webkit-transform-origin: 66% 66%;
  transform-origin: 66% 66%;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  transition: all 0.15s ease-in-out;
}
.is-open .Dropdown-arrow {
  border-color: #000 !important;
  border-width: inherit !important;
  -webkit-transform: rotate(-135deg);
  transform: rotate(-135deg);
  border-bottom: 2px solid #000 !important;
  border-right: 2px solid #000 !important;
}

.new-product-collection .owl-nav div {
  -webkit-transform: translate(20px, -235px) !important;
          transform: translate(20px, -235px) !important;
}
.fb-nf-product-title > h2::before {
  content: ' ' !important;
}

div.modal.fade.modal-wrapper.show {
  display: block;
  background: rgba(37, 36, 36, 0.8);
}
@media (max-width: 767px) {
  div.modal.fade.modal-wrapper.show {
    overflow: scroll;
  }
}
.owl-carousel .owl-dots.disabled,
.owl-carousel .owl-nav.disabled {
  display: block !important;
}
.owl-prev {
  margin-right: 10px !important;
}
@media (max-width: 767px) {
  .billing-address-col {
    margin-top: 30px !important;
    justify-content: flex-start !important;
  }
  .order-id-form {
    padding: 10px;
  }
}
.owl-nav {
  position: absolute;
  top: 0;
  float: right;
  right: 0;
}

#owl-main .owl-nav {
  display: none !important;
}

.similarProducts .owl-next {
  margin-right: 10px !important;
}

.buttons-div button {
  font-weight: bold;
  font-size: 17px;
}

@media (max-width: 991px) {
  .similarProducts .owl-nav {
    top: -5px !important;
  }
}

.fb-new_product_active .owl-nav button {
  -webkit-transform: translate(0, -250px);
          transform: translate(0, -250px);
}

.buttons-div button {
  margin-top: 30px;
}

@media (max-width: 991px) {
  .fb-new_product_active .owl-nav button {
    -webkit-transform: translate(0, -280px);
            transform: translate(0, -280px);
  }
}

@media (max-width: 767px) {
  .fb-new_product_active .owl-nav button {
    -webkit-transform: translate(0, -223px);
            transform: translate(0, -223px);
  }
}

@media (max-width: 576px) {
  .fb-new_product_active .owl-nav button {
    -webkit-transform: translate(0, -211px);
            transform: translate(0, -211px);
  }
}

@media (max-width: 500px) {
  .fb-new_product_active .owl-nav button {
    -webkit-transform: translate(0, -206px);
            transform: translate(0, -206px);
  }
}

@media (max-width: 480px) {
  .fb-new_product_active .owl-nav button {
    -webkit-transform: translate(0, -200px);
            transform: translate(0, -200px);
  }
}

@media (max-width: 450px) {
  .fb-new_product_active .owl-nav button {
    -webkit-transform: translate(0, -191px);
            transform: translate(0, -191px);
  }
}

@media (max-width: 420px) {
  .fb-new_product_active .owl-nav button {
    -webkit-transform: translate(0, -181px);
            transform: translate(0, -181px);
  }
}

.owl-next,
.owl-prev {
  opacity: 1;
  top: 0;
  background: #2c2c2c !important;
  color: #ffffff !important;
  border-radius: 5px;
  height: 30px;
  line-height: 30px;
  width: 30px;
  -webkit-transform: translate(0, -46px);
          transform: translate(0, -46px);
}

.pagination :hover {
  color: #e23e57;
}

.slider-area:hover .owl-nav {
  display: block !important;
  background-color: red;
}

#owl-main .owl-next,
#owl-main .owl-prev {
  height: 38px;
  width: 38px;
  line-height: 0;
  font-size: 28px;
}

.custom-control-input:checked ~ .custom-control-label::before {
  background-color: #e23e57;
  border-color: #e23e57;
}

.react-pdf__Page__canvas {
  max-width: 200px !important;
  height: auto !important;
}

.float-right {
  float: right !important;
}

.product-metric-quantity-unit {
  position: absolute;
  margin-top: 45px !important;
}

.cart-border-unit {
  margin-top: 0 !important;
  margin-left: 27px !important;
  display: flex !important;
  align-items: center;
  justify-content: center;
  border: 1px solid #e1e1e1;
  flex: 0 0 40px;
  color: #242424 !important;
}

.modal .cart-plus-minus .qtybutton1 {
  left: 45px;
}

.modal .cart-plus-minus .relative-btn {
  left: 183px;
}

@media (max-width: 767px) {
  .flag-img {
    margin-left: 1.5rem;
    margin-right: 1.5rem;
  }
}

.data-sheet .name {
  background: #f1f1f1;
  margin-bottom: 20px;
  padding: 0 10px;
  font-size: 13px;
}

@media (max-width: 991px) {
  .data-sheet .name {
    margin-bottom: 0.7rem;
  }
  .product-details-manufacturer {
    padding: 15px;
  }
}

#card-element {
  height: 42px !important;
  border: 1px solid #e5e5e5 !important;
  border-radius: 0 !important;
}

.StripeElement {
  height: 100% !important;
  top: 6px !important;
  position: relative !important;
}

.select-options {
  background: #fff none repeat scroll 0 0;
  border: 1px solid #e5e5e5;
  border-radius: 0;
  height: 42px;
  padding: 5px 10px;
  color: #a4a4a4;
}

.input-div {
  width: 100%;
  background-color: #f3f3f3;
  border: 1px solid #999999;
  border-radius: 3px;
  line-height: 23px;
  padding: 10px 20px;
  font-size: 14px;
  color: #7a7a7a;
  margin-bottom: 15px;
  border: 1px solid #cfcdcd;
}

@media (max-width: 767px) {
  #profile-payments {
    display: block !important;
  }

  #profile-payments button {
    margin: 20px 0;
  }
  #profile-payments .input-div {
    margin-bottom: 0 !important;
  }
}

.slick-prev:before,
.slick-next:before {
  font-family: 'slick';
  font-size: 26px;
  line-height: 1;
  opacity: 0.85;
  color: #2c2c2c;
}

.home-gallery > .slick-dots > li {
  position: relative !important;
  display: inline-block !important;
  width: 20px !important;
  height: 20px !important;
  margin: 0 5px !important;
  padding: 0 !important;
  cursor: pointer !important;
}

.slider-with-banner .owl-dots {
  position: relative;
  bottom: unset;
}
.slider-with-banner .owl-nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  top: 60%;
  left: 10px;
  right: 10px;
  z-index: 10;
  height: 0;
  clear: both;
  float: unset;
}
.slider-with-banner .owl-nav > .owl-prev,
.owl-next {
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0.5;
}

.react-datepicker-wrapper {
  width: 100%;
}

.react-datepicker-wrapper input {
  background: #fff none repeat scroll 0 0;
  border: 1px solid #e5e5e5;
  border-radius: 0;
  height: 42px;
  padding: 5px 10px;
  color: #a4a4a4;
  width: 100%;
  width: 100%;
  padding: 0 0 0 10px;
  color: #a4a4a4;
}

.pac-container {
  z-index: 999999 !important;
}

