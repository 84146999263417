@import '~slick-carousel/slick/slick.css';
@import '~slick-carousel/slick/slick-theme.css';
@import './components/index.css';
@import './nprogess.css';

body {
  margin: 0;
  padding: 0;
  /* font-family: sans-serif; */
}

.hm-searchbox .Dropdown-control {
  height: auto;
  border: none;
  color: #777;
}
.hm-searchbox .Dropdown-placeholder {
  line-height: 30px;
}
.hm-searchbox .select-search-category.nice-select::after {
  content: none;
}
.hm-searchbox .Dropdown-arrow {
  top: 20px;
  right: 25px;
}
.hm-searchbox .nice-select.select-search-category {
  line-height: 47px;
  height: 47px;
  padding: 0 20px 0 25px;
}
.hm-searchbox .Dropdown-control:before {
  content: '';
  position: absolute;
  right: 0px;
  height: 45%;
  background: rgba(0, 0, 0, 0.49);
  width: 1px;
  top: 50%;
  transform: translateY(-50%);
}
.hm-searchbox .Dropdown-menu {
  left: 0;
  overflow-x: hidden;
  overflow-y: auto;
  border-radius: 4px;
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0 0 0 1px rgba(68, 68, 68, 0.11);
  border: none;
}
.hm-searchbox .Dropdown-option {
  padding: 0px 10px;
  color: #777;
  line-height: 40px;
}
.hm-searchbox .Dropdown-option:hover {
  background-color: #e23e57;
  color: #fff;
}

.hm-searchbox .Dropdown-option.is-selected {
  background-color: #e23e57;
  color: #fff;
  font-weight: 600;
}
.hm-searchbox .Dropdown-arrow {
  border-color: inherit;
  border-style: solid;
  border-width: inherit;
  width: 5px;
  border-bottom: 2px solid #000;
  border-right: 2px solid #000;
  height: 5px;
  -webkit-transform-origin: 66% 66%;
  -ms-transform-origin: 66% 66%;
  transform-origin: 66% 66%;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
  -webkit-transition: all 0.15s ease-in-out;
  transition: all 0.15s ease-in-out;
}
.is-open .Dropdown-arrow {
  border-color: #000 !important;
  border-width: inherit !important;
  -webkit-transform: rotate(-135deg);
  -ms-transform: rotate(-135deg);
  transform: rotate(-135deg);
  border-bottom: 2px solid #000 !important;
  border-right: 2px solid #000 !important;
}

.new-product-collection .owl-nav div {
  transform: translate(20px, -235px) !important;
}
.fb-nf-product-title > h2::before {
  content: ' ' !important;
}

div.modal.fade.modal-wrapper.show {
  display: block;
  background: rgba(37, 36, 36, 0.8);
}
@media (max-width: 767px) {
  div.modal.fade.modal-wrapper.show {
    overflow: scroll;
  }
}
.owl-carousel .owl-dots.disabled,
.owl-carousel .owl-nav.disabled {
  display: block !important;
}
.owl-prev {
  margin-right: 10px !important;
}
@media (max-width: 767px) {
  .billing-address-col {
    margin-top: 30px !important;
    justify-content: flex-start !important;
  }
  .order-id-form {
    padding: 10px;
  }
}
.owl-nav {
  position: absolute;
  top: 0;
  float: right;
  right: 0;
}

#owl-main .owl-nav {
  display: none !important;
}

.similarProducts .owl-next {
  margin-right: 10px !important;
}

.buttons-div button {
  font-weight: bold;
  font-size: 17px;
}

@media (max-width: 991px) {
  .similarProducts .owl-nav {
    top: -5px !important;
  }
}

.fb-new_product_active .owl-nav button {
  transform: translate(0, -250px);
}

.buttons-div button {
  margin-top: 30px;
}

@media (max-width: 991px) {
  .fb-new_product_active .owl-nav button {
    transform: translate(0, -280px);
  }
}

@media (max-width: 767px) {
  .fb-new_product_active .owl-nav button {
    transform: translate(0, -223px);
  }
}

@media (max-width: 576px) {
  .fb-new_product_active .owl-nav button {
    transform: translate(0, -211px);
  }
}

@media (max-width: 500px) {
  .fb-new_product_active .owl-nav button {
    transform: translate(0, -206px);
  }
}

@media (max-width: 480px) {
  .fb-new_product_active .owl-nav button {
    transform: translate(0, -200px);
  }
}

@media (max-width: 450px) {
  .fb-new_product_active .owl-nav button {
    transform: translate(0, -191px);
  }
}

@media (max-width: 420px) {
  .fb-new_product_active .owl-nav button {
    transform: translate(0, -181px);
  }
}

.owl-next,
.owl-prev {
  opacity: 1;
  top: 0;
  background: #2c2c2c !important;
  color: #ffffff !important;
  border-radius: 5px;
  height: 30px;
  line-height: 30px;
  width: 30px;
  transform: translate(0, -46px);
}

.pagination :hover {
  color: #e23e57;
}

.slider-area:hover .owl-nav {
  display: block !important;
  background-color: red;
}

#owl-main .owl-next,
#owl-main .owl-prev {
  height: 38px;
  width: 38px;
  line-height: 0;
  font-size: 28px;
}

.custom-control-input:checked ~ .custom-control-label::before {
  background-color: #e23e57;
  border-color: #e23e57;
}

.react-pdf__Page__canvas {
  max-width: 200px !important;
  height: auto !important;
}

.float-right {
  float: right !important;
}

.product-metric-quantity-unit {
  position: absolute;
  margin-top: 45px !important;
}

.cart-border-unit {
  margin-top: 0 !important;
  margin-left: 27px !important;
  display: flex !important;
  align-items: center;
  justify-content: center;
  border: 1px solid #e1e1e1;
  flex: 0 0 40px;
  color: #242424 !important;
}

.modal .cart-plus-minus .qtybutton1 {
  left: 45px;
}

.modal .cart-plus-minus .relative-btn {
  left: 183px;
}

@media (max-width: 767px) {
  .flag-img {
    margin-left: 1.5rem;
    margin-right: 1.5rem;
  }
}

.data-sheet .name {
  background: #f1f1f1;
  margin-bottom: 20px;
  padding: 0 10px;
  font-size: 13px;
}

@media (max-width: 991px) {
  .data-sheet .name {
    margin-bottom: 0.7rem;
  }
  .product-details-manufacturer {
    padding: 15px;
  }
}

#card-element {
  height: 42px !important;
  border: 1px solid #e5e5e5 !important;
  border-radius: 0 !important;
}

.StripeElement {
  height: 100% !important;
  top: 6px !important;
  position: relative !important;
}

.select-options {
  background: #fff none repeat scroll 0 0;
  border: 1px solid #e5e5e5;
  border-radius: 0;
  height: 42px;
  padding: 5px 10px;
  color: #a4a4a4;
}

.input-div {
  width: 100%;
  background-color: #f3f3f3;
  border: 1px solid #999999;
  border-radius: 3px;
  line-height: 23px;
  padding: 10px 20px;
  font-size: 14px;
  color: #7a7a7a;
  margin-bottom: 15px;
  border: 1px solid #cfcdcd;
}

@media (max-width: 767px) {
  #profile-payments {
    display: block !important;
  }

  #profile-payments button {
    margin: 20px 0;
  }
  #profile-payments .input-div {
    margin-bottom: 0 !important;
  }
}

.slick-prev:before,
.slick-next:before {
  font-family: 'slick';
  font-size: 26px;
  line-height: 1;
  opacity: 0.85;
  color: #2c2c2c;
}

.home-gallery > .slick-dots > li {
  position: relative !important;
  display: inline-block !important;
  width: 20px !important;
  height: 20px !important;
  margin: 0 5px !important;
  padding: 0 !important;
  cursor: pointer !important;
}

.slider-with-banner .owl-dots {
  position: relative;
  bottom: unset;
}
.slider-with-banner .owl-nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  top: 60%;
  left: 10px;
  right: 10px;
  z-index: 10;
  height: 0;
  clear: both;
  float: unset;
}
.slider-with-banner .owl-nav > .owl-prev,
.owl-next {
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0.5;
}

.react-datepicker-wrapper {
  width: 100%;
}

.react-datepicker-wrapper input {
  background: #fff none repeat scroll 0 0;
  border: 1px solid #e5e5e5;
  border-radius: 0;
  height: 42px;
  padding: 5px 10px;
  color: #a4a4a4;
  width: 100%;
  width: 100%;
  padding: 0 0 0 10px;
  color: #a4a4a4;
}

.pac-container {
  z-index: 999999 !important;
}
