.ware-stage {
  transform: none !important;
  width: 100% !important;
}

.owl-carousel .ware-stage-outer {
  overflow: visible;
}

#ProductThumbs > .ware-stage-outer > .ware-stage > .owl-item {
  display: inline-grid;
  float: none;
}

.pagination.active {
  color: #e23e57 !important;
}

.product-content.list-product_content.special > h2 {
  font-size: small;
  word-break: break-all;
  width: 200px;
}

.fb-banner.fb-img-hover-effect.special > a > img {
  height: 518px;
}

.product-img.list-product_img > a {
  padding: 0px !important;
}
