.nav-icon-container {
  position: relative;
}
.nav-icon-container > .fa {
  font-size: 20px;
  margin-right: 20px;
}

.nav-icon-container > .no-counting {
  font-size: 20px;
  margin-right: 10px;
}

.nav-icon-container > .item-count {
  position: absolute;
  top: 10px;
  left: 16px;
  background-color: var(--red);
  display: block;
  overflow: hidden;
  min-width: 20px;
  line-height: 20px;
  font-size: 9px;
  font-weight: 400;
  color: #ffffff;
  background: #e23e57;
  text-align: center;
  border-radius: 100%;
}

.icon-container {
  position: relative;
  width: 105px;
}

.icon-container > .fa {
  font-size: 30px;
  margin-bottom: 12px;
}

.icon-container > .item-count {
  position: absolute;
  top: 0;
  right: 18px;
  background-color: #e23e57;
  display: block;
  overflow: hidden;
  min-width: 20px;
  line-height: 20px;
  font-size: 9px;
  font-weight: 400;
  color: #ffffff;
  background: #e23e57;
  text-align: center;
  border-radius: 100%;
}

@media (max-width: 990px) {
  .nav-icon-container > .item-count {
    top: 0px;
    left: 38px;
  }
}
